import { ApiCommunications } from "../../api/api-communications";
import { NullPromise } from "../../null-promise";
import { ItemReference } from "../../cache/definitions/cache-item-reference";
import { DataCacheBase } from "../../cache/data-cache-base";
import {
    PaymentProfile,
    RequestGetClientDefaultPaymentProfileListByIds,
    ResultGetClientDefaultPaymentProfileListByIds
} from "../../api/dealer-api-interface-franchisee";

export class PaymentProfileCache extends DataCacheBase<PaymentProfile>{
    constructor(api: ApiCommunications) {
        super(api);
        //depending on what high level controller api we create we could swap this to ClientApi etc.
    }

    //override;
    protected async internalFetch(requestIds: string[]): NullPromise<ItemReference<PaymentProfile>[]> {
        if (requestIds.length == 0)
            return null;

        const input: RequestGetClientDefaultPaymentProfileListByIds = {
            paymentProfileIds: requestIds
        };
        const result = await this.api.post<ResultGetClientDefaultPaymentProfileListByIds>(`api/Franchisee/GetClientDefaultPaymentProfileListByIds`, input);
        if (!result) return null;

        return result.profiles.map(paymentProfile => {
            const qcr: ItemReference<PaymentProfile> = {
                id: paymentProfile.id,
                displayValue: `${paymentProfile.name}`,
                data: paymentProfile,
                onClick: null
            };
            return qcr;
        });
    }
}

