/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BlobApi } from "../../../api/blob-api";
import { BranchQuote } from "../../../api/dealer-api-interface-franchisee";
import { Quote, QuotePresentation, QuotePrice, QuoteState } from "../../../api/dealer-api-interface-quote";
import { FranchiseeApi } from "../../../api/franchisee-api";
import { emptyGuid } from "../../../api/guid";
import { QuoteApi } from "../../../api/quote-api";
import { objectToBase64 } from "../../../blob/converters";
import { clone, compare } from "../../../components/clone";
import { localDateTimeToServer, localDateToServer, today } from "../../../components/datetime-converter";

import { tlang } from "../../../language/lang";
import { NullPromise } from "../../../null-promise";
import { QuoteContainer, QuoteContainerManager } from "../../../quotes/data/quote-container";
import { fireQuickSuccessToast } from "../../../toast-away";
import { DevelopmentError } from "../../../development-error";
import { userDataStore } from "../../common/current-user-data-store";
import { FranchiseeQuoteContainer } from "./franchisee-quote-container";
import { cache } from "../../cache/cache-registry";

export class FranchiseeQuoteContainerManager extends QuoteContainerManager {
    franchiseeApi: FranchiseeApi;

    constructor(original: QuoteContainer, quoteApi: QuoteApi, blobApi: BlobApi, franchiseeApi: FranchiseeApi) {
        super(original, quoteApi, blobApi);
        if (!(original instanceof FranchiseeQuoteContainer)) throw new DevelopmentError("FranchiseeQuoteContainerManager only supports FranchiseeQuoteContainer");
        this.franchiseeApi = franchiseeApi;
    }

    get franchiseeContainer(): FranchiseeQuoteContainer {
        return this.container as FranchiseeQuoteContainer;
    }

    get franchiseeBackup(): FranchiseeQuoteContainer {
        return this.backup as FranchiseeQuoteContainer;
    }

    get branchQuote(): BranchQuote {
        if (!this.franchiseeContainer.branchQuote) {
            throw new DevelopmentError("BranchQuote is null"); //no waiting
        }
        return this.franchiseeContainer.branchQuote;
    }

    protected flushCaches() {
        cache().quote.flush([this.quoteId]);
        cache().projectResourceLink.flush([this.quoteId]);
    }

    async fetchCache() {
        await Promise.allSettled([
            cache().quote.preFetch([this.quoteId]),
            cache().projectResourceLink.preFetch([this.quoteId])
        ]);
    }

    async createBranchQuote(id: string) {
        await userDataStore.loadCoreDetails();
        if (!userDataStore.defaultBranch) throw new Error(tlang`Current User does not have a %%branch%%.`);
        const branchQuote: BranchQuote = {
            branchId: userDataStore.defaultBranch.id,
            //Use the exact same id for the branch quote. makes it easy to extend
            id: id,
            clientId: emptyGuid,
            clientName: "",
            clientTypeId: emptyGuid,
            contactId: emptyGuid,
            clientTypeName: "",
            contactName: "",
            dateCreated: localDateTimeToServer(new Date()),
            recordVersion: "",
        };

        //if there is any reason we can't create this, we wont worry about it now, if we already have the quote
        //created. we will just attempt to create it later. if it cant be done later, it is probably
        //a system failure.
        const branchQuoteResult = await this.franchiseeApi.createBranchQuote({ branchQuote: branchQuote });
        if (!branchQuoteResult) return null;
        return branchQuoteResult.branchQuote;
    }

    async needsQuote(): Promise<boolean> {
        if (!this.container.quote) {
            const result = await this.api.getQuote({ quoteId: this.quoteId });
            let branchQuote = (await this.franchiseeApi.getBranchQuotes({ branchQuoteIds: [this.quoteId] }))?.branchQuotes[0] ?? null;
            //we will build a new branch quote on the fly.
            if (!branchQuote) branchQuote = await this.createBranchQuote(this.quoteId);

            if (result && branchQuote) {
                await this.resetBranchQuote(result.quote, result.quotePrice, result.quotePresentation, branchQuote);
                await this.fetchCache();
            } else return false;
        }
        return true;
    }

    isReadonly(): boolean {
        return !(this.quote.state == QuoteState.Draft || this.quote.state == QuoteState.Active);
    }

    async saveQuote(silently?: boolean): Promise<boolean> {
        this.quote.serviceProviderData = objectToBase64(this.container.quoteProviderData);

        this.flushCaches();
        const result = await this.api.updateQuote({
            quote: this.quote,
            quotePrice: this.quotePrice,
            numberSeed: this.numberSeed
        });
        const result1 = await this.franchiseeApi.updateBranchQuote({ branchQuote: this.branchQuote });
        if (result) {
            if (result1)
                await this.resetBranchQuote(result.quote!, result.quotePrice!, null, result1.branchQuote!);
            else
                await this.resetQuote(result.quote!, result.quotePrice!, null);
            await this.fetchCache();
            this.container.itemPrices = result.quoteItemPrices;
            this.backup.itemPrices = this.clone(result.quoteItemPrices);
            if (result1 && result) {
                await this.doAfterSave();
                if (!silently)
                    fireQuickSuccessToast(tlang`%%quote%% Saved "${this.quoteTitle}"`);
                return true;
            }
        }
        return false;
    }

    quoteChanged(): boolean {
        return super.quoteChanged() || !compare(this.franchiseeBackup.branchQuote, this.franchiseeContainer.branchQuote);
    }

    public async makeCopy(asAlternate = false): NullPromise<QuoteContainer> {
        await this.needsQuote();
        if (!this.branchQuote || !this.quote) return null;

        const newBranchQuote = clone(this.branchQuote);
        if (!asAlternate) {
            newBranchQuote.clientId = emptyGuid;
            newBranchQuote.clientName = "";
            newBranchQuote.contactId = emptyGuid;
            newBranchQuote.contactName = "";
        }
        newBranchQuote.dateCreated = localDateTimeToServer(new Date());

        var franchiseeConfiguraiton = await this.franchiseeApi.getFranchiseeConfiguration();
        const defaultValidity = franchiseeConfiguraiton?.franchiseeConfiguration.quoteValidity ?? 28;

        const quoteResult = await this.api.duplicateQuote({
            quoteId: this.quote.id,
            asAlternate: asAlternate,
            validUntilDate: this.quote.validUntilDate ?? localDateToServer(today(defaultValidity)),
            numberSeed: asAlternate ? this.quote.quoteOwnerId : null,
            quoteCustomerId: asAlternate ? this.quote.quoteCustomerId : null,
            title: asAlternate ? `${this.quote.title} - ${tlang`copy`}` : null,
            quoteState: asAlternate ? QuoteState.Active : null
        });
        if (quoteResult) {
            newBranchQuote.id = quoteResult.quote.id;
            const branchQuoteResult = await this.franchiseeApi.createBranchQuote({ branchQuote: newBranchQuote });
            if (!branchQuoteResult) {
                await this.api.deleteQuote({ quoteId: quoteResult.quote.id });
                return null;
            }

            return new FranchiseeQuoteContainer(
                quoteResult.quote.id,
                quoteResult.quote,
                quoteResult.quotePrice,
                quoteResult.quotePresentation, //QUOTE PRESENTATION SHOULD BE HERE
                null,
                null,
                null,
                branchQuoteResult.branchQuote);
        }

        return null;

    }

    public async makeAlternative(): NullPromise<QuoteContainer> {
        return await this.makeCopy(true);
    }

    public async deleteQuote(): Promise<boolean> {
        await this.needsQuote();
        await Promise.all([
            this.api.deleteQuote({ quoteId: this.quote.id }),
            this.franchiseeApi.deleteBranchQuote({ branchQuoteId: this.quote.id, })]);

        return true;
    }

    protected async resetBranchQuote(quote: Quote, quotePrice: QuotePrice, quotePresentation: QuotePresentation | null, branchQuote: BranchQuote) {
        await this.resetQuote(quote, quotePrice, quotePresentation);
        this.franchiseeContainer.branchQuote = branchQuote;
        this.franchiseeBackup.branchQuote = this.clone(branchQuote);
    }
}