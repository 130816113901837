import { html, TemplateResult } from "lit";
import { PromiseSnippet, PromiseTemplate } from "../../components/ui/events";
import { ModalDialog } from "../../components/ui/modal-base";
import { tlang } from "../../language/lang";
import { V6FrameData } from "./v6-quote-item-data";

export class V6NestedFrameModal extends ModalDialog {
    clickedFrame: () => V6FrameData | null;
    attributesBuilder: (fullRebuild: boolean) => Promise<TemplateResult[]>;
    svgProvider: (frame: V6FrameData) => TemplateResult;
    needsRebuild = true;
    attributes: TemplateResult[] = [];

    constructor(
        clickedFrame: () => V6FrameData | null,
        attributesBuilder: (fullRebuild: boolean) => Promise<TemplateResult[]>,
        svgProvider: (frame: V6FrameData) => TemplateResult

    ) {
        super();
        this.clickedFrame = clickedFrame;
        this.attributesBuilder = attributesBuilder;
        this.svgProvider = svgProvider;
    }
    private get frame(): V6FrameData | null | undefined {
        return this.clickedFrame();
    }
    protected modalClasses(): string {
        return 'modal-dialog v6-nested-frame-modal';
    }
    protected async getTitle(): PromiseSnippet {
        const frame = this.frame;
        if (!frame) return "";
        return tlang`Context Menu for "${frame.boundaryName} - ${frame.description}"`;
    }
    protected async bodyTemplate(): PromiseTemplate {
        this.attributes = await this.attributesBuilder(this.needsRebuild);
        this.needsRebuild = false;
        const frame = this.frame;
        if (!frame) {
            this.hideModal();
            return html``;
        }
        return html`
        <div class="row">
        
            <div class="col-8 accordion ModalColAttributeContents attributeContents">
                ${this.attributes}
            </div>
            <div class="col-4">
                ${this.svgProvider(frame)}
            </div>
        </div>
        `;

    }
}