import {
    EventResource,
    ProjectResourceView,
    ProjectResourceViewOptions
} from "../../../projects/views/project-resource-view";
import { PromiseTemplate, Snippet } from "../../../components/ui/events";
// eslint-disable-next-line import/named
import { html } from "lit";
import { DataTableWrapper, RequestPage, ResultPaginated } from "../../../components/ui/datatable-view";
import { Resource, ResourceType } from "../../../api/dealer-api-interface-project";
import { ProjectContainerManager } from "../../../projects/data/project-container";
import { emptyGuid } from "../../../api/guid";
import { tlang } from "../../../language/lang";
import { getQuoteNumberFormatted } from "../../../quotes/data/quote-helper-functions";
import { QuoteState, ViewQuoteSummary } from "../../../api/dealer-api-interface-quote";
import { moneyToHtml } from "../../../components/currency-formatter";
import { getPurchaseOrderNumberFormatted } from "../../../purchase-orders/data/purchase-order-helper-functions";
import { PurchaseOrder, PurchaseOrderState } from "../../../api/dealer-api-interface-franchisee";
import { launchQuote } from "../../../quotes/ui/launcher";
import { launchPurchaseOrder } from "../../../purchase-orders/ui/launcher";
import { cache } from "../../cache/cache-registry";
import { ItemReference } from "../../../cache/definitions/cache-item-reference";
import { ProjectResourceLink } from "../../cache/resource-link";
import { getQuoteContainer, getQuoteContainerManager } from "../../quotes/quote-ui-adapter";
import { getApiFactory } from "../../../api/api-injector";
import { fireQuickErrorToast } from "../../../toast-away";

import EllipseIcon from '../../../../assets/icons/ellipse.svg';
import CopyIcon from '../../../../assets/icons/copy.svg';
import CloseIcon from '../../../../assets/icons/close.svg';


interface FranchiseeProjectResourceTableOptions {
    projectManager: ProjectContainerManager;
    openResourceEvent: EventResource;
}

interface FranchiseProjectResourceQuoteTableOptions extends FranchiseeProjectResourceTableOptions {
    copyResourceEvent: EventResource;
    removeResourceEvent: EventResource;
}

export class FranchiseeProjectResourceQuoteTable extends DataTableWrapper<Resource> {
    projectManager: ProjectContainerManager;
    quoteCache = cache().quote;
    openResourceEvent: EventResource;
    copyResourceEvent: EventResource;
    removeResourceEvent: EventResource;

    constructor(options: FranchiseProjectResourceQuoteTableOptions) {
        super();
        this.projectManager = options.projectManager;
        this.openResourceEvent = options.openResourceEvent;
        this.copyResourceEvent = options.copyResourceEvent;
        this.removeResourceEvent = options.removeResourceEvent;
    }

    public verticalHeight(): string | undefined {
        return undefined;
    }

    public afterRowCreated(_row: any, _data: Resource, _dataIndex: number): void {
        // Set the data-status attribute, and add a class
        //        const rowClass = dataIndex % 2 === 0 ? "odd-row" : "even-row";
        //       $(row).addClass(rowClass);
    }

    public async getRowsFromServer(_request: RequestPage): Promise<ResultPaginated<Resource>> {
        await this.projectManager.needsProject();
        const items = this.projectManager.container.resources?.filter(x => x.typeOf == ResourceType.Quote);
        if (!items)
            return {
                count: 0,
                pageCount: 0,
                pageIndex: 0,
                pageSize: this.pageLength(),
                results: []
            };

        const quoteKeys = items.map(x => x.resourceId ?? emptyGuid) ?? [];

        //if there are any other callbacks we need to do, add them to the
        //all array which will let them run at the same time
        await Promise.all([
            this.quoteCache.preFetch(quoteKeys)
        ]);

        return {
            count: items.length,
            pageCount: 1,
            pageIndex: 0,
            pageSize: items.length,
            results: items
        };
    }

    public getColumns(): any[] {
        return [
            {
                title: tlang`Date`,
                width: "50px",
                data: "resourceId",
                className: "project-quote-created-date",
                render: (_value: string, _type: never, row: Resource) => {
                    const summary = this.getQuoteSummary(row.resourceId);

                    if (!summary)
                        return "";

                    const dt = new Date(summary.dateCreated);
                    return `${dt.toLocaleDateString()}`;
                }
            },
            {
                title: tlang`%%quote%% No.`,
                width: "50px",
                data: "resourceId",
                className: "project-quote-number",
                render: (_value: string, _type: never, row: Resource) => {
                    return this.quoteLink(row, getQuoteNumberFormatted(this.getQuoteSummary(row.resourceId)));
                }
            },
            {
                title: tlang`%%quote%% Title`,
                width: "50px",
                data: "resourceId",
                className: "project-quote-title",
                render: (_value: string, _type: never, row: Resource) => {
                    return this.quoteLink(row, this.getQuoteSummary(row.resourceId)?.title ?? "");
                }
            },
            {
                title: tlang`Status`,
                width: "50px",
                data: "resourceId",
                className: "project-quote-state",
                render: (_value: string, _type: never, row: Resource) => {
                    const summary = this.getQuoteSummary(row.resourceId);

                    if (!summary)
                        return "";

                    return QuoteState[summary.state];
                }
            },
            {
                title: tlang`Amount`,
                width: "50px",
                data: "resourceId",
                render: (_value: string, _type: never, row: Resource) => {
                    return `<nominated-pricelist-field price-id="${this.getQuotePriceId(row)}">
                    </nominated-pricelist-field>`;
                },
                className: "dt-right project-quote-price"
            },
            {
                title: "...",
                width: "32px",
                data: "resourceId",
                orderable: false,
                className: "item-menu",
                render: (_value: number, _type: never, row: Resource) => {
                    const quoteSummary = this.getQuoteSummary(row.resourceId);
                    return this.ellipsisMenu(quoteSummary);
                }
            }
        ];
    }

    ellipsisMenu(quoteSummary: ViewQuoteSummary | null): string {
        const deleteMenu =
            !(quoteSummary == null ||quoteSummary.state == QuoteState.Draft || quoteSummary.state == QuoteState.Active)
                ? ''
                : `<button type="button" class="multi-action-btn btn-circle">
                    <img class="icon  action-delete" src="/assets/icons/bin.svg">
                   </button>`;
        const copyMenu =
            `<button type="button" class="multi-action-btn btn-circle ">
                <img class="icon action-copy" src=${CopyIcon}>
            </button>`;
        const template = `
        <div class="multi-actions">
            <input type="checkbox" >
            <label>
                ${copyMenu}
                ${deleteMenu}
                <span class="multi-action-btn btn-circle">
                    <img class="icon" src=${CloseIcon} onclick="checkClosest(this,false)">
                </span>
                <span class="icon" >
                    <img src=${EllipseIcon} onclick="checkClosest(this,true)">
                </span>
            </label>
        </div>`;
        return template;
    }

    public bindClickEvents($dataTable: any) {
        $dataTable.on('click', '.quote-link',
            this.eventHandler(
                async (data: Resource) => {
                    await this.openResourceEvent(data.resourceId);
                }
            ));
        $dataTable.on('click', '.action-copy',
            this.eventHandler(
                async (data: Resource) => {
                    await this.copyResourceEvent(data.resourceId);
                }
            ));
        $dataTable.on('click', '.action-delete',
        this.eventHandler(
            async (data: Resource) => {
                await this.removeResourceEvent(data.resourceId);
            }
        ));
    }

    public getQuoteSummary(id: string) {
        const quoteSummary = this.quoteCache.getLocalData(id)?.quoteSummary;
        return quoteSummary ? quoteSummary as ViewQuoteSummary : null;
    }

    protected quoteLink(row: Resource, value: string) {
        return `<a class="quote-link" href="#" data-quoteid="${row.resourceId}" >${this.htmlEncode(value)}</a>`;
    }

    protected ordering(): boolean {
        return false;
    }

    private getQuotePriceId(row: Resource) {
        return this.getQuoteSummary(row.resourceId)?.priceId;
    }
}

interface FranchiseeProjectResourcePurchaseOrderTableOptions extends FranchiseeProjectResourceTableOptions {
    openQuoteResourceEvent: EventResource;
}

export class FranchiseeProjectResourcePurchaseOrderTable extends DataTableWrapper<Resource> {
    projectManager: ProjectContainerManager;
    private quoteCache = cache().quote;
    private purchaseOrderCache = cache().purchaseOrder;
    openResourceEvent: EventResource;
    openQuoteResourceEvent: EventResource;
    private linkCache = cache().projectResourceLink;

    constructor(options: FranchiseeProjectResourcePurchaseOrderTableOptions) {
        super();
        this.projectManager = options.projectManager;
        this.openResourceEvent = options.openResourceEvent;
        this.openQuoteResourceEvent = options.openQuoteResourceEvent;
    }

    public verticalHeight(): string | undefined {
        return undefined;
    }

    public afterRowCreated(_row: any, _data: Resource, _dataIndex: number): void {
        // Set the data-status attribute, and add a class
        //        const rowClass = dataIndex % 2 === 0 ? "odd-row" : "even-row";
        //       $(row).addClass(rowClass);
    }

    public async getRowsFromServer(_request: RequestPage): Promise<ResultPaginated<Resource>> {
        await this.projectManager.needsProject();
        const items = this.projectManager.container.resources?.filter(x => x.typeOf == ResourceType.PurchaseOrder);
        if (!items)
            return {
                count: 0,
                pageCount: 0,
                pageIndex: 0,
                pageSize: this.pageLength(),
                results: []
            };

        const poKeys = items.map(x => x.resourceId ?? emptyGuid) ?? [];

        await this.purchaseOrderCache.preFetch(poKeys).then(async () => {
            return await this.linkCache.getMany(poKeys);
        }).then(async (data: ItemReference<ProjectResourceLink>[] | null) => {
            const quoteIds = data?.map(x => x.data.quoteId) ?? [];
            return this.quoteCache.preFetch(quoteIds);
        });

        return {
            count: items.length,
            pageCount: 1,
            pageIndex: 0,
            pageSize: items.length,
            results: items
        };
    }

    public getColumns(): any[] {
        return [
            {
                title: tlang`%%purchase-order%% No.`,
                width: "50px",
                data: "resourceId",
                className: "project-order-number",
                render: (_value: string, _type: never, row: Resource) => {
                    return this.getPurchaseOrderLink(row, getPurchaseOrderNumberFormatted(this.getPurchaseOrder(row.resourceId)));
                }
            },
            {
                title: tlang`%%quote%% No.`,
                width: "50px",
                data: "resourceId",
                className: "project-order-quote-number",
                render: (value: string, _type: never, _row: Resource) => {
                    return this.quoteLink(this.getQuote(value)?.id ?? "", getQuoteNumberFormatted(this.getQuote(value)));
                }
            },
            {
                title: tlang`Status`,
                width: "50px",
                data: "resourceId",
                className: "project-order-state",
                render: (_value: string, _type: never, row: Resource) => {
                    const summary = this.getPurchaseOrder(row.resourceId);

                    if (!summary)
                        return "";

                    return PurchaseOrderState[summary.state];
                }
            },
            {
                title: tlang`Amount`,
                width: "50px",
                data: "resourceId",
                render: (_value: string, _type: never, row: Resource) => {
                    const price = this.getPrice(row);
                    return price ? moneyToHtml(price) : "";
                },
                className: "dt-right project-order-price"
            },
        ];
    }

    public bindClickEvents($dataTable: any) {
        $dataTable.on('click', '.purchase-order-link',
            this.eventHandler(
                async (data: Resource) => {
                    await this.openResourceEvent(data.resourceId);
                }
            ));

        $dataTable.on('click', '.quote-link',
            this.eventHandler(
                async (data: Resource) => {
                    const id = this.getQuote(data.resourceId)?.id;

                    if (!id)
                        throw new Error("Could not determine quote id");

                    await this.openQuoteResourceEvent(id);
                }
            ));
    }

    public getPurchaseOrder(id: string) {
        const po = this.purchaseOrderCache.getLocal(id)?.data.purchaseOrder;
        return po ? (po as any) as PurchaseOrder : null;
    }

    protected quoteLink(id: string, value: string) {
        return `<a class="quote-link" href="#" data-quoteid="${id}" >${this.htmlEncode(value)}</a>`;
    }

    protected ordering(): boolean {
        return false;
    }

    public getQuote(poId: string) {
        const qid = this.linkCache.getLocal(poId);
        const quoteSummary = this.quoteCache.getLocal(qid?.data.quoteId)?.data.quoteSummary;
        return quoteSummary ? quoteSummary as ViewQuoteSummary : null;
    }

    private getPrice(row: Resource) {
        return this.getPurchaseOrder(row.resourceId)?.calculatedGrossTotal;
    }

    private getPurchaseOrderLink(row: Resource, value: string) {
        return `<a class="purchase-order-link" href="#" data-purchase-order-id="${row.resourceId}" >${this.htmlEncode(value)}</a>`;
    }
}

export class FranchiseeProjectResourceView extends ProjectResourceView {
    private quoteTable: FranchiseeProjectResourceQuoteTable;
    private poTable: FranchiseeProjectResourcePurchaseOrderTable;
    private projectCache = cache().project;
    private projectApi = getApiFactory().project();

    constructor(options: ProjectResourceViewOptions) {
        super(options);

        this.quoteTable = this.getResourceQuoteTableFactory();
        this.poTable = this.getResourcePurchaseOrderTableFactory();
    }

    public override async invalidate(): Promise<void> {
        await super.invalidate();
        this.quoteTable.render();
        this.quoteTable.invalidate();

        this.poTable.render();
        this.poTable.invalidate();
    }

    public async refreshData(): Promise<void> {
        await this.quoteTable.refreshData();
        await this.poTable.refreshData();
    }

    public buttonMenu(): Snippet {
        return html`

        `;
    }

    protected getResourceQuoteTableFactory() {
        return new FranchiseeProjectResourceQuoteTable({
            projectManager: this.projectManager,
            openResourceEvent: async (id: string) => {
                return await this.openQuote(id, this.quoteTable.getQuoteSummary(id)?.title ?? "");
            },
            copyResourceEvent: async (id: string) => {
                return await this.copyQuote(id);
            },
            removeResourceEvent : async (quoteId: string) => {
                return await this.deleteQuote(quoteId);
            }
        });
    }

    protected getResourcePurchaseOrderTableFactory() {
        return new FranchiseeProjectResourcePurchaseOrderTable({
            projectManager: this.projectManager,

            openResourceEvent: async (id: string) => {
                return await this.openPurchaseOrder(id, this.poTable.getPurchaseOrder(id)?.title ?? "");
            },

            openQuoteResourceEvent: async (id: string) => {
                return await this.openQuote(id, this.poTable.getQuote(id)?.title ?? "");
            }
        });
    }

    protected async template(): PromiseTemplate {
        const peApiUrl = globalThis.pricingEngineConfiguration.pricingApiUrl;
        return html`
            <h2 class="section-header">${tlang`!!quote!!`}</h2>
            <div class="row">
                ${this.quoteTable.ui}
            </div>
            <pricing-list-controller></pricing-list-controller>
			<price-repository pricing-api-url="${peApiUrl}"></price-repository>
            <!-- Temporary remove purchase order table, it is high probble that we will need it again -->
            <!-- <h2 class="section-header">${tlang`!!purchase-order!!`}</h2>
            <div class="row">
                ${this.poTable.ui}
            </div> -->
        `;
    }

    private async openQuote(id: string, title: string): Promise<boolean> {
        return await launchQuote({ id: id, title: title, navigateIfAllowed: async () => await this.canClose() });
    }

    private async openPurchaseOrder(id: string, title: string): Promise<boolean> {
        return await launchPurchaseOrder({ id, title, navigateIfAllowed: async () => await this.canClose() });
    }

    private async copyQuote(id: string): Promise<boolean> {
        const qcm = getQuoteContainerManager(getQuoteContainer(id));
        const copy = await qcm.makeAlternative();
        if (copy) {
            const resource = await this.projectApi.createProjectResourceReference({
                resourceId: copy.quoteId,
                projectId: this.projectManager.projectId,
                typeOf: ResourceType.Quote,
                parentResourceId: emptyGuid,
                parentTypeOf: ResourceType.None
            });
            if (resource) {
                this.projectCache.updateLocal(this.projectManager.projectId);

                await this.refreshData();
                return this.openQuote(copy.quoteId, copy.quote?.title ?? "");
            }
            else {
                fireQuickErrorToast(tlang`Unable to copy %%quote%%`);
                // if we were unable to create the project link, remove the alternative quote
                return await getQuoteContainerManager(copy).deleteQuote();
            }
        }
        return false;
    }

    private async deleteQuote(quoteId: string): Promise<boolean> {

        const qcm = getQuoteContainerManager(getQuoteContainer(quoteId));
        var deleted = await qcm.deleteQuote() ?? false;
        if (deleted)
        {
            await this.projectApi.deleteProjectResourceReference({
                resourceId: quoteId,
                projectId: this.projectManager.projectId
            });
            this.projectCache.updateLocal(this.projectManager.projectId);
            this.projectManager.container.resources = this.projectManager.container.resources?.filter(resource => resource.resourceId !== quoteId) ?? null;
            await this.refreshData();
        }
        return deleted;
    }
}
