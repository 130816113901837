import {
    InputCreateQuote,
    InputCreateQuoteItem,
    InputDeleteQuote,
    InputDeleteQuoteItem,
    InputDuplicateQuote,
    InputDuplicateQuoteItem,
    InputUpdateQuote,
    InputUpdateQuoteItem,
    RequestGetQuote,
    RequestGetQuoteItemsSummary,
    RequestGetQuoteItemsSummaryById,
    RequestGetQuoteStateAudit,
    RequestGetQuoteSummary,
    RequestGetQuoteTotalsByState,
    ResultCreateQuote,
    ResultCreateQuoteItem,
    ResultDeleteQuote,
    ResultDeleteQuoteItem,
    ResultDuplicateQuote,
    ResultDuplicateQuoteItem,
    ResultGetQuote,
    ResultGetQuoteItemsSummary,
    ResultGetQuoteItemsSummaryById,
    ResultGetQuoteStateAudit,
    ResultGetQuoteSummary,
    ResultGetQuoteTotalsByState,
    ResultUpdateQuote,
    ResultUpdateQuoteItem
} from "./dealer-api-interface-quote";
import { ApiCommunications } from "./api-communications";
import { NullPromise } from "../null-promise";
import { QuoteApi } from "./quote-api";
import { tenantId } from "./current-user";

export class QuoteApiImpl implements QuoteApi {
    api: ApiCommunications;

    constructor(api: ApiCommunications) {
        this.api = api;
    }

    async deleteQuote(input: InputDeleteQuote): NullPromise<ResultDeleteQuote> {
        return await this.api.post<ResultDeleteQuote>('api/Quote/DeleteQuote', input);
    }

    async duplicateQuote(input: InputDuplicateQuote): NullPromise<ResultDuplicateQuote> {
        return await this.api.post<ResultDuplicateQuote>('api/Quote/DuplicateQuote', input);
    }

    async getQuoteSummary(request: RequestGetQuoteSummary): NullPromise<ResultGetQuoteSummary> {
        return await this.api.post<ResultGetQuoteSummary>('api/Quote/GetQuoteSummary', request);
    }

    async getQuote(request: RequestGetQuote): NullPromise<ResultGetQuote> {
        return await this.api.post<ResultGetQuote>('api/Quote/GetQuote', request);
    }

    async createQuote(input: InputCreateQuote): NullPromise<ResultCreateQuote> {
        return await this.api.post<ResultCreateQuote>('api/Quote/CreateQuote', input);
    }

    async updateQuote(input: InputUpdateQuote): NullPromise<ResultUpdateQuote> {
        return await this.api.post<ResultUpdateQuote>('api/Quote/UpdateQuote', input);
    }

    async createQuoteItem(input: InputCreateQuoteItem): NullPromise<ResultCreateQuoteItem> {
        return await this.api.post<ResultCreateQuoteItem>(
            'api/QuoteItem/CreateQuoteItem', input);
    }

    async updateQuoteItem(input: InputUpdateQuoteItem): NullPromise<ResultUpdateQuoteItem> {
        return await this.api.post<ResultUpdateQuoteItem>(
            'api/QuoteItem/UpdateQuoteItem', input);
    }

    async deleteQuoteItem(input: InputDeleteQuoteItem): NullPromise<ResultDeleteQuoteItem> {
        return await this.api.post<ResultDeleteQuoteItem>(
            'api/QuoteItem/DeleteQuoteItem', input);
    }

    async duplicateQuoteItem(input: InputDuplicateQuoteItem): NullPromise<ResultDuplicateQuoteItem> {
        return await this.api.post<ResultDuplicateQuoteItem>(
            'api/QuoteItem/DuplicateQuoteItem', input);
    }

    async getQuoteTotalsByState(request: RequestGetQuoteTotalsByState): NullPromise<ResultGetQuoteTotalsByState> {
        return await this.api.post<ResultGetQuoteTotalsByState>(
            'api/Quote/GetQuoteTotalsByState', request);
    }

    async getQuoteItemsSummary(request: RequestGetQuoteItemsSummary): NullPromise<ResultGetQuoteItemsSummary> {
        return await this.api.post<ResultGetQuoteItemsSummary>(
            'api/QuoteItem/GetQuoteItemsSummary', request);
    }

    createQuoteItemSVGThumbnailPath(quoteId: string, quoteItemId: string): string {
        const d = new Date().getTime();
        return `T${tenantId()}/services/quote/${quoteId}/quoteItem/${quoteItemId}/${d}/thumbnail.svg`;
    }

    async getQuoteItemsSummaryById(request: RequestGetQuoteItemsSummaryById): NullPromise<ResultGetQuoteItemsSummaryById> {
        return await this.api.post<ResultGetQuoteItemsSummaryById>(
            'api/QuoteItem/GetQuoteItemsSummaryById', request);
    }

    async getQuoteStateAudit(request: RequestGetQuoteStateAudit): NullPromise<ResultGetQuoteStateAudit> {
        return await this.api.post<ResultGetQuoteStateAudit>(
            "api/Quote/GetQuoteStateAudit", request);
    }
}