
import { getApi } from "../../../api/api-injector";
import { NullPromise } from "../../../null-promise";
import { supplierQuoteItemContentType } from "../../../quotes/data/supplier-quote-item-content-type";
import { v6Config } from "../../v6config";
import { TemplateResultEvent } from "../../common-types";
import { V6FillByFramePickerDataProvider } from "../../v6-picker/v6-fill-picker-data-provider";


import { V6ListItem, V6QuoteIGU } from "../v6-quote-item-data";
import { convertQuoteIGUsToFamilyData, convertResourceTagsFamilyData } from "./v6-attribute-listitem-converter";
import { V6AttributePickerSelectHandler, V6AttributePickerSelectValue } from "./v6-frame-attribute-picker";


export function createV6AttributePickerSelectHandler(
    supplierId: () => string,
    frameObjectReference: (() => string) | null,
    sidebarTemplate: TemplateResultEvent | null,
    quoteIGUProvider: (() => NullPromise<V6QuoteIGU[]>) | null): V6AttributePickerSelectHandler {
    const pickFillorIGUForFrame = async (resourceName: string, objectReference: string, subType: string, resourceClassId: number): Promise<V6AttributePickerSelectValue> => {
        const dataLoader = new V6FillByFramePickerDataProvider(getApi(), supplierId(), objectReference, subType);
        const data = await dataLoader.getPage({
            filter: { textFilter: "" },
            pageSize: 10000000,
            pageIndex: 0
        });
        const familyData = convertResourceTagsFamilyData(resourceClassId, data?.items ?? []);
        const fill = await v6Config().getPickerFactory().familyDataPicker(supplierId(), familyData, sidebarTemplate, resourceName, true);
        return {
            "value": fill?.objectReference,
            "displayValue": fill?.description ?? fill?.code
        };

    };


    return {
        getIGUs: async (): Promise<V6QuoteIGU[]> => {
            return (await quoteIGUProvider?.()) ?? [];
        },
        pickFrameFinish: async (optionCode: string): Promise<V6AttributePickerSelectValue> => {
            const finish = await v6Config().getPickerFactory().finishPicker(supplierId(),
                {
                    name: frameObjectReference ? "finish-by-frame" : "finish",
                    objectReference: frameObjectReference ? frameObjectReference() : undefined,
                    optionCode: optionCode,
                    optionType: 0,
                    pageSize: 10
                });
            return {
                "value": finish?.objectReference,
                "displayValue": finish?.description
            };
        },

        pickFrameFill: async (resourceName: string): Promise<V6AttributePickerSelectValue> => {
            if (!frameObjectReference) {
                const value = await v6Config().pickFill(supplierId());
                return {
                    value: value?.objectReference,
                    displayValue: value?.description
                };
            }
            return await pickFillorIGUForFrame(resourceName, frameObjectReference(), "fill", supplierQuoteItemContentType.CID_FILL);
        },

        pickQuoteIGU: async (resourceName: string): Promise<V6AttributePickerSelectValue> => {
            const igus = (await quoteIGUProvider?.()) ?? [];
            const items = convertQuoteIGUsToFamilyData(resourceName, igus);
            if (!items)
                return {
                    "value": undefined,
                    "displayValue": undefined
                };
            const igu = await v6Config().getPickerFactory().familyDataPicker(supplierId(), items, sidebarTemplate, resourceName);
            return {
                "value": igu?.objectReference,
                "displayValue": igu?.description ?? igu?.code
            };
        },
        pickFrameIGU: async (resourceName: string): Promise<V6AttributePickerSelectValue> => {
            if (!frameObjectReference) {
                const value = await v6Config().pickIGU(supplierId());
                return {
                    value: value?.objectReference,
                    displayValue: value?.description
                };
            }
            return await pickFillorIGUForFrame(resourceName, frameObjectReference(), "igu", supplierQuoteItemContentType.CID_IGU);
        },


        pickFrameLouvre: async (_resourceName: string): Promise<V6AttributePickerSelectValue> => {

            const value = await v6Config().pickLouvre(supplierId());
            return {
                value: value?.objectReference,
                displayValue: value?.description
            };
        },

        pickResource: async (classId: number, family: string, tags: string): Promise<V6AttributePickerSelectValue> => {
            const x = await v6Config().getPickerFactory().resourcePicker(supplierId(), {
                familyRef: family,
                resourceClassId: classId,
                tags: tags,
                sideBar: sidebarTemplate
            });

            return { value: x?.objectReference, displayValue: x?.description ?? x?.code ?? "" };


        },
        pickItemFromList: async (items: V6ListItem[]): Promise<V6AttributePickerSelectValue> => {
            const x = await v6Config().getPickerFactory().listItemPicker(supplierId(), items, sidebarTemplate);
            return { value: x?.objectReference, displayValue: x?.description ?? x?.code ?? "" };
        },
    };
}
