import { getApi } from "../../../api/api-injector";
import { AsyncConstructor } from "../../../async-constructor";
import { WaitPatientlySaving } from "../../../components/ui/modal-saving";
import { DevelopmentError } from "../../../development-error";
import { tlang } from "../../../language/lang";
import { NullPromise } from "../../../null-promise";
import { ProjectContainerManager } from "../../../projects/data/project-container";
import { PurchaseOrderContainerManager } from "../../../purchase-orders/data/purchase-order-container";
import { V6FrameData, V6PropertyGroup, V6QuoteIGU, V6QuoteItem } from "../../../v6config/v6-quote-item/v6-quote-item-data";

import { QuoteContainerManager } from "../quote-container";
import { QuoteItemContainer } from "../quote-item-container";
import { quoteSupplierProvider } from "../quoteSupplierProvider";
import { supplierQuoteItemContentType } from "../supplier-quote-item-content-type";

interface InputQuoteItem {
    frameCode: string;
    frameData: V6FrameData;
    options: V6PropertyGroup[];
    quantity: number;
    description: string;
    quoteItemCommonId: string;
}
interface InputCreateSupplierQuote {
    supplierId: string;
    defaultQuoteOptions: V6PropertyGroup[];
    quoteIGUs: V6QuoteIGU[];
    includeValidation: boolean | null;
    forceIGUUpdate: boolean;
    quoteItems: InputQuoteItem[];

    title: string;
    comment: string;
    shipaddress: string[];
    quoteCommonId: string;
}
interface ResultCreateSupplierQuote {
    supplierId: string;
    defaultQuoteOptions: V6PropertyGroup[];
    quoteIGUs: V6QuoteIGU[];
    includeValidation: boolean | null;
    forceIGUUpdate: boolean;
    quoteItems: InputQuoteItem[];

}

export class V6SupplierQuote implements AsyncConstructor {
    qcm: QuoteContainerManager;
    supplierId: string;
    input: InputCreateSupplierQuote | null = null;
    pcm: PurchaseOrderContainerManager;
    projectcm: ProjectContainerManager;
    constructor(projectContainerManager: ProjectContainerManager, purchaseOrderContainerManager: PurchaseOrderContainerManager, quoteContainerManager: QuoteContainerManager, homeOfficeSupplierId?: string) {
        this.qcm = quoteContainerManager;
        this.pcm = purchaseOrderContainerManager;
        this.projectcm = projectContainerManager;
        this.supplierId = homeOfficeSupplierId ?? "";
    }
    async afterConstruction(): Promise<void> {
        await this.qcm.needsQuote();
        await this.qcm.needsQuoteItems();
        await this.pcm.needsPurchaseOrder();
        await this.projectcm.needsProject();
        this.supplierId = this.supplierId === "" ? this.qcm.quote.supplierId : this.supplierId;
        this.input = {
            defaultQuoteOptions: this.getDefaultOptions(),
            includeValidation: false,
            quoteIGUs: this.getQuoteIGUs(),
            forceIGUUpdate: true,
            supplierId: this.supplierId,
            quoteItems: this.buildQuoteItems(),
            quoteCommonId: this.qcm.quote.id,

            title: this.pcm.purchaseOrder.title,
            comment: this.pcm.purchaseOrder.quoteDescription,
            shipaddress: [this.projectcm.project.defaultAddress.line1,
            this.projectcm.project.defaultAddress.line2 ?? "",
            this.projectcm.project.defaultAddress.locality ?? "",
            this.projectcm.project.defaultAddress.region ?? "",
            this.projectcm.project.defaultAddress.postcode,
            this.projectcm.project.defaultAddress.country ?? ""
            ]
        };
    }
    buildQuoteItems(): InputQuoteItem[] {
        const result: InputQuoteItem[] = [];
        const items = this.qcm.container.items?.filter(quoteItem =>
            quoteItem.serviceProvider === quoteSupplierProvider.v6
            && quoteItem.quoteItemContentType === supplierQuoteItemContentType.CID_FRAM) ?? [];
        for (let i = 0; i < items.length; i++) {
            const itemContainer = this.qcm.quoteItemContainer(items[i].id);
            const v6Item = this.getV6QuoteItem(itemContainer);
            result.push({
                frameCode: v6Item.frameData.objectReference,
                frameData: v6Item.frameData,
                quantity: itemContainer.item.quantity,
                options: v6Item.quoteItemOptions,
                description: itemContainer.item.description,
                quoteItemCommonId: itemContainer.item.id
            });
        }
        return result;
    }

    //override this
    getDefaultOptions(): V6PropertyGroup[] {
        return [];
    }
    getQuoteIGUs(): V6QuoteIGU[] {
        return [];
    }
    getV6QuoteItem(_container: QuoteItemContainer): V6QuoteItem {
        throw new DevelopmentError("must override getV6QuoteItem");
    }

    async run(): NullPromise<ResultCreateSupplierQuote> {
        const waiting = new WaitPatientlySaving(() => tlang`Generating Supplier Quote`);
        try {
            const result = await getApi().post<ResultCreateSupplierQuote>('api/v6/Supplier/CreateQuote', this.input);
            return result;
        } finally {
            waiting.hideModal();
        }
    }
}