

export function objectToBase64(data: any | null): string | null {
    if (!data) return null;
    const strData = JSON.stringify(data);
    return btoa(strData);
}
export function base64ToObject<T>(base64: string | null | undefined): T | null {
    if (!base64) return null;
    const json = atob(base64);
    return JSON.parse(json) as T;
}

/**
 * Turns the contents of the given File into a base64 encoded string.
 * @param file The file to be converted.
 */
export function fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        //Assert the type to be string (as string). Even though the result of the reader can be string | ArrayBuffer | null
        // we are using readAsDataURL method, which returns a base64 encoded string
        reader.onloadend = () => resolve((reader.result) as string);
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
    });
}