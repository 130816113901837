import {
    V6FrameData,
    V6Property,
    V6PropertyGroup,
    V6QuoteItem
} from "../../../v6config/v6-quote-item/v6-quote-item-data";
import {ReportItemGroup, ReportItemProperty, ReportItemPropertyGroup} from "../../../api/dealer-api-interface-report";
import {isEmptyOrSpace} from "../../../components/ui/helper-functions";

export function frameOptionV6Template(frame: V6QuoteItem): ReportItemGroup {
    const displayVal = (main: string | null | undefined, next: string | null | undefined) => {
        if (!isEmptyOrSpace(main)) return main;
        if (!isEmptyOrSpace(next)) return next;
        return "";
    };

    const groupHasAttributes = (g: V6PropertyGroup) => {
        return g.attributes.filter(a => a.visible).length > 0;
    };

    const visibleGroups = (g: V6PropertyGroup[]) => g.filter(g => groupHasAttributes(g));

    const stackHasAttributes = (nf: V6FrameData): boolean => {
        const processFrame = (frame: V6FrameData): boolean => {
            return (frame.attributeGroups.some(g => groupHasAttributes(g)) ||
                frame.nestedFrames.some(nf => processFrame(nf)));
        };
        return processFrame(nf);
    };

    const mapPropertyGroup = (a: V6PropertyGroup): ReportItemPropertyGroup => {
        return {
            description: displayVal(a.description, a.name) ?? "",
            properties: a.attributes.map(mapAttribute)
        }
    }

    const mapAttribute = (a: V6Property): ReportItemProperty => {
        return {
            label: displayVal(a.codeDescription, a.code) ?? "",
            value: displayVal(a.displayValue, a.value) ?? ""
        };
    }

    const mapOptions = (frames: V6FrameData[] | null): ReportItemGroup[] => {

        const filtered = frames?.filter(stackHasAttributes) ?? [];

        return filtered.map(x => {
            return {
                description: x.description ?? "",
                propertyGroups: visibleGroups(x.attributeGroups).map(mapPropertyGroup),
                nestedItemGroups: mapOptions(x.nestedFrames) ?? null
            }
        });
    }

    const itemOptions = visibleGroups(frame.quoteItemOptions).map(mapPropertyGroup);
    const nestedOptions = mapOptions([frame.frameData]);

    return {
        description: null,
        propertyGroups: itemOptions,
        nestedItemGroups: nestedOptions
    }
}