// noinspection HtmlUnknownAttribute

// eslint-disable-next-line import/named
import { html } from "lit";
import { MenuIconOption, PageControl, PageControlOptions, PageManager } from "../../components/ui/page-control";
import { EventSnippet, PromiseSnippet, PromiseTemplate, Snippet } from "../../components/ui/events";
import { ClientApi } from "../../api/client-api";
import { ClientDetailView } from "./client-detail-view";
import { tlang } from "../../language/lang";
import { getApiFactory } from "../../api/api-injector";
import { allowPageControlTabChange, isAutoSaving } from "../../components/save-workflow";
import { ClientContainer, ClientContainerManager as ClientContainerManager } from "../data/client-container-manager";
import { DataEntryPageControlView, ModalViewBase } from "../../components/ui/data-entry-screen-base";
import { constructAsync } from "../../async-constructor";
import { validId } from "../../components/ui/helper-functions";


export interface ClientViewOptions {
    title: EventSnippet;
    clientContainer: ClientContainer;
}

export class ClientDataEntryView extends DataEntryPageControlView {
    clientApi: ClientApi = getApiFactory().client();
    public get clientContainer(): ClientContainer {
        return this.clientContainerManager.container;
    };
    protected clientContainerManager: ClientContainerManager;
    private readonly title: EventSnippet;
    private readonly detailView: ClientDetailView;

    constructor(options: ClientViewOptions) {
        super();
        this.title = options.title;


        this.clientContainerManager = this.clientContainerManagerFactory(options.clientContainer);

        this.detailView = this.clientDetailViewFactory();
    }

    /**
     * inherited;
     * @returns
     */
    protected async bodyTemplate(): PromiseTemplate {
        this.buildClientActionMenu();
        return super.bodyTemplate();
    }
    protected isNew(): boolean {
        return !validId(this.clientContainer.clientId);
    }
    /**
     * inherited;
     * @returns
     */
    public async afterConstruction() {
        await this.clientContainerManager.needsClient();

        await this.detailView.afterConstruction();
        await this.detailView.render();

        //this will create the pagecontrol
        await super.afterConstruction();
        this.buildClientActionMenu();
    }

    /**
     * inherited;
     * @returns
     */
    protected createPageControl(): PageControl {
        // build static pages for each of the configured table settings
        const getInitialPageManagers = (): PageManager[] => {
            const pages: PageManager[] = [];
            pages.push(this.detailView?.createPageManager());
            return pages;

        };

        const options: PageControlOptions = {
            defaultTabIndex: 0,
            menuIcons: undefined,
            pageInitializer: () => getInitialPageManagers()
        };
        return new PageControl(options);
    }

    /**
     * inherited;
     * @returns
     */
    public internalDataChanged(): boolean {
        return this.clientContainerManager.changed();
    }

    /**
     * inherited;
     * @returns
     */
    protected async internalSaveData(): Promise<boolean> {
        return await this.clientContainerManager.saveClient(isAutoSaving());
    }

    /**
     * inherited;
     * @returns
     */
    public isReadonly(): boolean {
        return this.clientContainerManager.isReadonly();
    }

    /**
     * inherited;
     * @returns
     */
    protected getValidationErrors() {
        const errors = this.detailView.getValidationErrors();
        return errors;
    }

    /**
     * inherited;
     * @returns
     */
    public async prepareForSave(): Promise<void> {
        if (this.isReadonly()) return;

        this.detailView.prepareForSave();
    }

    /**
     * inherited;
     * @returns
     */
    public getTitle(): Snippet {
        return this.title();
    }

    /**
     * inherited;
     * @returns
     */
    async allowPageSwitch(): Promise<boolean> {
        return await allowPageControlTabChange(this.getAutoSavePromptOptions());
    }

    protected clientDetailViewFactory() {
        return new ClientDetailView(this, {
            clientContainerManager: this.clientContainerManager
        });
    }

    protected clientContainerManagerFactory(container: ClientContainer): ClientContainerManager {
        return new ClientContainerManager(container, this.clientApi, this.title);
    }

    private buildClientActionMenu() {
        const menuIcons: MenuIconOption[] = [];

        const save = {
            event: async () => {
                if (this.isReadonly())
                    return false;

                return this.performAutoSave();
            },
            caption: () => tlang`Save %%client%%`
        };

        if (!isAutoSaving())
            menuIcons.push(save);

        this.pageControl.setMenuIcons(menuIcons);
    }
}

export class ClientView extends ModalViewBase {
    view: ClientDataEntryView | null = null;
    options: ClientViewOptions;

    constructor(options: ClientViewOptions) {
        super();
        this.options = options;
    }
    /**
     * inherited
     * @returns
     */
    protected async getTitle(): PromiseSnippet {
        return this.view?.getTitle() ?? "";
    }
    /**
     * inherited
     * @returns
     */
    protected modalSize(): string {
        return "modal-fullscreen";
    }

    /**
     * inherited
     * @returns
     */
    async canClose(): Promise<boolean> {
        return await this.view?.canClose() ?? true;
    }
    protected createView(): ClientDataEntryView {
        return new ClientDataEntryView(this.options);
    }
    async afterConstruction(): Promise<void> {
        this.view = await constructAsync(this.createView());
        this.view.onRender = async () => await this.render();
        await this.view.render();
        this.ui.addEventListener('shown.bs.modal', this.setFocusAndSelect);
    }

    private setFocusAndSelect(_event: Event) {
        setTimeout(() => {
            super.setFocusOnInputElementAndSelect("frm-client-details", "client-name-");
          }, 0);
      }

    protected async bodyTemplate(): PromiseTemplate {
        return html`${this.view?.ui}`;
    }
}