import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';


@customElement('icon-refresh')
export class IconRefresh extends LitElement {
    protected createRenderRoot(): Element | ShadowRoot {
        return this;
    }
    render() {
        return html`<i class="fa-solid fa-arrows-rotate"></i>`;
    }
}
