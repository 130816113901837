
import { tlang } from "../../language/lang";
import { EventSnippet } from "./events";

import { WaitPatiently } from "./modal-spinner";



//a progress window default to run will doing a save to the server
export class WaitPatientlySaving extends WaitPatiently {

    constructor(title?: EventSnippet, message?: EventSnippet) {
        super(title ?? (() => tlang`Saving`), message ?? (() => tlang`please wait while we update the data`));
    }
    borderType() {
        return "basic";
    }


}