//import * as provider from "./v6-quote-item-data-provider";
import { V6QuoteItemView } from "./v6-quote-item-view";
import * as data from './v6-quote-item-data';
import { ApiCommunications } from "../../api/api-communications";
import { V6QuoteItemProvider, V6QuoteItemViewDataHandler } from "./v6-quote-item-view/v6-quote-item-view-datahandler";
import { EventBoolean, EventNumber, EventSnippet, EventString, EventVoid } from "../../components/ui/events";

import { V6QuoteItemPrice, ResultGetV6ItemPrice, ResultGetV6ItemValidation, ResultQuoteItem, ResultProcessV6QuoteItem } from "./v6-data-objects";

import { NullPromise } from "../../null-promise";
import { getApi } from "../../api/api-injector";
import { multiCastPromise } from "../../multicast-promise";
import { QuoteDefaultsEvent, QuoteItemDateRefreshedEvent, QuoteIGUListEvent } from "./v6-events";
import { V6QuoteItemEvent } from "../../components/ui/v6-events";


const emptyGuid = "00000000-0000-0000-0000-000000000000";


//options for starting up a V6 Quote Item Editor
export interface QuoteItemEditOptions {
    getDefaultsForQuoteItem: QuoteDefaultsEvent;
    container?: any | null;
    propertiesTemplate?: EventSnippet;
    quantityProvider: EventNumber;
    onDataRefresh?: ((item: data.V6QuoteItem) => Promise<void>) | null;
    readonly: EventBoolean;
    quoteIGUProvider: QuoteIGUListEvent;
    quoteItemCommonId: EventString;
}



export async function getQuoteItemValidations(supplierId: string, v6DataTrackingId: string | null | undefined, v6DataSessionId: number | null | undefined, includePricing?: boolean): Promise<ResultGetV6ItemValidation | null> {
    return await getApi().post<ResultGetV6ItemValidation>('api/v6/configitem/getv6itemvalidations',
        {//RequestV6QuoteItem.cs
            supplierId: supplierId,
            v6DataTrackingId: v6DataTrackingId,
            v6DataSessionId: v6DataSessionId,
            includePricing: includePricing ?? null
        });
};

export async function releaseQuoteTracking(supplierId: string, v6DataTrackingId: string | null | undefined, v6DataSessionId: number | null | undefined) {
    getApi().post<ResultQuoteItem>('api/v6/configitem/ReleaseTrackingReference',
        {//RequestV6QuoteItem.cs
            supplierId: supplierId,
            v6DataTrackingId: v6DataTrackingId,
            v6DataSessionId: v6DataSessionId
        });
}
const v6GetFrameInformationURI = 'api/v6/configitem/getframeinformationv2';
export const processV6QuoteItemState = {
    updating: "updating",
    validating: "validating",
    completed: "completed",
    finalizing: "finalizing",
    errors: "errors"
};
export async function processV6QuoteItem(
    quoteItem: data.V6QuoteItem,
    quantity: number,
    defaults: data.V6PropertyGroup[],
    quoteIGUProvider?: QuoteIGUListEvent,
    progress?: (caption: string) => void): Promise<ResultProcessV6QuoteItem | null> {
    await progress?.(processV6QuoteItemState.updating);
    /*
            {//RequestV6QuoteItem.cs
                supplierId: this.quoteItemTracker.item?.supplierId,
                v6DataTrackingId: this.quoteItemTracker.v6DataTrackingId,
                v6DataSessionId: this.quoteItemTracker.v6DataSessionId,
                frameData: this.quoteItemTracker.item?.frameData,
                quoteIGUs: await this.quoteIGUProvider(),
                forceIGUUpdate: true,
                defaultQuoteOptions: this.getDefaultsForQuoteItem(),
                quoteItemOptions: this.quoteItemTracker.item?.quoteItemOptions,
                itemQuantity: this.quantityProvider(),
                IncludeValidation: true // to be configured
            });

    */
    const resultQuoteItem = await getApi().post<ResultQuoteItem>(v6GetFrameInformationURI,
        {//RequestV6QuoteItem.cs
            supplierId: quoteItem.supplierId,
            v6DataTrackingId: emptyGuid,
            v6DataSessionId: 0,
            frameData: quoteItem.frameData,
            defaultQuoteOptions: defaults,
            quoteIGUs: await quoteIGUProvider?.(),
            forceIGUUpdate: true,
            quoteItemOptions: quoteItem.quoteItemOptions,
            itemQuantity: quantity // to be configured,

        });

    if (!resultQuoteItem) return null;
    try {
        progress?.(processV6QuoteItemState.validating);
        const extendedInfo = await getQuoteItemValidations(quoteItem.supplierId,
            resultQuoteItem.v6DataTrackingId,
            resultQuoteItem.v6DataSessionId,
            true);
        if (extendedInfo && resultQuoteItem.item)
            return {
                extendedInfo: { validation: extendedInfo.messages, pricing: extendedInfo.quoteItemPrice },
                quoteItem: resultQuoteItem.item
            };
        return null;
    } finally {
        //do not wait
        releaseQuoteTracking(resultQuoteItem.item?.supplierId ?? "", resultQuoteItem.v6DataTrackingId, resultQuoteItem.v6DataSessionId);
    }
}

export interface V6QuoteItemEditorServiceOptions {
    objectReference: string | null;
    api: ApiCommunications;
    quantityProvider: EventNumber;
    getDefaultsForQuoteItem: QuoteDefaultsEvent;
    onDataRefreshed: QuoteItemDateRefreshedEvent;
    propertyTemplate?: EventSnippet;
    readonly?: EventBoolean;
    quoteIGUProvider: QuoteIGUListEvent;
    quoteItemCommonId: EventString;
}
//editor service acts a a controller between the data provider and the view to make it click together.
//this abstracts the view from knowing where the data comes from or what happens when changes are made
export class V6QuoteItemEditorService implements V6QuoteItemProvider, V6QuoteItemViewDataHandler {
    view: V6QuoteItemView;
    quoteItemTracker: ResultQuoteItem = {
        item: null,
        v6DataTrackingId: emptyGuid,
        v6DataSessionId: 0
    };
    objectReference: string | null;
    api: ApiCommunications;

    //carrier packet
    data: any | null = null;
    onDataRefreshed: QuoteItemDateRefreshedEvent;
    private _isUpdatingQuote = false;
    getDefaultsForQuoteItem: QuoteDefaultsEvent;
    quantityProvider: EventNumber;
    updatingChangedEvent: EventVoid | null = null;
    readonly: EventBoolean;
    quoteIGUProvider: QuoteIGUListEvent;
    quoteItemCommonId: EventString;


    //launch a new quoteItemEditor
    static createQuoteItemEditorService(options: QuoteItemEditOptions): V6QuoteItemEditorService {
        const quoteItemEditService = new V6QuoteItemEditorService(
            {
                api: getApi(),
                objectReference: null,
                quantityProvider: options.quantityProvider,
                getDefaultsForQuoteItem: options.getDefaultsForQuoteItem,
                onDataRefreshed: options.onDataRefresh ?? null,
                propertyTemplate: options.propertiesTemplate,
                readonly: options.readonly,
                quoteIGUProvider: options.quoteIGUProvider,
                quoteItemCommonId: options.quoteItemCommonId
            });
        if (options.container) {
            quoteItemEditService.viewContainer = $(options.container)[0] ?? null;
        }
        return quoteItemEditService;
    }

    static async v6LoadQuoteItemFrameEditor(supplierId: string,
        frameResource: string,
        quantityProvider: EventNumber,
        quoteItemCommonId: EventString,
        dataRefreshCallback: V6QuoteItemEvent,
        propertiesTemplate?: EventSnippet,
        getDefaultsForQuoteItem?: QuoteDefaultsEvent,
        readonly?: EventBoolean,
        quoteIGUProvider?: QuoteIGUListEvent): Promise<V6QuoteItemEditorService | null> {
        const quoteItemEditor = this.v6buildQuoteItemFrameEditor(dataRefreshCallback,
            quantityProvider,
            quoteItemCommonId,
            propertiesTemplate,
            getDefaultsForQuoteItem,
            readonly,
            quoteIGUProvider

        );
        if (quoteItemEditor) {
            await quoteItemEditor.load({
                quoteItem: null,
                supplierId: supplierId,
                frameCode: frameResource
            });
        }
        return quoteItemEditor;
    };


    static v6buildQuoteItemFrameEditor(
        dataRefreshEvent: V6QuoteItemEvent,
        quantityProvider: EventNumber,
        quoteItemCommonId: EventString,
        propertiesTemplate?: EventSnippet,
        getDefaultsForQuoteItem?: QuoteDefaultsEvent,
        readonly?: EventBoolean,
        quoteIGUProvider?: QuoteIGUListEvent
    ): V6QuoteItemEditorService | null {

        const emptyOptions: QuoteDefaultsEvent = () => [];
        const quoteItemEditor = this.createQuoteItemEditorService({
            quantityProvider: quantityProvider,
            getDefaultsForQuoteItem: getDefaultsForQuoteItem ?? emptyOptions,
            onDataRefresh: async (item) => {
                await dataRefreshEvent(item);
            },
            propertiesTemplate: propertiesTemplate,
            readonly: readonly ?? (() => false),
            quoteIGUProvider: quoteIGUProvider ?? (() => []),
            quoteItemCommonId: quoteItemCommonId
        });
        return quoteItemEditor;
    };


    constructor(options: V6QuoteItemEditorServiceOptions) {
        this.quoteIGUProvider = options.quoteIGUProvider;

        this.readonly = options.readonly ?? (() => false);
        this.quantityProvider = options.quantityProvider;
        this.api = options.api;
        this.onDataRefreshed = options.onDataRefreshed;
        this.getDefaultsForQuoteItem = options.getDefaultsForQuoteItem;
        this.quoteItemCommonId = options.quoteItemCommonId;
        this.objectReference = options.objectReference;
        this.view = new V6QuoteItemView(this, this, options.propertyTemplate, options.readonly, this.quoteIGUProvider);

        this.quoteItemTracker.item = this.emptyQuoteItem();
        //lets force a blank rendering just in case it is displayed before we have any real data
        this.load({
            quoteItem: this.quoteItemTracker.item
        });
    }

    public get isUpdating(): boolean {
        return this._isUpdatingQuote;
    }

    public set isUpdating(value: boolean) {
        if (this._isUpdatingQuote !== value) {
            this._isUpdatingQuote = value;
            this.view.requestRedraw();
            if (this.updatingChangedEvent) this.updatingChangedEvent();

        }
    }

    public get viewContainer(): HTMLElement | null {
        if (this.view instanceof V6QuoteItemView) {
            return this.view.container;
        } else
            throw new Error("container not available in modal form");
    }

    /// perform different operations depending on which options are passed in.
    /// if quoteItem is not null, it is immediately assigned in

    public set viewContainer(container: HTMLElement | null) {
        if (this.view instanceof V6QuoteItemView) {
            this.view.container = container;
        } else
            throw new Error("container not available in modal form");
    }

    public get quoteItem(): data.V6QuoteItem | null {
        return this.quoteItemTracker?.item;
    }

    //setting the quote item like this externally is only done when opening up a new session for the first time.
    public set quoteItem(quoteItem: data.V6QuoteItem | null) {
        this.quoteItemTracker.item = quoteItem;
        this.quoteItemTracker.v6DataTrackingId = emptyGuid;
        this.quoteItemTracker.v6DataSessionId = null;
        this.view.quoteItemUpdated();
    }

    //  if quoteItem is null, supplierid and framecode must not be null
    public load(options: { quoteItem?: data.V6QuoteItem | null, supplierId?: string | null, frameCode?: string | null; }): Promise<void> {
        if (options?.quoteItem) {
            this.quoteItem = options?.quoteItem;
            return Promise.resolve();
        } else if (options?.frameCode && options?.supplierId) {
            return this.loadFrameData(options?.supplierId, options?.frameCode);
        } else return Promise.resolve();
    }

    dispose() {
        this.view.container = null;
    }

    //this routine is encapsulating the raw execution to the v6 server, and planting and
    //receiving tracking information to allow state maintenance on the server for

    private getQuoteItemPricePromise: NullPromise<V6QuoteItemPrice> | null = null;
    public async getQuoteItemPrice(): NullPromise<V6QuoteItemPrice> {
        console.log('start getQuoteItemPrice()');
        const val = await multiCastPromise(this.getQuoteItemPricePromise,
            (p) => this.getQuoteItemPricePromise = p,
            async () => {
                let resultPrice = this.quoteItemTracker.v6DataTrackingId == emptyGuid || this.quoteItemTracker.v6DataSessionId == null
                    ? null
                    : await this.internalGetQuoteItemPrice();

                if (!resultPrice || resultPrice?.v6DataTrackingId !== this.quoteItemTracker.v6DataTrackingId) {
                    await this.updateAndRefreshQuoteItem();
                    resultPrice = await this.internalGetQuoteItemPrice();
                }
                return resultPrice?.quoteItemPrice ?? null;
            });
        console.log('end getQuoteItemPrice()');
        return val;
    }
    //this is called by any View Items that edit their content, triggering a full refresh
    public onAttributeChange(attr: data.V6Property) {
        //we have just received an update of a frame attribute event.
        //we want to send this to the server, and when we get a reply we are going to
        //update the view. we are not going to wait or block at this point in time.
        //we really dont care particularly about the attr. this is just an indicator
        //of the attr switched that is causing us to run the update.
        //we always send the entire model back to the server, as we never know
        //what state the server is in, being semi stateless.
        const lock = this.isUpdating;
        this.isUpdating = true;
        this.updateAndRefreshQuoteItem()
            .catch(() => {
                console.log('error while updating quote Item attribute on the server');
                attr.value = attr.originalValue;
                this.view.requestRedraw();
            })
            .finally(() => {
                this.isUpdating = lock;
            });
    }
    //default item for rendering a blank display before a real item is retrieved for the first time
    private emptyQuoteItem(): data.V6QuoteItem {
        return {
            supplierId: "",

            frameData: {
                bounds: "",
                boundaryName: "",
                description: "loading....",
                objectReference: "",
                attributeGroups: [],
                nestedFrames: []
            },
            messages: [],
            thumbnail: "",
            quoteItemOptions: [],
            annotationIssues: [],
            validationIssues: []
        };
    }

    private async loadFrameData(supplierId: string, frameCode: string): Promise<void> {
        this.quoteItemTracker.v6DataTrackingId = emptyGuid;
        this.quoteItemTracker.v6DataSessionId = null;
        this.quoteItemTracker.item = {
            supplierId: supplierId,
            frameData: {
                objectReference: frameCode,
                bounds: "",
                boundaryName: "",
                description: "",
                attributeGroups: [],
                nestedFrames: []

            },
            quoteItemOptions: this.getDefaultsForQuoteItem(),
            messages: [],
            annotationIssues: [],
            validationIssues: []
        };
        await this.updateAndRefreshQuoteItem();
    }

    private async internalGetQuoteItemPrice(): Promise<ResultGetV6ItemPrice | null> {
        return await this.api.post<ResultGetV6ItemPrice>('api/v6/configitem/getitemprice',
            {//RequestV6QuoteItem.cs
                supplierId: this.quoteItemTracker.item?.supplierId,
                v6DataTrackingId: this.quoteItemTracker.v6DataTrackingId,
                v6DataSessionId: this.quoteItemTracker.v6DataSessionId,
                quantity: this.quantityProvider()
            });
    }

    //optimization
    private async updateAndRefreshQuoteItem(): Promise<void> {

        if (!this.quoteItemTracker.item) return;
        const igus = data.getIGUSubsetInUse(this.quoteItemTracker.item, await this.quoteIGUProvider());
        const resultQuoteItem = await this.api.post<ResultQuoteItem>(v6GetFrameInformationURI,
            {//RequestV6QuoteItem.cs
                supplierId: this.quoteItemTracker.item?.supplierId,
                v6DataTrackingId: this.quoteItemTracker.v6DataTrackingId,
                v6DataSessionId: this.quoteItemTracker.v6DataSessionId,
                frameData: this.quoteItemTracker.item?.frameData,
                quoteIGUs: igus,
                forceIGUUpdate: true,
                defaultQuoteOptions: this.getDefaultsForQuoteItem(),
                quoteItemOptions: this.quoteItemTracker.item?.quoteItemOptions,
                itemQuantity: this.quantityProvider(),
                quoteItemCommonId: this.quoteItemCommonId()
            });

        if (!resultQuoteItem) {
            //do not update any details if we dont have a result.
            //this will be handled higher up
            throw new Error("Quote Item Update Failed");
        }
        this.quoteItemTracker.v6DataTrackingId = resultQuoteItem?.v6DataTrackingId ?? emptyGuid;
        this.quoteItemTracker.v6DataSessionId = resultQuoteItem?.v6DataSessionId;
        this.quoteItemTracker.item = resultQuoteItem?.item ?? null;
        this.view.quoteItemUpdated();
        if (this.onDataRefreshed && resultQuoteItem?.item) await this.onDataRefreshed(resultQuoteItem?.item);

    }

}