import * as uuid from "uuid";

export const emptyGuid = '00000000-0000-0000-0000-000000000000';

export const newGuid: () => string = self.crypto && (self.crypto as any).randomUUID
    ? (): string => {
        return (self.crypto as any).randomUUID();
    }
    : (): string => {
        return uuid.v4();
    };
