// eslint-disable-next-line import/named
import { html, TemplateResult } from "lit";
import { QuoteItemType } from "../../api/dealer-api-interface-quote";
import { isAutoSaving } from "../../components/save-workflow";

import { DataTracker, FieldType } from "../../components/ui/databinding/data-tracker";
import { DataBinding } from "../../components/ui/databinding/databinding";
import { PromiseSnippet, PromiseTemplate } from "../../components/ui/events";
import { ModalDialog } from "../../components/ui/modal-base";

import { FormInputAssistant } from "../../components/ui/templateresult/form-input-assistant";
import { tlang } from "../../language/lang";
import { DevelopmentError } from "../../development-error";
import { quoteItemContentType } from "../data/default-quote-item-content-type";
import { QuoteItemContainer } from "../data/quote-item-container";
import { QuoteItemView } from "./quote-item-view";
import { newGuid } from "../../api/guid";

export class FreehandLineItemDialog extends ModalDialog {
    quoteItemContainer: QuoteItemContainer;
    dataTracker: DataTracker;
    ok = false;
    interval: NodeJS.Timeout | null = null;
    forceReadonly: boolean;

    constructor(quoteItemContainer: QuoteItemContainer, forceReadonly: boolean) {
        super();
        this.forceReadonly = forceReadonly;
        if (quoteItemContainer.item.itemType !== QuoteItemType.Basic) throw new DevelopmentError(`freehand-line-item-view, ${quoteItemContainer.item.description} is not a freehand`);
        if (quoteItemContainer.item.quoteItemContentType !== quoteItemContentType.freehand) throw new DevelopmentError(`quote item ${quoteItemContainer.item.title} is not a freehand item`);

        this.quoteItemContainer = quoteItemContainer;

        const item = this.quoteItemContainer.item;
        const price = this.quoteItemContainer.price;
        this.dataTracker = new DataTracker(new DataBinding(this.ui));

        this.dataTracker.addObjectBinding(() => item, 'title', 'title', FieldType.string, false);
        this.dataTracker.addObjectBinding(() => item, 'description', 'description', FieldType.string, false);
        this.dataTracker.addObjectBinding(() => item, 'quantity', 'quantity', FieldType.int, false);

        this.dataTracker.addObjectBinding(() => item, 'comment', 'comment', FieldType.string, false);
        this.dataTracker.addObjectBinding(() => price, 'singleUnitCost', 'singleUnitCost', FieldType.money, false);

    }
    protected async canClose(): Promise<boolean> {
        if (isAutoSaving())
            this.save();
        return true;
    }
    protected async getTitle(): PromiseSnippet {
        this.ui.addEventListener('shown.bs.modal', this.setFocusAndSelect);
        return tlang`%%freehand%%`;
    }
    protected modalSize(): string {
        return "modal-md";
    }
    protected save() {
        if (this.forceReadonly) return;
        this.dataTracker.applyChangeToValue();
        this.quoteItemContainer.price.quantityCost = this.quoteItemContainer.price.singleUnitCost * this.quoteItemContainer.item.quantity;
        this.ok = true;
    }

    private setFocusAndSelect(_event: Event) {
        setTimeout(() => {
            super.setFocusOnInputElementAndSelect("freehand-item-editor-container", "title-");
          }, 0);
      }

    protected renderFooterTemplate(): boolean {
        return !isAutoSaving();
    }
    protected footerTemplate(): TemplateResult {
        const saveEvent = () => {
            this.save();
            this.hideModal();
        };
        const closeEvent = () => this.hideModal();
        const template = this.forceReadonly
            ? html`
            <button @click=${closeEvent} class="btn btn-secondary">Close</button>`
            : html`
            <button @click=${closeEvent} class="btn btn-secondary">Close</button>
            <button @click=${saveEvent} class="btn btn-primary">Save</button>
        `;
        return template;
    }


    protected async bodyTemplate(): PromiseTemplate {
        const forms = new FormInputAssistant(this.dataTracker, this.forceReadonly);
        return html`
            <form class="freehand-item-editor-container form-one-col">
                <div class="row">
                    <div class="form-column">
                        ${forms.text("title")}
                        ${forms.text("description")}
                        ${forms.int("quantity")}
                        ${forms.money("singleUnitCost", "Unit Cost", 2)}
                        ${forms.note("comment", "Comment", 500)}
                    </div>
                </div>
            </form>
     `;
    }

    protected getCloseMessage(): string {
        return '(Autosave enabled)';
    }
}
//base class to manage the intricacies of quoteitems that may be v6 or otherwise
export class FreehandLineItemView extends QuoteItemView {

    //this should be called immediately after the constructor and if the return result is not
    //true then this page is not valid, and should be assumed to be cancelled
    public async prepareEditor(): Promise<void> {
        if (this.quoteItemContainer) {
            this._readyToEdit = true;
        } else {
            this._readyToEdit = await this.createQuoteItem();
        }

    }

    async createQuoteItem(): Promise<boolean> {
        const result = await this.quoteManager.createQuoteItem({
            id: newGuid(),
            title: tlang`New %%freehand%%`,
            description: "",
            quantity: 1,
            comment: "",
            quoteItemContentType: quoteItemContentType.freehand,
            externalProvider: null,
            price: {
                singleUnitCost: 0,
                supplierGrossSingleUnitCost: 0
            },
            thumbnail: null
        });
        if (result) {
            this.quoteItemContainer = result;
            return true;
        }
        return false;
    }

    public get hasPropertyDialog(): boolean {
        return true;
    }
    public get hasModalEditDialog(): boolean {
        return true;
    }
    public async executeModalEditDialog(): Promise<void> {
        if (!this.quoteItemContainer) return;
        const dlg = new FreehandLineItemDialog(this.quoteItemContainer, this.quoteManager.isReadonly());
        await dlg.showModal();
        if (dlg.ok) {
            this.quoteItemContainer = await this.quoteManager.saveAndUpdateQuoteItemWithIndicator(this.quoteItemContainer, "");
        }
    }


}