import { ApiCommunications } from "../../api/api-communications";
import { NullPromise } from "../../null-promise";
import { ItemReference } from "../../cache/definitions/cache-item-reference";
import { DataCacheBase } from "../../cache/data-cache-base";
import { PurchaseOrder, RequestGetPurchaseOrdersById, ResultGetPurchaseOrdersById } from "../../api/dealer-api-interface-franchisee";
import { getPurchaseOrderNumberFormatted } from "../../purchase-orders/data/purchase-order-helper-functions";
import { PurchaseOrderCacheData } from "./cache-data";


export class PurchaseOrderCache extends DataCacheBase<PurchaseOrderCacheData> {
    constructor(api: ApiCommunications) {
        super(api);
    }

    protected async processInternalFetch(resultPurchaseOrder: ResultGetPurchaseOrdersById | null): NullPromise<ItemReference<PurchaseOrderCacheData>[]> {
        if (!resultPurchaseOrder) return null;

        return resultPurchaseOrder.purchaseOrders.map(q => {
            const qvs: ItemReference<PurchaseOrderCacheData> = {
                id: q.id,
                displayValue: `${getPurchaseOrderNumberFormatted((q as any) as PurchaseOrder)}`,
                data: {
                    purchaseOrder: q
                },
                onClick: null
            };
            return qvs;
        });
        //
    }

    //override
    protected async internalFetch(requestIds: string[]): NullPromise<ItemReference<PurchaseOrderCacheData>[]> {
        const input: RequestGetPurchaseOrdersById = {
            purchaseOrderIds: requestIds
        };

        const purchaseOrderPromise = this.api.post<ResultGetPurchaseOrdersById>(`api/Franchisee/GetPurchaseOrdersById`, input);

        await Promise.all([purchaseOrderPromise]);

        const resultPO = await purchaseOrderPromise;
        return await this.processInternalFetch(resultPO);
    }
}

