// eslint-disable-next-line import/named
import { html } from "lit";
import { DataTracker, FieldType } from "../../../../components/ui/databinding/data-tracker";
import { DataBinding } from "../../../../components/ui/databinding/databinding";
import { PromiseTemplate } from "../../../../components/ui/events";
import { FormInputAssistant } from "../../../../components/ui/templateresult/form-input-assistant";
import { tlang } from "../../../../language/lang";

import { QuoteItemsView, QuoteItemsViewOptions } from "../../../../quotes/views/quote-items-view";

declare global {
  interface HTMLElementTagNameMap {
    'summary-price-table': any;
    'price-controller': any;
    'price-repository': any;
  }

}

export class FranchiseeQuoteItemsViewV7 extends QuoteItemsView {
  dataBinding: DataBinding;
  dataTracker: DataTracker;

  constructor(options: QuoteItemsViewOptions) {
    super(options);

    this.dataBinding = new DataBinding(this.ui);
    this.dataTracker = new DataTracker(this.dataBinding);

    const addField = (fieldName: string, propertyType?: FieldType, nullable?: boolean, editorFieldName?: string, data?: () => any) => {
      this.dataTracker.addObjectBinding(data ?? (() => this.quoteManager.container.quotePrice), fieldName, editorFieldName ?? fieldName, propertyType ?? FieldType.string, nullable ?? false);
    };

    addField("termsAndConditions", FieldType.string, true, undefined, () => this.quoteManager.quote);
  }

  public async prepareForSave() {
    this.dataTracker.applyChangeToValue();
  }

  protected async template(): PromiseTemplate {
    const forms = new FormInputAssistant(this.dataTracker);
    const pricingController = window.softtech.pricingEngineController;
    if (pricingController) {
      pricingController.initialize(this.quoteManager.quote.priceId);
    }

    return html`
        <div>
          ${this.table.ui}
        </div>
        <form class="py-3 px-0 quote-price-summary form-two-col ">
          <div class="row">
            <div class="quote-items-terms">
              ${forms.note("termsAndConditions", tlang`Terms And Conditions`)}
            </div>
            <div class="quote-items-summary">
              <summary-price-table></summary-price-table>
              </div>
            </div>
        </form>
        `;
  }
}
