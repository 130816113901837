//this should be a generic unit. unbound from routes. routes is an implementation specific

import { Router } from '@vaadin/router';
import type { Params } from '@vaadin/router';
import type { Route } from '@vaadin/router';
import { getCurrentUser } from '../api/current-user';

export const router = new Router();

export function setRoutes(routes: Route[]) {
  router.setRoutes([
    // Redirect to URL without trailing slash
    {
          path: '(.*)/',
          action: (context, commands) => {
              const newPath = context.pathname.slice(0, -1);
              return commands.redirect(newPath);
          },
      },
      {
          path: '/',
          action: (context, commands) => {
              if (context.pathname === '/') {
                  // do nothing
              }

              if (getCurrentUser()?.isSupplier) {
                  const newPath = '/supplier';
                  return commands.redirect(newPath);
              } else {
                  const newPath = '/quotes';
                  return commands.redirect(newPath);
              }
          },
      },
      ...routes,
  ]);
}
export const attachRouter = (outlet: HTMLElement) => {
  router.setOutlet(outlet);
};

export const urlForName = (name: string, params?: Params) => {
  return router.urlForName(name, params);
};
