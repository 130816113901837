import { clone, compare } from "../../components/clone";
import { tlang } from "../../language/lang";
import { fireQuickSuccessToast } from "../../toast-away";
import { Project, ProjectState, Resource } from "../../api/dealer-api-interface-project";
import { ProjectApi } from "../../api/project-api";
import { QuoteApi } from "../../api/quote-api";
import { getApiFactory } from "../../api/api-injector";
import { getProjectNumberFormatted } from "../../dealer-franchisee/projects/data/project-helper-functions";

export class ProjectContainer {
    projectId: string;
    project: Project | null;
    resources: Resource[] | null;

    constructor(projectId: string,
        project: Project | null,
        resources: Resource[] | null) {
        this.projectId = projectId;
        this.project = project;
        this.resources = resources;
    }
}

export type EventNotify = (() => Promise<void>);

export class ProjectContainerManager {
    backup: ProjectContainer;
    container: ProjectContainer;
    api: ProjectApi = getApiFactory().project();
    quoteApi: QuoteApi = getApiFactory().quote();
    afterSave: EventNotify[] = [];

    constructor(original: ProjectContainer, projectApi: ProjectApi) {
        this.api = projectApi;

        if (original.project && original.project.id !== original.projectId) throw new Error(`Invalid argument Project Id must match projectId`);

        this.container = original;
        this.backup = this.clone(this.container);
    }

    /**
     * the id for this managed container
     */
    get projectId(): string {
        return this.container.projectId;
    }

    /**
     * returns the project object after needsProject is called, or throws an error if the project is unavailable.
     */
    get project(): Project {
        if (!this.container.project) {
            throw new Error(tlang`Project is null`);
        }
        return this.container.project;
    }

    public get projectTitle(): string {
        return tlang`#${getProjectNumberFormatted(this.project)} - ${this.project.title}`;
    }

    /**
     * Simple wrapper around structuredClone
     * @param item an object of any basic type to clone
     * @returns
     */
    clone<ItemType>(item: ItemType): ItemType {
        return clone(item);
    }

    /**
     * this will ensure at an async level that the project property is valid, before accessing the property synchronously
     * @returns true if the project property is now valid
     */
    async needsProject(): Promise<boolean> {
        if (!this.container.project) {
            const result = await this.api.getProject({ projectId: this.projectId });
            if (result) {
                this.resetProject(result.project, result.resources);
            } else return false;
        }
        return true;
    }

    async refreshProject(): Promise<void>{
        const result = await this.api.getProject({ projectId: this.projectId });
        if (result)
            this.resetProject(result.project, result.resources);
    }

    /**
     * this is called to send all the project information to the server.
     */
    public async saveProject(silently?: boolean): Promise<boolean> {
        const result = await this.api.updateProject({ project: this.project });
        if (result) {
            this.resetProject(result.project, result.resources);
            if (!silently)
                fireQuickSuccessToast(tlang`%%project%% Saved "${this.projectTitle}"`);

            await this.doAfterSave();
            return true;
        }
        return false;
    }

    /**
     * checks if there were any changes made
     */
    projectChanged(): boolean {
        return !compare(this.backup.project, this.container.project);
    }

    public changed(): boolean {
        return !compare(this.backup, this.container);
    }

    public isReadonly(): boolean {
        return this.project.state != ProjectState.Active;
    }

    /**
     * replaces the backups and originals of objects with this new set of objects to become the master
     * @param project
     */
    private resetProject(project: Project, resources: Resource[]) {
        this.container.project = project;
        this.backup.project = this.clone(project);

        this.container.resources = resources;
        this.backup.resources = this.clone(resources);
    }

    /**
     * execute all bound events after any save operation to allow for re-rendering and refreshing of state
     */
    private async doAfterSave(): Promise<void> {
        this.afterSave.forEach(async (afterSaveEvent) => await afterSaveEvent());
    }
}