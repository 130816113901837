import {
    PurchaseOrderDetailView,
    PurchaseOrderDetailViewOptions
} from "../../../purchase-orders/views/purchase-order-detail-view";
import { DataBinding } from "../../../components/ui/databinding/databinding";
import { DataTracker, FieldType } from "../../../components/ui/databinding/data-tracker";
// eslint-disable-next-line import/named
import { html } from "lit";
import { FormInputAssistant } from "../../../components/ui/templateresult/form-input-assistant";
import { tlang } from "../../../language/lang";
import { moneyToStr } from "../../../components/currency-formatter";
import { isEmptyOrSpace } from "../../../components/ui/helper-functions";
import { PromiseTemplate, Snippet } from "../../../components/ui/events";
import { PurchaseOrderState } from "../../../api/dealer-api-interface-franchisee";
import { PurchaseOrderApi } from "../../../api/purchase-order-api";
import { getApiFactory } from "../../../api/api-injector";

export class FranchiseePurchaseOrderDetailView extends PurchaseOrderDetailView {
    private readonly dataBinding: DataBinding;
    private readonly dataTracker: DataTracker;
    private purchaseOrderApi: PurchaseOrderApi = getApiFactory().purchaseOrder();

    constructor(options: PurchaseOrderDetailViewOptions) {
        super(options);

        this.dataBinding = new DataBinding(this.ui, undefined, (input: string, internalId: string) => `purchase-order-${input}-${internalId}`);
        this.dataTracker = new DataTracker(this.dataBinding);

        const addField = (fieldName: string, propertyType?: FieldType, nullable?: boolean, editorFieldName?: string, data?: () => any) => {
            this.dataTracker.addObjectBinding(data ?? (() => this.purchaseOrderManager.container.purchaseOrder),
                fieldName, editorFieldName ?? fieldName, propertyType ?? FieldType.string, nullable ?? false);
        };

        addField("title");
        addField("installationDate", FieldType.date, true);
        addField("quoteDescription", FieldType.string, false);
        addField("projectDescription", FieldType.string, false);
        addField("notesToVendor", FieldType.string, false);
    }

    public override async prepareForSave(): Promise<void> {
        await this.purchaseOrderManager.needsPurchaseOrder();
        this.dataTracker.applyChangeToValue();
    }

    public override getValidationErrors(): string[] {
        const errors = super.getValidationErrors();

        if (this.purchaseOrderManager.container.purchaseOrder &&
            isEmptyOrSpace(this.purchaseOrderManager.container.purchaseOrder.title)) {
            errors.push(tlang`Please add a %%purchase-order%% Title`);
        }

        return errors;
    }

    public override buttonMenu(): Snippet {
        let src: string | null = null;

        if (this.purchaseOrderManager.purchaseOrder.state == PurchaseOrderState.Issued ||
            this.purchaseOrderManager.purchaseOrder.state == PurchaseOrderState.Completed) {
            src = this.purchaseOrderApi.api
                .fullUrl(`api/file/${this.purchaseOrderApi.createPurchaseOrderReportPath(this.purchaseOrderManager.purchaseOrderId)}`);
        }

        return src != null ?
            html`
                <a class="btn btn-secondary" href="${src}">Download Report</a>
            ` : html``;
    }

    protected override async template(): PromiseTemplate {
        const forms = new FormInputAssistant(this.dataTracker, this.purchaseOrderManager.isReadOnly());

        return html`
            <form class="py-3 px-0 frm-purchase-order-details form-two-col">
                <div class="row">
                    <div>
                        ${forms.textRequired("title", tlang`%%purchase-order%% Title`)}

                        <bs-form-display data-label="${tlang`%%supplier%% Reference`}"
                                         data-placeholder="${tlang`%%supplier%% Reference`}">
                        </bs-form-display>

                        ${forms.date("installationDate", tlang`Installation Date`, "date")}
                        ${forms.note("notesToVendor", tlang`Notes to Vendor`)}
                    </div>

                    <div>
                        ${forms.note("quoteDescription", tlang`%%quote%% Description`)}
                        ${forms.note("projectDescription", tlang`%%project%% Description`)}
                    </div>
                </div>
            </form>

            <h2 class="section-header">${tlang`%%purchase-order%% Items`}</h2>
            <div>
                ${this.table.ui}
            </div>

            <form class="py-3 px-0 purchase-order-price-summary form-two-col">
                <div class="row">
                    <div class=""></div>

                    <div class="purchase-order-items-summary">
                        <div class="purchase-order-items-summary-wrapper">
                            <div class="row">
                                <div class="order-price-summary">
                                    <div class="order-price-summary-wrapper">
                                        <bs-form-display data-label="${tlang`Sub Total`}"
                                                         value="${moneyToStr(this.purchaseOrderManager.purchaseOrder.calculatedNetTotal)}">
                                        </bs-form-display>

                                        <bs-form-display
                                                data-label="${tlang`Tax`}"
                                                value="${moneyToStr(this.purchaseOrderManager.purchaseOrder.calculatedTaxAmount)}">
                                        </bs-form-display>

                                        <bs-form-display data-label="${tlang`Total`}"
                                                         value="${moneyToStr(this.purchaseOrderManager.purchaseOrder.calculatedGrossTotal)}">
                                        </bs-form-display>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        `;
    }
}