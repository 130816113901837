//a return result that must have and object reference, a code, and an attached resource with

import { ApiCommunications } from "../../api/api-communications";
import { ResourceFamilyPickerDataProviderBase, ResourceFamilyResults } from "../../picker/family-resource-picker-data";

// a means of communicating with a service to get data for the picker

//base class for fetching paginated resource data from the servers
export class V6ResourceFamilyPickerDataProvider extends ResourceFamilyPickerDataProviderBase {
    supplierId: string;
    api: ApiCommunications;
    resourceClass: number;
    family: string;
    tag: string;
    constructor(api: ApiCommunications, supplierId: string, family: string, resourceClass: number, tag: string) {
        super();
        this.api = api;

        this.supplierId = supplierId;
        this.family = family;
        this.resourceClass = resourceClass;
        this.tag = tag;
    }

    public getResourceClass(): number {
        return this.resourceClass;
    }
    protected path(): string {
        return 'api/v6/ConfigItem/GetResourceBrowserInformation';
    }
    //incase the filter we have is not the same as what must be provided to the server
    public getResourceImageUrl(resourceClass: number, libId: number, id: number): string {
        return this.api.fullUrl(`api/v6/ConfigItem/Resource/${this.supplierId}/${resourceClass}/${libId}/${id}/resfile.jpg`);
    }

    public getFamilyImageUrl(resourceClass: number, libId: number, id: number): string {
        return this.api.fullUrl(`api/v6/ConfigItem/Family/${this.supplierId}/${resourceClass}/${libId}/${id}/resfile.jpg`);
    }
    public async getData(): Promise<ResourceFamilyResults | null> {
        return (await this.api.post<ResourceFamilyResults>(this.path(),
            {
                "supplierCode": this.supplierId,
                "familyReference": this.family,
                "resourceClass": this.getResourceClass(),
                "tags": this.tag
            }
        )) ?? null;
    }
}

