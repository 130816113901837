import '../styles/custom.scss';

import apiHost from './apiHost';
import langFile from '../assets/lang/lang.json';

import '@softtech/v7-component-library';

function documentReady(fn) {
    // see if DOM is already available
    if (
            document.readyState === "complete" ||
            document.readyState === "interactive"
    ) {
        // call on next available tick
        setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

documentReady(async () => {
    let countdown = 10;
    const interval = setInterval(() => {
        countdown--;
        const c = document.body.querySelector('#counter');
        if (c) c.innerHTML = countdown < 0 ? `Houston, we have a problem. please try to force-refresh ` : `(${countdown})`;
        if (countdown < 0) clearInterval(interval);

    }, 1000);

    const licenseServerHost = await (await fetch(`${apiHost.host}/api/System/LicenseServer`)).json();


    globalThis.dealerConfiguration = {
        apiHost: apiHost.host,
    licenseServerHost: licenseServerHost.host,
    dealerDeploymentId: licenseServerHost.dealerDeploymentId,
    lang: langFile
    };

    globalThis.pricingEngineConfiguration = {
        pricingApiUrl: new URL(apiHost.pricingEngineApiUrl)
    };

    window.softtech = {
        ...(window.softtech ?? {}),
        pricingEngineConfig: { pricingEngineApiUrl: apiHost.pricingEngineApiUrl },
        orderServiceConfig: { backendUrl: apiHost.orderServiceApiUrl },
    } as any;

    globalThis.frameEngineConfiguration = {
        frameEngineApiUrl: new URL(apiHost.frameEngineApiUrl)
    };

    globalThis.reportingServiceConfiguration = {
        reportingServiceApiUrl: apiHost.reportingServiceApiUrl,
    };


    globalThis.orderServiceConfiguration = {
        ApiUrl: new URL(apiHost.orderServiceApiUrl),
    };

    //`<franchisee-app-index appTitle="Dealer WebModule" class="d-flex flex-column min-vh-100"></franchisee-app-index>`
    const mainElement = document.createElement('franchisee-app-index');
    mainElement.setAttribute('appTitle', "Dealer WebModule");
    mainElement.className = "d-flex flex-column min-vh-100";
    document.body.querySelector('#splash-screen')?.remove();
    clearInterval(interval);
    document.body.insertBefore(mainElement, mainElement.firstChild);
    addFrameEngineScripts(apiHost.frameEngineUI);
    addPricingEngineScript(apiHost.pricingEngineUiUrl);
    addPickerServiceScripts(apiHost.pickerServiceUrl);
    addOptimizerServiceScripts(apiHost.optimizerServiceUrl);
    addOrderServiceScripts(apiHost.orderServiceUiUrl);
    addIngestionServiceScripts(apiHost.ingestionServiceUiUrl);
});

function addFrameEngineScripts(frameEngineUiUrl) {
    if(!frameEngineUiUrl){
        console.error('Url For Frame Engine UI is unset');
    }
    const addScriptToBody = (scriptName) => {
        const scriptTag = document.createElement('script');
        scriptTag.src = new URL(scriptName, frameEngineUiUrl).toString();
        document.body.append(scriptTag);
    }

    addScriptToBody('frame-engine-ui.bundle.js');
}

function addPricingEngineScript(pricingEngineUrl)
{
    if(!pricingEngineUrl){
    console.error('Url For Pricing Engine UI is unset');
    }

    const striptTag = document.createElement('script');
    striptTag.src = new URL('/pricingComponent.js', pricingEngineUrl).toString();
    document.body.append(striptTag);
}

function addPickerServiceScripts(pickerServiceUiUrl) {
    if(!pickerServiceUiUrl){
        console.error('Url For Picker Service UI is unset');
    }
    const addScriptToBody = (scriptName) => {
        const scriptTag = document.createElement('script');
        scriptTag.src = new URL(scriptName, pickerServiceUiUrl).toString();
        document.body.append(scriptTag);
    }

    addScriptToBody('components.bundle.js');
}

function addOptimizerServiceScripts(optimizerServiceUiUrl) {
    if(!optimizerServiceUiUrl){
        console.error('Url For Optimizer Service UI is unset');
    }
    const addScriptToBody = (scriptName) => {
        const scriptTag = document.createElement('script');
        scriptTag.src = new URL(scriptName, optimizerServiceUiUrl).toString();
        document.body.append(scriptTag);
    }

    addScriptToBody('optimizer-service.bundle.js');

}

function addOrderServiceScripts(orderServiceUiUrl) {
    if(!orderServiceUiUrl){
        console.error('Url For Order Service UI is unset');
    }
    const addScriptToBody = (scriptName) => {
        const scriptTag = document.createElement('script');
        scriptTag.src = new URL(scriptName, orderServiceUiUrl).toString();
        document.body.append(scriptTag);
    }

    addScriptToBody('order-service.bundle.js');
    addScriptToBody('quote-order-number.bundle.js');
}

function addIngestionServiceScripts(ingestionServiceUiUrl) {
    if(!ingestionServiceUiUrl){
        console.error('Url For Ingestion Service UI is unset');
    }
    const addScriptToBody = (scriptName) => {
        const scriptTag = document.createElement('script');
        scriptTag.src = new URL(scriptName, ingestionServiceUiUrl).toString();
        document.body.append(scriptTag);
    }

    addScriptToBody('ingestion.bundle.js');
}
