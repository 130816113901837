import { ApiCommunications } from "../../api/api-communications";
import { ClientPrimaryContact, RequestGetClientPrimaryContactByIds, ResultGetClientPrimaryContactByIds } from "../../api/dealer-api-interface-client";
import { NullPromise } from "../../null-promise";
import { ItemReference } from "../../cache/definitions/cache-item-reference";
import { DataCacheBase } from "../../cache/data-cache-base";



export class ClientPrimaryContactCache extends DataCacheBase<ClientPrimaryContact>{
    constructor(api: ApiCommunications) {
        super(api);
        //depending on what high level controller api we create we could swap this to ClientApi etc.
    }
    //override;
    protected async internalFetch(requestIds: string[]): NullPromise<ItemReference<ClientPrimaryContact>[]> {
        const input: RequestGetClientPrimaryContactByIds = {
            clientIds: requestIds
        };
        const result = await this.api.post<ResultGetClientPrimaryContactByIds>(`api/client/GetClientPrimaryContactByIds`, input);

        if (!result) return null;

        return result.clientPrimaryContacts.map(c => {

            const qcr: ItemReference<ClientPrimaryContact> = {
                id: c.id,
                displayValue: `N/A`,
                data: c,
                onClick: null
            };
            return qcr;
        });
    }
}

