import { ApiCommunications } from "../../api/api-communications";
import { NullPromise } from "../../null-promise";
import { ItemReference } from "../../cache/definitions/cache-item-reference";
import { DataCacheBase } from "../../cache/data-cache-base";
import { RequestGetResourcesListByIds, ResultGetResourcesListByIds } from "../../api/dealer-api-interface-project";
import { cache } from "./cache-registry";
import { ResourceCacheData } from "./cache-data";

export class ResourcesCache extends DataCacheBase<ResourceCacheData> {
    constructor(api: ApiCommunications) {
        super(api);
    }

    //override;
    protected async internalFetch(requestIds: string[]): NullPromise<ItemReference<ResourceCacheData>[]> {
        const input: RequestGetResourcesListByIds = {
            resourceIds: requestIds
        };

        const resourcePromise = this.api.post<ResultGetResourcesListByIds>(`api/Project/GetResourcesListByIds`, input);
        const resultResources = await resourcePromise;
        if (!resultResources) return null;

        const items = resultResources.resources.map(q => {
            const qvs: ItemReference<ResourceCacheData> = {
                id: q.resourceId,
                displayValue: `${q.projectId}`,
                data: { resource: q },
                onClick: null
            };
            return qvs;
        });

        //TODO: Should we use one cache to load up another?
        const projectIds = [...new Set(items.map(item => item.displayValue))];
        await cache().project.getMany(projectIds);

        return resultResources.resources.map(q => {
            const qvs: ItemReference<ResourceCacheData> = {
                id: q.resourceId,
                displayValue: `${q.projectId}`,
                data: { resource: q },
                onClick: null
            };
            return qvs;
        });
    }
}
