import { ApiCommunications } from "../../../api/api-communications";
import { supplierQuoteItemContentType } from "../../../quotes/data/supplier-quote-item-content-type";
import { FamilyResourceTag, ResourceFamilyEndpoint, ResourceFamilyResults } from "../../../picker/family-resource-picker-data";
import { ResourceTag } from "../../v6-picker/v6-resource-picker-data-provider";
import { V6ListItem, V6QuoteIGU } from "../v6-quote-item-data";

export class V6StaticFamilyDataEndpoint implements ResourceFamilyEndpoint {
    api: ApiCommunications;
    supplierId: string;
    familyData: ResourceFamilyResults;
    constructor(api: ApiCommunications, supplierId: string, familyData: ResourceFamilyResults) {
        this.api = api;
        this.supplierId = supplierId;
        this.familyData = familyData;
    }

    getResourceClass(): number {
        //is not used
        return -1;
    }
    async getData(): Promise<ResourceFamilyResults | null> {
        return this.familyData;
    }
    //incase the filter we have is not the same as what must be provided to the server
    public getResourceImageUrl(resourceClass: number, libId: number, id: number): string {
        return this.api.fullUrl(`api/v6/ConfigItem/Resource/${this.supplierId}/${resourceClass}/${libId}/${id}/resfile.jpg`);
    }
    //incase the filter we have is not the same as what must be provided to the server
    public getFamilyImageUrl(resourceClass: number, libId: number, id: number): string {
        return this.api.fullUrl(`api/v6/ConfigItem/Family/${this.supplierId}/${resourceClass}/${libId}/${id}/resfile.jpg`);
    }

}

export function convertListItemsToFamilyData(data: V6ListItem[]): ResourceFamilyResults {
    const result: ResourceFamilyResults = {
        family: {
            name: "",
            libId: 0,
            id: 0,
            families: [],
            items: data.map(x => {
                const ids = x.imageReference.split('|');
                const rt: FamilyResourceTag = {
                    objectReference: x.code,
                    code: x.code,
                    libId: parseInt(ids[2]),
                    id: parseInt(ids[3]),
                    description: x.description,
                    resourceClassId: parseInt(ids[0])
                };
                return rt;
            })
        }
    };
    return result;

}


export function convertResourceTagsFamilyData(classId: number, data: ResourceTag[]): ResourceFamilyResults {
    const result: ResourceFamilyResults = {
        family: {
            name: "",
            libId: 0,
            id: 0,
            families: [],
            items: data.map(x => {

                const rt: FamilyResourceTag = {
                    objectReference: x.objectReference,
                    code: x.code,
                    libId: x.libId,
                    id: x.id,
                    description: x.description,
                    resourceClassId: classId
                };
                return rt;
            })
        }
    };
    return result;

}

export function convertQuoteIGUsToFamilyData(familyName: string, data: V6QuoteIGU[]): ResourceFamilyResults | null {
    if (data.length === 0) return null;
    const result: ResourceFamilyResults = {
        family: {
            name: familyName,
            libId: 0,
            id: 0,
            families: [],
            items: data.map(x => {
                const rt: FamilyResourceTag = {
                    objectReference: x.objectReference,
                    code: x.code,
                    libId: x.iguLibId,
                    id: x.iguId,
                    description: x.description,
                    resourceClassId: supplierQuoteItemContentType.CID_IGU
                };
                return rt;
            })
        }
    };
    return result;

}