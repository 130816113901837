// eslint-disable-next-line import/named
import { html } from "lit";

import { ApiCommunications } from "./api/api-communications";
import { ModalDialog } from "./components/ui/modal-base";

import { NullPromise } from "./null-promise";

import { getCurrentUser, ResultTenantLogin, setApiToken, setCurrentUser, UserPublicInfo } from "./api/current-user";
import { jsonRequest } from "./api/api-request";
import { tlang } from "./language/lang";
import { PromiseSnippet, PromiseTemplate } from "./components/ui/events";
import { multiCastPromise } from "./multicast-promise";
import { fireQuickErrorToast, fireQuickSuccessToast } from "./toast-away";
import { ServiceResponseType, ResultGetResetTenantPasswordServiceResponse, ResultConfirmLoginServiceResponse } from "./api/dealer-api-interface-user";
import { isEmptyOrSpace } from "./components/ui/helper-functions";

import PoweredBySofttechImage from '../assets/images/PoweredBySoftTech-blue.svg';

import DefaultLoginImage from '../assets/images/login-img.png';
import SecrureQuoteLoginImage from '../assets/images/SecureQuote_Splash.jpg';

//there can be cases where multiple asynchronous parts are all trying to run at the same time, and
//if we are not authenticated yet, would try to run many modals at the same time
//this promise is used to make all inputs wait on the same promise instance

const loginScreenImageMap = {
    '': DefaultLoginImage,
    'securequote': SecrureQuoteLoginImage
}

function getSupplierIdentifierFromUrl(): string {
    const urlParams = new URLSearchParams(location.search);
    const supplierParamValue = urlParams.get('supplier');
    return supplierParamValue?.toLowerCase() ?? '';
}
function getLoginImage(supplierId: string): string {
    return loginScreenImageMap[supplierId.toLowerCase()];
}

export async function run2FA() {
    await (new Enable2FADialog()).showModal();
}

export async function runResetPassword(userName?: string){
    if (isEmptyOrSpace(userName))
    {
        fireQuickErrorToast("Login name (email address) cannot be empty.");
        return;
    }
    const input = {
        userName: userName
    };
    const result = await jsonRequest<ResultGetResetTenantPasswordServiceResponse>('/api/User/GetResetTenantPassword', input, globalThis.dealerConfiguration.apiHost);
    if (result.status == 200 && result.value?.responseType === ServiceResponseType.Ok)
        fireQuickSuccessToast("Please, check your email for instruction to reset password.");
    else
        fireQuickErrorToast(`${result.value?.responseError?.message}`);

}

let _authenticationPromise: NullPromise<ResultTenantLogin> | null = null;
export async function TryAuthenticate(api: ApiCommunications, user: UserPublicInfo | null): NullPromise<ResultTenantLogin> {
    return multiCastPromise(
        _authenticationPromise,
        (p) => _authenticationPromise = p,
        async () => {
            const modal: AuthenticateModal = new AuthenticateModal(api, user);
            await modal.showModal();
            return modal.result;
        }
    );
}
//this will be replaced out by workbox with a real address

const signInMode = {
    signIn: 1,
    updatePassword: 2,
    get2FA: 3

};

export type EventCallback = (value: ResultTenantLogin) => void;
export class AuthenticateModal extends ModalDialog {
    result: ResultTenantLogin | null = null;
    password: string;
    api: ApiCommunications;
    error: string | null;
    loading = false;
    user: UserPublicInfo | null;
    userName: string;
    lockUser: boolean;
    signInMode: number = signInMode.signIn;
    passwordConfirm = "";
    passwordUpdateToken: any;
    needs2FA = false;
    code2FA = "";

    constructor(api: ApiCommunications, user: UserPublicInfo | null) {
        super();
        this.error = null;
        this.user = user;
        this.api = api;
        this.password = "";

        if (user)
            this.userName = user.userName ?? "";
        else
            this.userName = "";

        this.lockUser = user !== null;
    }

    //if the login happens inline as part of an expired token, make sure it comes to the front and
    //other inline progress dialogs do not over lap
    protected ZIndex(): number | undefined {
        //Override as needed
        return 888888;
    }
    protected modalSize(): string {
        return "modal-fullscreen-login login-page";
    }

    async cancel() {
        this.result = null;
        this.hideModal();
        await setCurrentUser(null);
        setApiToken(null);

    }
    async signIn(): Promise<void> {
        this.error = null;

        if (this.signInMode == signInMode.get2FA)
            this.code2FA = (this.ui.querySelector('#code2FA') as HTMLInputElement).value;
        else {
            this.code2FA = "";
            this.userName = (this.ui.querySelector('#username') as HTMLInputElement).value;
            this.password = (this.ui.querySelector('#password') as HTMLInputElement).value;
        }
        const supplierLogin = (this.ui.querySelector('#Input_SupplierLogin') as HTMLInputElement).checked;
        this.loading = true;
        await this.render();
        try {
            if (this.signInMode == signInMode.signIn || this.signInMode == signInMode.get2FA) {
                const input = {
                    userName: this.userName,
                    password: this.password,
                    dealerDeploymentId: globalThis.dealerConfiguration.dealerDeploymentId,
                    code2FA: this.code2FA
                };
                const loginApiEndpoint = supplierLogin ? 'api/Login/Supplier' : 'api/Login/Tenant';
                const result = await jsonRequest<ResultTenantLogin>(loginApiEndpoint, input, globalThis.dealerConfiguration.licenseServerHost);
                if (result.status == 200 && result.value) {
                    if (result.value.authenticationToken == "") {
                        if (result.value.passwordChangeRequired) {
                            this.passwordUpdateToken = result.value.passwordChangeRequired.token;
                            this.password = "";
                            this.signInMode = signInMode.updatePassword;
                            return;
                        } else if (result.value.requires2FA) {
                            this.signInMode = signInMode.get2FA;
                        }
                    } else {
                        // ConfirmLogin's purpose is to detect supplier login masquerading as a tenant login.
                        const confirmLoginResponse = await jsonRequest<ResultConfirmLoginServiceResponse>('api/User/ConfirmLogin', input, globalThis.dealerConfiguration.apiHost, result.value.authenticationToken);

                        if (confirmLoginResponse.status == 200) {
                            if (confirmLoginResponse.value?.responseType === ServiceResponseType.UnAuthorized) {
                                this.error = tlang`Login unsuccessful: Supplier user cannot login as a fabricator user.`;
                            } else {
                                this.result = result.value;
                                this.result.publicInfo.isSupplier = supplierLogin;
                                this.hideModal();
                            }
                        } else {
                            this.error = `Login unsuccessful: ${result.statusText}`;
                        }
                    }
                } else {
                    this.error = `Login unsuccessful: ${result.statusText}`;
                }
            } else {
                this.passwordConfirm = (this.ui.querySelector('#password-confirm') as HTMLInputElement).value;
                if (this.password !== this.passwordConfirm) {
                    this.error = tlang`Password does not match Confirmation`;
                    await this.render();
                }
                const input = {
                    userName: this.userName,
                    password: this.password,
                    dealerDeploymentId: globalThis.dealerConfiguration.dealerDeploymentId,
                    token: this.passwordUpdateToken
                };
                //password update
                const result = await jsonRequest<ResultTenantLogin>('api/Login/TenantChangePassword', input, globalThis.dealerConfiguration.licenseServerHost);
                if (result.status == 200 && result.value) {
                    this.result = result.value;
                    this.hideModal();
                } else {
                    this.error = `Password Update unsuccessful: ${result.statusText}`;
                    this.signInMode = signInMode.signIn;
                    await this.render();
                }

            }
        } finally {
            this.loading = false;
            await this.render();
        }
    }

    async bodyTemplate(): PromiseTemplate {

        //any errors coming back in regard to failed authentication are displayed below the password in this injected location.
        const error = this.error !== null ?
            html`
                <div class="alert alert-danger" role="alert">
                    ${this.error}
                </div>`
            : html``;

        const keydownEvent = async (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                e.preventDefault();
                e.stopPropagation();
                await this.signIn();
            }
        };
        const signInEvent = async (e: Event) => {
            e.preventDefault();
            e.stopPropagation();
            await this.signIn();
        };
        const signOutEvent = async (e: Event) => {
            e.preventDefault();
            e.stopPropagation();
            await this.cancel();
        };
        const logOutTemplate = () => {
            return this.lockUser
                ? html`
                    <button @click=${signOutEvent} type="button" class="btn btn-secondary">Sign-Out</button>`
                : html``;
        };

        const changePasswordEvent = (e: Event) => {
            e.preventDefault();
            e.stopPropagation();
            const username = (<HTMLInputElement>document.getElementById("username")).value;
            runResetPassword(username);
          };

        const confirmation = this.signInMode !== signInMode.updatePassword
            ? html``
            : html`
                <div class="form-item text-field">
                    <label for="pwd" class="form-label">Confirm Password</label>
                    <input type="password" @keydown=${keydownEvent} class="form-control" id="password-confirm"
                        ?readonly=${this.loading} />
                </div>`;
        let title = 'Log in';
        switch (this.signInMode) {
            case signInMode.signIn:
                title = tlang`Log In`;
                break;
            case signInMode.get2FA:
                title = tlang`Enter 2FA Credential`;
                break;
            case signInMode.updatePassword:
                title = tlang`Update Password`;

        }

        const passwordLabel = this.signInMode === signInMode.signIn || this.signInMode === signInMode.get2FA
            ? tlang`Password` : tlang`Enter New Password`;
        const userReadonly = this.loading || this.lockUser ||
            this.signInMode == signInMode.updatePassword || this.signInMode == signInMode.get2FA;

        const usernameField = html`
            <div class="form-item text-field">
                <label for="username" class="form-label">Username</label>
                <input type="text" class="form-control" id="username" name="username" .value=${this.userName}
                    ?readonly=${userReadonly}
                    title="Usernames are often your email address.">
            </div>`;
        const mainTemplate = this.signInMode === signInMode.get2FA
            ? html`
                ${usernameField}
                <div class="form-item text-field mb-3">
                    <label for="code2FA" class="form-label">${tlang`Enter 2FA Code`}</label>
                    <input type="text" @keydown=${keydownEvent} class="form-control" id="code2FA" ?readonly=${this.loading} />
                </div>
                <div class="form-group">
                    <button @click=${signInEvent} type="button" class="btn btn-primary">Sign-in</button>
                    ${logOutTemplate()}
                </div>
                <div class="form-links">
                    <button id="btnLogout" type="submit" class="btn btn-link">Forgot Password</button>
                </div>
                <div class="supplier-logo">
                    <!--Paste supplier logo here-->
                </div>
                `
            : html`
                ${usernameField}
                <div class="form-item text-field mb-3">
                    <label for="pwd" class="form-label">${passwordLabel}</label>
                    <input type="password" @keydown=${keydownEvent} class="form-control" id="password" ?readonly=${this.loading}
                        .value=${this.password} />
                </div>
                ${confirmation}
                <div class="form-group">
                <div class="checkbox">
                        <label for="Input_RememberMe">
                            <input
                                type="checkbox"
                                data-val="true"
                                data-val-required="The Remember me field is required."
                                id="Input_RememberMe"
                                name="Input.RememberMe"
                                value="true" />
                            Remember me
                        </label>
                    </div>
                    <div class="checkbox">
                        <label for="Input_SupplierLogin">
                            <input
                                type="checkbox"
                                id="Input_SupplierLogin"
                                name="Input.SupplierLogin"
                                />
                            Login as Supplier
                        </label>
                    </div>
                </div>
                <div class="form-item form-actions">
                    <button @click=${signInEvent} type="button" class="btn btn-primary">Sign-in</button>
                    ${logOutTemplate()}
                </div>
                </div>
                <div class="form-links">
                    <button id="btnLogout" type="submit" class="btn btn-link" @click=${changePasswordEvent}>Forgot Password</button> </div>
                </div>
                <div class="supplier-logo">
                    <img src=${PoweredBySofttechImage}>
                </div>
            `;

        const supplierId = getSupplierIdentifierFromUrl();
        const loginPageImage = getLoginImage(supplierId);
        //very simple dialog. might be worth wrapping in a form control
        return html`
        <div class="login-page-image">
            <img src=${loginPageImage}>
        </div>
        <div class="page-content">
            <div class="dealer-logo">
                <!--Paste dealer logo here-->
            </div>
            <div class="login-page-content">
                <h2>${title}</h2>
                <div id="account">
                    ${mainTemplate}
                    ${error}
                    </div>
                </div>
        </div>
        `;
    }

}

class Enable2FADialog extends ModalDialog {

    needsPassword = true;
    error = "";
    token = "";
    pwd = "";
    needsVerification = true;
    verificationCode = "";

    protected async bodyTemplate(): PromiseTemplate {
        const sendInfo = async (code2FA?: string) => {
            const user = getCurrentUser();
            const input = {
                userName: user?.userName,
                password: this.pwd,
                dealerDeploymentId: globalThis.dealerConfiguration.dealerDeploymentId,
                code2FA: code2FA
            };

            const path = !code2FA ? 'Tenant2FARegistration' : 'Tenant';
            return await jsonRequest<ResultTenantLogin>(`api/Login/${path}`, input, globalThis.dealerConfiguration.licenseServerHost);
        };

        const verifyEvent = async () => {
            this.error = "";
            this.verificationCode = (this.ui.querySelector('#verification-code') as HTMLInputElement).value;
            const result = await sendInfo(this.verificationCode);
            if (result.status == 200 && result.value) {
                if (result.value.authenticationToken !== "") {
                    this.needsPassword = false;
                    this.needsVerification = false;
                    await this.render();
                    return;
                } else {
                    if (result.value.passwordChangeRequired)
                        this.error = tlang`Please signout and login again, as a password change is required`;
                    else if (result.value.requires2FA)
                        this.error = tlang`This account is already using 2FA`;
                    await this.render();
                }
            } else {
                this.error = `Code invalid, please try again`;
                await this.render();
            }

        };
        const verifyPasswordEvent = async () => {
            this.error = "";
            this.pwd = (this.ui.querySelector('#password') as HTMLInputElement).value;
            const result = await sendInfo();
            if (result.status == 200 && result.value) {
                if (result.value.authenticationToken !== "") {
                    this.needsPassword = false;
                    this.token = result.value.token2FA ?? "";
                    await this.render();
                    setTimeout(() => {
                        (this.ui.querySelector('#verification-code') as HTMLInputElement).focus();
                    }, 100);

                    return;
                } else {
                    if (result.value.passwordChangeRequired)
                        this.error = tlang`Please signout and login again, as a password change is required`;
                    else if (result.value.requires2FA)
                        this.error = tlang`This account is already using 2FA`;
                    await this.render();
                }
            } else {
                this.error = `Login unsuccessful: ${result.statusText}`;
                await this.render();
            }


        };
        const closeEvent = () => {
            this.hideModal();
        };

        const errTemplate = () => {
            if (this.error !== "")
                return html`
                <div class="alert alert-danger" role="alert">
                    ${this.error}
                </div>`;
            else return html``;
        };
        if (this.needsPassword) {
            return html`
            <div>
                <h1>${tlang`Please confirm password to continue`}</h1>
                <div class="row mb-3">
                    <bs-form-input data-id="password" type="password" data-label=${tlang`Password`}></bs-form-input>
                </div>
                <div class=" mb-3">
                    <button class="btn btn-primary" @click=${verifyPasswordEvent}>${tlang`Verify`}</button>
                    <button class="btn btn-secondary" @click=${closeEvent}>${tlang`Close`}</button>
                </div>
                ${errTemplate()}
            </div>
            `;
        } else if (this.needsVerification) {
            const el = globalThis.kjua({ text: `otpauth://totp/Softtech%20Dealer?secret=${this.token}&issuer=Softech` });

            return html`
                <div>
                    <h1>${tlang`scan code into authentication app`}</h1>
                    <div class="row mb-3">
                        ${el}
                    </div>
                    <div class="row mb-3">
                        <bs-form-input data-id="verification-code" type="text" data-label=${tlang`Enter Code From App`}></bs-form-input>
                    </div>
                    <div class=" mb-3"> <button class="btn btn-primary" @click=${verifyEvent}>${tlang`Verify`}</button>
                        <button class="btn btn-secondary" @click=${closeEvent}>${tlang`Close`}</button>
                    </div>
                    ${errTemplate()}

                </div>
        `;
        } else {
            return html`
                <div>
                    <h1>${tlang`2FA is now enabled`}</h1>
                    <button class="btn btn-success" @click=${closeEvent}>${tlang`Close`}</button>
                    ${errTemplate()}

                </div>
        `;
        }
    }
    protected async getTitle(): PromiseSnippet {
        return tlang`Enable 2FA`;
    }

}
