export enum ServiceResponseType {
    None = 0,
    Ok = 1,
    NotFound = 2,
    UnAuthenticated = 4,
    UnAuthorized = 8,
    Error = 16,
    TransientDbError = 32,
    ValidationFailure = 64,
    ModifyNotFound = 128
}

export interface ServiceResponseError {
    message: string;
    stackTrace?: string;
}

export interface ServiceResponseInvalid {
    /// <summary>
    ///     Response type coded value.
    /// </summary>
    /// <remarks>
    ///     This indicates either none, ok, not found, unauthorized, etc..
    /// </remarks>
    responseType: ServiceResponseType;
    /// <summary>
    ///     Response type decoded value
    /// </summary>
    responseTypeCaption: string;
    /// <summary>
    ///     if ResponseType is Error, then this will contain extended information such as the exception etc etc.
    /// </summary>
    responseError: ServiceResponseError | null;
}

/// </summary>
export interface ServiceResponse<TResult> extends ServiceResponseInvalid {
    /// <summary>
    ///     Data package associated with the result.
    /// </summary>
    result: TResult | null;
}
