
import { tlang } from "../../language/lang";
import { EventSnippet } from "./events";

import { WaitPatiently } from "./modal-spinner";
//a progress window default to run will doing a save to the server
export class WaitPatientlyLoading extends WaitPatiently {

    constructor(title?: EventSnippet, message?: EventSnippet) {
        super(title ?? (() => tlang`Loading`), message ?? (() => tlang`just a moment...`));
    }
    borderType() {
        return "basic";
    }


}