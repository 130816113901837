//Provide a global abstraction from the implementation details of an api call
import { BlobApi } from "./blob-api";
import { ApiCommunications } from "./api-communications";
import { QuoteApi } from "./quote-api";
import { ClientApi } from "./client-api";
import { ProjectApi } from "./project-api";
import { FranchiseeApi } from "./franchisee-api";
import { PaymentProfileApi } from "./payment-profile-api";
import { PurchaseOrderApi } from "./purchase-order-api";
import { ReportApi } from "./report-api";
import { SupplierApi } from "./supplier-api";
import { PricingEngineApi } from "./pricing-engine-api";
import { FrameEngineApi } from "./frame-engine-api";

export type ApiProvider = (selector?: string) => ApiCommunications;

export interface ApiFactory {
    blob: () => BlobApi;
    quote: () => QuoteApi;
    client: () => ClientApi;
    project: () => ProjectApi;
    franchisee: () => FranchiseeApi;
    paymentProfile: () => PaymentProfileApi;
    purchaseOrder: () => PurchaseOrderApi;
    reporting: () => ReportApi;
    supplier: () => SupplierApi;
    pricingEngine: () => PricingEngineApi;
    frameEngine: () => FrameEngineApi;
}

let _apiInjector: ApiProvider = () => {
    throw new Error("please call setApiInjector");
};

let _apiFactory: ApiFactory = {
    quote: () => {
        throw new Error("please call setApiInjector - quote api not registered");
    },
    blob: () => {
        throw new Error("please call setApiInjector - blob api not registered");
    },
    client: () => {
        throw new Error("please call setApiInjector - client api not registered");
    },
    project: () => {
        throw new Error("please call setApiInjector - project api not registered");
    },
    franchisee: () => {
        throw new Error("please call setApiInjector - franchisee api not registered");
    },
    paymentProfile: () => {
        throw new Error("please call setApiInjector - paymentProfile api not registered");
    },
    purchaseOrder: () => {
        throw new Error("please call setApiInjector - purchaseOrder api not registered");
    },
    reporting: () => {
        throw new Error("please call setApiInjector - reporting api not registered");
    },
    supplier: () => {
        throw new Error("please call setApiInjector - supplier api not registered");
    },
    pricingEngine: () => {
        throw new Error("please call setApiInjector - pricingengine api not registered");
    },
    frameEngine: () => {
        throw new Error("please call setApiInjector - frameengine api not registered");
    },
};

export function setApiInjector(apiInjector: ApiProvider) {
    _apiInjector = apiInjector;
}

export function getApi(selector?: string): ApiCommunications {
    return _apiInjector(selector);
}

export function setApiFactory(factory: ApiFactory) {
    _apiFactory = factory;
}

export function getApiFactory() {
    return _apiFactory;
}
