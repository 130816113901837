import { html, TemplateResult } from 'lit';
import { PromiseTemplate } from '../../../components/ui/events';
import * as data from '../v6-quote-item-data';
import { FrameAttributeChangeEvent } from './v6-quote-item-view-datahandler';

export interface V6FrameAttributeViewOptions {
    supplierId: string;
    readonly: boolean;
    valueChangedTemplate?: TemplateResult;
}

export interface V6FrameAttributeViewEventHandlers {
    //callback when an attribute has been modified
    onAttributeChange: (attr: data.V6Property) => void;
    // call back to let the ui know a particular property has focus, or mousefocus
    attributeFocused: (data: data.V6Property | null, hovering?: boolean) => void;
}

//base class for an attribute editor
export class V6FrameAttributeView {
    attribute: data.V6Property;
    _ui: HTMLElement | null;
    lastValue: string | null;
    onDataChanged?: FrameAttributeChangeEvent;
    viewOptions: V6FrameAttributeViewOptions;
    viewEvents: V6FrameAttributeViewEventHandlers;
    protected get inputType(): string { return "text"; };
    constructor(attribute: data.V6Property, viewOptions: V6FrameAttributeViewOptions, viewEvents: V6FrameAttributeViewEventHandlers) {
        this._ui = null;
        this.viewOptions = viewOptions;
        this.attribute = attribute;
        this.lastValue = this.attribute.value ?? null;
        this.viewEvents = viewEvents;

    }
    get readonly() {
        return this.attribute.isReadonly || this.viewOptions.readonly;
    }
    protected fallback(first: string | null | undefined, second: string) {
        return !first || first.trim() === "" ? second : first;
    }
    protected isTarget(e: Event) { return e.target instanceof HTMLInputElement; }

    //override in subclass
    protected getValue(e: Event): string {
        return (e.target as HTMLInputElement).value ?? "";
    }
    //override in subclass
    public async template(): PromiseTemplate {
        const label = this.fallback(this.attribute.codeDescription,
            this.attribute.code);
        return html`
        <div class="v6config-attribute row mb-2 align-items-center form-col-item">
            <div class="v6config-attribute-title form-col-label">${label}</div>
            <div class="v6config-attribute-input form-col-input">${await this.editTemplate()}${this.spinnerTemplate()}</div>
        </div>`;
    }
    spinnerTemplate(): TemplateResult {
        if (this.attribute.value === this.attribute.originalValue) return html``;

        return this.viewOptions.valueChangedTemplate ?? html`
            <div class="spinner-grow text-warning float-end push-right " role="status" style="z-index:1080">
                <span class="visually-hidden">Loading...</span>
            </div>`;
    }
    public async editTemplate(): PromiseTemplate {
        return html``;
    }
    editControlFocused(hovering?: boolean) {
        this.viewEvents.attributeFocused(this.attribute, hovering);
    }
    editControlUnfocused(hovering?: boolean) {
        this.viewEvents.attributeFocused(null, hovering);
    };
    //use in subclass template
    changeEvent(e: Event, extraChanges?: () => void) {
        if (!this.isTarget(e)) return;
        const value = this.getValue(e);

        //original value check is to ensure we are not going to the server for the exact same value
        //which might happen with pickers.
        if (value != this.attribute.originalValue) {
            const valueChanged = this.attribute.value !== value;
            this.attribute.value = value;
            extraChanges?.();
            //lastvalue is used for dropdowns and edits that might send multiple signals for the same
            //event
            if (value !== this.lastValue || valueChanged) {
                this.lastValue = value;
                this.doDataChanged();
            }
        }
    }
    doDataChanged() {
        this.fireDataChangedEvent();
    }
    fireDataChangedEvent() {
        console.log(`${this.attribute.codeDescription} changed to "${this.attribute.value}"`);
        this.viewEvents.onAttributeChange(this.attribute);
    }
}


