import { WaitPatiently } from "../../components/ui/modal-spinner";
import { UINavigator } from "../../components/ui/navigator";
import { tlang } from "../../language/lang";
import { QuoteContainer } from "../../quotes/data/quote-container";
import { cache } from "../cache/cache-registry";
import { userDataStore } from "../common/current-user-data-store";
import { getQuoteContainer, getQuoteContainerManager, quoteViewFactory } from "../quotes/quote-ui-adapter";
import { navigation } from "../../components/ui/navigation-path";
import { getQuoteTitle } from "../../quotes/data/quote-helper-functions";

export function quoteNav(): UINavigator {
    return {
        code: navigation.quote,
        isModal: true,
        event: async function (id: string, data?: any, eventInfo?: { title: string; }): Promise<boolean> {
            const container = data instanceof QuoteContainer ? data : getQuoteContainer(id);
            const waiting = new WaitPatiently(() => tlang`Opening %%quote%%`, () =>
                tlang`please wait while we initialize the %%quote%% "${eventInfo?.title}"`);
            try {
                const view = await quoteViewFactory({
                    title: getQuoteTitle, // not used in subclasses
                    quoteContainerManager: getQuoteContainerManager(container),
                    customerCache: cache().client,
                    userCache: cache().userProfile,
                    ownerId: async () => userDataStore.defaultBranch?.id ?? "",
                });
                waiting.hideModal();
                await view.showModal();
            }
            finally {
                waiting.hideModal();
            }
            return true;
        }
    };
}
