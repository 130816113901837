// eslint-disable-next-line import/named
import { TemplateResult } from "lit";
import { ConvertEnumToFormSelectOptions } from "../../FormInputView";
import { DataTracker } from "../databinding/data-tracker";
import { DataBinding } from "../databinding/databinding";
import { formInput } from "./form-input";
import { formSelect } from "./form-select";
import { formRadioGroup } from "./form-radio-group";
import { formPicker } from "./form-picker";

export interface InputClasses {
    id: string;
    classes: string;
}
export class FormInputAssistant {
    dataTracker: DataTracker;
    forceReadonly: boolean;
    classes: InputClasses[] = [];

    constructor(dataTracker: DataTracker, forceReadonly = false) {
        this.dataTracker = dataTracker;
        this.forceReadonly = forceReadonly;
    }

    get dataBinding(): DataBinding {
        return this.dataTracker.binder;
    }
    id(name: string): string {
        return `${this.dataTracker.binder.internalId}-${name}`;
    }
    private static pascalCase(value: string) {
        if (value.length > 0)
            return value.slice(0, 1).toUpperCase() + value.slice(1);
        else
            return value;
    }

    public text(fieldName: string, title?: string, maxLength?: number): TemplateResult {
        return this.forceReadonly
            ? this.formInputReadOnly(fieldName, "text", title, maxLength)
            : this.formInput(fieldName, "text", title, maxLength);
    }

    public textRequired(fieldName: string, title?: string, maxLength?: number): TemplateResult {
        return this.forceReadonly
            ? this.formInputReadOnly(fieldName, "text", title, maxLength)
            : this.formInputRequired(fieldName, "text", title, maxLength);
    }

    public textHidden(fieldName: string, title?: string): TemplateResult {
        return this.forceReadonly
            ? this.formInputReadOnly(fieldName, "hidden", title)
            : this.formInput(fieldName, "hidden", title);
    }

    public textReadonly(fieldName: string, title?: string): TemplateResult {
        return this.formInputReadOnly(fieldName, "text", title);
    }

    public checkbox(fieldName: string, title?: string): TemplateResult {
        return this.forceReadonly
            ? this.formInputReadOnly(fieldName, "checkbox", title)
            : this.formInput(fieldName, "checkbox", title);
    }

    public radioGroup<EnumeratedType>(fieldName: string, options: EnumeratedType, title?: string, hideZeroValue?: boolean): TemplateResult {
        return this.forceReadonly
            ? this.formRadioGroup(fieldName, ConvertEnumToFormSelectOptions(options, !hideZeroValue), title, true)
            : this.formRadioGroup(fieldName, ConvertEnumToFormSelectOptions(options, !hideZeroValue), title);
    }

    public note(fieldName: string, title?: string, maxLength?: number): TemplateResult {
        return this.forceReadonly
            ? this.formInputReadOnly(fieldName, "area", title, maxLength)
            : this.formInput(fieldName, "area", title, maxLength);
    }

    public noteRequired(fieldName: string, title?: string, maxLength?: number): TemplateResult {
        return this.forceReadonly
            ? this.formInputReadOnly(fieldName, "area", title, maxLength)
            : this.formInputRequired(fieldName, "area", title, maxLength);
    }

    public noteReadonly(fieldName: string, title?: string): TemplateResult {
        return this.formInputReadOnly(fieldName, "area", title);
    }

    public date(fieldName: string, title: string | undefined, type = "datetime-local"): TemplateResult {
        return this.forceReadonly
            ? this.formInputReadOnly(fieldName, type, title)
            : this.formInput(fieldName, type, title);
    }

    public dateRequired(fieldName: string, title: string | undefined, type = "datetime-local"): TemplateResult {
        return this.forceReadonly
            ? this.formInputReadOnly(fieldName, type, title)
            : this.formInputRequired(fieldName, type, title);
    }

    public dateReadonly(fieldName: string, title: string | undefined, type = "datetime-local"): TemplateResult {
        return this.formInputReadOnly(fieldName, type, title);
    }

    public int(fieldName: string, title?: string): TemplateResult {
        return this.forceReadonly
            ? this.formInputReadOnly(fieldName, "number", title)
            : this.formInput(fieldName, "number", title);
    }

    public intRequired(fieldName: string, title?: string): TemplateResult {
        return this.forceReadonly
            ? this.formInputReadOnly(fieldName, "number", title)
            : this.formInputRequired(fieldName, "number", title);
    }

    public intReadonly(fieldName: string, title?: string): TemplateResult {
        return this.formInputReadOnly(fieldName, "number", title);
    }

    public float(fieldName: string, title?: string): TemplateResult {
        return this.forceReadonly
            ? this.formInputReadOnly(fieldName, "number", title)
            : this.formInput(fieldName, "number", title);
    }

    public floatRequired(fieldName: string, title?: string): TemplateResult {
        return this.forceReadonly
            ? this.formInputReadOnly(fieldName, "number", title)
            : this.formInputRequired(fieldName, "number", title);
    }

    public floatReadonly(fieldName: string, title?: string): TemplateResult {
        return this.formInputReadOnly(fieldName, "number", title);
    }

    public money(fieldName: string, title?: string, decimalPlaces?: number): TemplateResult {
        return this.forceReadonly
            ? this.formInputReadOnly(fieldName, "money", title, undefined, decimalPlaces ?? 2)
            : this.formInput(fieldName, "money", title, undefined, decimalPlaces ?? 2);
    }

    public moneyRequired(fieldName: string, title?: string, decimalPlaces?: number): TemplateResult {
        return this.forceReadonly
            ? this.formInputReadOnly(fieldName, "money", title, undefined, decimalPlaces ?? 2)
            : this.formInputRequired(fieldName, "money", title, undefined, decimalPlaces ?? 2);
    }

    public moneyReadonly(fieldName: string, title?: string, decimalPlaces?: number): TemplateResult {
        return this.formInputReadOnly(fieldName, "money", title, undefined, decimalPlaces);
    }


    public enumPicker<EnumeratedType>(fieldName: string, options: EnumeratedType, title?: string): TemplateResult {
        return this.forceReadonly
            ? this.formSelectReadonly(fieldName, ConvertEnumToFormSelectOptions(options, false), title)
            : this.formSelect(fieldName, ConvertEnumToFormSelectOptions(options, false), title);
    }

    public enumPickerReadonly<EnumeratedType>(fieldName: string, options: EnumeratedType, title?: string): TemplateResult {
        return this.formSelectReadonly(fieldName, ConvertEnumToFormSelectOptions(options, false), title);
    }

    public enumFilter<EnumeratedType>(fieldName: string, options: EnumeratedType, title?: string): TemplateResult {
        return this.forceReadonly
            ? this.formSelectReadonly(fieldName, ConvertEnumToFormSelectOptions(options, true), title)
            : this.formSelect(fieldName, ConvertEnumToFormSelectOptions(options, true), title);
    }

    public enumFilterReadonly<EnumeratedType>(fieldName: string, options: EnumeratedType, title?: string): TemplateResult {
        return this.formSelectReadonly(fieldName, ConvertEnumToFormSelectOptions(options, true), title);
    }

    /**
     *
     * @param fieldName fieldname of the object to read and write data to
     * @param options a string generated by MapArrayToFormInputSelectValue
     * @param title optional title
     * @returns
     */

    public picker(fieldName: string, display: string, clickEvent: () => void, title?: string) {
        return this.forceReadonly
            ? this.formPicker(fieldName, display, clickEvent, title, false, true)
            : this.formPicker(fieldName, display, clickEvent, title, false);
    }

    public pickerRequired(fieldName: string, display: string, clickEvent: () => void, title?: string) {
        return this.forceReadonly
            ? this.formPicker(fieldName, display, clickEvent, title, true, true)
            : this.formPicker(fieldName, display, clickEvent, title, true);
    }

    public pickerReadonly(fieldName: string, display: string, title: string) {
        return this.formPicker(fieldName, display, () => {
            //
        }, title, false, true);
    }

    private formInputRequired(fieldName: string, type?: string, title?: string, maxLength?: number, decimalPlaces?: number): TemplateResult {
        type = type ?? "text";
        title = title ?? FormInputAssistant.pascalCase(fieldName);
        return formInput(fieldName, title, this.dataBinding, this.dataTracker, type, false, true, maxLength, decimalPlaces);
    }

    private formInputReadOnly(fieldName: string, type?: string, title?: string, maxLength?: number, decimalPlaces?: number): TemplateResult {
        type = type ?? "text";
        title = title ?? FormInputAssistant.pascalCase(fieldName);
        return formInput(fieldName, title, this.dataBinding, this.dataTracker, type, true, false, maxLength, decimalPlaces);
    }

    private formInput(fieldName: string, type?: string, title?: string, maxLength?: number, decimalPlace?: number): TemplateResult {
        type = type ?? "text";
        title = title ?? FormInputAssistant.pascalCase(fieldName);
        const classes = this.getClasses(fieldName);
        return formInput(fieldName, title, this.dataBinding, this.dataTracker, type, false, false, maxLength, decimalPlace, classes);
    }

    private formRadioGroup(fieldName: string, options: string, title?: string, readOnly = false, required = false) {
        title = title ?? FormInputAssistant.pascalCase(fieldName);
        const classes = this.getClasses(fieldName);
        return formRadioGroup(fieldName, title, options, this.dataBinding, this.dataTracker, readOnly, required, classes);
    }
    getClasses(fieldName: string) {
        return this.classes
            .filter(x => x.id === "*" || x.id == fieldName)
            .map(x => x.classes)
            .join(" ");
    }

    private formSelect(fieldName: string, options: string, title?: string) {
        title = title ?? FormInputAssistant.pascalCase(fieldName);
        return formSelect(fieldName, "", title, options, this.dataBinding, this.dataTracker, false);
    }

    private formPicker(fieldName: string, display: string, clickEvent: () => void, title?: string, required = false, readonly = false) {
        title = title ?? FormInputAssistant.pascalCase(fieldName);
        const classes = this.getClasses(fieldName);
        return formPicker(fieldName, title, display, this.dataBinding, this.dataTracker, clickEvent, required, readonly, classes);
    }

    private formSelectReadonly(fieldName: string, options: string, title?: string) {
        title = title ?? FormInputAssistant.pascalCase(fieldName);
        return formSelect(fieldName, "", title, options, this.dataBinding, this.dataTracker, true);
    }
}