import { lang } from "./lang";
import { DictionaryEntry, techincalDictionary } from "./technical-dictionary-data";

function addDict(name: string, single: string, plural: string) {
    const entry = new DictionaryEntry(
        () => lang(single, `ref:dict:${name}:single`),
        () => lang(plural, `ref:dict:${name}:plural`));
    techincalDictionary[name] = entry;

    //force registration
    lang(`%%${name}%% !!${name}!!`, undefined, true);
}

export function registerTechnicalDictionary() {
    addDict("branch", "Branch", "Branches");
    addDict("payment-profile", "Client Type", "Client Types");
    addDict("client", "Client", "Clients");
    addDict("contact", "Contact", "Contacts");
    addDict("estimate", "Estimate", "Estimates");
    addDict("estimate-abbrev", "EST", "EST");
    addDict("franchisee", "Fabricator", "Fabricators");
    addDict("freehand", "Freehand Item", "Freehand Items");
    addDict("invoice", "Invoice", "Invoices");
    addDict("project", "Project", "Projects");
    addDict("project-abbrev", "PRO", "PRO");
    addDict("purchase-order", "Purchase Order", "Purchase Orders");
    addDict("quote", "Quote", "Quotes");
    addDict("order", "Order", "Orders");
    addDict("quote-abbrev", "QTE", "QTE");
    addDict("quote-item", "Quote Item", "Quote Items");
    addDict("supplier", "Supplier", "Suppliers");
    addDict("tax", "Tax", "Taxes");
    addDict("purchase-order-abbrev", "P/O", "P/O");
    addDict("custom-frame", "Custom Frame", "Custom Frames");
    addDict("standard-frame", "Standard Frame", "Standard Frames");
    addDict("pricing", "Pricing", "Pricing");
    addDict("catalogs", "Catalogues", "Catalogues");

    //RESOURCES
    addDict("resource", "Resource", "Resources");
    addDict("frame", "Frame", "Frames");
    addDict("igu", "Glass", "Glass");
    addDict("louvre", "Louvre", "Louvers");
    addDict("quote-igu", "Quote Glass", "Quote Glass");
    addDict("component", "Hardware", "Hardware");
    addDict("extrusion", "Extrusion", "Extrusions");
    addDict("fill", "Fill", "Fills");
    addDict("assembly", "Assembly", "Assemblies");
    addDict("attachment-bag", "Bag", "Bags");
    addDict("gasket", "Gasket", "Gasket");
    addDict("finish", "Finish", "Finishes");

    // Settings
    addDict("optimizer-settings", "Optimizer Settings", "Optimizer Settings")
    addDict("end-trim", "End Trim", "End Trim");
    addDict("kerf-angled", "Kerf Angled", "Kerf Angled");
    addDict("kerf-straight", "Kerf Straight", "Kerf Straight");
}


