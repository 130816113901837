// eslint-disable-next-line import/named
import { html, TemplateResult } from "lit";
import { emptyGuid } from "../../api/guid";
import { ClientApi } from "../../api/client-api";
import { DataBinding, getInternalId } from "../../components/ui/databinding/databinding";
import { Client, ClientState } from "../../api/dealer-api-interface-client";
import "../../components/ui/maps/google-place-autocomplete";
import { DataTracker, FieldType } from "../../components/ui/databinding/data-tracker";
import { FormInputAssistant } from "../../components/ui/templateresult/form-input-assistant";
import { Place } from "../../components/ui/maps/place";
import { tlang } from "../../language/lang";
import {
    bsFormRadioGroupYesNoOptions,
    displayYesNo,
    isEmptyOrSpace,
    isValidEmail,
    validId
} from "../../components/ui/helper-functions";
import { googleMapApiKey } from "../../api/google-api";
import { getApiFactory } from "../../api/api-injector";
import { mapTemplate, updateAddress } from "../../components/ui/maps/map-helpers";
import { clone, compare } from "../../components/clone";
import { ClientContainerManager } from "../data/client-container-manager";
import { DataEntryPageControlView, PageControlChildTab} from "../../components/ui/data-entry-screen-base";
import { PromiseTemplate, Snippet } from "../../components/ui/events";

export interface ClientDetailViewOptions {
    clientContainerManager: ClientContainerManager;
}

export class ClientDetailView extends PageControlChildTab {
    clientContainerManager: ClientContainerManager;
    clientApi: ClientApi = getApiFactory().client();
    internalId: string;
    billingAsPhysicalAddress = true;
    dataBinding: DataBinding;
    dataTracker: DataTracker;
    forms: FormInputAssistant;
    //Def sure this is not the intention
    dataBindingBilling: DataBinding;
    dataTrackerBilling: DataTracker;
    protected lngPhysical?: number;
    protected latPhysical?: number;
    protected lngBilling?: number;
    protected latBilling?: number;

    constructor(owner:DataEntryPageControlView, options: ClientDetailViewOptions) {
        super(owner);
        this.internalId = getInternalId();

        this.clientContainerManager = options.clientContainerManager;

        this.dataBinding = new DataBinding(this.ui, undefined, (input: string, internalId: string) =>
            `client-${input}-${internalId}`);
        this.dataTracker = new DataTracker(this.dataBinding);

        this.dataBindingBilling = new DataBinding(this.ui, undefined, (input: string, internalId: string) =>
            `client-billing-${input}-${internalId}`);
        this.dataTrackerBilling = new DataTracker(this.dataBindingBilling);

        const addField = (fieldName: string, propertyType?: FieldType, nullable?: boolean, editorFieldName?: string, data?: () => any) => {
            this.dataTracker.addObjectBinding(data ?? (() => this.client), fieldName,
                editorFieldName ?? fieldName, propertyType ?? FieldType.string, nullable ?? false);
        };

        const addBillingField = (fieldName: string, propertyType?: FieldType, nullable?: boolean, editorFieldName?: string, data?: () => any) => {
            this.dataTrackerBilling.addObjectBinding(data ?? (() => this.client), fieldName,
                editorFieldName ?? fieldName, propertyType ?? FieldType.string, nullable ?? false);
        };

        addField("name");
        addField("businessPhoneNumber");
        addField("primaryContactName", FieldType.string, false, undefined, ()=>this.clientContainerManager.container);
        addField("primaryContactEmail", FieldType.string, false, undefined, () => this.clientContainerManager.container);
        addField("state", FieldType.int, false);
        addField("line1", FieldType.string, false, undefined, () => this.client?.physicalAddress);
        addField("locality", FieldType.string, false, undefined, () => this.client?.physicalAddress);
        addField("region", FieldType.string, false, undefined, () => this.client?.physicalAddress);
        addField("postcode", FieldType.string, false, undefined, () => this.client?.physicalAddress);
        addField("country", FieldType.string, false, undefined, () => this.client?.physicalAddress);

        addBillingField("line1", FieldType.string, false, undefined, () => this.client?.billingAddress);
        addBillingField("locality", FieldType.string, false, undefined, () => this.client?.billingAddress);
        addBillingField("region", FieldType.string, false, undefined, () => this.client?.billingAddress);
        addBillingField("postcode", FieldType.string, false, undefined, () => this.client?.billingAddress);
        addBillingField("country", FieldType.string, false, undefined, () => this.client?.billingAddress);

        this.forms = new FormInputAssistant(this.dataTracker);
    }

    public async afterConstruction() {

        if (this.clientContainerManager.container.client) {
            await this.render();
        }
        this.clientContainerManager.afterSave.push(async () => await this.afterSave());
    }

    async onEnter(): Promise<void> {
        await this.loadOrRefresh();
    }

    protected getCaption(): Snippet {
        return tlang`%%client%% Info`;
    }

    public allowDeletePage(): boolean {
        return false;
    }

    protected get client(): Client {
        return this.clientContainerManager.client;
    }

    public async loadOrRefresh(): Promise<void> {
        await this.clientContainerManager.needsClient()

        const client = this.clientContainerManager.client;
        if (client.billingAddress) {
            this.billingAsPhysicalAddress = compare(client.physicalAddress, client.billingAddress);
        }

        this.latPhysical = client.physicalAddress.latitude ? parseFloat(client.physicalAddress.latitude) : undefined;
        this.lngPhysical = client.physicalAddress.longitude ? parseFloat(client.physicalAddress.longitude) : undefined;

        this.latBilling = client.billingAddress?.latitude ? parseFloat(client.billingAddress.latitude) : undefined;
        this.lngBilling = client.billingAddress?.longitude ? parseFloat(client.billingAddress.longitude) : undefined;

        await this.render();
    }

    public async prepareForSave() {
        if (this.dataTracker.modified)
            this.dataTracker.applyChangeToValue();

        if (!this.billingAsPhysicalAddress && this.dataTrackerBilling.modified) {
            this.dataTrackerBilling.applyChangeToValue();
        }
        else if (this.billingAsPhysicalAddress && this.client) {
            this.client.billingAddress = clone(this.client.physicalAddress);
        }
    }

    public getValidationErrors() {
        const errors: string[] = [];

        const companyName = this.client.name;

        if (isEmptyOrSpace(companyName))
            errors.push(tlang`Please provide a Company Name`);

        if (this.client?.id == emptyGuid) {
            const contactName = this.clientContainerManager.container.primaryContactName;
            const contactEmail = this.clientContainerManager.container.primaryContactEmail ?? "";

            if (isEmptyOrSpace(contactName))
                errors.push(tlang`Please provide a Primary %%contact%%`);

            if (isEmptyOrSpace(contactEmail))
                errors.push(tlang`Please provide a Company Email`);
            else if (!isValidEmail(contactEmail))
                errors.push(tlang`Please provide a valid Company Email`);
        }

        return errors;
    }

    protected async afterSave() {
        if (this.client.physicalAddress && this.client.billingAddress) {
            this.billingAsPhysicalAddress = compare(this.client.physicalAddress, this.client.billingAddress);
        }
    }

    protected updateAddress(address: Place) {
        const latlng = updateAddress(address, this.dataBinding);

        if (latlng) {
            if (this.client) {
                //Updating the client values so it gets saved
                this.client.physicalAddress.latitude = latlng.lat.toString();
                this.client.physicalAddress.longitude = latlng.lng.toString();

                //Update the render values
                this.latPhysical = latlng.lat;
                this.lngPhysical = latlng.lng;
            }

            this.render(); //no wait
        }
    }

    protected updateBillingAddress(address: Place) {
        const latlng = updateAddress(address, this.dataBindingBilling);

        if (latlng) {
            if (this.client && this.client.billingAddress) {
                //Updating the client values so it gets saved
                this.client.billingAddress.latitude = latlng.lat.toString();
                this.client.billingAddress.longitude = latlng.lng.toString();

                //Update the render values
                this.latBilling = latlng.lat;
                this.lngBilling = latlng.lng;
            }

            this.render(); //no wait
        }
    }

    protected primaryContactTemplate(): TemplateResult {
        return !validId(this.client?.id) ?
            html`
                ${this.forms.textRequired("primaryContactName", tlang`Primary %%contact%%`, 100)}
                ${this.forms.textRequired("primaryContactEmail", tlang`Email`, 100)}` :
            html`
                ${this.forms.textReadonly("primaryContactName", tlang`Primary %%contact%%`)}
                ${this.forms.textReadonly("primaryContactEmail", tlang`Email`)}`;
    }

    protected async bodyTemplate(): PromiseTemplate {
        const yesNoOptions = bsFormRadioGroupYesNoOptions();
        return html`
            <form class="frm-client-details form-two-col">
                <div class="row">
                    <h2>${tlang`%%client%% Details`}:</h2>
                    <div>
                        ${this.forms.textRequired("name", tlang`%%client%% Name`, 100)}
                        ${this.forms.text("businessPhoneNumber", tlang`%%client%% Phone`, 30)}
                    </div>

                    <div>
                        ${this.primaryContactTemplate()}
                        ${this.forms.radioGroup("state", ClientState, tlang`%%client%% Status`)}
                    </div>
                </div>

                <div class="row">
                    <div>
                        <h2>${tlang`Physical Address`}:</h2>
                        <div class="row mb-2 align-items-center form-col-item form-col-item-empty"></div>
                        <google-place-autocomplete class="left right" data-id="clientauto" outline
                                                   searchType=${"address"}
                                                   .apiKey=${googleMapApiKey} label="Lookup" @place-changed=${_ =>
                                this.updateAddress(_.detail.place)}>
                        </google-place-autocomplete>


                        ${this.forms.text("line1", tlang`Street Address`, 120)}
                        ${this.forms.text("locality", tlang`City`, 120)}
                        ${this.forms.text("region", tlang`State`, 120)}
                        ${this.forms.text("postcode", tlang`Zip Code`, 20)}
                        ${this.forms.text("country", tlang`Country`, 80)}
                        ${this.forms.checkbox("shipToPhysicalAddress", tlang`Default Shipping Address`)}

                        <div class="address-map">
                            ${mapTemplate(this.latPhysical, this.lngPhysical)}
                        </div>
                    </div>
                    <div>
                        <h2>${tlang`Billing Address`}:</h2>

                        <bs-form-radio-group
                                data-label=${tlang`Same as Physical Address`}
                                data-id=${`client-billing-physical-${this.internalId}`}
                                .value=${displayYesNo(this.billingAsPhysicalAddress)}
                                .options=${yesNoOptions}
                                @radio-changed=${_ => this.hideAndUpdateBilling(_.detail)}>
                        </bs-form-radio-group>

                        ${this.getBillingAddressTemplate()}
                    </div>
                </div>
            </form>
        `;
    }

    protected getBillingAddressTemplate(): TemplateResult {
        const formsBilling = new FormInputAssistant(this.dataTrackerBilling);

        return this.billingAsPhysicalAddress ? html`` : html`
            <google-place-autocomplete class="left right" data-id="clientauto" outline searchType=${"address"}
                                       apiKey=${googleMapApiKey} label=${tlang`Quick Find`} @place-changed=${_ =>
                    this.updateBillingAddress(_.detail.place)}>
            </google-place-autocomplete>

            ${formsBilling.textRequired("line1", tlang`Street Address`, 120)}
            ${formsBilling.text("locality", tlang`City`, 120)}
            ${formsBilling.text("region", tlang`State`, 120)}
            ${formsBilling.textRequired("postcode", tlang`Zip Code`, 20)}
            ${formsBilling.text("country", tlang`Country`, 80)}
        `;
    }

    protected hideAndUpdateBilling(value: string) {
        try {
            if (value == "Yes" && !this.billingAsPhysicalAddress) {
                if (this.client && this.client.billingAddress) {
                    this.client.billingAddress.latitude = this.client.physicalAddress.latitude;
                    this.client.billingAddress.longitude = this.client.physicalAddress.longitude;
                }

                this.dataBindingBilling.setValue("line1", this.dataBinding.getValue("line1"));
                this.dataBindingBilling.setValue("locality", this.dataBinding.getValue("locality"));
                this.dataBindingBilling.setValue("region", this.dataBinding.getValue("region"));
                this.dataBindingBilling.setValue("postcode", this.dataBinding.getValue("postcode"));
                this.dataBindingBilling.setValue("country", this.dataBinding.getValue("country"));

                this.billingAsPhysicalAddress = true;

            } else if (value == "No") {
                this.billingAsPhysicalAddress = false;

            }
        } finally {
            this.render();
        }
    }
}