import { EventBooleanAsync, EventVoidAsync } from "../../components/ui/events";
import { navigation } from "../../components/ui/navigation-path";
import { launchUI, navigate } from "../../components/ui/navigator";

export interface LaunchQuoteOptions {
    id?: string | null;
    data?: any;
    title?: string;
    //if undefined, no navigation will be performed.
    //if defined, it must be true, and navigation will occur or no launch allowed
    navigateIfAllowed?: EventBooleanAsync;
    after?: EventVoidAsync;
}

export async function launchQuote(options: LaunchQuoteOptions) {
    return await launchUI({
        code: navigation.quote,
        id: options.id ?? "",
        data: options.data,
        eventInfo: { title: options.title ?? "" },
        onAllowNavigation: async () => {
            if (options.navigateIfAllowed === undefined) return true;
            //call the canClose event from the parent. and if this is true
            //we assume everything is saved, and we can navigate away with this link
            if ((await options.navigateIfAllowed())) {
                await navigate("/");
                return true;
            }
            return false;
        },
        onFinally: options.after
    });

}