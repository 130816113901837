// eslint-disable-next-line import/named
import { html, TemplateResult } from "lit";
import { QuoteItemType } from "../../api/dealer-api-interface-quote";
import { isAutoSaving } from "../../components/save-workflow";
import { DataTracker, FieldType } from "../../components/ui/databinding/data-tracker";
import { DataBinding } from "../../components/ui/databinding/databinding";
import { PromiseSnippet, PromiseTemplate } from "../../components/ui/events";
import { ModalDialog } from "../../components/ui/modal-base";
import { FormInputAssistant } from "../../components/ui/templateresult/form-input-assistant";
import { tlang } from "../../language/lang";
import { DevelopmentError } from "../../development-error";
import { quoteItemContentType } from "../data/default-quote-item-content-type";
import { QuoteItemContainer } from "../data/quote-item-container";
import { QuoteItemView } from "./quote-item-view";

export class ShippingLineItemDialog extends ModalDialog {
    quoteItemContainer: QuoteItemContainer;
    dataTracker: DataTracker;
    ok = false;
    forceReadonly: boolean;

    constructor(quoteItemContainer: QuoteItemContainer, forceReadonly: boolean) {
        super();
        this.forceReadonly = forceReadonly;
        if (quoteItemContainer.item.itemType !== QuoteItemType.Basic) throw new DevelopmentError(`shipping-line-item-view, Invalid  item type`);
        if (quoteItemContainer.item.quoteItemContentType !== quoteItemContentType.shipping) throw new DevelopmentError(`shipping-line-item-view, Invalid  item type`);

        this.quoteItemContainer = quoteItemContainer;

        const item = this.quoteItemContainer.item;
        const price = this.quoteItemContainer.price;
        this.dataTracker = new DataTracker(new DataBinding(this.ui));

        this.dataTracker.addObjectBinding(() => item, 'title', 'title', FieldType.string, false);
        this.dataTracker.addObjectBinding(() => item, 'description', 'description', FieldType.string, false);
        this.dataTracker.addObjectBinding(() => price, 'singleUnitCost', 'singleUnitCost', FieldType.money, false);

    }

    protected async canClose(): Promise<boolean> {
        if (isAutoSaving()) {
            this.save();
        }
        return true;

    }
    protected async getTitle(): PromiseSnippet {
        return html`${tlang`Shipping`}`;
    }
    protected modalSize(): string {
        return "modal-md";
    }
    protected save() {
        if (this.forceReadonly) return;
        this.dataTracker.applyChangeToValue();
        this.quoteItemContainer.price.quantityCost = this.quoteItemContainer.price.singleUnitCost;
        this.ok = true;
    }
    protected renderFooterTemplate(): boolean {
        return !isAutoSaving();
    }
    protected footerTemplate(): TemplateResult {
        const saveEvent = () => {
            this.save();
            this.hideModal();
        };
        const closeEvent = () => {
            this.hideModal();
        };

        const template = this.forceReadonly
            ? html`
                <button @click=${closeEvent} class="btn btn-secondary">Close</button>`
            : html`
                    <button @click=${closeEvent} class="btn btn-secondary">Close</button>
                    <button @click=${saveEvent} class="btn btn-primary">Save</button>
        `;
        return template;
    }

    protected async bodyTemplate(): PromiseTemplate {
        //quantity is always 1 on shipping, so using only 2dp on singleUnitCost
        const forms = new FormInputAssistant(this.dataTracker, this.forceReadonly);
        return html`
            <form class="shipping-item-editor-container form-one-col">
                <div class="row">
                    <div class="form-column">
                        ${forms.text("title", tlang`Title`)}
                        ${forms.text("description", tlang`Description`)}
                        ${forms.money("singleUnitCost", tlang`Cost`)}
                    </div>
                </div>
            </form>
     `;
    }
}
//base class to manage the intricacies of quoteitems that may be v6 or otherwise
export class ShippingLineItemView extends QuoteItemView {

    public get hasPropertyDialog(): boolean {
        return true;
    }
    public get hasModalEditDialog(): boolean {
        return true;
    }
    public async executeModalEditDialog(): Promise<void> {
        if (!this.quoteItemContainer) return;
        const dlg = new ShippingLineItemDialog(this.quoteItemContainer, this.quoteManager.isReadonly());
        await dlg.showModal();
        if (dlg.ok) {
            //price and item has been updated by modal
            this.quoteItemContainer = await this.quoteManager.saveAndUpdateQuoteItemWithIndicator(this.quoteItemContainer, "");
        }
    }

    public async prepareForSave(): Promise<void> {
        //there is nothing to do. this wont ever be called as a shipping line item is not a pagecontrol
    }

}