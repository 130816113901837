// eslint-disable-next-line import/named
import { html, TemplateResult } from "lit";
import { ModalDialog } from "./modal-base";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { marked } from "marked";
import { tlang } from "../../language/lang";
import { init } from "../app-global-init";
import { PromiseSnippet, PromiseTemplate } from "./events";

export interface ConfirmButtons {
    ok: string;
    cancel: string;
}
export const confirmationButtons: ConfirmButtons[] = [];

init(() => {
    confirmationButtons.push(

        {
            ok: tlang`Yes`,
            cancel: tlang`No`
        });
    confirmationButtons.push(
        {
            ok: tlang`Ok`,
            cancel: tlang`Cancel`
        });
    confirmationButtons.push(
        {
            ok: tlang`Continue`,
            cancel: tlang`Cancel`
        });
});

export enum ConfirmationButtonType {
    yesNo = 0,
    okCancel = 1,
    continueCancel = 2,


}
export async function AskConfirmation(message: string, confirmButtons?: ConfirmButtons, modalSize?: string): Promise<boolean> {
    if (!confirmButtons) confirmButtons = confirmationButtons[ConfirmationButtonType.continueCancel];
    const modal: ConfirmationDialog = new ConfirmationDialog(message, confirmButtons, modalSize);
    await modal.showModal();
    return modal.result;
}


// noinspection HtmlUnknownAttribute
class ConfirmationDialog extends ModalDialog {
    message: string;
    result = false;
    buttons: ConfirmButtons;
    _modalSize = "modal-md";

    constructor(message: string, buttons: ConfirmButtons, modalSize?: string) {
        super();
        this.message = message;
        this.buttons = buttons;

        if (modalSize) {
            this._modalSize = modalSize;
        }
    }

    protected async getTitle(): PromiseSnippet {
        return "Confirm";
    }

    footerTemplate(): TemplateResult {

        const okEvent = () => {
            this.result = true;
            this.hideModal();
        };
        return html`
            <button @click=${okEvent} type="button" class="btn btn-primary">${this.buttons.ok}</button>
            <button @click=${()=> this.hideModal()} type="button" class="btn
                btn-secondary">${this.buttons.cancel}</button>
        `;
    }

    async bodyTemplate(): PromiseTemplate {
        return html`
            ${unsafeHTML(marked.parse(this.message))}
        `;
    }
}