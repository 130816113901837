import {css, html, LitElement} from "lit";
import {customElement, property} from "lit/decorators.js";
import {classMap} from 'lit/directives/class-map.js';
import {Place} from "./place";
import "./google-maps-api";

@customElement('google-place-autocomplete')
export class GooglePlaceAutocomplete extends LitElement {
    static styles = css`
    *{
        box-sizing:border-box;
    }
    .form-col-item{
        --bs-gutter-x: 30px;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: calc(-0.5 * var(--bs-gutter-x));
        margin-left: calc(-0.5 * var(--bs-gutter-x));
        align-items: center !important;
        margin-bottom: 0.5rem !important;
    }
    label {
        display: inline-block;
        flex-shrink: 0;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        margin-top: var(--bs-gutter-y);
        flex: 0 0 auto;
        width: 16.66666667%;
        font-size: 0.75rem;
        font-weight: 500;
    }
    .form-col-input{
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        margin-top: var(--bs-gutter-y);
        flex: 0 0 auto;
        width: 83.33333333%;
    }
    .pac-target-input{
        margin: 0;
        font-family: inherit;
        appearance: none;
        font-weight: 300;
        line-height: 1.5;
        display: block;
        transition: none;
        background-color: #fff;
        background-position: right 0.5rem center;
        width: 100%;
        border: 1px solid #BDBDBD !important;
        border-radius: 0 !important;
        padding: 0.2rem 0.35rem;
        height: 34px;
        color: #666666;
        font-size: 0.875rem;
        outline: none !important;
    }
    .pac-target-input:focus{
        color: #1e1e1e;
        background-color: #edf5fc;
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
        outline-color: #3282CC !important;
    }
    @media (max-width: 575.98px){
        label,
        .form-col-input{
            width: 100%;
        }
    }
    @media (min-width: 576px){
        label {
            width: 16.66666667%;
        }
        .form-col-input{
            width: 83.33333333%;
        }
    }
    @media (min-width: 768px){
        label {
            width: 25%;
        }
        .form-col-input{
            width: 75%;
        }
    }
    @media (min-width: 1600px){
        label {
            width: 16.66666667%;
        }
        .form-col-input{
            width: 83.33333333%;
        }
    }
    `;
    @property({type: String, reflect: true})
    apiKey = '';
    @property({type: Boolean, reflect: true})
    apiLoaded = false;
    @property({type: Object})
    _geocoder: any;
    @property({type: String})
    searchCountryCode: any;
    @property({type: Object})
    searchBounds = {};
    @property({type: String})
    searchType: any;
    @property({type: Boolean, reflect: true})
    searchBoundsStrict = false;
    @property({type: Boolean})
    _invalid = false;
    @property({type: Object, reflect: true})
    latLng = {
        lat: 0,
        lng: 0,
    };
    @property({type: Object, reflect: true})
    place: any = {};
    @property({type: Object})
    _place = {};
    @property({type: Object})
    _places: any;
    @property({type: Object})
    valueObject: any;
    @property({type: String, reflect: true})
    _value: string;
    @property({type: String, reflect: true})
    label: string;
    @property({type: Boolean})
    disabled = false;
    @property({type: Boolean})
    outline = false;
    @property({type: String})
    version = '3.48';
    @property({type: String})
    language = '';
    @property({type: String, attribute: 'map-id'})
    mapId = '';

    constructor() {
        super();
        this.label = 'Choose Place';
        this._value = '';
    }

    get root() {
        return this.shadowRoot || this;
    }

    updated(changedProperties: any) {
        super.updated(changedProperties);
        changedProperties.forEach((oldValue: any, propName: string) => {

            //Only need on place change are we dispatching an event.
            if (propName == "place" && this._value != '')
                this.dispatchEvent(
                    new CustomEvent(`${propName}-changed`, {
                        detail: {
                            [propName]: this[propName],
                        },
                    }),
                );

            switch (propName) {
                case '_value':
                    this._svalChanged(this._value);
                    break;
                case 'valueObject':
                    this._valueChanged(this.valueObject, oldValue);
                    break;
                case 'searchCountryCode':
                case 'searchBounds':
                case 'searchBoundsStrict':
                case 'searchType':
                case 'apiLoaded':
                    this._searchBiasChanged();
                    break;
                default:
                    break;
            }
        });
    }

    getInputField() {
        return this.root.querySelector(`#autocompleteInput`);
    }

    _mapsApiLoaded() {
        this.initGMap();
    }

    initGMap() {
        if (!this._geocoder && !this._places) {
            this._geocoder = new google.maps.Geocoder();
            this._places = new google.maps.places.Autocomplete(this.getInputField(), {});
            google.maps.event.addListener(this._places, 'place_changed', this._onChangePlace.bind(this));
            this.apiLoaded = true;
            this._searchBiasChanged();
            this.dispatchEvent(
                new CustomEvent('api-loaded', {
                    detail: {
                        text: 'Google api is ready',
                    },
                }),
            );
        }
    }

    _searchBiasChanged() {
        const {searchCountryCode, searchBounds, searchType} = this;
        if (this.apiLoaded) {
            if (
                searchBounds &&
                // eslint-disable-next-line
                searchBounds.hasOwnProperty('east') &&
                // eslint-disable-next-line
                searchBounds.hasOwnProperty('west') &&
                // eslint-disable-next-line
                searchBounds.hasOwnProperty('north') &&
                // eslint-disable-next-line
                searchBounds.hasOwnProperty('south')
            ) {
                this._places.setBounds(searchBounds);
            } else {
                this._places.setBounds();
            }
            if (searchCountryCode && searchCountryCode.length === 2) {
                this._places.setComponentRestrictions({
                    country: searchCountryCode.toString(),
                });
            } else {
                this._places.setComponentRestrictions();
            }
            if (
                searchType &&
                ['address', 'geocode', 'establishment', '(regions)', '(cities)'].includes(searchType)
            ) {
                this._places.setTypes([searchType.toString()]);
            } else {
                this._places.setTypes([]);
            }
        }
    }

    _valueChanged(newValue: any, oldValue: any) {
        // update the search term and the invalid flag if the value is being set for the first time,
        // or if the value has changed and is not the same as the search term
        if (!oldValue || newValue.search !== oldValue.search || newValue.search !== this._value) {
            this._invalid =
                !newValue ||
                !(newValue.place_id && newValue.latLng && newValue.latLng.lat && newValue.latLng.lng);
        }
    }

    _svalChanged(newValue: any) {
        // reset the invalid property if the user has typed in the input field

        // if the newValue matches the selected place, which could happen if
        // the user types after selecting a place, then deletes the typing
        if (newValue && this.place && this.place.search && newValue === this.place.search) {
            this.valueObject = {
                place_id: this.place.place_id,
                search: newValue,
                latLng: {
                    lat: this.place.latLng.lat,
                    lng: this.place.latLng.lng,
                },
            };
            this._invalid = false;
            return;
        }
        // if blank and not a required input
        if (!newValue) {
            this.valueObject = {
                place_id: '',
                search: '',
                latLng: {
                    lat: 0,
                    lng: 0,
                },
            };
            this._place = {};
            this._invalid = true;
            return;
        }
        // if the new _value matches the value.search, which could happen if
        // the value is changed externally (possibly through data binding) which
        // causes _value to be changed triggering this function _svalChanged()
        if (
            newValue &&
            this.valueObject &&
            this.valueObject.search &&
            newValue === this.valueObject.search
        ) {
            // nothing has really changed, just return
            return;
        }
        // if the existing value is blank, and the new value is not
        if ((!this.valueObject || !this.valueObject.search) && newValue) {
            this.valueObject = {
                place_id: '',
                search: newValue,
                latLng: {
                    lat: 0,
                    lng: 0,
                },
            };
            this._place = {};
            this._invalid = true;
            return;
        }
        // otherwise, the value is invalid
        this.valueObject = {
            place_id: '',
            search: newValue,
            latLng: {
                lat: 0,
                lng: 0,
            },
        };
        this._place = {};
        this._invalid = true;
    }

    _clearLocation() {
        this._value = '';
    }

    geocode(address: string, options: any) {
        return new Promise((resolve, reject) => {
            if (!this._geocoder) {
                reject(new Error('Geocoder not ready.'));
            } else {
                const opts = options || {};
                opts.address = address || '';
                this._geocoder.geocode(opts, (results: any, status: any) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (status === google.maps.GeocoderStatus.OK && results && results[0]) {
                        const p = this._extractPlaceInfo(results[0], opts.address);
                        resolve(p);
                    } else {
                        reject(status);
                    }
                });
            }
        });
    }

    reverseGeocode(latlng: any, options: any) {
        return new Promise((resolve, reject) => {
            if (!this._geocoder) {
                reject(new Error('Geocoder not ready.'));
            } else {
                const opts = options || {};
                if (latlng) {
                    opts.location = latlng;
                }
                this._geocoder.geocode(opts, (results: any, status: any) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (status === google.maps.GeocoderStatus.OK && results && results[0]) {
                        const p = this._extractPlaceInfo(results[0], '');
                        resolve(p);
                    } else {
                        reject(status);
                    }
                });
            }
        });
    }

    _onChangePlace() {
        const pl = this._places.getPlace();
        if (pl.geometry) {
            const p = this._extractPlaceInfo(pl, this.getInputField().value);
            this._place = p;
            // this._invalid = false;
            // this._invalid = false;
            this.latLng = {
                lat: p.latLng.lat,
                lng: p.latLng.lng,
            };
            this._value = this.getInputField().value;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.valueObject = {search: this.getInputField().value, ...p,};
            this.place = this.valueObject;
        }
    }

    // eslint-disable-next-line
    _extractPlaceInfo(pl: any, searchTerm: any) {
        const p: Place = {
            place_id: pl.place_id,
            formatted_address: pl.formatted_address,
            search: searchTerm || pl.formatted_address,
            latLng: {
                lat: pl.geometry.location.lat(),
                lng: pl.geometry.location.lng(),
            },
            basic: {
                name: pl.name || '',
                address: '',
                city: '',
                state: '',
                stateCode: '',
                postalCode: '',
                country: '',
                countryCode: '',
                phone: pl.formatted_phone_number || '',
            },
            placeDetails: {
                address_components: [],
                icon: pl.icon,
                international_phone_number: pl.international_phone_number || '',
                permanently_closed: pl.permanently_closed || false,
                types: pl.types ? JSON.parse(JSON.stringify(pl.types)) : [],
                website: pl.website || '',
                url: pl.url || '',
                utc_offset_minutes: pl.utc_offset_minutes,
            },
        };

        // extract address components
        const address = {
            street_number: '',
            route: '',
        };

        // eslint-disable-next-line
        for (let i = 0; i < pl.address_components.length; i++) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            p.placeDetails.address_components.push(JSON.parse(JSON.stringify(pl.address_components[i])));
            switch (pl.address_components[i].types[0]) {
                case 'locality':
                    p.basic.city = pl.address_components[i].long_name;
                    break;
                case 'administrative_area_level_1':
                    p.basic.stateCode = pl.address_components[i].short_name;
                    p.basic.state = pl.address_components[i].long_name;
                    break;
                case 'country':
                    p.basic.country = pl.address_components[i].long_name;
                    p.basic.countryCode = pl.address_components[i].short_name;
                    break;
                case 'postal_code':
                    p.basic.postalCode = pl.address_components[i].long_name;
                    break;
                case 'street_number':
                    address.street_number = pl.address_components[i].short_name;
                    p.basic.address = `${address.street_number} ${address.route}`;
                    p.basic.streetNumber = address.street_number;
                    break;
                case 'route':
                    address.route = pl.address_components[i].long_name;
                    p.basic.address = `${address.street_number} ${address.route}`;
                    p.basic.route = address.route;
                    break;
                default:
                    address[pl.address_components[i].types[0]] = pl.address_components[i].long_name;
            }
        }
        return p;
    }

    render() {
        return html`
            <google-maps-api
                    id="api"
                    api-key="${this.apiKey}"
                    version="${this.version}"
                    language="${this.language}"
                    map-id="${this.mapId}"
                    @api-load=${() => this._mapsApiLoaded()}>
            </google-maps-api>

            <div class="row mb-2 align-items-center form-col-item">
                <label class="col-2 form-col-label" for="">${this.label}</label>
                <div class="col-10 form-col-input">
                    <input id="autocompleteInput" class=${classMap({outline: this.outline})}
                           type="text"
                           placeholder="Quick Find"
                           .value=${this._value}
                           ?disabled="${this.disabled}"
                    />
                </div>
            </div>
        `;
    }
}