export class EAbort implements Error {

    name: string;
    message: string;
    stack?: string | undefined;
    cause?: Error | undefined;
    constructor(name?: string, message?: string, stack?: string | undefined, cause?: Error | undefined) {
        this.name = name ?? "Abort";
        this.message = message ?? "Operation Aborted";
        this.stack = stack;
        this.cause = cause;
    }
}
