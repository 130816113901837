import { WaitPatiently } from "../../components/ui/modal-spinner";
import { UINavigator } from "../../components/ui/navigator";
import { tlang } from "../../language/lang";
import { userDataStore } from "../common/current-user-data-store";
import { navigation } from "../../components/ui/navigation-path";
import { PurchaseOrderContainer } from "../../purchase-orders/data/purchase-order-container";
import {
    getPurchaseOrderContainer,
    getPurchaseOrderContainerManager,
    purchaseOrderViewFactory
} from "../purchase-orders/purchase-order-ui-adapter";
import { getPurchaseOrderTitle } from "../../purchase-orders/data/purchase-order-helper-functions";
import { cache } from "../cache/cache-registry";

export function purchaseOrderNav(): UINavigator {
    return {
        code: navigation.purchaseOrder,
        isModal: true,
        event: async function (id: string, data?: any, eventInfo?: { title: string; }): Promise<boolean> {
            const container = data instanceof PurchaseOrderContainer ? data : getPurchaseOrderContainer(id);
            const waiting = new WaitPatiently(() => tlang`Opening %%purchase-order%%`, () =>
                tlang`Please wait while we construct the %%purchase-order%% "${eventInfo?.title}"`);
            try {
                const view = await purchaseOrderViewFactory({
                    title: getPurchaseOrderTitle,
                    purchaseOrderContainerManager: getPurchaseOrderContainerManager(container),
                    purchaseOrderCache: cache().purchaseOrder,
                    purchaseOrderOwnerId: async () => userDataStore.defaultBranch?.id ?? "",
                });
                waiting.hideModal();
                await view.showModal();
            } finally {
                waiting.hideModal();
            }
            return true;
        }
    };
}
