// eslint-disable-next-line import/named
import { Route } from '@vaadin/router';
import { customElement, query } from 'lit/decorators.js';
import { AppIndex, MenuItem } from '../components/app-index';
import { appConfig } from './app-config';
import { attachRouter, setRoutes } from "../components/router";
import { getApi, setApiFactory, setApiInjector } from '../api/api-injector';
import { ServiceResponseInvalid, ServiceResponseType } from '../service_response';
import { DealerApiCommunications } from '../api/dealer-api-communications';
import { ValidationError } from '../api/validation-error';
import { QuoteApiImpl } from '../api/quote-api-impl';
import { BlobApiImpl } from '../api/blob-api-impl';
import { DealerClientApi } from "../api/client-api-implementation";
import { ProjectApiImplementation } from "../api/project-api-implementation";
import { DealerFranchiseeApi } from '../api/franchisee-api-implementation';
import { PaymentProfileApiImplementation } from "../api/payment-profile-api-implementation";
import { iconRegistry } from '../components/ui/icons/register-icons';
import { PurchaseOrderApiImplementation } from '../api/purchase-order-api-implementation';
import { getCurrentUser, setAfterApplyUserEvent, setCurrentUser } from '../api/current-user';
import { userDataStore } from './common/current-user-data-store';
import { setErrorDialogEventHandler, showError } from "../components/ui/show-error";
import { tlang } from '../language/lang';
import { showValidations } from '../components/ui/modal-validationhandler';
import { QuoteSupplier, setQuoteSupplierProviderCallback } from '../quotes/quote-service';
import { v6Config } from '../v6config/v6config';
import { showDevelopmentError } from "../development-error";
import { setBeforeConstructionEvent } from '../async-constructor';
import { runGlobalInit } from '../components/app-global-init';
import { registerUINavigators } from './register-ui-navigators';
import { setCacheRegistry } from './cache/cache-registry';
import { ClientCache } from './cache/client-cache';
import { ContactReferenceCache } from './cache/contact-reference-cache';
import { PaymentProfileCache } from './cache/payment-profile-cache';
import { ProjectCache } from './cache/project-cache';
import { QuoteCache } from './cache/quote-cache';
import { ResourcesCache } from './cache/resources-cache';
import { UserProfileCache } from './cache/user-profile-cache';
import { PurchaseOrderCache } from './cache/purchase-order-cache';
import { ClientPrimaryContactCache } from './cache/client-primarycontact-cache';
import { ReportApiImplementation } from "../api/report-api-implementation";
import { ProjectResourceLinkCache } from './cache/project-resource-link';
import {
    setQuoteProviderDataFactory,
    setQuoteProviderDataValidateAndUpgradeEvent
} from '../quotes/data/quote-provider-data';
import {
    createFranchiseeQuoteProviderData,
    validateAndUpdateFranchiseeQuoteProviderData
} from './quotes/data/franchisee-quote-provider-data';
import { ErrorDialog } from '../components/ui/modal-errorhandler';
import { EventSnippet } from '../components/ui/events';
import { SupplierApiImplementation } from "../api/supplier-api-implementation";
import { bindSendPurchaseOrderQuoteToSupplier } from '../purchase-orders/data/purchase-order-transmission';
import { createSupplierQuote } from './purchase-orders/purchase-order-ui-adapter';
import { html } from 'lit';
import { PricingEngineApiCommunications } from '../api/pricing-engine-api-communications';
import { PricingEngineApiImplementation } from '../api/pricing-engine-api-implementation';
import { FrameEngineApiImplementation } from '../api/frame-engine-api-implementation';
import { FrameEngineApiCommunications } from '../api/frame-engine-api-communications';

// this is our concrete top level application, everything else should be generic
@customElement('franchisee-app-index')
export class FranchiseeAppIndex extends AppIndex {
    // Do not remove below line as this will stop icons from working on our site.
    protected icons = iconRegistry;
    @query('#main-body')
    private mainBody!: HTMLElement;
    private appConfig;

    constructor() {
        super();
        this.appConfig = appConfig();
        setRoutes(this.getRoutes());

        setErrorDialogEventHandler(async (item: ServiceResponseInvalid | Error, title: EventSnippet) =>
            await (new ErrorDialog(title, item)).showModal());

        //Bind data provider information inot the generic
        setQuoteProviderDataFactory(createFranchiseeQuoteProviderData);
        setQuoteProviderDataValidateAndUpgradeEvent(validateAndUpdateFranchiseeQuoteProviderData);

        //create a generic global handler for communication errors that go unresolved.
        //this will typically only be things we don't want to happen or not considered normal workflow
        const responseHandler = async (response: ServiceResponseInvalid | ValidationError[]) => {
            if (!Array.isArray(response)) {
                if (response.responseType === ServiceResponseType.UnAuthorized) {
                    await setCurrentUser(null);
                    return;
                }
                const caption = response.responseTypeCaption;
                const msg = response.responseError?.message ?? "";
                const stack = response.responseError?.stackTrace ?? "";
                console.log(`ServiceResponse Error [${caption}] "${msg}" \n StackTrace:${stack}`);
                await showError(response, () => tlang`Server Error Occurred`);
            } else {
                console.log("Service Response Validation Errors Returned");
                const msg: string[] = [];
                response.forEach(err => {
                    const errMsg = `${err.property} -> ${err.message}`;
                    msg.push(errMsg);
                    console.log(errMsg);
                });
                await showValidations(msg, () => tlang`Validation Issues from Server`);
            }
        };

        const userLoginEvent = async () => {
            const reload = () => {
                this.requestUpdate();
                setTimeout(async () => await userDataStore.loadCoreDetails(), 200);
            };

            if (getCurrentUser() !== null) {
                //Make any api calls or anything else here that are necessary to be used for the current user
                try {
                    await userDataStore.loadCoreDetailsAfterLogin();
                    this.userDisplayName = getCurrentUser()?.friendlyName;
                    this.userName = getCurrentUser()?.userName;
                } catch (e) {
                    userDataStore.clear();
                    await showDevelopmentError(e as Error);
                    reload();
                }
            } else {
                userDataStore.clear();
                reload();
                this.userDisplayName = "";
                //we always want to login again

            }
        };
        setAfterApplyUserEvent(userLoginEvent);

        //any class using the async construct pattern will be assurred of a valid login
        //before the afterconstruction is called.
        setBeforeConstructionEvent(async () => await userDataStore.loadCoreDetails());


        setApiFactory({
            quote: () => new QuoteApiImpl(getApi()),
            blob: () => new BlobApiImpl(getApi()),
            client: () => new DealerClientApi(getApi()),
            project: () => new ProjectApiImplementation(getApi()),
            franchisee: () => new DealerFranchiseeApi(getApi()),
            paymentProfile: () => new PaymentProfileApiImplementation(getApi()),
            purchaseOrder: () => new PurchaseOrderApiImplementation(getApi()),
            reporting: () => new ReportApiImplementation(getApi('reporting')),
            supplier: () => new SupplierApiImplementation(getApi()),
            pricingEngine: () => new PricingEngineApiImplementation(getApi('pricing')),
            frameEngine: () => new FrameEngineApiImplementation(getApi('frameEngine')),
        });
        //Dependency inject an api for the entire application
        setApiInjector((selector?: string) => {
                const redirect = () => {
                    //Redirect to home page, next query will force a login to occur
                    window.location.href = "/";
                };

                if (selector === 'pricing') {
                    return new PricingEngineApiCommunications("", responseHandler, redirect);
                } else if (selector == 'frameEngine') {
                    return new FrameEngineApiCommunications("", responseHandler, redirect);
                } else if (selector == 'reporting') {
                    return new DealerApiCommunications(globalThis.reportingServiceConfiguration.reportingServiceApiUrl, responseHandler, redirect);
                } else {
                    return new DealerApiCommunications("", responseHandler, redirect);
                }
            }
        );
        this.userDisplayName = getCurrentUser()?.friendlyName;

        setCacheRegistry(() => {
            const api = getApi('');
            return {
                client: new ClientCache(api),
                contact: new ContactReferenceCache(api),
                primaryContact: new ClientPrimaryContactCache(api),
                paymentProfile: new PaymentProfileCache(api),
                project: new ProjectCache(api),
                quote: new QuoteCache(api),
                resource: new ResourcesCache(api),
                userProfile: new UserProfileCache(api),
                purchaseOrder: new PurchaseOrderCache(api),
                projectResourceLink: new ProjectResourceLinkCache(api)
            };
        });

        setQuoteSupplierProviderCallback(async () => {
            const v6Suppliers = await v6Config().suppliers() ?? [];
            return v6Suppliers.map(s => {
                const qs: QuoteSupplier = {
                    supplierId: s.id,
                    description: s.name
                };
                return qs;
            });
        });

        //bind the high level processor to the low level code

        bindSendPurchaseOrderQuoteToSupplier(createSupplierQuote);


        window.addEventListener('unhandledrejection', function (event) {
            showError({
                responseType: ServiceResponseType.Error,
                responseTypeCaption: tlang`unhandled error`,
                responseError: {
                    message: event.reason.message,
                    stackTrace: event.reason.stackTrace
                }
            }, () => tlang`Unhandled Error inside a promise occurred`);
        });

        runGlobalInit();

        registerUINavigators();

        setTimeout(async () => {
            await userDataStore.loadCoreDetails();
        }, 100);

    }

    protected getMenuItems(): Array<MenuItem> {
        return this.appConfig.menuPageItems;
    }

    protected getRoutes(): Route[] {
        return this.appConfig.routes;
    }

    protected firstUpdated() {
        attachRouter(this.mainBody);
    }

    protected async getLogoUrl() {
        if (getCurrentUser()?.isSupplier) {
            return html`<svg width="1" height="1" ></svg>`;
        }
        await userDataStore.loadCoreDetails();
        const api = getApi();
        const logoURl = api.fullUrl(`api/file/${userDataStore.franchisee?.positiveLogoVirtualPath}`) ?? await super.getLogoUrl();

        return userDataStore.franchisee?.positiveLogoVirtualPath
            ? html`<img src=${logoURl} class="img-fluid" alt="Brand Image" width="90" />`
            : html`<svg width="1" height="1" ></svg>`;
    }

}

