import { PurchaseOrder, PurchaseOrderItem, PurchaseOrderState } from "../../api/dealer-api-interface-franchisee";
import { getApiFactory } from "../../api/api-injector";
import { clone, compare } from "../../components/clone";
import { tlang } from "../../language/lang";
import { fireQuickSuccessToast } from "../../toast-away";
import { EventNotify } from "../../quotes/data/quote-container";

export class PurchaseOrderContainer {
    purchaseOrderId: string;
    purchaseOrder: PurchaseOrder | null;
    items: PurchaseOrderItem[] | null;

    constructor(purchaseOrderId: string,
        purchaseOrder: PurchaseOrder | null,
        items: PurchaseOrderItem[] | null) {
        this.purchaseOrder = purchaseOrder;
        this.items = items;
        this.purchaseOrderId = purchaseOrderId;
    }
}

export class PurchaseOrderContainerManager {
    backup: PurchaseOrderContainer;
    container: PurchaseOrderContainer;
    api = getApiFactory().purchaseOrder();
    afterSave: EventNotify[] = [];

    constructor(original: PurchaseOrderContainer) {
        if (original.purchaseOrder && original.purchaseOrder.id !== original.purchaseOrderId)
            throw new Error('Invalid container configuration. PurchaseOrderId must match purchaseOrderId');
        this.container = original;
        this.backup = clone(this.container);
    }

    get purchaseOrderId(): string {
        return this.container.purchaseOrderId;
    }

    protected async doAfterSave(): Promise<void> {
        this.afterSave.forEach(async (afterSaveEvent) => await afterSaveEvent());
    }

    public get purchaseOrder(): PurchaseOrder {
        if (!this.container.purchaseOrder) {
            throw new Error("Purchase order is empty");
        }

        return this.container.purchaseOrder;
    }

    public get purchaseOrderTitle(): string {
        return tlang`#${this.purchaseOrder.purchaseOrderNumber} - ${this.purchaseOrder.title}`;
    }

    public isReadOnly() {
        return !(this.purchaseOrder.state == PurchaseOrderState.Draft);
    }

    public async needsPurchaseOrder(): Promise<boolean> {
        if (!this.container.purchaseOrder) {
            const result = await this.api.getPurchaseOrder({ purchaseOrderId: this.purchaseOrderId });

            if (result)
                this.resetPurchaseOrder((result.purchaseOrder as unknown) as PurchaseOrder, result.purchaseOrderItems);
            else return false;
        }

        return true;
    }

    public async savePurchaseOrder(silentSave = true): Promise<boolean> {
        const result = await this.api.updatePurchaseOrder({ purchaseOrder: this.purchaseOrder });

        if (result) {
            this.resetPurchaseOrder(result.purchaseOrder, result.purchaseOrderItems);

            if (!silentSave)
                fireQuickSuccessToast(tlang`%%purchase-order%% saved`);

            await this.doAfterSave();

            return true;
        }

        return false;
    }

    public purchaseOrderChanged(): boolean {
        return !compare(this.backup.purchaseOrder, this.container.purchaseOrder);
    }

    public changed(): boolean {
        return !compare(this.backup, this.container);
    }

    protected resetPurchaseOrder(purchaseOrder: PurchaseOrder, purchaseOrderItems: PurchaseOrderItem[]) {
        this.container.purchaseOrder = purchaseOrder;
        this.container.items = purchaseOrderItems;

        this.backup.purchaseOrder = clone(purchaseOrder);
        this.backup.items = clone(purchaseOrderItems);
    }
}