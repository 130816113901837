import { K$ } from "@kyleyharris/kquery";
//a class that supports running an event on a timer with the abilty to start and
//reset the timer, and force the event.
//intended use is binding delayed keystroke events etc.
export class TimedTrigger {
    private interval: number;
    private event: (e) => void;
    private timerId: NodeJS.Timeout | null;
    constructor(interval: number, event: (e) => void) {
        this.interval = interval;
        this.event = event;
        this.timerId = null;
    }

    static addTextTrigger($input: K$, event) {
        $input.each(function (elem: any) {
            elem.textTrigger = new TimedTrigger(500, event);
            elem.textTrigger.bindTextTrigger(elem);
        });
    }

    public bindTextTrigger(input: HTMLInputElement) {
        const eventStart = (e) => { this.start(e); };
        const eventTriggerEarly = (e) => { this.triggerEarly(e); };
        input.addEventListener("keyup",
            eventStart);
        input.addEventListener("change",
            eventTriggerEarly);
    }
    public getResetEvent() { return (e) => this.reset(e); }
    public getTriggerEarlyEvent() { return (e) => this.triggerEarly(e); }

    stop() {
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
    }
    triggerEarly(e) {
        if (this.timerId) {
            this.stop();
            this.event(e);
        }
    }
    start(e) {
        this.stop();
        this.timerId = setTimeout(
            () => {
                this.timerId = null;
                this.event(e);
            },
            this.interval);
    }
    reset(e) {
        // interchangeable
        this.start(e);
    }
}

