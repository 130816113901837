
import { ApiCommunications } from "../../api/api-communications";
import { ResourcePageRequest, ResourcePickerDataProvider, ResourceTag } from "./v6-resource-picker-data-provider";

export interface Fill extends ResourceTag {
    lib: string;
}

export interface FillFilter {
    textFilter: string;
}


export class V6FillByFramePickerDataProvider extends ResourcePickerDataProvider<Fill, FillFilter> {
    objectReference: string;
    subType: string;
    protected path(): string { return "api/v6/ConfigItem/GetFillResourcesByFrame"; }
    protected convertFilter(filter: FillFilter | null): any {
        return filter?.textFilter;
    }

    constructor(api: ApiCommunications, supplierId: string, objectReference: string, subType: string) {
        super(api, supplierId);
        this.subType = subType;
        this.objectReference = objectReference;
    }

    protected inputData(input: ResourcePageRequest<FillFilter>): any {
        return {
            supplierCode: this.supplierId,
            subType: this.subType,
            frameReference: this.objectReference,
            pageIndex: input.pageIndex,
            pageSize: input.pageSize,
            filter: this.convertFilter(input.filter)
        };
    }

}
