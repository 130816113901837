import { ApiCommunications } from "./api-communications";
import { InputCopyFileByVirtualPath, InputUpdateFileByVirtualPath, ResultCopyFileByVirtualPath, ResultUpdateFileByVirtualPath } from "./dealer-api-interface-blob";
import { NullPromise } from "../null-promise";
import { BlobApi } from "./blob-api";

export class BlobApiImpl implements BlobApi {
    api: ApiCommunications;

    constructor(api: ApiCommunications) {
        this.api = api;
    }

    async copyFileByVirtualPath(input: InputCopyFileByVirtualPath): NullPromise<ResultCopyFileByVirtualPath> {
        return await this.api.post<ResultUpdateFileByVirtualPath>('api/File/CopyFileByVirtualPath', input);
    }

    async updateFileByVirtualPath(input: InputUpdateFileByVirtualPath): NullPromise<ResultUpdateFileByVirtualPath> {
        return await this.api.post<ResultUpdateFileByVirtualPath>('api/File/UpdateFileByVirtualPath', input);
    }
}