import { html } from 'lit';
import { PromiseTemplate } from '../../../components/ui/events';
import { V6FrameAttributeView } from './v6-frame-attribute';

export class V6AttributeBoolean extends V6FrameAttributeView {
    public async editTemplate(): PromiseTemplate {
        const d = this.attribute;
        const event = (e) => this.changeEvent(e);

        const str = html`
                <input @focus=${()=> this.editControlFocused()} ?readonly=${this.readonly}
                ?disabled=${this.readonly}
                class="form-check-input"
                type="checkbox" value="" .checked=${d.value.toLowerCase() === "true"} @click=${event}>
        `;
        return str;
    }
    protected isTarget(e: Event) { return e.target instanceof HTMLInputElement; }
    //override in subclass
    protected getValue(e: Event): string {
        return (e.target as HTMLInputElement).checked ? "True" : "False";
    }
}
