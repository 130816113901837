// eslint-disable-next-line import/named
import { html } from "lit";
import { EventGetReference, EventSnippet, PromiseSnippet, PromiseTemplate } from "../../components/ui/events";
import { MenuIconOption, PageControl, PageControlOptions, PageManager } from "../../components/ui/page-control";
import { DataEntryOwner, DataEntryPageControlView, ModalViewBase } from "../../components/ui/data-entry-screen-base";
import { PurchaseOrderContainer, PurchaseOrderContainerManager } from "../data/purchase-order-container";
import { tlang } from "../../language/lang";
import { isAutoSaving } from "../../components/save-workflow";
import { PurchaseOrder, PurchaseOrderState } from "../../api/dealer-api-interface-franchisee";
import { constructAsync } from "../../async-constructor";
import { PurchaseOrderDetailView, PurchaseOrderDetailViewOptions } from "./purchase-order-detail-view";
import { DataCacheGeneric } from "../../cache/generic-data-cache";
import { DevelopmentError } from "../../development-error";
import { sendPurchaseOrderQuoteToSupplier } from "../data/purchase-order-transmission";

export interface PurchaseOrderViewOptions {
    title: EventSnippet;
    purchaseOrderContainerManager: PurchaseOrderContainerManager;
    purchaseOrderOwnerId: EventGetReference;
    purchaseOrderCache: DataCacheGeneric;
}

export interface PurchaseOrderViewChildFactory {
    getDetailView(options: PurchaseOrderDetailViewOptions): PurchaseOrderDetailView;
}

export class PurchaseOrderViewChildFactoryImpl implements PurchaseOrderViewChildFactory {
    parent: PurchaseOrderDataEntryView;

    constructor(parent: PurchaseOrderDataEntryView) {
        this.parent = parent;
    }

    getDetailView(options: PurchaseOrderDetailViewOptions): PurchaseOrderDetailView {
        return new PurchaseOrderDetailView(options);
    }
}

export class PurchaseOrderDataEntryView extends DataEntryPageControlView {
    protected title: EventSnippet;
    protected purchaseOrderOwnerId: EventGetReference;
    protected purchaseOrderContainerManager: PurchaseOrderContainerManager;
    protected detailView: PurchaseOrderDetailView;
    protected purchaseOrderCache: DataCacheGeneric;
    protected purchaseOrderViewChildFactory: PurchaseOrderViewChildFactory;

    constructor(options: PurchaseOrderViewOptions, owner?: DataEntryOwner) {
        super(owner);
        this.purchaseOrderViewChildFactory = this.getPurchaseOrderViewChildFactory();
        this.purchaseOrderContainerManager = options.purchaseOrderContainerManager;
        this.title = options.title;
        this.purchaseOrderOwnerId = options.purchaseOrderOwnerId;
        this.purchaseOrderCache = options.purchaseOrderCache;

        this.detailView = this.purchaseOrderViewChildFactory.getDetailView(
            { purchaseOrderCache: this.purchaseOrderCache, purchaseOrderManager: this.purchaseOrderContainerManager });

        this.purchaseOrderContainerManager.afterSave.push(async () => {
            await this.detailView.render();
            await this.render();
        });
    }

    public get purchaseOrder(): PurchaseOrder {
        if (!this.purchaseOrderContainer.purchaseOrder) throw new DevelopmentError("Purchase order container is null");
        return this.purchaseOrderContainer.purchaseOrder;
    }

    public get purchaseOrderContainer(): PurchaseOrderContainer {
        return this.purchaseOrderContainerManager.container;
    }

    public async afterConstruction() {
        await this.purchaseOrderContainerManager.needsPurchaseOrder();

        await this.detailView.render();

        //this will create the page control
        await super.afterConstruction();
        this.buildActionMenu();
    }

    public createPageControl(): PageControl {
        // build static pages for each of the configured table settings
        const getInitialPageManagers = (): PageManager[] => {
            const pages: PageManager[] = [];
            pages.push(this.createDetailViewPage());
            return pages;
        };

        const options: PageControlOptions = {
            defaultTabIndex: 0,
            menuIcons: undefined,
            pageInitializer: () => getInitialPageManagers()
        };
        return new PageControl(options);
    }

    public override internalDataChanged(): boolean {
        return this.purchaseOrderContainerManager.changed();
    }

    public override getDataDictionaryName(): string {
        return tlang`%%purchase-order%%`;
    }

    public override isReadonly(): boolean {
        return this.purchaseOrderContainerManager.isReadOnly();
    }

    public async getTitle(): PromiseSnippet {
        return this.title(this.purchaseOrderContainerManager.purchaseOrder);
    }

    public override async prepareForSave(): Promise<void> {
        if (this.isReadonly()) return;

        await this.detailView.prepareForSave();
    }

    protected getPurchaseOrderViewChildFactory(): PurchaseOrderViewChildFactory {
        return new PurchaseOrderViewChildFactoryImpl(this);
    }

    protected createDetailViewPage(): PageManager {
        return {
            caption: () => tlang`Details`,
            canClose: () => Promise.resolve(false),
            canLeave: async () => await this.allowPageSwitch(),
            hasDelete: () => false,
            onEnter: async () => {
                await this.detailView.invalidate();
            },
            content: () => {
                return this.detailView.ui;
            },
            buttonMenu: () => {
                return this.detailView.buttonMenu();
            }
            ,
            data: this.detailView
        };
    }

    protected override async bodyTemplate(): PromiseTemplate {
        this.buildActionMenu();
        return super.bodyTemplate();
    }

    protected override async internalSaveData(): Promise<boolean> {
        return this.purchaseOrderContainerManager.savePurchaseOrder(isAutoSaving());
    }

    protected override getValidationErrors(): string[] {
        return this.detailView.getValidationErrors();
    }

    protected async internalSetState(state: PurchaseOrderState): Promise<boolean> {
        this.purchaseOrderContainerManager.purchaseOrder.state = state;
        let saved = await this.performAutoSave();

        if (state === PurchaseOrderState.Issued) {
            await this.experimentalCreateSupplierQuote();
        }
        return saved;
    }
    async experimentalCreateSupplierQuote() {
        await sendPurchaseOrderQuoteToSupplier(this.purchaseOrderContainerManager.purchaseOrder.branchQuoteId);
    }

    private buildActionMenu() {
        const menuIcons: MenuIconOption[] = [];

        const cancel = {
            caption: () => tlang`Cancel %%purchase-order%%`,
            event: async () => {
                return await this.setState(PurchaseOrderState.Cancelled);
            },
            classList: "btn btn-secondary"
        };
        const issue = {
            caption: () => tlang`Issue %%purchase-order%%`,
            event: async () => {
                return await this.setState(PurchaseOrderState.Issued);
            }
        };
        const complete = {
            caption: () => tlang`Complete %%purchase-order%%`,
            event: async () => {
                return await this.setState(PurchaseOrderState.Completed);
            }
        };

        const save = {
            event: async () => {
                if (this.isReadonly())
                    return false;

                return await this.performAutoSave();
            },
            caption: () => tlang`Save %%purchase-order%%`
        };

        switch (this.purchaseOrderContainerManager.purchaseOrder.state) {
            case PurchaseOrderState.Draft:
                menuIcons.push(issue);
                menuIcons.push(cancel);
                break;
            case PurchaseOrderState.Issued:
                menuIcons.push(complete);
                menuIcons.push(cancel);
                break;
        }

        if (!isAutoSaving())
            menuIcons.push(save);

        this.pageControl.setMenuIcons(menuIcons);
    }

    private async setState(state: PurchaseOrderState): Promise<boolean> {
        await this.prepareForSave();
        return this.internalSetState(state);
    }
}

export class PurchaseOrderView extends ModalViewBase {
    view: PurchaseOrderDataEntryView | null = null;
    options: PurchaseOrderViewOptions;

    constructor(options: PurchaseOrderViewOptions) {
        super();
        this.options = options;
    }

    public override async afterConstruction(): Promise<void> {
        this.view = await constructAsync(this.createView());
        this.view.onRender = async () => await this.render();
        this.view.render();
    }

    protected override async canClose(): Promise<boolean> {
        return await this.view?.canClose() ?? true;
    }

    protected override async getTitle(): PromiseSnippet {
        return await this.view?.getTitle() ?? "";
    }

    protected override modalSize(): string {
        return "modal-fullscreen";
    }

    protected override renderFooterTemplate(): boolean {
        return false;
    }

    protected createView(): PurchaseOrderDataEntryView {
        return new PurchaseOrderDataEntryView(this.options, this);
    }

    protected override async bodyTemplate(): PromiseTemplate {
        return html`${this.view?.ui}`;
    }
}

