//a return result that must have and object reference, a code, and an attached resource with

import { ApiCommunications } from "../../api/api-communications";
import { ResultPaginated } from "../../paginated_result";

//extra information
export interface ResourceTag {
    objectReference: string;
    code: string;
    libId?: number;
    id?: number;
    description: string;
}

export interface ResourcePageResult<Resource extends ResourceTag> {
    //total results for query
    total: number;
    //number of elements returned in this result
    count: number;
    //the page index 0.. returned
    pageIndex: number;
    pageCount: number;
    items: Array<Resource>;
}

// a page request providing a filter to the server
export interface ResourcePageRequest<Filter> {
    pageIndex: number;
    pageSize: number;
    filter: Filter | null;
}

// a means of communicating with a service to get data for the picker
export interface ResourceEndpoint<Resource extends ResourceTag, Filter> {
    getResourceClass(): number;
    //    supplier: data.V6Supplier;
    getPage(input: ResourcePageRequest<Filter>): Promise<ResourcePageResult<Resource> | null>;
}

//base class for fetching paginated resource data from the servers
export class ResourcePickerDataProvider<Resource extends ResourceTag, Filter extends object> implements ResourceEndpoint<Resource, Filter> {
    supplierId: string;
    api: ApiCommunications;
    constructor(api: ApiCommunications, supplierId: string) {
        this.api = api;
        this.supplierId = supplierId;
    }

    public getResourceClass(): number {
        throw new Error("override the resource class");
    }
    protected path(): string { throw new Error("Override the path"); }
    //incase the filter we have is not the same as what must be provided to the server
    protected convertFilter(filter: Filter | null): any {
        return filter;
    }
    protected inputData(input: ResourcePageRequest<Filter>): any {
        return {
            supplierId: this.supplierId,
            pageIndex: input.pageIndex,
            pageSize: input.pageSize,
            filter: this.convertFilter(input.filter)
        };
    }
    public getResourceImageUrl(resourceClass: number, id: number, libId: number): string {
        return `api/v6/ConfigItem/Resource/${this.supplierId}/${resourceClass}/${libId}/${id}/resfile.jpg`;
    }
    public getFamilyImageUrl(resourceClass: number, id: number, libId: number): string {
        return `api/v6/ConfigItem/Family/${this.supplierId}/${resourceClass}/${libId}/${id}/resfile.jpg`;
    }

    public async getPage(input: ResourcePageRequest<Filter>): Promise<ResourcePageResult<Resource> | null> {
        const response = await this.api.post<ResultPaginated<Resource>>(this.path(),
            this.inputData(input)
        );

        if (!response) return null;
        const pageData = response;
        let count = pageData.count;
        if (count === 0) count = 1;
        const r: ResourcePageResult<Resource> = {
            total: pageData.count,
            count: pageData.results.length,
            pageIndex: pageData.pageIndex,
            pageCount: pageData.pageCount,
            items: pageData.results
        };
        return r;
    }
}

