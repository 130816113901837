import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';


@customElement('icon-save')
export class IconSave extends LitElement {
    protected createRenderRoot(): Element | ShadowRoot {
        return this;
    }
    render() {
        return html`<i class="fa-solid fa-cloud-arrow-up"></i>`;
    }
}
