
// https://css-tricks.com/lets-create-a-lightweight-native-event-bus-in-javascript/



export class EventBus<DetailType = any> {
    private static _instance: EventBus;

    public static get Instance() {
        return EventBus._instance || (EventBus._instance = new this('DefaultEventBus'));
    }

    private eventTarget: EventTarget;

    constructor(description = 'eventBus') {
        this.eventTarget = document.appendChild(document.createComment(description));
    }

    on(type: string, listener: (event: CustomEvent<DetailType>) => void) {
        this.eventTarget.addEventListener(type, listener as EventListener);
    }

    once(type: string, listener: (event: CustomEvent<DetailType>) => void) {
        this.eventTarget.addEventListener(type, listener as EventListener, { once: true });
    }

    off(type: string, listener: (event: CustomEvent<DetailType>) => void) {
        this.eventTarget.removeEventListener(type, listener as EventListener);
    }

    emit(type: string, detail?: DetailType) {
        return this.eventTarget.dispatchEvent(new CustomEvent(type, { detail }));
    }
}

