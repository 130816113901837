import { html, TemplateResult } from 'lit';
import { PromiseSnippet, PromiseTemplate } from '../../components/ui/events';
import { ModalDialog } from '../../components/ui/modal-base';
import { WaitPatientlyLoading } from '../../components/ui/modal-loading';
import { fireToastError } from '../../toast-away';
import { ResourceEndpoint, ResourcePageResult, ResourceTag } from './v6-resource-picker-data-provider';

export interface ResourcePickerOptions {
    //will pick and trigger the event on click, rather than on select button
    pickImmediately?: boolean;
    pageSize?: number;
    show?: {
        selectButton?: boolean;
        indicator?: boolean;
        cancelButton?: boolean;
    };
    resourceName?: string;
}
//a view object that supports searching and selecting information for a specific kind of resource
//using a filter. used as an abstract base class
export class ResourcePicker<Resource extends ResourceTag, Filter> extends ModalDialog {
    //the means to get the data from somewhere.
    comms: ResourceEndpoint<Resource, Filter>;
    //the currently selected item, either as a picker, or a browser highlighted row
    selected: Resource | null = null;
    //configuration for operation and callbacks
    options: ResourcePickerOptions;
    //the page to be rendered.
    currentPageIndex = 0;
    //the result set which is going to be rendered.
    lastResult: ResourcePageResult<Resource> | null;
    //the filter used when fetching the data
    private _filter: Filter | null;

    constructor(comms: ResourceEndpoint<Resource, Filter>, options: ResourcePickerOptions) {
        super();
        this.comms = comms;

        this.ui.className += " v6-resource-picker";
        this.options = options;
        this.lastResult = null;
        this._filter = null;
    }

    //update the filter from the UI and re-render the view
    public setFilter(filter: Filter) {
        this._filter = filter;
        //reset the index so that we dont get empty pages
        this.currentPageIndex = 0;
        this.refreshData();
    }
    //update the pageindex and rerender the view
    public setPageIndex(index: number) {
        this.currentPageIndex = index;
        this.refreshData();
    }
    //this should be overridden by sub-classes that have filters and need to impletment the filter editors and handlers
    protected filterTemplate(): TemplateResult {
        return html``;
    }
    //override this and provide the <th> elements to match what is returned by the columnsTemplate
    protected tableHeaderTemplate(): TemplateResult {
        return html`
        <th>Object Reference</th>
        <th>Code</th>`;
    }
    //override this to provide a single row of the view based on the item
    //this should be a template of <td> elements
    protected columnsTemplate(item: Resource): TemplateResult {
        return html`
        <td>${item.objectReference}</td>
        <td>${item.code}</td>`;
    }
    //this is the primary template for the data table, inclusive of pagination
    private tableTemplate(): TemplateResult {
        const result = this.lastResult ?? null;
        return html`
                <div class="table-responsive resource-picker-modal">
                    <table class="table cell-border table-striped dataTable interactive-table" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                ${this.options?.show?.indicator ? html`<td>*</td>` : html``}
                                ${this.tableHeaderTemplate()}
                            </tr>
                        </thead>
                        <tbody class="table-body">
                            ${result?.items.map(r => this.rowTemplate(r))}
                        </tbody>
                    </table>
                    <div class="pagination-box">
                        <div id='pagination-text' class="text-right mx-1">
                            Page ${(result?.pageIndex ?? 0) + 1} of ${result?.pageCount}
                        </div>
                        <div id="pagination-pages" class="text-right">
                            <bs-paginator @page-change=${(e: CustomEvent)=> this.setPageIndex(e.detail.index)}
                                .index=${(result?.pageIndex ?? 0) + 1}
                                .count=${result?.pageCount}></bs-paginator>
                        </div>
                    </div>
                </div>`;
    }

    protected renderFooterTemplate(): boolean {
        return this.options.show?.selectButton != undefined || this.options.show?.cancelButton != undefined;
    }

    protected footerTemplate(): TemplateResult {
        const selectButton = this.options.show?.selectButton
            ? html`<button type="button" class="btn btn-primary" @click=${() => this.select()}>Select</button>`
            : html``;
        const cancelButton = this.options.show?.cancelButton
            ? html`<button type="button" class="btn btn-primary" @click=${() => this.cancel()}>Cancel</button>`
            : html``;
        return html`${selectButton}${cancelButton}`;

    }
    cancel() {
        this.selected = null;
        this.hideModal();
    }

    // the row template
    private rowTemplate(item: Resource): TemplateResult {

        const clickEvent = (e: Event) => {
            //when we click on an item we will set that item as the selected item for the picker
            //and we will call the event if set.
            //if we are running modally, then we will close the modal
            const tr = e.currentTarget as HTMLTableRowElement;
            this.selected = this.lastResult?.items.filter(x => x.objectReference === tr.dataset.objectReference)[0] ?? null;
            if (this.options.pickImmediately || !this.options.show?.selectButton) {
                this.select();
            }
            if (this.options.show?.indicator)
                tr.querySelector('input[name=index-selector]')?.setAttribute("checked", "checked");
        };

        return html`
        <tr data-object-reference=${item.objectReference} data-code=${item.code} @click=${clickEvent} @dblclick=${clickEvent}>
            ${this.options.show?.indicator
                ? html`<td><input type="radio" name="index-selector" value=${item.objectReference} /></td>`
                : html``}
            ${this.columnsTemplate(item)}
        </tr>`;
    }

    protected async bodyTemplate(): PromiseTemplate {
        return html`
            <div class="modal-body-wrapper">
                ${this.filterTemplate()}
            </div>
            ${this.lastResult ? this.tableTemplate() : html`
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>`}`;
    }

    protected closeButtonTemplate(): TemplateResult {
        return html`<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>`;
    }

    //public, but should never really need to be called
    private async refreshData(): Promise<ResourcePageResult<Resource> | null> {
        const input =
        {
            pageIndex: this.currentPageIndex,
            pageSize: this.options.pageSize ?? 15,
            filter: this._filter
        };
        const wait = new WaitPatientlyLoading();
        try {
            const result = await this.comms.getPage(input);
            if (!result)
                this.cancel();
            this.lastResult = result;
            await this.render();
        } catch (e) {
            fireToastError(e as Error);
        } finally {
            wait.hideModal();
        }
        return this.lastResult;
    }

    protected async getTitle(): PromiseSnippet {
        if (this.options.resourceName)
            return `Select ${this.options.resourceName}`;
        else
            return "Select Item";
    }

    protected select() {
        this.hideModal();
    }
    public async onShowModal(): Promise<void> {
        await this.render();
        if (!this.lastResult) await this.refreshData();
    }

}
