// noinspection HtmlUnknownAttribute
import { MenuIconOption, PageControl, PageControlOptions, PageManager } from "../../components/ui/page-control";
import { hideModalDialog, showModalDialog } from "../../components/ui/modal-helper";
import { EventSnippet, PromiseSnippet, PromiseTemplate } from "../../components/ui/events";
import { ProjectApi } from "../../api/project-api";
import { ProjectState, ViewProjectResourceReference } from "../../api/dealer-api-interface-project";
import { ProjectResourceView } from "./project-resource-view";
import { ProjectDetailView } from "./project-detail-view";
import { ProjectContainer, ProjectContainerManager } from "../data/project-container";
// eslint-disable-next-line import/named
import { html, TemplateResult } from "lit";
import { getInternalId } from "../../components/ui/databinding/databinding";
import { tlang } from "../../language/lang";
import { getApiFactory } from "../../api/api-injector";
import { allowPageControlTabChange, canClose, isAutoSaving, SavePromptOptions } from "../../components/save-workflow";
import { getSaveIndicator } from "../../components/ui/save-notifier";
import { showValidations } from "../../components/ui/modal-validationhandler";
import { DataCacheGeneric } from "../../cache/generic-data-cache";
import { AskConfirmation, confirmationButtons, ConfirmationButtonType } from "../../components/ui/modal-confirmation";
import { ViewBase } from "../../components/ui/view-base";
import { FranchiseeProjectResourceView } from "../../dealer-franchisee/projects/views/franchisee-project-resource-view";

export interface ProjectViewOptions {
    projectContainerManager: ProjectContainerManager;
    quoteCache: DataCacheGeneric;
    purchaseOrderCache: DataCacheGeneric;
    title: EventSnippet;
}

// Project view encloses the entire project edit and view process
// contains detail view
// resources view
// shipping view.
export class ProjectView extends ViewBase {

    projectApi: ProjectApi = getApiFactory().project();
    get projectContainer(): ProjectContainer {
        return this.projectContainerManager.container;
    }
    projectContainerManager: ProjectContainerManager;
    private readonly title: EventSnippet;
    private readonly elementId: string;
    private pageControl: PageControl;
    protected readonly detailView: ProjectDetailView;
    private readonly resourceView: ProjectResourceView;
    quoteCache: DataCacheGeneric;
    purchaseOrderCache: DataCacheGeneric;


    constructor(options: ProjectViewOptions) {
        super();
        this.elementId = `project-view-${getInternalId()}`;
        this.title = options.title;
        this.quoteCache = options.quoteCache;
        this.purchaseOrderCache = options.purchaseOrderCache;

        this.projectContainerManager = options.projectContainerManager;

        this.detailView = this.projectDetailViewFactory(options);
        this.resourceView = this.projectResourceViewFactory();

        this.projectContainerManager.afterSave.push(async () => {
            await this.detailView.render();

            // await this.resourceView.loadOrRefresh();
            // this.resourceView.render();

            await this.render();
        });

        this.pageControl = this.createPageControl();
    }

    public async afterConstruction() {
        await this.projectContainerManager.needsProject();
        await this.detailView.render();
        this.buildProjectActionMenu();
    }

    async openResourceItem(resourceItem: ViewProjectResourceReference): Promise<void> {
        alert(`Open Resource ${resourceItem.typeOf.toString()}`);
    }

    public async canLoseFocus(): Promise<boolean> {
        return true;
    }

    public async showModal(): Promise<void> {
        await this.projectContainerManager.needsProject();
        await this.detailView.render();
        await this.resourceView.render();
        await this.render();
        await showModalDialog(this.ui);
    }

    public async hideModal(): Promise<void> {
        await hideModalDialog(this.ui);
    }

    protected async autoSaveProject(): Promise<boolean> {
        return await this.saveProject(true);
    }

    protected async saveProject(autoSave: boolean): Promise<boolean> {
        if (autoSave && !(await this.projectNeedsSave())) return true;

        let saved = false;
        const indicator = await getSaveIndicator(autoSave);
        try {
            const errors = await this.getValidationErrors();
            if (errors.length != 0) {
                await showValidations(errors);
                return false;
            }
            saved = await this.projectContainerManager.saveProject(autoSave);
            this.buildProjectActionMenu();

            return saved;
        } finally {
            indicator.completed(saved);
        }
    }

    protected isReadonly(): boolean {
        return this.projectContainerManager.isReadonly();
    }

    protected async getValidationErrors() {
        await this.prepareProjectForSave();
        // TODO skip shipping validation for now, since the page will soon be folded into the main details view
        const errors = [...this.detailView.getValidationErrors()];
        return errors;
    }

    protected async prepareProjectForSave(): Promise<boolean> {
        if (this.isReadonly()) return false;

        await this.detailView.prepareForSave();
        return true;
    }

    protected async projectNeedsSave(): Promise<boolean> {
        //if readonly, check for things changed at the data-object layer, but dont copy from the UI
        if (this.isReadonly()) return this.projectContainerManager.changed();

        await this.prepareProjectForSave();
        return this.projectContainerManager.changed();
    }

    protected async allowPageSwitch(): Promise<boolean> {
        return await allowPageControlTabChange(this.getAutoSavePromptOptions());
    }

    protected getAutoSavePromptOptions(): SavePromptOptions {
        return {
            autoSaveEvent: async () => await this.autoSaveProject(),
            dictionaryName: '%%project%%',
            isReadonly: this.isReadonly(),
            needsSaveEvent: async () => await this.projectNeedsSave()
        };
    }

    protected createPageControl(): PageControl {
        // build static pages for each of the configured table settings
        const getInitialPageManagers = (): PageManager[] => {
            const pages: PageManager[] = [];
            pages.push(this.createDetailPage());
            pages.push(this.createResourcePage());
            return pages;

        };
        const options: PageControlOptions = {
            defaultTabIndex: 0,
            menuIcons: undefined,
            pageInitializer: () => getInitialPageManagers()
        };
        return new PageControl(options);
    }

    protected createResourcePage(): PageManager {
        return {
            caption: () => tlang`Financials`,
            canClose: () => Promise.resolve(false),
            canLeave: async () => await this.allowPageSwitch(),
            hasDelete: () => false,
            onEnter: async () => {
                await this.resourceView.invalidate();
            },
            content: () => {
                return this.resourceView.ui;
            },
            buttonMenu: () => {
                return this.resourceView.buttonMenu();
            },
            data: this.resourceView
        };
    }

    protected async canClose(): Promise<boolean> {
        return canClose(this.getAutoSavePromptOptions());
    }

    protected projectResourceViewFactory() {
        return new ProjectResourceView({
            projectManager: this.projectContainerManager,
            quoteCache: this.quoteCache,
            purchaseOrderCache: this.purchaseOrderCache,
            canClose: async () => {
                if (await this.canClose()) {
                    await this.hideModal();
                    return true;
                }

                return false;

            }
        });
    }

    protected projectDetailViewFactory(_options: ProjectViewOptions) {
        return new ProjectDetailView({
            projectManager: this.projectContainerManager
        });
    }

    protected async getTitle(): PromiseSnippet {
        return this.title(this.projectContainer.project);
    }

    //TODO: Create the Modal class that we can use and then pass options to render footers, etc..
    protected async template(): PromiseTemplate {
        const eventCloseQuote = async (e: Event) => {
            e.preventDefault();
            e.stopPropagation();
            if (await this.canClose())
                await this.hideModal();
        };

        return html`
            <div class="modal" data-bs-backdrop="static" tabindex="-1">
                <div class="modal-dialog modal-fullscreen">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">${await this.getTitle()}</h5>
                            <button @click=${eventCloseQuote} type="button" class="btn-close" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            ${await this.bodyTemplate()}
                        </div>
                    </div>
                </div>
            </div>`;

    }

    private buildProjectActionMenu() {
        const menuIcons: MenuIconOption[] = [];

        const cancel = {
            caption: () => tlang`Cancel %%project%%`,
            event: async () => {
                return await this.setProjectState(ProjectState.Cancelled);
            }
        }
        const complete = {
            caption: () => tlang`Complete %%project%%`,
            event: async () => {
                return await this.setProjectState(ProjectState.Completed);
            }
        }

        const save = {
            event: async () => {
                if (this.isReadonly())
                    return false;

                return this.saveProject(false);
            },
            caption: () => tlang`Save %%project%%`
        };

        const addQuote = {
            event: async () => {
                if (this.isReadonly())
                    return false;

                return await this.canCreateQuote() && this.internalCreateQuote();
            },
            caption: () => tlang`Add new %%quote%%`
        }

        if (!isAutoSaving())
            menuIcons.push(save);

        if (!this.projectContainerManager.isReadonly()) {
            menuIcons.push(complete);
            menuIcons.push(cancel);
            menuIcons.push(addQuote);
        }

        this.pageControl.setMenuIcons(menuIcons);
    }

    private async setProjectState(projectState: ProjectState) {
        try {
            const stateText = projectState == ProjectState.Cancelled ? "cancel" : "complete"
            if (await AskConfirmation(tlang`Do you want to ${stateText} this %%project%%?`, confirmationButtons[ConfirmationButtonType.yesNo])) {
                this.projectContainerManager.project.state = projectState;
                await this.internalSaveData();
                this.buildProjectActionMenu();
            }
            return false;
        }
        finally {
            await this.render();
        }
    }

    protected async internalCreateQuote(): Promise<boolean> {
        // implemented in subclass
        return false;
    }

    protected async canCreateQuote(): Promise<boolean> {
        // implemented in subclass
        return false;
    }

    /**
     * inherited
     * @returns
     */
    protected async internalSaveData(): Promise<boolean> {
        //this will populate the managed quote with data from the UI
        return await this.projectContainerManager.saveProject(isAutoSaving());
    }

    protected async refreshResources() : Promise<void>{
        await this.projectContainerManager.refreshProject();
        if (this.pageControl.activePage?.data instanceof FranchiseeProjectResourceView) {
            (this.pageControl.activePage?.data as FranchiseeProjectResourceView).refreshData();
        }
        await this.resourceView.invalidate();
    }

    private createDetailPage(): PageManager {
        return {
            caption: () => tlang`Details`,
            canClose: () => Promise.resolve(false),
            canLeave: async () => await this.allowPageSwitch(),
            hasDelete: () => false,
            onEnter: async () => {
                await this.detailView.loadOrRefresh();
            },
            content: () => {
                return this.detailView.ui;
            },
            data: this.detailView
        };
    }

    private bodyTemplate(): TemplateResult {
        return html`
            <div id=${this.elementId} class="page-content">
                ${this.pageControl.ui}
            </div>`;
    }
}