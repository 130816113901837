import { ProjectApi } from "./project-api";
import {
    InputCreateProject,
    InputCreateProjectResourceReference,
    InputDeleteProject,
    InputDeleteProjectResourceReference,
    InputUpdateProject,
    RequestBrowseProjectSummary,
    RequestGetProject,
    RequestGetProjectResource,
    ResultBrowseProjectSummary,
    ResultCreateProjectResourceReference,
    ResultCreateProject,
    ResultDeleteProjectResourceReference,
    ResultDeleteProject,
    ResultGetProjectResource,
    ResultGetProject,
    ResultUpdateProject,
    RequestGetResourceLinks,
    ResultGetResourceLinks
} from "./dealer-api-interface-project";
import { NullPromise } from "../null-promise";
import { ApiCommunications } from "./api-communications";

export class ProjectApiImplementation implements ProjectApi {
    api: ApiCommunications;
    constructor(api: ApiCommunications) {
        this.api = api;
    }

    async browseProjectSummary(body: RequestBrowseProjectSummary): NullPromise<ResultBrowseProjectSummary> {
        return await this.api.post<ResultBrowseProjectSummary>('api/Project/BrowseProjectSummary', body);
    }

    async createProject(body: InputCreateProject): NullPromise<ResultCreateProject> {
        return await this.api.post<ResultCreateProject>('api/Project/CreateProject', body);
    }

    async createProjectResourceReference(body: InputCreateProjectResourceReference): NullPromise<ResultCreateProjectResourceReference> {
        return await this.api.post<ResultCreateProjectResourceReference>('api/Project/CreateProjectResourceReference', body);
    }

    async deleteProject(body: InputDeleteProject): NullPromise<ResultDeleteProject> {
        return await this.api.post<ResultDeleteProject>('api/Project/DeleteProject', body);
    }

    async deleteProjectResourceReference(body: InputDeleteProjectResourceReference): NullPromise<ResultDeleteProjectResourceReference> {
        return await this.api.post<ResultDeleteProjectResourceReference>('api/Project/DeleteProjectResourceReference', body);
    }

    async getProject(body: RequestGetProject): NullPromise<ResultGetProject> {
        return await this.api.post<ResultGetProject>('api/Project/GetProject', body);
    }

    async getProjectResource(body: RequestGetProjectResource): NullPromise<ResultGetProjectResource> {
        return await this.api.post<ResultGetProjectResource>('api/Project/GetProjectResource', body);
    }

    async updateProject(body: InputUpdateProject): NullPromise<ResultUpdateProject> {
        return await this.api.post<ResultUpdateProject>('api/Project/UpdateProject', body);
    }
    async getResourcesLinks(body: RequestGetResourceLinks): NullPromise<ResultGetResourceLinks> {
        return await this.api.post<ResultGetResourceLinks>('api/Project/GetResourcesLinks', body);
    }
}