// eslint-disable-next-line import/named
import { LitElement, html, TemplateResult } from 'lit';
import { property, } from 'lit/decorators.js';
import { until } from 'lit/directives/until.js';
import { getCurrentUser, setCurrentUser } from '../api/current-user';
import { run2FA, runResetPassword } from '../authenticate-modal';
import { tlang } from '../language/lang';
import { registerTechnicalDictionary } from '../language/technical-dictionary';
import { showDevelopmentError } from "../development-error";
import './FormInputView.ts';
import { urlForName } from "./router";
import { isEmptyOrSpace } from './ui/helper-functions';

import PoweredBySoftTech from '../../assets/images/PoweredBySoftTech.svg';

export interface MenuItem {
  name: string;
  title: string;
}

// eslint-disable-next-line no-var -- var declared as it is replaced by
declare var __webmodulebuildnumber__: string;

export class AppIndex extends LitElement {
  @property({ type: String }) appTitle?: string;
  @property({ type: String }) currentPage?: string;
  @property({ type: String }) menuItems?: string;
  @property({ type: String }) buildNumber?: string = __webmodulebuildnumber__;
  @property({ type: String }) userDisplayName?: string;
  @property({ type: String }) userName?: string;

  constructor() {
    super();
    registerTechnicalDictionary();
    this.addEventListener("webmodule-page-navigation", this.updateNavigation);

    window.onunhandledrejection = event => {
      console.warn(`UNHANDLED PROMISE REJECTION: ${event.reason}`);
      showDevelopmentError(event.reason);
    };

    window.onerror = function (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) {
      // this ResizeObserver error can be completely ignored
      if ((typeof event === 'string') && event.includes('ResizeObserver loop limit exceeded')) return;

      console.warn(`UNHANDLED ERROR: ${error ? error.stack : event}`);
      if (source?.includes('apexcharts.js')) return;

      showDevelopmentError(error ?? `
${event}
source:${source}
line:${lineno}
col :${colno}
`);
    };
  }

  protected createRenderRoot(): Element | ShadowRoot {
    return this;
  }

  protected getMenuItems(): Array<MenuItem> {
    if (this.menuItems)
      return JSON.parse(this.menuItems);
    return [];
  }

  protected getMenuItemTemplate(menuItem: MenuItem): TemplateResult {
    return html`
        <li class=${"nav-item " + (menuItem.name.toLowerCase() === this.currentPage?.toLowerCase() ? " active" : "")}>
          <a class="nav-link" aria-current="page" href="${urlForName(menuItem.name)}">${menuItem.title}</a>
        </li>`;
  }

  render() {
    console.log();

    const logoutEvent = async (e: Event) => {
      e.preventDefault();
      await setCurrentUser(null);
    };
    const changePasswordEvent = (e: Event) => {
      e.preventDefault();
      runResetPassword(this.userName);
    };
    const enable2FAEvent = (e: Event) => {
      e.preventDefault();
      run2FA();
    };
    const title2FA = getCurrentUser()?.Is2FAEnabled ? tlang`Register new 2FA App` : tlang`Enable 2FA`;
    const userMenu = !isEmptyOrSpace(this.userDisplayName)
      ? html`
        <span class="navbar-text">
          <div class="dropdown current-user">
            <a class="dropdown-toggle" href="#" role="button" id="current-user-menu-link" data-bs-toggle="dropdown"
              aria-expanded="false">${tlang`Welcome`} ${this.userDisplayName}
            </a>
            <ul class="dropdown-menu" aria-labelledby="current-user-menu-link">
              <li><a @click=${logoutEvent} class="dropdown-item" href="#">${tlang`Logout`}</a></li>
              <li><a @click=${changePasswordEvent} class="dropdown-item" href="#">${tlang`Change Password`}</a></li>
              <li><a @click=${enable2FAEvent} class="dropdown-item" href="#">${title2FA}</a></li>
            </ul>
          </div>
        </span>`
      : html``;

    return html`
    <header>
      <nav class="navbar navbar-expand-lg navbar-header">
        <div class="container-fluid px-3">
          <a class="navbar-brand" href="/">
            ${until(this.getLogoUrl(), html`<svg width="1" height="1" ></svg>`)}
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target=".navbar-collapse"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="navbar-collapse collapse" id="siteNavBar">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              ${this.getMenuItems().map(x => this.getMenuItemTemplate(x))}
            </ul>
            ${userMenu}
          </div>
        </div>
      </nav>
    </header>

    <main id="main-page" role="main" class="flex-shrink-0 main-page">
      <div id="main-body">

      </div>
    </main>

    <footer class="footer mt-auto py-2">
      <div class="d-flex flex-wrap justify-content-center align-content-center justify-content-md-between">
        <div
          class="col-md d-flex flex-wrap flex-lg-nowrap justify-content-center justify-content-md-start align-items-center">
          <div class="st-logo px-3">
            <img src=${PoweredBySoftTech} alt="Powered by SoftTech online" />
          </div>
          <div class="st-copyright px-3">Copyright Soft Tech NZ LTD &copy; ${(new Date()).getFullYear()}</div>
        </div>
        <div class="col-md d-flex flex-wrap justify-content-center justify-content-md-end align-items-center">
          <div class="st-phone px-3">For support contact: support@softtech.com</div>
        </div>
      </div>
    </footer>
    `;
  }

  protected async getLogoUrl(): Promise<TemplateResult> {
    return html``;
  }

  private updateNavigation(e) {
    this.currentPage = e.detail;
  }
}
