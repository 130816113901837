import { DevelopmentError } from "../../development-error";

type EventCreateSupplierQuote = (quoteId: string) => Promise<string>;
let _event: EventCreateSupplierQuote | null = null;
export function bindSendPurchaseOrderQuoteToSupplier(event: EventCreateSupplierQuote) {
    _event = event;
}

/**
 * later on this should just be a boolean return, and it will be queued for final checking
 * @param quoteId
 * @returns supplier reference or empty string
 */
export async function sendPurchaseOrderQuoteToSupplier(quoteId: string): Promise<string> {
    if (!_event) throw new DevelopmentError("sendPurchaseOrderQuoteToSupplier has no event");
    return await _event(quoteId);
}