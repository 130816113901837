import { ClientPrimaryContact } from "../../api/dealer-api-interface-client";
import { DataCache } from "../../cache/data-cache";
import { DataCacheGeneric } from "../../cache/generic-data-cache";
import { DevelopmentError } from "../../development-error";
import {
    ClientCacheData,
    ContactCacheData,
    ProjectCacheData,
    PurchaseOrderCacheData,
    QuoteCacheData
} from "./cache-data";
import { ProjectResourceLink } from "./resource-link";
import { TenantLoginPublicInfo } from "../../api/dealer-api-interface-user";

interface DealerCacheRegistry {
    client: DataCache<ClientCacheData>;
    contact: DataCache<ContactCacheData>;
    primaryContact: DataCache<ClientPrimaryContact>;
    projectResourceLink: DataCache<ProjectResourceLink>;
    paymentProfile: DataCacheGeneric;
    project: DataCache<ProjectCacheData>;
    quote: DataCache<QuoteCacheData>;
    resource: DataCacheGeneric;
    userProfile: DataCache<TenantLoginPublicInfo>;
    purchaseOrder: DataCache<PurchaseOrderCacheData>;
}

let _cache: DealerCacheRegistry | null = null;
let _event: (() => DealerCacheRegistry) | null = null;

export function setCacheRegistry(event: () => DealerCacheRegistry) {
    _event = event;
}

export function cache(): DealerCacheRegistry {
    if (!_cache) {
        _cache = _event?.() ?? null;
        if (!_cache) throw new DevelopmentError("DealerCacheRegistry setCacheRegistry invalid");
    }

    return _cache;
}