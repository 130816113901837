import { WaitPatiently } from "../../components/ui/modal-spinner";
import { UINavigator } from "../../components/ui/navigator";
import { tlang } from "../../language/lang";
import { navigation } from "../../components/ui/navigation-path";
import { ProjectContainer } from "../../projects/data/project-container";
import { getProjectContainer, projectViewFactory } from "../projects/project-ui-adapter";
import { getProjectTitle } from "../projects/data/project-helper-functions";

export function projectNav(): UINavigator {
    return {
        code: navigation.project,
        isModal: true,
        event: async function (id: string, data?: any, eventInfo?: { title: string; }): Promise<boolean> {
            const container = data instanceof ProjectContainer ? data : getProjectContainer(id);
            const waiting = new WaitPatiently(() => tlang`Opening %%project%%`, () =>
                tlang`Please wait while we construct the %%project%% "${eventInfo?.title}"`);
            try {
                const view = await projectViewFactory(container, getProjectTitle);
                waiting.hideModal();
                await view.showModal();
            } finally {
                waiting.hideModal();
            }
            return true;
        }
    };
}
