// eslint-disable-next-line import/named
import { html } from "lit";
import { QuoteItem, QuoteItemPrice } from "../../api/dealer-api-interface-quote";
import { base64ToObject, objectToBase64 } from "../../blob/converters";
import { WaitPatiently } from "../../components/ui/modal-spinner";
import { v6MatchAllAttributesOnQuoteItem, V6Property, V6PropertyGroup, V6QuoteIGU, V6QuoteItem, ValueEditorType } from "../../v6config/v6-quote-item/v6-quote-item-data";
import { V6QuoteItemEditorService } from "../../v6config/v6-quote-item/v6-quote-item-editor-service";
import { QuoteItemView, QuoteItemViewOptions } from "../views/quote-item-view";
import { QuoteItemContainer } from "../data/quote-item-container";
import { sanitizeFrameDataForSave, sanitizePropertyGroupForSave, v6Config } from "../../v6config/v6config";
import { PromiseTemplate, Snippet } from "../../components/ui/events";
import { DataBinding, getInternalId } from "../../components/ui/databinding/databinding";
import { DataTracker, FieldType } from "../../components/ui/databinding/data-tracker";
import { FormInputAssistant } from "../../components/ui/templateresult/form-input-assistant";
import { tlang } from "../../language/lang";
import { clone } from "../../components/clone";
import { emptyGuid, newGuid } from "../../api/guid";
import { money } from "../../components/currency-formatter";
import { quoteSupplierProvider } from "../data/quoteSupplierProvider";
import { DevelopmentError } from "../../development-error";
import { isEmptyOrSpace } from "../../components/ui/helper-functions";
import { Information } from "../../components/ui/modal-option";
import { supplierQuoteItemContentType } from "../data/supplier-quote-item-content-type";

export class QuoteItemFrameViewForV6 extends QuoteItemView {
    itemService: V6QuoteItemEditorService | null = null;
    dataTracker: DataTracker;

    constructor(options: QuoteItemViewOptions) {
        super(options);
        this.dataTracker = new DataTracker(new DataBinding(this.ui, getInternalId()));
        const addField = (fieldName: string, propertyType?: FieldType, nullable?: boolean, editorFieldName?: string, data?: () => any) => {
            this.dataTracker.addObjectBinding(data ?? (() => this.quoteItemContainer?.item), fieldName, editorFieldName ?? fieldName, propertyType ?? FieldType.string, nullable ?? false);
        };

        addField("title");
        addField("description");
        addField("quantity", FieldType.int);
        addField("comment");
    }

    /**
     * inherited
     * @returns
     */
    public isTab(): boolean {
        return true;
    }
    /**
     * inherited
     * @returns
     */
    public async prepareForSave(): Promise<void> {
        if (!this.itemService) return;
        if (this.quoteItemContainer) {
            if (this.dataTracker.modified)
                this.dataTracker.applyChangeToValue();
            //this is a delayed event binding. the first time this event is called
            //will be at the initial load from inside v6LoadQuoteItemFrameEditor
            //the this.itemService will be null at that point in time, and its not important.
            //qi will be null if we are still binding

            this.clearMatchingFillSubstitutes();
            //the provider data is a base64 encoding of the v6configuration data
            //normally we only want to assign this data when we hit the save button
            //for now, we are updating the quoteitem every single call back so that
            //it stress tests the system
            //TODO-we dont need to adjust this every single time normally, only when
            if (!this.quoteItemContainer.data) throw new DevelopmentError("v6-quote-item-frame-view, quoteItemContainer.data is null");
            this.quoteItemContainer.data.providerData = this.v6QuoteItemBase64();


            //go get the freshest price from V6 for this item
            //this is a very costly process.. so now that we autosave all the time, we want to restrict doing
            //this if nothing else has changed, we assume the price will match
            if (this.quoteManager.changedItem(this.quoteItemContainer?.item.id))
                await this.populateQuoteItemPrice(this.quoteItemContainer.item, this.quoteItemContainer.price);

            return;
        }
        return;
    }

    private clearMatchingFillSubstitutes() {
        if (!this.itemService) return;
        const quoteItem = this.itemService.quoteItem;
        if (!quoteItem) return;
        let fillOption: V6Property | null = null;
        quoteItem?.quoteItemOptions.forEach(g => g.attributes.forEach(p => {
            if (p.valueType == ValueEditorType.Picker && p.picker?.resourceClassId === -1 && p.picker.tags.toLowerCase() === "fill")
                fillOption = p;
        }));
        const fillSubstitutes: V6Property[] = v6MatchAllAttributesOnQuoteItem(quoteItem, (attr) => {
            return attr.valueType === ValueEditorType.Glazing;
        });
        fillSubstitutes.forEach(p => {
            if (p.value === fillOption?.value) {
                p.value = "";
                p.originalValue = "";
                p.displayValue = "";
            }

        });

    }

    private restoreMatchingFillSubstitutes() {
        if (!this.itemService) return;
        const quoteItem = this.itemService.quoteItem;
        if (!quoteItem) return;
        let fillOption: V6Property | null = null;
        quoteItem?.quoteItemOptions.forEach(g => g.attributes.forEach(p => {
            if (p.valueType == ValueEditorType.Picker && p.picker?.resourceClassId === -1 && p.picker.tags.toLowerCase() === "fill")
                fillOption = p;
        }));
        const fillSubstitutes: V6Property[] = v6MatchAllAttributesOnQuoteItem(quoteItem, (attr) => {
            return attr.valueType === ValueEditorType.Glazing && attr.value === "";
        });
        fillSubstitutes.forEach(p => {
            p.value = fillOption?.value ?? "";
            p.originalValue = fillOption?.originalValue ?? "";
            p.displayValue = fillOption?.displayValue ?? "";


        });

    }


    async populateQuoteItemPrice(quoteItem: QuoteItem, quoteItemPrice: QuoteItemPrice) {
        const price = await this.itemService?.getQuoteItemPrice();
        if (price) {
            //TODO which is the right price to assign here. the Total Quote Price, or the net Item Price
            quoteItemPrice.singleUnitCost = money(price.netSellPrice / quoteItem.quantity, 4);
            quoteItemPrice.quantityCost = money(quoteItemPrice.singleUnitCost * quoteItem.quantity);

            quoteItemPrice.supplierGrossSingleUnitCost = money(price.totalSellPrice / quoteItem.quantity, 4);
            quoteItemPrice.supplierGrossQuantityCost = money(quoteItemPrice.supplierGrossSingleUnitCost * quoteItem.quantity);
        }
    }

    public async prepareEditor(): Promise<void> {
        if (this.quoteItemContainer) {
            this._readyToEdit = await this.v6CreateOrBindQuoteItem(this.quoteItemContainer);
        } else {
            this._readyToEdit = await this.v6CreateOrBindQuoteItem();
        }
    }

    // }
    public getImg(): string {
        if (this.itemService) {
            return this.itemService.view.svgImage ?? "";
        }
        return "";
    }

    v6QuoteItemBase64(): string | null {
        if (this.itemService?.quoteItem) {
            const quoteItemCopy = clone(this.itemService.quoteItem);
            quoteItemCopy.thumbnail = undefined;
            sanitizePropertyGroupForSave(quoteItemCopy.quoteItemOptions);
            sanitizeFrameDataForSave(quoteItemCopy.frameData);
            return objectToBase64(quoteItemCopy);
        } else return null;
    }

    quoteItemPropertiesTemplate(): Snippet {
        const forms = new FormInputAssistant(this.dataTracker, this.quoteManager.isReadonly());
        if (this.quoteItemContainer?.item) {
            return html`
                <div class="form-one-col quoteItemFrameProperties">
                    <div class="row attributeContents">
                        <div class="v6config-group">
                            <div class="v6config-group-header">
                                ${tlang`Properties`}
                            </div>
                            ${forms.textRequired("title")}
                            ${forms.text("description")}
                            ${forms.intRequired("quantity")}
                            ${forms.note("comment")}
                        </div>
                    </div>
                </div>`;
        } else return html``;
    }

    public internalDataChanged(): boolean {
        return this.quoteManager.changedItem(this.quoteItemContainer?.item.id);
    }

    protected async internalSaveData(): Promise<boolean> {
        if (this.quoteItemContainer) {
            this.quoteItemContainer = await this.quoteManager.saveAndUpdateQuoteItem(this.quoteItemContainer, this.getImg());
            return this.quoteManager.lastSaveSuccessful;
        }
        return false;
    }

    protected getCaption(): Snippet {
        const title = this.itemService?.quoteItem?.frameData.description ?? "";
        if (title === "")
            return html`
                        <div class="spinner-border text-info" role="status">
                            <span class="visually-hidden">${tlang`Loading...`}</span>
                        </div>`;
        return title;
    }
    public allowDeletePage(): boolean {
        return true;
    }

    protected async bodyTemplate(): PromiseTemplate {
        if (this.itemService) {
            this.restoreMatchingFillSubstitutes();
            await this.itemService.view.render();
            return html`${this.itemService.view.ui}`;
        } else return html``;
    }

    protected async v6CreateOrBindQuoteItem(container?: QuoteItemContainer): Promise<boolean> {

        const getQuantity = () => {
            try {
                const uiValue = this.dataTracker.getEditorValue("quantity") as number;
                return uiValue;
            } catch {
                return this.quoteItemContainer?.item.quantity ?? 1;
            }
        };
        const providerType = quoteSupplierProvider.v6;
        const providerReferenceId: string = emptyGuid;
        let providerData = "";

        //create the event binder to pass into the V6Configurator so that we can
        //tie it back to the quote item we are going to create
        const v6QuoteItemChangedEvent = async () => {
            return;
        };
        let result: QuoteItemContainer | null = null;
        let waiting: WaitPatiently | null = null;
        try {
            if (!container) {
                const newQuoteItemId = newGuid();
                const supplierId = this.supplierId;
                if (!isEmptyOrSpace(supplierId)) {
                    const objRef = await v6Config().pickFrame(supplierId);
                    if (objRef) {
                        const imgSrc = v6Config().getResourceTagImgSrc(supplierId, objRef);
                        waiting = new WaitPatiently(() => tlang`Initializing %%frame%%`, () => html`
                            <p><b>${tlang`Please wait while we configure the %%frame%%`}</b></p>
                            <p><b>${objRef.code}</b> ${objRef.description}</p>
                            <img src=${imgSrc} style="max-width:18rem" />
                            `);
                        this.itemService = await V6QuoteItemEditorService.v6LoadQuoteItemFrameEditor(supplierId,
                            objRef.objectReference,
                            getQuantity,
                            () => newQuoteItemId,
                            v6QuoteItemChangedEvent,
                            () => this.quoteItemPropertiesTemplate(),
                            () => this.getQuoteDefaults(),
                            () => this.quoteManager.isReadonly(),
                            async () => await this.getQuoteIGUs());
                        if (this.itemService) {
                            this.itemService.updatingChangedEvent = () => this.refreshParent();
                            providerData = this.v6QuoteItemBase64() ?? "";
                        }
                    }
                }

                if (this.itemService) {

                    const price = await this.itemService.getQuoteItemPrice();

                    result = await this.quoteManager.createQuoteItem({
                        id: newQuoteItemId,
                        title: this.itemService?.quoteItem?.frameData.description ?? tlang`new %%frame%%`,
                        description: this.itemService?.quoteItem?.frameData.description ?? "",
                        quantity: 1,
                        comment: "",
                        quoteItemContentType: supplierQuoteItemContentType.CID_FRAM,
                        externalProvider: {
                            service: providerType,
                            referenceId: providerReferenceId,
                            data: providerData
                        },
                        price: {
                            singleUnitCost: money(price?.netSellPrice ?? 0),
                            supplierGrossSingleUnitCost: money(price?.totalSellPrice ?? 0)
                        },
                        thumbnail: this.itemService.view.svgImage

                    });
                }
                if (result) {
                    this.quoteItemContainer = result;
                    container = this.quoteItemContainer;
                }

            } else {
                if (container.data) {
                    const svgSrc = this.quoteApi.api.fullUrl(`api/file/${container.item.virtualThumbnailPath}`);
                    waiting = new WaitPatiently(() => tlang`Initializing %%frame%%`, () => html`
                        <p><b>${tlang`Loading`}</b></p>
                        <p><b>${container?.item.title}</b></p>
                        <img src=${svgSrc} style="max-width:18rem" />`);
                    const igus = await this.getQuoteIGUs();
                    const v6QuoteItem = base64ToObject(container.data.providerData ?? "") as V6QuoteItem;
                    this.updateQuoteIGUDisplayReferences(v6QuoteItem, igus);

                    const response = await fetch(svgSrc);
                    let svg = "";
                    if (response.status == 200)
                        svg = await response.text();
                    v6QuoteItem.thumbnail = svg;
                    this.itemService = V6QuoteItemEditorService.v6buildQuoteItemFrameEditor(
                        v6QuoteItemChangedEvent,
                        getQuantity,
                        () => this.quoteItemContainer?.item.id ?? "",
                        () => this.quoteItemPropertiesTemplate(),
                        () => this.getQuoteDefaults(),
                        () => this.quoteManager.isReadonly(),
                        async () => await this.getQuoteIGUs()
                    );
                    if (this.itemService) {
                        this.itemService.updatingChangedEvent = () => this.refreshParent();
                        this.itemService.quoteItem = v6QuoteItem;
                        //this will refresh validation, and load the item into memory
                        // we are not awaiting this process.
                        const service = this.itemService;

                        if (!this.quoteManager.isReadonly()) {
                            service.isUpdating = true;
                            this.itemService.view.firstLoad = true;
                            this.populateQuoteItemPrice(container.item, container.price)
                                .finally(() => {
                                    service.view.firstLoad = false;
                                    service.isUpdating = false;
                                });
                        }

                    }
                }
            }
            if (!this.itemService) return false;

            if (container && this.itemService) {
                this.itemService.data = container;
                //this.pageControl.addPage(this.v6CreateQuoteItemPage(this.itemService));
                return true;
            }
            return false;
        } finally {
            waiting?.hideModal();
        }
    }

    updateQuoteIGUDisplayReferences(v6QuoteItem: V6QuoteItem, igus: V6QuoteIGU[]) {
        const attr = v6MatchAllAttributesOnQuoteItem(v6QuoteItem, (a) => {
            const igu = igus.find(x => x.objectReference === a.value);
            if (igu) {
                a.displayValue = igu.description ?? igu.code;
                return true;
            }
            return false;
        });
        console.log(`updated Quote IGU display value on ${attr.length} attributes`);
    }

    protected async getQuoteIGUs(): Promise<V6QuoteIGU[]> {
        throw new DevelopmentError("getQuoteIGUs() must override");
    }
    protected getQuoteDefaults(): V6PropertyGroup[] {
        throw new DevelopmentError("getQuoteDefaults() must override");
    }
    getDataDictionaryName(): string {
        return '%%frame%%';
    }
    public isReadonly(): boolean {
        return this.quoteManager.isReadonly();
    }

    protected async readyToClose(): Promise<boolean> {
        if (this.itemService?.isUpdating) {
            await Information(tlang`please wait until processing is complete and try again`);
            return false;
        }
        return true;
    }


}