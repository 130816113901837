import { ApiCommunications } from "../../api/api-communications";
import { Contact } from "../../api/dealer-api-interface-client";
import { NullPromise } from "../../null-promise";
import { ItemReference } from "../../cache/definitions/cache-item-reference";
import { DataCacheBase } from "../../cache/data-cache-base";
import { ContactCacheData } from "./cache-data";

interface RequestGetContactListByIds {
    contactIds: string[];
}

interface ResultGetContactListByIds {
    contacts: Contact[];
}

export class ContactReferenceCache extends DataCacheBase<ContactCacheData>{
    constructor(api: ApiCommunications) {
        super(api);
    }

    protected async internalFetch(requestIds: string[]): NullPromise<ItemReference<ContactCacheData>[]> {
        const input: RequestGetContactListByIds = {
            contactIds: requestIds
        };
        const result = await this.api.post<ResultGetContactListByIds>(`api/client/GetContactListByIds`, input);
        if (!result) return null;
        return result.contacts.map(c => {
            const qcr: ItemReference<ContactCacheData> = {
                id: c.id,
                displayValue: `${c.name}`,
                data: {contact: c},
                onClick: null
            };
            return qcr;
        });
    }
}

