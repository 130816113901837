import { LitElement, html, css, property, state, customElement, queryAll } from "lit-element";

import { getApiFactory } from "../api/api-injector";
import { QuoteApi } from "../api/quote-api";
import { emptyGuid } from "../api/guid";
import { QuoteState } from "../api/dealer-api-interface-quote";
import { ResultGetQuoteSummary } from "../api/dealer-api-interface-quote";
import { getQuoteNumberFormatted } from "../quotes/data/quote-helper-functions";

interface Quote {
  quoteId: string;
  number: string;
  title: string;
  date: string;
}

@customElement("quote-picker-modal")
export class QuotePickerModal extends LitElement {
  static get styles() {
      return css`
        .modal {
            display: none;
            position: fixed;
            z-index: 1;
            left: 0;
            top: 0;
            width: 100%;
            height: 95%;
            overflow: auto;
            background-color: rgb(0, 0, 0);
            background-color: rgba(0, 0, 0, 0.4);
            padding-top: 60px;
        }
        .modal-content {
            background-color: #fefefe;
            margin: 5% auto;
            padding: 10px 20px 20px 20px;
            border: 1px solid #888;
            border-radius: 10px;
            width: 65%;
        }
        .header-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .search-container {
            text-align: right;
        }
        .search-input {
            height: 28px;
            margin-bottom: 5px;
            width: 350px;
            border: 1px solid #acacac;
        }
        .table-container {
            max-height: 375px;
            min-height: 50px;
            overflow-y: auto;
            margin-top: 5px;
            margin-bottom: 10px;
        }
        .data-table {
            display: table;
            width: 100%;
        }
        .header-row, .data-row {
            display: table-row;
        }
        .header-row > div, .data-row > div {
            display: table-cell;
            padding: 10px;
        }
        .header-row {
            background-color: #3282CC;
            color: #B5F1FB;
        }
        .header-row > div {
            display: table-cell;
            padding: 10px;
            border-right: 1px solid #FFFFFF;
        }
        .no-border-header-cell {
            border-right: none !important;
        }
        .even-row {
            background-color: #ECECEC;
            color: #000000;
        }
        .odd-row {
            background-color: #FFFFFF;
            color: #000000;
        }
        .quote-number {
            width: 15%;
        }
        .quote-title {
            width: 50%;
        }
        .modified-date {
            width: 20%;
        }
        .order-number {
            width: 15%;
        }
        .button-container {
            text-align: right;
        }
        .modal-button{
            border: 1px solid rgb(26, 91, 151);
            color: rgb(255, 255, 255);
            background-image: linear-gradient(rgb(50, 130, 204), rgb(26, 91, 151));
            width: 100px;
            height: 30px;
            border-radius: 5px;
            margin-left: 15px;
            margin-top: 5px;
            font-family: "Sarabun", Arial, Helvetica, -apple-system, BlinkMacSystemFont, sans-serif;
            font-weight: 500;
            cursor: pointer;
        }
        .modal-button:disabled {
            background-color: #CCCCCC;
            color: #888888;
            cursor: auto;
        }
        input[type="checkbox"] {
            transform: scale(1.1);
        }
        .selected {
            filter: brightness(70%);
        }
        .spinner-border {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 2rem !important;
            height: 2rem;
            vertical-align: -0.125em;
            color: #bbb !important;
            border: 0.15em solid currentColor;
            border-right-color: transparent;
            border-radius: 50%;
            animation: 0.75s linear infinite spinner-border;
            overflow-y: hidden;
        }
        @keyframes spinner-border {
            to {
                transform: rotate(360deg) /* rtl:ignore */;
            }
        }
        `;
  }

  @property({ type: Array })
  private quotes: Quote[] = [];

  @property({ type: Boolean, reflect: true })
  private open = false;

  @state()
  private selectedQuotes: Quote[] = [];

  @state()
  private search: string = "";

  @state()
  private isLoadingQuotes = true;

  @state()
  private errorMessage = "";

  @queryAll('input[type="checkbox"]')
  private checkboxes!: NodeListOf<HTMLInputElement>;

  @queryAll('div.data-row')
  private dataRows!: NodeListOf<HTMLElement>;

  private api: QuoteApi = getApiFactory().quote();

  constructor() {
    super();
    this.toggleSelectQuote = this.toggleSelectQuote.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener("keydown", this.handleEscapeKey);
    document.addEventListener('order-numbers-set', this.onOrderNumbersSet);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener("keydown", this.handleEscapeKey);
    document.removeEventListener('order-numbers-set', this.onOrderNumbersSet);
  }

  onOrderNumbersSet = () => {
      setTimeout(() => { this.doOnOrderNumbersSet(); }, 0);
  }

  doOnOrderNumbersSet() {
    this.dataRows.forEach((row) => {
      const checkbox = row.querySelector('input[type="checkbox"]') as HTMLInputElement;
      if (checkbox) {
        const parentRow = checkbox.closest('.data-row');
        const quoteOrderNumberElement = parentRow?.querySelector('quote-order-number');
        if (quoteOrderNumberElement) {
          const shadowRoot = quoteOrderNumberElement.shadowRoot;
          if (shadowRoot) {
            const orderDiv = shadowRoot.querySelector('div[data-orderid]');
            if (orderDiv && orderDiv.getAttribute('data-orderid') !== '0') {
              checkbox.checked = false;
              checkbox.disabled = true;
            }
          }
        }
      }
    });
  }

  render() {
    const isAddButtonDisabled = this.selectedQuotes.length === 0;
    if (!this.open)
        return html``;
    return html`
    <div class="modal" style="display: ${this.open ? 'block' : 'none'};">
        <div class="modal-content">
        <div class="header-container">
            <h3>Add Quote BOM</h3>
            <div class="search-container">
                <input class="search-input" type="text" @input=${this.updateSearch} placeholder="Type quote number or title">
            </div>
        </div>
        <div class="table-container">
        ${this.errorMessage ? html`<div>${this.errorMessage}</div>` :
            this.isLoadingQuotes ? html`<div class="spinner-border"></div>` : html`
                <div class="data-table">
                <div class="header-row">
                    <div class="quote-number">Quote Number</div>
                    <div class="quote-title">Quote Title</div>
                    <div class="modified-date">Modified Date</div>
                    <div class="order-number">Order number</div>
                    <div class="quote-select no-border-header-cell"></div>
                </div>
                ${this.quotes.filter(this.isQuoteMatchingSearch.bind(this)).map((quote, index) => html`
                    <div class="data-row ${index % 2 === 0 ? 'even-row' : 'odd-row'} ${this.isQuoteSelected(quote) ? 'selected' : ''}">
                    <div class="quote-number">${quote.number}</div>
                    <div class="quote-title">${quote.title}</div>
                    <div class="modified-date">${quote.date}</div>
                    <div class="order-number"><quote-order-number quote-id=${quote.quoteId}></quote-order-number></div>
                    <div class="quote-select">
                        <input type="checkbox" @change="${this.toggleSelectQuote}" data-index="${index}" ?checked=${this.isQuoteSelected(quote)}>
                    </div>
                    </div>
                `)}
                </div>
            `}
        </div>
        <div class="button-container">
            <button class="modal-button" @click="${this.addQuotes}" ?disabled=${isAddButtonDisabled}>Add</button>
            <button class="modal-button" @click="${this.closeModal}">Cancel</button>
        </div>
        </div>
    </div>
    `;
  }

  async openModal() {
    this.errorMessage = "";
    this.isLoadingQuotes = true;
    this.open = true;
    await this.getQuotes();
    this.isLoadingQuotes = false;
    this.search = "";
    this.selectedQuotes = [];
  }

  closeModal() {
    this.open = false;
    // Reset the state of the checkboxes
    this.checkboxes.forEach(cb => {
      cb.checked = false;
    })
  }

  private handleEscapeKey = (event: KeyboardEvent) => {
    if (event.key === "Escape" && this.open) {
      this.closeModal();
    }
  };

  private isQuoteSelected(quote: Quote) {
    return this.selectedQuotes.includes(quote);
  }

  private isQuoteMatchingSearch(quote: Quote) {
    return (
      quote.number.toLowerCase().includes(this.search.toLowerCase()) ||
      quote.title.toLowerCase().includes(this.search.toLowerCase())
    );
  }

  private updateSearch(e) {
    this.search = e.target.value;
  }

  private toggleSelectQuote(e: Event) {
    const target = e.target as HTMLInputElement;
    const index = Number(target.dataset.index);
    const quote = this.quotes[index];

    // Assuming each row has a unique ID or class to identify the corresponding 'quote-order-number' element
    const parentRow = target.closest('.data-row');
    const quoteOrderNumberElement = parentRow?.querySelector('quote-order-number');
    if (quoteOrderNumberElement) {
      const shadowRoot = quoteOrderNumberElement.shadowRoot;
      if (shadowRoot) {
        const orderDiv = shadowRoot.querySelector('div[data-orderid]');
        if (orderDiv && orderDiv.getAttribute('data-orderid') !== '0') {
          e.preventDefault();
          target.checked = false;
          return;
        }
      }
    }

    if (target.checked) {
      this.selectedQuotes = [...this.selectedQuotes, quote];
    } else {
      this.selectedQuotes = this.selectedQuotes.filter(selected => selected !== quote);
    }
  }

  private addQuotes() {
    const selectedQuoteIds = this.selectedQuotes.map((quote) => quote.quoteId);
    this.dispatchEvent(
      new CustomEvent("quote-picker-quotes-selected", {
        detail: { selectedQuoteIds },
      })
    );
    this.closeModal();
  }

  private async getQuotes() {
    try {
      const quoteStates = QuoteState.Draft | QuoteState.Active | QuoteState.Issued | QuoteState.Accepted;
      const results = (await this.api.getQuoteSummary({
        quoteOwnerId: emptyGuid,
        pageIndex: 0,
        pageSize: 200,
        description: null,
        createdById: null,
        statesToLoad: quoteStates,
        title: null,
        sortField: "lastModifiedDate",
        sortAsc: false,
      })) as ResultGetQuoteSummary;
      this.quotes = results.quoteSummary.results.map((quote) => {
        return {
          quoteId: quote.id,
          number: getQuoteNumberFormatted(quote),
          title: quote.title,
          date: this.utcToLocalString(quote.lastModifiedDate),
        };
      });
    } catch (error) {
      if (error instanceof Error) {
        this.errorMessage = `${error.message}\n${error.stack}`;
      } else {
        this.errorMessage =
          "An error occurred while loading quotes: " + JSON.stringify(error);
      }
    } finally {
      this.isLoadingQuotes = false;
    }
  }

  private utcToLocalString(utcDateString: string | null) {
    if (!utcDateString) {
      return "";
    }
    const date = new Date(utcDateString);
    return date.toLocaleString();
  }
}
