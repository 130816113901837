import { html, render } from "lit";
import { AsyncConstructor } from "../../async-constructor";
import { showDevelopmentError } from "../../development-error";
import { PromiseTemplate } from "./events";

export class ViewBase implements AsyncConstructor {
    ui: HTMLDivElement = document.createElement('div') as HTMLDivElement;
    async afterConstruction(): Promise<void> {
        //Nothing to do
    }
    protected async template(): PromiseTemplate {
        return html``;
    }
    private _rendering = false;
    private _renderRequestCount = 0;
    public async render(): Promise<void> {
        this._renderRequestCount++;
        if (this._rendering) {
            console.log("Unexpected Nested Or SideBySide Render Call");
            // push the render to the end of the queue
            // it is likely that the current render cycle is rendering stale data.
            setTimeout(() => this.internalRender(), 50);
            if (this._renderRequestCount > 10)
                showDevelopmentError("Recursive Render Cycle Error");
            return;

        }
        await this.internalRender();
    }
    private async internalRender() {
        this._rendering = true;
        try {
            render(await this.template(), this.ui);
            this._renderRequestCount--;
        } finally {
            this._rendering = false;
        }
    }
}