/* eslint-disable @typescript-eslint/no-non-null-assertion */
// @ts-check
// eslint-disable-next-line import/named
import { html, render, TemplateResult } from 'lit';
//lit directives must have the .js
import { unsafeSVG } from 'lit/directives/unsafe-svg.js';
import { V6QuoteItemProvider, V6QuoteItemViewDataHandler } from './v6-quote-item-view/v6-quote-item-view-datahandler';
import * as data from './v6-quote-item-data';
import { V6FrameAttributeGroup } from './v6-quote-item-view/v6-frame-attribute-group';
import { emptyGuid } from '../../api/guid';
import { V6FrameAttributeViewEventHandlers, V6FrameAttributeViewOptions } from './v6-quote-item-view/v6-frame-attribute';
import {
  V6AttributePickerSelectHandler
} from './v6-quote-item-view/v6-frame-attribute-picker';
import { EventBoolean, EventSnippet, PromiseTemplate } from '../../components/ui/events';
import { getInternalId } from '../../components/ui/databinding/databinding';
import { getValidationIssueHeading, highestValidationRank, rankToValidationStyle, V6Message, validationDisplayName, validationStyles } from './v6-data-objects';
import { lang, tlang } from '../../language/lang';
import { createV6AttributePickerSelectHandler } from './v6-quote-item-view/attribute-picker-handler';
import { QuoteIGUListEvent } from "./v6-events";
import { ViewBase } from '../../components/ui/view-base';
import { firstValidString, isEmptyOrSpace } from '../../components/ui/helper-functions';
import { V6NestedFrameModal } from './v6-nested-frame-modal';
import { ClickActuator } from '../../components/ui/click-actuator';
import { clone } from '../../components/clone';
import { DebugTimer } from '../../components/time';

const fraPropertiesGroupName = "FRA PROPERTIES";
interface FrameGroupDataItem {
  frame: data.V6FrameData;
  attributeGroups: V6FrameAttributeGroup[];
}

export interface V6QuoteItemViewOptions {
  itemProvider: V6QuoteItemProvider;
}

interface ValidationTooltip {
  id: string;
  x: number;
  y: number;
  radius?: number;
  noOffset?: boolean;
  body: EventSnippet;
  icon: string;
}
// class to be used as a micro service to display and render a quote-item
export class V6QuoteItemView extends ViewBase {

  private svgElement: HTMLElement = document.createElement("div");
  internalId = getInternalId();
  validationTooltips: ValidationTooltip[] = [];
  dataHandler: V6QuoteItemViewDataHandler;
  attributeGroups: FrameGroupDataItem[] | null;
  svgImage = "";
  itemProvider: V6QuoteItemProvider;
  attributeEventHandlers: V6FrameAttributeViewEventHandlers;
  attributeViewOptions: V6FrameAttributeViewOptions;
  attributePickerSelectHandler: V6AttributePickerSelectHandler;
  propertyTemplate?: EventSnippet;
  private quoteItemReference: data.V6QuoteItem | null;
  private viewContainer: HTMLElement | null;
  private activeFrame: data.V6FrameData | null;

  private activeFrameNotation: string | null;
  private activeAttributeFrameNotation: string | null;
  private hoverFrame: data.V6FrameData | null = null;
  frameDimensionsValue: string | null = null;
  validationMessages: V6Message[] = [];
  svgClickActuator: ClickActuator;
  readonly: EventBoolean;
  quoteIGUProvider: QuoteIGUListEvent | null;
  /**
   * this is a design feature that will render the svg
   * zoomed into the specific nested frame selected
   */
  flagDisplayZoomToNestedFrame = true;

  /**
   * this lets the user click on a frame on the image
   * and zoom in to that frame for easier editing
   */
  flagDisplayClickToSelectFrame = true;
  flagDisplayShowAllAttributes = false;
  flagDisplayContextMenu = false;
  flagDisplayFrameHoverInformation = true;
  flagDisplayLightName = true;
  svgId: string | null = null;
  svgMouse: { x: number; y: number; } = { x: 0, y: 0 };
  /**
   * this stores the current svg viewbox, used to convert mouse clicks into co-ordinates
   */
  svgClickableViewBox: number[] | null = null;

  lastClickedFrameNotation: string | null = null;
  v6NestedFrameModal: V6NestedFrameModal | null = null;
  firstLoad = false;
  hoverInfoTimeout: NodeJS.Timeout | null = null;

  constructor(itemProvider: V6QuoteItemProvider, dataHandler: V6QuoteItemViewDataHandler, propertyTemplate?: EventSnippet,

    readonly?: EventBoolean, quoteIGUProvider?: QuoteIGUListEvent) {
    super();
    this.readonly = readonly ?? (() => false);
    this.dataHandler = dataHandler;
    this.itemProvider = itemProvider;
    this.ui = document.createElement('div');
    this.quoteItemReference = null;
    this.viewContainer = null;
    this.attributeGroups = null;
    this.activeFrame = null;
    this.activeFrameNotation = null;
    this.activeAttributeFrameNotation = null;
    this.quoteIGUProvider = quoteIGUProvider ?? null;
    this.propertyTemplate = propertyTemplate;
    this.attributeEventHandlers = {
      onAttributeChange: (attr: data.V6Property) => this.dataHandler.onAttributeChange(attr),
      attributeFocused: (data: data.V6Property | null, hovering?: boolean) => this.attributeFocused(data, hovering)
    };

    this.svgClickActuator = new ClickActuator(async () => await this.svgMenu(), async () => await this.svgZoom());
    const supplierId = () => this.itemProvider.quoteItem?.supplierId;
    const readOnly = () => this.readonly() || (this.itemProvider.isUpdating ?? false);
    this.attributeViewOptions = {

      get supplierId(): string {
        return supplierId() ?? emptyGuid;
      },
      get readonly(): boolean {
        return readOnly();
      }
    };

    this.attributePickerSelectHandler = createV6AttributePickerSelectHandler(
      () => this.itemProvider.quoteItem?.supplierId ?? emptyGuid,
      () => this.itemProvider.quoteItem?.frameData.objectReference ?? "",
      () => this.sideBarTemplate(),
      async () => await this.getQuoteIGUs());


  }
  populateSVGAnnotations() {

    this.validationTooltips = [];
    if (!this.quoteItemReference) return;
    if (!this.quoteItemReference?.frameData) return;
    if (!this.activeFrame) return;
    const boundary = boundaryToV6ViewBox(this.activeFrame.bounds);
    if (!boundary) return false;
    const left = boundary[0];
    const top = boundary[1] + boundary[3];

    const typeToClass = (type: string) => {
      switch (type) {
        case validationStyles.note:
          return "list-group-item list-group-item-info";
        case validationStyles.information:
          return "list-group-item list-group-item-primary";
        case validationStyles.warning:
          return "list-group-item list-group-item-warning";
        case validationStyles.critical:
          return "list-group-item list-group-item-danger";
      }
      return "list-group-item list-group-item-primary";

    };
    const getIcon = (style: string) => {
      switch (style) {
        case validationStyles.note:
          return "note";
        case validationStyles.information:
          return "info";
        case validationStyles.warning:
          return "warning";
        case validationStyles.critical:
          return "error";
      }
      return "info";
    };

    const aIssues = this.quoteItemReference.annotationIssues ?? [];
    const issueFrames: { frame: data.V6FrameData; issues: data.V6Validation[]; }[] = [];
    aIssues.forEach(issue => {
      const frames = data.v6MatchFrame(this.quoteItemReference!.frameData, (parent: data.V6FrameData | null, frame: data.V6FrameData) => {
        if (!parent) return false;
        const vb = boundaryToV6ViewBox(frame.bounds);
        if (!vb) return false;
        if (viewBoxContainsPoint(vb, issue.globalPositionX, issue.globalPositionY)) {
          return true;
        }
        return false;
      }, true);
      if (frames.length > 0) {
        const theFrame = frames[frames.length - 1];
        const match = issueFrames.filter(x => x.frame == theFrame);
        if (match.length > 0)
          match[0].issues.push(issue);
        else
          issueFrames.push({ frame: theFrame, issues: [issue] });
      }
    });
    issueFrames.forEach(item => {
      const vb = boundaryToV6ViewBox(item.frame.bounds);
      if (!vb) return;
      const x = vb[0] + (vb[2] / 2);
      const y = vb[1] + (vb[3] / 2);

      const rows = item.issues.map(issue => html`<li class=${typeToClass(issue.style)}><span
    class="me-3 fw-bold">${getValidationIssueHeading(issue)}</span>${lang(issue.text)}</li>`);
      this.validationTooltips.push({
        id: `annotation-issue-${item.frame.boundaryName}`,
        body: () => html`
                <ul class="list-group text-start">
                  ${rows}
                </ul>`,
        x: x,
        y: y,
        icon: getIcon(rankToValidationStyle(highestValidationRank(item.issues)))
      });

    });

    if (this.quoteItemReference.validationIssues?.length ?? 0 > 0) {
      //we never want to see the name on these
      this.quoteItemReference?.validationIssues?.forEach(issue => issue.displayName = validationDisplayName.never);

      const validations = this.quoteItemReference?.validationIssues?.map(issue => {
        return html`
                <li class=${typeToClass(issue.style)}><span
                    class="me-3 fw-bold">${getValidationIssueHeading(issue)}</span>${lang(issue.text)}</li>`;

      });

      this.validationTooltips.push({
        id: `validation-issue`,
        body: () => html`
                            <ul class="list-group text-start">
                              ${validations}
                            </ul>`,
        x: left + 2,
        y: top - 2,
        noOffset: true,
        icon: getIcon(rankToValidationStyle(highestValidationRank(this.quoteItemReference.validationIssues)))

      });

    }

  }
  protected get lastClickedFrame(): data.V6FrameData | null {
    return this.frameByNotation(this.lastClickedFrameNotation);
  }
  async svgZoom(): Promise<void> {
    if (this.lastClickedFrame)
      this.setActiveFrame(this.lastClickedFrame);
    this.lastClickedFrameNotation = null;
  }
  async svgMenu(): Promise<void> {
    if (this.lastClickedFrameNotation && !isEmptyOrSpace(this.lastClickedFrameNotation)) {
      this.hideSVGToolTip();
      await this.displayFrameMenu(this.lastClickedFrameNotation);
      this.lastClickedFrameNotation = null;
    }

  }
  protected async getQuoteIGUs(): Promise<data.V6QuoteIGU[]> {
    return (await this.quoteIGUProvider?.()) ?? [];
  }

  public async requestRedraw() {
    await this.render();
  }
  public async render(): Promise<void> {
    console.log("Rendering V6 Config");
    await super.render();
    if (this.v6NestedFrameModal) this.v6NestedFrameModal.render();
  }
  public get container(): HTMLElement | null {
    return this.viewContainer;
  }

  public set container(parent: HTMLElement | null) {
    //if we are already attached to a parent then detach ourselves
    if (this.viewContainer && this.ui) {
      this.viewContainer.removeChild(this.ui);
    }

    this.viewContainer = parent;
    if (this.viewContainer) {
      //if we have a valid parent, lets insert ourselves
      if (this.itemProvider.quoteItem)
        this.render();//no wait
      this.viewContainer.appendChild(this.ui);
    } else {
      this.ui = document.createElement('div'); //deallocate all references to DOM elements for disposal
    }
  }

  setValidationMessages(messages: V6Message[] | null) {
    this.validationMessages = messages ?? [];
    this.render();//no wait
  }

  public get activeNestedFrames(): data.V6FrameData[] | null {
    return this.activeFrame?.nestedFrames ?? null;
  }
  public setActiveFrame(frame: data.V6FrameData, render = true) {
    this.activeFrame = frame;
    this.hoverFrame = null;
    this.frameDimensionsValue = null;
    this.activeFrameNotation = this.frameNotation(frame);
    if (this.flagDisplayZoomToNestedFrame)
      this.populateSVGAnnotations();

    if (render)
      this.render();//no wait
  }

  public frameNotation(frame?: data.V6FrameData | null): string {
    if (!frame) return "";
    let result: string | null = null;
    const recurse = (parent: data.V6FrameData, parentNotation: string) => {
      if (frame === parent) {
        result = parentNotation;
        return;
      }
      parent.nestedFrames.forEach(x => {
        recurse(x, `${parentNotation}_${x.boundaryName}`);
      });
    };
    if (this.quoteItemReference?.frameData) {
      recurse(this.quoteItemReference?.frameData, "toplevel");
      return result ?? "";
    } else
      return "";

  }

  public frameByNotation(notation: string | null): data.V6FrameData | null {
    if (!notation || notation === "") return this.quoteItemReference?.frameData ?? null;
    let result: data.V6FrameData | null = null;
    const recurse = (parent: data.V6FrameData, parentNotation: string) => {
      if (notation === parentNotation && this.stackHasAttributes(parent)) {
        result = parent;
        return;
      }

      parent.nestedFrames.forEach(x => {
        recurse(x, `${parentNotation}_${x.boundaryName}`);
      });
    };
    if (this.quoteItemReference?.frameData) {
      recurse(this.quoteItemReference?.frameData, "toplevel");
      return result ?? null;
    } else
      return null;

  }

  public attributeFocused(property: data.V6Property | null, hovering?: boolean) {
    if (this.v6NestedFrameModal) return;
    const relatedFrame = property ? this.getRelatedFrameFromName(property.code) : null;
    if (relatedFrame !== null) {
      const newNotation = this.frameNotation(relatedFrame);
      if (hovering) {

        if (relatedFrame != this.hoverFrame) {
          this.hoverFrame = relatedFrame;
          this.render();//no wait
        }

      } else if (newNotation !== this.activeAttributeFrameNotation) {
        this.activeAttributeFrameNotation = newNotation;
        this.render();//no wait
      }
    } else {
      if (hovering) {
        if (this.hoverFrame != null) {
          this.hoverFrame = null;
          this.render();//no wait
        }
      } else if (this.activeAttributeFrameNotation !== null) {
        this.activeAttributeFrameNotation = null;
        this.render();//no wait
      }
    }

  }

  public quoteItemUpdated() {
    const reapplyActiveFrame = this.quoteItemReference !== null;

    this.quoteItemReference = this.itemProvider.quoteItem;
    this.attributeGroups = [];
    if (this.quoteItemReference) {
      let newActiveFrame = this.quoteItemReference.frameData;

      if (reapplyActiveFrame) {
        const oldFrame = this.frameByNotation(this.activeFrameNotation);
        if (oldFrame)
          newActiveFrame = oldFrame;
      }
      this.setActiveFrame(newActiveFrame, false);

      this.injectFillSubstituteAttributes(this.quoteItemReference.frameData);

      const detailsGroup = this.quoteItemReference.frameData.attributeGroups.filter(x => x.name?.toLowerCase() === "details");
      const otherattributes = this.quoteItemReference.frameData.attributeGroups.filter(x => x.name?.toLowerCase() !== "details");
      const itemoptions = this.quoteItemReference.quoteItemOptions;
      const allGroups: data.V6PropertyGroup[] = [];
      allGroups.push(...detailsGroup, ...itemoptions, ...otherattributes);

      const attrFilter = (activeFrame?: data.V6FrameData) =>
        (attr: data.V6Property, group: data.V6PropertyGroup) => (this.flagDisplayShowAllAttributes ||
          !this.getRelatedFrameFromName(attr.code, activeFrame)) && group.name !== fraPropertiesGroupName;

      this.sortUserDefinedDimensionAttributes();




      this.attributeGroups.push({
        frame: this.quoteItemReference.frameData,
        attributeGroups:
          allGroups.map(g => new V6FrameAttributeGroup(g,
            this.attributeViewOptions,
            this.attributeEventHandlers,
            this.attributePickerSelectHandler,
            undefined,
            attrFilter()
          ))


      });
      const processNested = (frameData: data.V6FrameData | undefined, includeGroups: boolean, parentTitle?: string) => {
        const titleStr = (f: data.V6FrameData) => parentTitle ? `${parentTitle} / ${f.description}` : f.description;

        if (!frameData) return;
        if (includeGroups) {
          const groups: V6FrameAttributeGroup[] = [];
          frameData.attributeGroups.forEach(attrGroup => {
            groups.push(new V6FrameAttributeGroup(
              attrGroup,
              this.attributeViewOptions,
              this.attributeEventHandlers,
              this.attributePickerSelectHandler,
              parentTitle,
              attrFilter(frameData)));
          });
          this.attributeGroups?.push({
            frame: frameData,
            attributeGroups: groups
          });
        }
        frameData.nestedFrames.forEach(f => processNested(f, true, titleStr(f)));
      };
      processNested(this.quoteItemReference?.frameData, false);

    } else {
      this.attributeGroups = [];
    }
    this.frameDimensionsValue = null;
    if (this.v6NestedFrameModal) this.v6NestedFrameModal.needsRebuild = true;

    this.populateSVGAnnotations();
    this.render();//no wait
    console.log("rendered");

  }
  injectFillSubstituteAttributes(frameData: data.V6FrameData) {
    type FMatch = { p: data.V6FrameData; f: data.V6FrameData; fills: data.V6Property[]; };
    const frameMatches: FMatch[] = [];
    data.v6MatchFrame(frameData, (parent: data.V6FrameData | null, fd: data.V6FrameData) => {
      if (!parent) return false;
      const propMatch = (p: data.V6Property) => p.valueType === data.ValueEditorType.Glazing;
      const hasGlazingReference = data.v6MatchGroupsByProperty(parent,
        (p: data.V6Property) => p.valueType === data.ValueEditorType.Glazing
          && this.getLightNameFromCode(p.code) == fd.boundaryName.toLowerCase(),
        false).length > 0;
      const childGlazing = data.v6MatchAllAttributesOnFrame(fd,
        propMatch, true);
      const match = !hasGlazingReference && childGlazing.length > 0;
      if (match) frameMatches.push({ p: parent, f: fd, fills: childGlazing });
      return match;
    }, true);

    frameMatches.forEach((item: FMatch) => {
      if (!item.p) return;
      const values = [...new Set(item.fills.map(attr => attr.value))];
      const property: data.V6Property = {
        code: tlang`${item.f.boundaryName} All Glass`,
        originalValue: values.length > 1 || values.length == 0 ? "" : values[0],
        value: values.length > 1 || values.length == 0 ? "" : values[0],
        displayValue: values.length > 1 ? tlang`Mixed Selection` : item.fills[0].displayValue,
        codeDescription: tlang`${item.f.boundaryName} All Glass`,
        picker: clone(item.fills[0].picker),
        valueType: data.ValueEditorType.CombinationGlazing,
        isReadonly: false,
        visible: true
      };
      const groups = item.p.attributeGroups.filter(g => g.name === "Glass");
      if (groups.length != 1) {
        const group: data.V6PropertyGroup = {
          name: "Glass",
          description: "Glass Substitution",
          attributes: [property]
        };
        item.p.attributeGroups.push(group);
      } else {
        groups[0].attributes.push(property);
      }

    });

  }

  private sortUserDefinedDimensionAttributes() {
    if (!this.quoteItemReference) return;
    const udmGroups = data.v6MatchGroupsByProperty(this.quoteItemReference.frameData, (attr: data.V6Property) => {
      return attr.valueType === data.ValueEditorType.Dimension;
    }, true);
    udmGroups.forEach(g => {
      g.attributes.sort((a1: data.V6Property, a2: data.V6Property): number => {
        const a1Sort = (a1.code.toLowerCase().includes("width") ? "A-" : "B-") + this.getLightNameFromCode(a1.code);
        const a2Sort = a2.code.toLowerCase().includes("width") ? "A-" : "B-" + this.getLightNameFromCode(a2.code);
        return a1Sort.localeCompare(a2Sort);
      });
    });
  }

  getTitle(): string {
    return "Frame Configuration";
  }

  footerTemplate(): unknown {
    return html`

        `;
  }


  async template(): PromiseTemplate {
    const classes = "v6-quote-item-view " + this.getViewClasses();
    return html`
            <div class=${classes}>

              <div class="v6-quote-item-view-header">
                <h2>${this.getTitle()} - ${this.quoteItemReference?.frameData.description ?? ""}-
                  ${this.objectReferenceTemplate()}</h2>
              </div>
              ${await this.bodyTemplate()}
              <div class="v6-quote-item-view-footer">
                ${this.footerTemplate()}    </div>
            </div>

        `;
  }

  getBodyClasses(): string {
    return "";
  }

  sideBarTemplate(): TemplateResult | null {
    //Alexey: Do we have sidebar?
    return html`
            <div class="col-xs-12 quoteItemImageWrapper">
              <div class="quoteItemImage">
                ${unsafeSVG(this.svgImage)}
              </div>
            </div>`;
  };

  async bodyTemplate(): PromiseTemplate {
    const classes = "quote-item-view-body " + this.getBodyClasses();
    console.log('building Template');

    const quoteItemFrameNav = html`
            <h4>Frame Navigation</h4>
            <div class="frame-treeview">
              ${this.getFrameTreeViewTemplate()}
            </div>`;
    const quoteItemFrameHeader = html`
            <h4>Frame Options
              <div class="subheader">${this.activeFrameTitle()}</div>
            </h4>`;
    const quoteItemFrameOptions = html`
            <div class="accordion attributeContents">
              ${await this.getGroupTemplates(this.attributeGroups)}
            </div>`;
    const quoteItemToolBarTemplate = html`
        <div class="v6-quote-item-toolbar row">
          <div class="col-12">
            ${this.magnifyBtnTemplate()}
            ${this.getMoveUpFrameBtnTemplate(false)}
            ${this.getMoveUpFrameBtnTemplate(true)}
            ${this.showAllAttributesBtnTemplate()}   </div>
        </div>
        `;
    const firstLoadTemplate = this.firstLoad
      ? html`
            <ul class="list-group m-3 text-start">
              <li class="list-group-item list-group-item-info">
                ${tlang`Your content is refreshing`}
              </li>
            </ul>`
      : html``;

    this.renderSvgDiv();
    const quoteItemImageWrapper = html`
            <div class="quoteItemImage" @dblclick=${(e: PointerEvent) => this.svgClick(e)}
              @mousemove=${(e: PointerEvent) => this.svgMouseMove(e)}
              @click=${(e: PointerEvent) => this.svgClick(e)}
              @contextmenu=${(e) => this.svgContextMenu(e)}
              }
              @oncontextmenu=${(e) => this.svgContextMenu(e)}
              @mouseout=${(e: PointerEvent) => this.svgMouseMove(e, true)}>
              <div class="svg-tooltip bg-light border border-dark " id=${this.svgToolTipId()} display="none"
                style="position: fixed; display: none;" @mouseleave=${() => this.hideSVGToolTip()}>
              </div>
              ${this.svgElement}
            </div>
            <p class="card-text quoteItemImageText">${this.frameDimensions()}</p>`;
    return html`
            <div class=${classes}>
              <div class="row">
                <div class="col-3 quoteItemFrameNav">
                  ${quoteItemFrameNav}
                </div>
                <div class="col-3 quoteItemFrameOptions">
                  ${quoteItemFrameHeader}
                  ${this.getItemPropertiesTemplate()}
                  ${quoteItemFrameOptions}
                </div>
                <div class="col-6 quoteItemImageWrapper">
                  <div class=" ">
                    ${firstLoadTemplate}
                    ${quoteItemToolBarTemplate}
                    ${quoteItemImageWrapper}
                  </div>
                </div>

              </div>
            </div>`;
  }
  private renderSvgDiv() {


    setTimeout(() => {
      const svg = this.createSVGImage();
      const unsafe = unsafeSVG(svg);
      render(html`${unsafe}`, this.svgElement);

    }, 1);
  }

  private get hoverToolTipMenuId(): string {
    return `svg-tooltip-${this.internalId}-hoverinfo`;
  }
  svgHoverContext(e: PointerEvent) {
    if (this.readonly()) return;
    if (!this.flagDisplayFrameHoverInformation) return;
    this.lastClickedFrameNotation = this.frameNotation(this.getFrameFromSVGMouseEvent(e));
    const frame = this.frameByNotation(this.lastClickedFrameNotation);
    const parentFrame = this.getFrameParent(frame);
    if (!frame) return;

    const menuId = this.hoverToolTipMenuId;
    if (!isEmptyOrSpace(this.lastClickedFrameNotation)) {
      registerTooltip(menuId, () =>
        html`
                <div style="width: 450px;">
                  ${this.buildHoverMenu(parentFrame, frame)}
                </div>
               ` );
      showTooltip(e, this.svgToolTipId(), menuId, 400, 20);

    }

  }
  svgContextMenu(e: PointerEvent): any {
    e.preventDefault();

    if (this.readonly()) return;
    if (!this.flagDisplayContextMenu) return;
    this.lastClickedFrameNotation = this.frameNotation(this.getFrameFromSVGMouseEvent(e));
    const frame = this.frameByNotation(this.lastClickedFrameNotation);
    const parentFrame = this.getFrameParent(frame);
    if (!frame) return;
    const menuId = `svg-tooltip-${this.internalId}-contextmenu`;
    if (!isEmptyOrSpace(this.lastClickedFrameNotation)) {
      registerTooltip(menuId, () =>
        html`
                <div style="width: 300px;">
                  ${this.buildContextMenu(parentFrame, frame)}
                </div>
               ` );
      showTooltip(e, this.svgToolTipId(), menuId, 0, 0);

    }
  }
  svgToolTipId() {
    return "svg-tooltip-" + this.internalId;
  }

  locateDimensionAttributes(parentFrame: data.V6FrameData | null, frame: data.V6FrameData) {
    let dimensions = !parentFrame
      ? data.v6MatchAllAttributesOnFrame(frame!, (attr: data.V6Property) => {
        return ((attr.code == "Height" || attr.code === "Width"));
      })
      : data.v6MatchAllAttributesOnFrame(parentFrame, (attr: data.V6Property) => {
        return (attr.valueType === data.ValueEditorType.Dimension && this.getRelatedFrameFromName(attr.code, parentFrame) === frame) &&
          (attr.code.includes(" Height") || attr.code.includes(" Width"));
      });
    if (dimensions.length === 0) {
      dimensions = data.v6MatchAllAttributesOnFrame(frame!, (attr: data.V6Property) => {
        return attr.valueType == data.ValueEditorType.Dimension && ((attr.code.includes(" Height") || attr.code.includes(" Width")));
      });
    }

    return dimensions;
  }
  buildHoverMenu(parentFrame: data.V6FrameData | null, frame: data.V6FrameData): TemplateResult {

    const dimensions = this.locateDimensionAttributes(parentFrame, frame);
    let dimension = "";
    if (dimensions.length > 0) {
      const dLabel = (dimensions[0].code.includes(("Height"))) ? "Height/Width" : "Width/Height";
      dimension = `${dLabel} ${dimensions[0]?.displayValue} x ${dimensions[1]?.displayValue ?? "??"} `;
    }

    const fraProperties = () => {
      return data.v6MatchAllAttributesOnFrame(frame, (_attr: data.V6Property, group: data.V6PropertyGroup) => {
        return group.name === fraPropertiesGroupName;
      });
    };

    const otherInformation = !parentFrame
      ? dimensions
      : [...dimensions, ...data.v6MatchAllAttributesOnFrame(parentFrame, (attr: data.V6Property) => {
        return this.getRelatedFrameFromName(attr.code, parentFrame) === frame
          && !((attr.code.includes(" Height") || attr.code.includes(" Width")));
      }), ...fraProperties()];
    const strip = (value: string) => {
      if (!parentFrame) return value;
      const split = value.split(' ');
      if (split.length < 2) return value;
      if (this.getRelatedFrameFromName(value, parentFrame))
        return value.substring(split[0].length);
      return value;
    };
    const otherTemplates = () => {
      return otherInformation.map((info) => {
        return html`
          <li class="list-group-item list-group-item-secondary pb-0 pt-0">
            <div class="row">
              <div class="col-4 fw-bold">
                ${strip(firstValidString(info.codeDescription, info.code))}
              </div>
              <div class="col-8">${info.displayValue ?? info.value}</div>
            </div>
          </li>
        `;
      });
    };

    return html` <ul class="list-group text-start">
      <li class="list-group-item list-group-item-primary ">
        <div class="row ">
          <div class="col-12">
            ${frame?.boundaryName} - ${frame?.description}
          </div>
        </div>
      </li>
      <!--
        <li class="list-group-item list-group-item-info">
            <div class="row">
                <div class="col-4">
                    ${tlang`Dimension`}
                </div>
                <div class="col-8">
                    ${dimension}
                </div>
            </div>
        </li>
      -->
      ${otherTemplates()}
    </ul>`;
  }

  buildContextMenu(parentFrame: data.V6FrameData | null, frame: data.V6FrameData): TemplateResult {

    const dimensions = !parentFrame
      ? data.v6MatchAllAttributesOnFrame(frame, (attr: data.V6Property) => {
        return ((attr.code.includes("Height") || attr.code.includes("Width")));
      })
      : data.v6MatchAllAttributesOnFrame(parentFrame, (attr: data.V6Property) => {
        return (this.getRelatedFrameFromName(attr.code, parentFrame) === frame) &&
          (attr.code.includes(" Height") || attr.code.includes(" Width"));
      });
    let dimension = "";
    if (dimensions.length === 2) {
      const dLabel = (dimensions[0].code.includes(("Height"))) ? "Height/Width" : "Width/Height";

      dimension = `${dLabel} ${dimensions[0].value} x ${dimensions[1].value} `;
    }

    return html`
        <nav @onmouseleave=${()=> this.hideSVGToolTip()} class="navbar bg-light">
          <ul class="nav navbar-nav m-3 text-start">
            <li class="nav-item">
              <span class="fw-bold">${frame?.boundaryName} - ${frame?.description}<br />${dimension}
              </span>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Do Something</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#"> Contact </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#"> Blogs </a>
            </li>
          </ul>
        </nav>`;
  }
  getItemPropertiesTemplate(): TemplateResult {
    const classes = this.activeFrame !== this.rootFrame ? "d-none" : "";

    return html`
        <div class=${classes}>
          ${this.propertyTemplate?.()}
        </div>
            `;
  }
  getMoveUpFrameBtnTemplate(oneLevel = false): unknown {
    const clickEvent = (_e: Event) => {
      const prevFrame = oneLevel ? this.getFrameParent(this.activeFrame) : this.rootFrame;
      if (prevFrame)
        this.setActiveFrame(prevFrame);
    };
    const disabled = this.activeFrame === this.rootFrame;

    return this.toolbarBtnTemplate(oneLevel ? "fa-solid fa-angle-up" : "fa-solid fa-angles-up"
      , clickEvent, disabled);
  }

  protected toolbarBtnTemplate(iconTemplate: string, action: (e: Event) => void, disabled = false): TemplateResult {
    const clickAction = (e: Event) => {
      if (disabled) return;
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
      action(e);
    };
    const disabledClass = disabled ? " disabled " : "";
    return html`
    <i @click=${clickAction} class="${iconTemplate} fa-2x fa-border ${disabledClass}"></i>`;
  }
  protected magnifyBtnTemplate(): TemplateResult {
    const disabled = !this.hasVisibleNestedFrames(this.rootFrame);
    const clickEvent = (_e: Event) => {
      this.flagDisplayZoomToNestedFrame = !this.flagDisplayZoomToNestedFrame;
      this.render();
    };
    return this.toolbarBtnTemplate(
      this.flagDisplayZoomToNestedFrame
        ? "fas fa-expand"
        : "fas fa-compress", clickEvent, disabled);
  }

  protected showAllAttributesBtnTemplate(): TemplateResult {
    const disabled = false;
    const clickEvent = (_e: Event) => {
      this.flagDisplayShowAllAttributes = !this.flagDisplayShowAllAttributes;
      this.render();
    };
    //<i class="fa-solid fa-folder-closed"></i><i class="fa-solid fa-folder-open"></i>
    return this.toolbarBtnTemplate(
      this.flagDisplayShowAllAttributes
        ? "fa-solid fa-folder-closed"
        : "fa-solid fa-folder-open", clickEvent, disabled);
  }

  protected getFrameParent(activeFrame: data.V6FrameData | null): data.V6FrameData | null {
    if (!activeFrame) return null;
    if (!this.rootFrame) return null;
    let parent: data.V6FrameData | null = null;
    const recurse = (frame: data.V6FrameData) => {
      if (frame.nestedFrames.includes(activeFrame)) {
        parent = frame;
        return;
      }
      frame.nestedFrames.forEach(x => {
        if (!parent)
          recurse(x);
      });
    };
    recurse(this.rootFrame);
    return parent;
  }

  private getFrameFromSVGMouseEvent(e: PointerEvent): data.V6FrameData | null {
    let result: data.V6FrameData | null = null;
    if (e.target instanceof SVGElement && e.target?.id == `${this.svgId}_click`) {
      const svg: SVGElement = $(e.target).closest('svg')[0];
      const width = svg.clientWidth;
      const height = svg.clientHeight;
      const xPos = e.offsetX;
      const yPos = e.offsetY; //- svg.clientHeight;

      const xPerc = xPos / width;
      const yPerc = yPos / height;
      if (!this.svgClickableViewBox) return null;

      const frameX = this.svgClickableViewBox[0] + (this.svgClickableViewBox[2] * xPerc);
      const frameY = this.svgClickableViewBox[1] + (this.svgClickableViewBox[3] * yPerc);

      // First we check the nested frames of the active frame. this is good if we are zoomed in,
      // but if we are zoomed out, we want to be able to drill in if possible
      this.activeNestedFrames?.forEach(frame => {
        const vb = boundaryToViewBox(frame.bounds);
        if (this.stackHasAttributes(frame) && (vb && viewBoxContainsPoint(vb, frameX, frameY))) {
          result = frame;
        }
      });

      if (!result && !this.flagDisplayZoomToNestedFrame) {
        //if we didn't hit a nested frame and we are in expanded mode, check the full parent heirarchy
        this.rootFrame?.nestedFrames?.forEach(frame => {
          const vb = boundaryToViewBox(frame.bounds);
          if (this.stackHasAttributes(frame) && (vb && viewBoxContainsPoint(vb, frameX, frameY))) {
            result = frame;
          }
        });

      }

    }
    return result;

  }
  private hideSVGToolTip() {
    hideTooltip(this.svgToolTipId());
  }
  svgMouseMove(e: PointerEvent, leaving = false) {
    if (!this.flagDisplayClickToSelectFrame) return;
    if (!(e.target instanceof SVGElement)) return;
    if (this.hoverInfoTimeout) {
      clearTimeout(this.hoverInfoTimeout);
      this.hoverInfoTimeout = null;
    }

    //only hide the hover info if the moust moves more than a slight amount
    if (this.svgMouse.x !== -1 && (Math.abs(e.offsetX - this.svgMouse.x) > 10 || Math.abs(e.offsetY - this.svgMouse.y) > 10)) {
      this.svgMouse.x = -1;
      this.hideSVGToolTip();
    }

    //this code is used to find out and zoom into a clicked nested frame
    if (leaving) {
      if (this.hoverFrame != null) {
        this.hoverFrame = null;
        this.renderSvgDiv();//no wait
      }
      return;
    }
    const frame = this.getFrameFromSVGMouseEvent(e);

    if (frame != this.hoverFrame) {
      this.hoverFrame = frame;
      this.renderSvgDiv();//no wait
    }
    if (frame && !leaving) {
      this.hoverInfoTimeout = setTimeout(() => {
        this.hoverInfoTimeout = null;

        //reset the mouse location to track movements
        this.svgMouse.x = e.offsetX;
        this.svgMouse.y = e.offsetY;
        this.svgHoverContext(e);
      }, 300);
    }


  }

  svgClick(e: PointerEvent) {
    if (!(e.target instanceof SVGElement)) return;
    if (!this.flagDisplayClickToSelectFrame) return;

    this.lastClickedFrameNotation = this.frameNotation(this.getFrameFromSVGMouseEvent(e));
    if (e.button === 0 || !e.button)
      this.svgClickActuator.click();
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();

  }
  protected async displayFrameMenu(notation: string) {
    const getFrame = () => this.frameByNotation(notation);
    const getFrameParent = () => this.getFrameParent(getFrame());

    let attrGroups: V6FrameAttributeGroup[] = [];
    const buildTemplates = async (rebuild = false) => {
      const theFrame = getFrame();
      const activeFrame = getFrameParent();
      if (!activeFrame) return [];
      if (!theFrame) return [];
      if (rebuild) {
        attrGroups =
          [...activeFrame.attributeGroups.map(g =>
            new V6FrameAttributeGroup(g,
              this.attributeViewOptions,
              this.attributeEventHandlers,
              this.attributePickerSelectHandler,
              "",
              (item: data.V6Property) => {
                const relatedFrame = this.getRelatedFrameFromName(item.code, activeFrame);
                return relatedFrame?.boundaryName === theFrame?.boundaryName;
              })),
          ...theFrame.attributeGroups.map(g =>
            new V6FrameAttributeGroup(g,
              this.attributeViewOptions,
              this.attributeEventHandlers,
              this.attributePickerSelectHandler,
              "",
              (item: data.V6Property) => {
                const relatedFrame = this.getRelatedFrameFromName(item.code, theFrame);
                return !relatedFrame;
              }))];
      }
      console.log("rendering dialog");
      return await this.getGroupTemplates([{
        frame: activeFrame,
        attributeGroups: attrGroups
      }], activeFrame);

    };
    this.v6NestedFrameModal = new V6NestedFrameModal(getFrame, buildTemplates,
      (highlightFrame: data.V6FrameData) => this.createSVGImageTemplate(highlightFrame)
    );
    try {
      await this.v6NestedFrameModal.showModal();
    } finally {
      this.v6NestedFrameModal = null;
    }
  }
  createSVGImageTemplate(highlightFrame: data.V6FrameData): TemplateResult {
    const parentFrame = this.getFrameParent(highlightFrame);
    const img = this.createSVGImage({
      highlightFrame: highlightFrame,
      activeFrame: parentFrame,
      zoomToNestedFrame: false
    });
    const img1 = this.createSVGImage({
      activeFrame: highlightFrame,
      zoomToNestedFrame: true
    });
    return html`
            <div class="mb-3">
              <h3>${tlang`Wide View`}</h3>
              ${unsafeSVG(img)}
            </div>
            <div>
              <h3>${highlightFrame?.boundaryName}</h3>
              ${unsafeSVG(img1)}
            </div>`;

  }

  activeFrameTitle(): string {
    const currentFrame = this.getCurrentFrameData();
    if (!currentFrame) return "";
    return currentFrame.description;
  }

  private getFrameBoundary(currentFrame: data.V6FrameData | null): string | null {
    if (!currentFrame) return null;
    if (currentFrame === this.rootFrame) return null;
    return currentFrame.bounds;
  }
  getCurrentFrameBoundary(): string | null {
    return this.getFrameBoundary(this.getCurrentFrameData());

  }

  getActiveAttributeFrameBoundary(): string | null {
    return this.getFrameBoundary(this.getActiveAttributeFrameData());
  }

  getFrameTreeViewTemplate(): TemplateResult {
    const frameClickEvent = (e: Event, frame: data.V6FrameData) => {
      e.preventDefault();
      this.setActiveFrame(frame);
    };

    const createChildOL = (frame: data.V6FrameData) => {
      if (!frame || frame.nestedFrames.length === 0) return html``;
      // skip any nested frames that have no attributes in their nested stack
      return html`
                <ul>
                  ${frame.nestedFrames.filter(nf => this.stackHasAttributes(nf)).map(nf => createFrameLI(nf, frame))}
                </ul>
            `;
    };

    const createFrameLI = (frame: data.V6FrameData | undefined, parentFrame: data.V6FrameData | null) => {
      if (!frame) return html``;
      const lightRef = isEmptyOrSpace(frame.boundaryName) || parentFrame != this.activeFrame ? "" : `${frame.boundaryName} - `;
      if (frame === this.activeFrame) {
        return html`
                    <li class="v6-frame-navigation-active">
                      <a @click=${(e) => frameClickEvent(e,
                                  frame)}>${frame.description}</a>
                      ${createChildOL(frame)}
                    </li>`;
      } else {
        const mouseEnterEvent = () => {
          this.hoverFrame = frame;
          this.render();//no wait
        };
        const mouseLeaveEvent = () => {
          this.hoverFrame = null;
          this.render();//no wait
        };

        const frameClick = (e) => frameClickEvent(e, frame);

        return html`
                    <li class="v6-frame-navigation">
                      <a href="#" @click=${frameClick} @mouseenter=${mouseEnterEvent}
                        @mouseleave=${mouseLeaveEvent}>${lightRef}${frame.description}</a>
                      ${createChildOL(frame)}
                    </li>`;
      }
    };

    return html`
            <div class="">
              <ul>
                ${createFrameLI(this.quoteItemReference?.frameData, null)}
              </ul>
            </div>`;
  }

  hasVisibleNestedFrames(nf: data.V6FrameData | null): boolean {
    if (!nf) return false;
    return nf.nestedFrames.some(cnf => this.stackHasAttributes(cnf));
  }
  stackHasAttributes(nf: data.V6FrameData): boolean {
    const processFrame = (frame: data.V6FrameData): boolean => {
      return (frame.attributeGroups.some(g => g.attributes.filter(a => a.visible).length > 0) ||
        frame.nestedFrames.some(nf => processFrame(nf)));
    };
    return processFrame(nf);
  }

  objectReferenceTemplate(): TemplateResult {
    return this.quoteItemReference === null || this.quoteItemReference?.frameData.objectReference === "loading..." ?
      html`
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>`
      : html`${this.frameCode(this.quoteItemReference?.frameData.objectReference ?? "")}`;
  }

  frameCode(objectReference: string | null): string {
    if (!objectReference) return "";
    else
      return objectReference.substring(1, objectReference.length - 1).split(":")[2];
  }

  async getGroupTemplates(attributeGroups: FrameGroupDataItem[] | null, activeFrame?: data.V6FrameData | null): Promise<TemplateResult[]> {
    if (!attributeGroups) return [];
    if (activeFrame === undefined) activeFrame = this.activeFrame;
    const results: TemplateResult[] = [];
    for (let iGroup = 0; iGroup < attributeGroups?.length; iGroup++) {
      const frameGroupData = attributeGroups[iGroup];
      for (let i = 0; i < frameGroupData.attributeGroups.length; i++) {
        const g = frameGroupData.attributeGroups[i];
        if (g.visible) {
          results.push(await g.template(frameGroupData.frame === activeFrame));
        }
      }
    }

    return results;
  }

  protected getViewClasses(): string {
    return '';
  }

  protected frameDimensions(thisFrame?: data.V6FrameData): string {
    let dimension = "";
    if ((!thisFrame && this.frameDimensionsValue == null) || thisFrame) {
      const frame = thisFrame ?? (this.flagDisplayZoomToNestedFrame ? this.activeFrame : this.quoteItemReference?.frameData);
      if (!frame) return "";

      const parentFrame = frame ? this.getFrameParent(frame) : null;
      const dimensions = this.locateDimensionAttributes(parentFrame, frame);
      if (dimensions.length > 0) {
        const dLabel = (dimensions[0].code.includes(("Height"))) ? ["H", "W"] : ["W", "H"];
        dimension = `${dLabel[0]}${dimensions[0]?.displayValue}x${dLabel[1]}${dimensions[1]?.displayValue ?? "??"} `;
      }

      if (!thisFrame)
        this.frameDimensionsValue = dimension;
      return dimension;
    }
    return this.frameDimensionsValue ?? "";

  }

  private getLightNameFromCode(code: string): string {
    const split = code.split(' ');
    if (split.length <= 1) return "";
    return split[0].toLowerCase();
  }
  private getRelatedFrameFromName(name: string, activeFrame?: data.V6FrameData): data.V6FrameData | null {
    const lightName = this.getLightNameFromCode(name);
    const frames = activeFrame?.nestedFrames ?? this.activeNestedFrames;
    return frames?.find(x => x.boundaryName.toLowerCase() === lightName) ?? null;
  }

  private getCurrentFrameData(): data.V6FrameData | null {
    return this.frameByNotation(this.activeFrameNotation);
  }

  private getActiveAttributeFrameData(): data.V6FrameData | null {
    return this.frameByNotation(this.activeAttributeFrameNotation);
  }

  private get rootFrame(): data.V6FrameData | null {
    return this.quoteItemReference?.frameData ?? null;
  }
  private createSVGImage(options?: {
    highlightFrame?: data.V6FrameData;
    zoomToNestedFrame?: boolean;
    activeFrame?: data.V6FrameData | null;
    activeAttributeFrameNotation?: string | null;
  }): string {
    if (!options) {
      options = {
      };
    }
    if (options.zoomToNestedFrame === undefined) options.zoomToNestedFrame = this.flagDisplayZoomToNestedFrame;
    if (options.activeFrame === undefined) options.activeFrame = this.activeFrame;
    if (options.activeAttributeFrameNotation === undefined) options.activeAttributeFrameNotation = this.activeAttributeFrameNotation;

    const timer = new DebugTimer();
    this.svgImage = removeSizeFromSVG(this.quoteItemReference?.thumbnail);
    this.svgId = getSVGId(this.svgImage);
    let svgImage = this.svgImage;
    timer.stop("removeSizeFromSvg");

    timer.start();
    //this returns null on rootframe
    const currentFrameBoundary = this.getFrameBoundary(options.activeFrame);
    if (options.activeFrame === this.rootFrame && this.rootFrame)
      svgImage = alterSVGViewBox(svgImage, this.rootFrame.bounds);

    if (options.zoomToNestedFrame) {
      if (currentFrameBoundary)
        svgImage = alterSVGViewBox(svgImage, currentFrameBoundary);
    } else {
      svgImage = injectFrameBoundaryInSVG(svgImage,
        {
          boundary: options.activeFrame !== this.rootFrame ? currentFrameBoundary : null
        });
    }

    timer.stop("alterSvgView");

    const boundary = options.zoomToNestedFrame
      ? options.activeFrame === this.rootFrame ? this.rootFrame?.bounds : currentFrameBoundary
      : this.quoteItemReference?.frameData.bounds;
    this.svgClickableViewBox = boundaryToViewBox(boundary ?? null) ?? [0, 0, 100, 100];


    timer.start();
    if (options.activeAttributeFrameNotation) {
      svgImage = injectFrameBoundaryInSVG(svgImage, {
        boundary: this.getActiveAttributeFrameBoundary(),
        color: "red"
      });
    }
    if (this.hoverFrame) {
      svgImage = injectFrameBoundaryInSVG(svgImage, {
        boundary: this.hoverFrame.bounds,
        color: "orange"
      });
    }
    if (options.highlightFrame) {
      svgImage = injectFrameBoundaryInSVG(svgImage, {
        boundary: options.highlightFrame.bounds,
        color: "green"
      });
    }


    //THIS MUST BE NEAR LAST. THIS IS OUR MOUSE CLICK LAYER. ONLY ANNOTATIONS COME AFTER
    svgImage = injectFrameBoundaryInSVG(svgImage, {
      boundary: this.quoteItemReference?.frameData.bounds,
      color: "white",
      opacity: "0.0",
      id: "click"
    });
    timer.stop("injectFrameBoundary");

    timer.start();
    const onePercentX = this.svgClickableViewBox[2] / 100;
    const onePercentY = this.svgClickableViewBox[3] / 100;
    const onePercent = this.svgClickableViewBox ? Math.max(onePercentX, onePercentY) : 1.5;


    if (this.flagDisplayLightName) {
      const filterNf = options.activeFrame?.nestedFrames.filter(x => this.stackHasAttributes(x));
      if (filterNf && filterNf.length > 0) {
        const textData = [...filterNf.map(nf => {
          const box = boundaryToV6ViewBox(nf.bounds)!;
          const item: SVGText = {
            direction: 0,
            x: box[0] + (box[2] / 2) - (onePercentY * 2.25 * nf.boundaryName.length / 2),
            y: box[1] + (box[3] / 2) + (onePercentY * 3),
            fontSize: onePercent * 3,
            text: nf.boundaryName
          };
          return item;
        }), ...filterNf.map(nf => {
          const box = boundaryToV6ViewBox(nf.bounds)!;
          const value = this.frameDimensions(nf);
          const item: SVGText = {
            direction: 0,
            x: box[0] + (box[2] / 2) - (onePercentY * 1.5 * value.length / 2),
            y: box[1] + (box[3] / 2) - (onePercentY),
            fontSize: onePercent * 1.8,
            text: value
          };
          return item;
        })];
        svgImage = injectTextInSVG(svgImage, textData, { color: "black", opacity: "90" });
      }
    }
    timer.stop("add lightnames");
    timer.start();
    clearToolTips();
    this.validationTooltips.forEach(validationToolTip => {
      registerTooltip(`svg-tooltip-${this.internalId}-${validationToolTip.id}`, () =>
        html`
                <div style="width: 590px;">
                  ${validationToolTip.body()}
                </div>
               ` );

      svgImage = injectAnnotationInSVG(svgImage, {
        boundary: this.rootFrame?.bounds,
        color: "red",
        opacity: "1",
        id: "click-annotate",
        tooltipElementId: `svg-tooltip-${this.internalId}`,
        tooltipId: `svg-tooltip-${this.internalId}-${validationToolTip.id}`,
        x: validationToolTip.x,
        y: validationToolTip.y,
        icon: validationToolTip.icon,
        noOffset: validationToolTip.noOffset,
        radius: validationToolTip.radius ? validationToolTip.radius * onePercent : onePercent * 1.5


      });

    });
    timer.stop("tooltips");
    return svgImage;
  }

}


interface Point {
  x: number;
  y: number;
}

interface FrameBoundaryOptions {
  boundary?: string | null;
  color?: string;
  opacity?: string;
  id?: string;
  tooltip?: string;
}
interface FrameValidationOptions {
  boundary?: string | null;
  color?: string;
  opacity?: string;
  id?: string;
  tooltipId?: string;
  tooltipElementId: string;
  x: number;
  y: number;
  radius?: number;
  icon?: string;
  noOffset?: boolean;
}

interface SVGText {
  x: number;
  y: number;
  fontSize: number;
  direction: number;
  text: string;
}
interface FrameTextOptions {
  color?: string;
  opacity?: string;
}

function injectTextInSVG(thumbnail: string | null | undefined, text: SVGText[], options: FrameTextOptions) {
  if (thumbnail) {

    const overlayClass = `overlay-${getInternalId()}-text`;
    const overlayColor = options.color ?? "cyan";
    const overlayOpacity = options.opacity ?? "0.2";
    let result = thumbnail;
    const svgId = thumbnail.match(/<svg id="(.*?)"/);
    if (svgId) {
      result =
        result.replace(/<\/style>/, `#${svgId[1]} .${overlayClass}{fill:${overlayColor}; fill-opacity:${overlayOpacity}; font-family: monospace; }
            </style>`);

      const data = text.map(item => {

        return `<text x="${item.x}" font-size="${item.fontSize}" y="${item.y * -1}" rotate="${item.direction ?? 0}"  class="${overlayClass}" >${item.text}</text>`;
      }).join();
      result = result.replace(/<\/svg>/,
        `${data}
        </svg>
        `);
    }
    return result;
  } else
    return "";

}

function injectFrameBoundaryInSVG(thumbnail: string | null | undefined, options: FrameBoundaryOptions): string {
  if (thumbnail) {
    if (!options.boundary) return thumbnail;

    const points = boundaryToSVGPoints(options.boundary);

    const overlayClass = `overlay-${getInternalId()}`;
    const overlayColor = options.color ?? "cyan";
    const overlayOpacity = options.opacity ?? "0.2";
    let result = thumbnail;
    const svgId = thumbnail.match(/<svg id="(.*?)"/);
    if (svgId) {
      result =
        result.replace(/<\/style>/, `#${svgId[1]} .${overlayClass}{fill:${overlayColor}; fill-opacity:${overlayOpacity}; }
            </style>`);

      const id = options.id ?? getInternalId();
      const rectid = `${svgId[1]}_${id}`;
      result = result.replace(/<\/svg>/,
        `<rect id=${rectid} x=${points[0].x} y=${points[1].y} width=${points[2].x - points[0].x} height=${points[0].y - points[1].y} class="${overlayClass}" />
        </svg>
        `);
    }
    return result;
  } else
    return "";
}

let tooltipManager: { id: string; value: EventSnippet; }[] = [];

function registerTooltip(id: string, value: EventSnippet) {
  const match = tooltipManager.filter(x => x.id == id);
  if (match.length === 1)
    match[0].value = value;
  else {
    const existing = tooltipManager.find(x => x.id === id);
    if (existing) {
      existing.value = value;
    } else
      tooltipManager.push({ id: id, value: value });
  }
}
function clearToolTips() {
  tooltipManager = [];
}
function showTooltip(evt: PointerEvent, tooltipElementId: string, tooltipId: string, offsetX?: number, offsetY?: number) {
  const tooltip = document.getElementById(tooltipElementId);

  if (!tooltip) return;
  if (!evt) return;
  if (!(evt.target instanceof SVGElement)) return;

  const text = tooltipManager.find(x => x.id == tooltipId)?.value;
  if (!text) return;
  const template = text();
  render(template, tooltip);

  tooltip.style.display = "block";
  tooltip.style.left = (evt.pageX - (offsetX ?? 600)) + 'px';
  tooltip.style.top = (evt.pageY + (offsetY ?? 10)) + 'px';
}

function hideTooltip(tooltipElementId: string) {
  const tooltip = document.getElementById(tooltipElementId);
  if (!tooltip) return;
  tooltip.style.display = "none";
}

globalThis.v6DealerShowTooltip = showTooltip;
globalThis.v6DealerHideTooltip = hideTooltip;
function injectAnnotationInSVG(thumbnail: string | null | undefined, options: FrameValidationOptions): string {
  if (thumbnail) {
    if (!options.boundary) return thumbnail;

    const radius = options.radius ?? 5;
    const overlayClass = `overlay-context-${getInternalId()}`;
    const overlayColor = options.color ?? "yellow";
    const overlayOpacity = options.opacity ?? "0.8";
    let result = thumbnail;
    const svgId = thumbnail.match(/<svg id="(.*?)"/);
    const offset = options.noOffset ? 0 : radius;
    if (svgId) {
      result =
        result.replace(/<\/style>/, `#${svgId[1]} .${overlayClass}{fill:${overlayColor}; fill-opacity:${overlayOpacity}; border-radius:"50%" }
            </style>`);

      result = result.replace(/<\/svg>/,
        `<image x="${options.x - offset}" y="${(options.y * -1) - offset}" width="${radius * 2}" height="${radius * 2}" href="./assets/icons/${options.icon ?? "info"}.svg"  class="${overlayClass} annotation svg-tooltip"
                   onmousemove="v6DealerShowTooltip(evt, '${options.tooltipElementId}', '${options.tooltipId}');"   onmouseleave="v6DealerHideTooltip(evt,  '${options.tooltipElementId}');"
                />
        </svg>
        `);
    }
    return result;
  } else
    return "";
}



//remove the size from the viewport as its a scalable image. we want to render it as we please
function removeSizeFromSVG(thumbnail: string | null | undefined): string {
  if (thumbnail)
    return thumbnail.replace(/style="height:(.*); width:(.*)"/, 'style="max-height:500px;"');
  else
    return "";

}

function alterSVGViewBox(thumbnail: string | null | undefined, newBoundary: string | null): string {
  if (!thumbnail) return "";
  if (!newBoundary) return thumbnail ?? "";
  const viewBox = boundaryToViewBox(newBoundary);
  if (!viewBox) return thumbnail;
  const dimension = `${viewBox[0]}, ${viewBox[1]}, ${viewBox[2]}, ${viewBox[3]}`;
  if (thumbnail)
    return thumbnail.replace(/viewBox\s*=\s*"(.*)"/, `viewbox="${dimension}"`);
  else
    return "";

}

function getSVGId(thumbnail: string | null | undefined): string | null {
  if (!thumbnail) return null;
  const match = thumbnail.match(/id\s*=\s*"(.*?)"/);
  if (match) {
    return match[1];
  }
  return null;
}

/*
function getViewBoxFromSVG(thumbnail: string | null | undefined): number[] | null {
    if (!thumbnail) return null;
    const match = thumbnail.match(/viewBox\s*=\s*"(.*)"/);
    if (match) {
        return match[1].split(' ').map(x => parseFloat(x));
    }
    return null;
}
*/
function boundaryToSVGPoints(boundary: string): Point[] {
  const points: Array<Point> = [];
  boundary.split('|').forEach(pointData => {
    const pointVal = pointData.split(',');
    const point: Point = { x: parseFloat(pointVal[0]), y: parseFloat(pointVal[1]) * -1 };
    points.push(point);
  });
  return points;
}
function boundaryToPoints(boundary: string): Point[] {
  const points: Array<Point> = [];
  boundary.split('|').forEach(pointData => {
    const pointVal = pointData.split(',');
    const point: Point = { x: parseFloat(pointVal[0]), y: parseFloat(pointVal[1]) };
    points.push(point);
  });
  return points;
}

function svgPointsToViewBox(points: Point[]): number[] {
  return [
    points[0].x,
    points[1].y,
    points[2].x - points[0].x,
    points[0].y - points[1].y
  ];
}

function pointsToViewBox(points: Point[]): number[] {
  return [
    points[0].x,
    points[0].y,
    points[2].x - points[0].x,
    points[2].y - points[0].y
  ];
}

function boundaryToViewBox(boundary: string | null): number[] | null {
  if (!boundary) return null;
  const v = svgPointsToViewBox(boundaryToSVGPoints(boundary));
  //  console.log(`viewbox=${v[0]},${v[1]}, ${v[2]}, ${v[3]}`);
  return v;
}

function boundaryToV6ViewBox(boundary: string | null): number[] | null {
  if (!boundary) return null;
  const v = pointsToViewBox(boundaryToPoints(boundary));
  //  console.log(`viewbox=${v[0]},${v[1]}, ${v[2]}, ${v[3]}`);
  return v;
}


function viewBoxContainsPoint(viewBox: number[], x: number, y: number) {
  return (viewBox[0] <= x) && (viewBox[0] + viewBox[2] > x)
    && (viewBox[1] <= y) && (viewBox[1] + viewBox[3] > y);
}


