
import { ApiCommunications } from "../../api/api-communications";
import { ResourcePageRequest, ResourcePickerDataProvider, ResourceTag } from "./v6-resource-picker-data-provider";

export interface RGB {
    R: number;
    G: number;
    B: number;
}
export interface Finish extends ResourceTag {
    lib: string;
    groupCode: string;
    groupDescription: string;
    hexColour: string;
    additionalProperties: RGB;
}

export interface FinishFilter {
    textFilter: string;
}

export class V6FinishPickerDataProvider extends ResourcePickerDataProvider<Finish, FinishFilter> {
    protected path(): string { return "api/v6/ConfigItem/GetFinishResources"; }
    protected convertFilter(filter: FinishFilter): any {
        return filter?.textFilter;
    }
}

export class V6FinishByFramePickerDataProvider extends ResourcePickerDataProvider<Finish, FinishFilter> {
    optionType: number;
    optionCode: string;
    objectReference: string;
    protected path(): string { return "api/v6/ConfigItem/GetFinishResourcesByFrame"; }
    protected convertFilter(filter: FinishFilter | null): any {
        return filter?.textFilter;
    }
    constructor(api: ApiCommunications, supplierId: string, optionType: number, optionCode: string, objectReference: string) {
        super(api, supplierId);

        this.optionType = optionType;
        this.optionCode = optionCode;
        this.objectReference = objectReference;
    }
    protected inputData(input: ResourcePageRequest<FinishFilter>): any {
        return {
            supplierCode: this.supplierId,
            frameReference: this.objectReference,
            optionType: this.optionType,
            optionCode: this.optionCode,
            pageIndex: input.pageIndex,
            pageSize: input.pageSize,
            filter: this.convertFilter(input.filter)
        };
    }

}
