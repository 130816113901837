import { ClientApi } from "../../api/client-api";
import { DataTableWrapper, RequestPage, ResultPaginated } from "../../components/ui/datatable-view";
import {
    Address,
    ResultBrowseClientSummary,
    ViewClientSummary,
} from "../../api/dealer-api-interface-client";
import { EventSnippet } from "../../components/ui/events";
import { tlang } from "../../language/lang";
import { getApiFactory } from "../../api/api-injector";

export interface ClientSummaryTableBaseOptions {
    title: EventSnippet;
}

export class ClientSummaryTableBase extends DataTableWrapper<ViewClientSummary> {
    title: EventSnippet;
    api: ClientApi = getApiFactory().client();
    filter: string | null;

    constructor(options: ClientSummaryTableBaseOptions) {
        super();
        this.title = options.title;
        this.filter = null;
    }

    async getRowsFromServer(request: RequestPage): Promise<ResultPaginated<ViewClientSummary>> {
        const results = await this.api.browseClientSummary({
            pageIndex: request.pageIndex,
            pageSize: request.pageSize,
            sortField: request.sortField,
            sortAsc: !request.sortAsc,
            clientOwnerId: null,
            filter: this.filter
        });
        if (!results) return {
            count: 0, pageCount: 0, pageIndex: 0, pageSize: this.pageLength(), results: []
        };

        await this.doPreFetching(results);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- It is not null


        return results.clientSummary;
    }
    async doPreFetching(results: ResultBrowseClientSummary) {
        await Promise.all(this.getPreFetched(results));
    }

    getDefaultSortAsc(): boolean {
        return false;
    }

    useAutoWidthColumns(): boolean {
        return false;
    }

    enableFiltering(): boolean {
        return true;
    }

    updateFilter(_searchTerm: string | null) {
        this.filter = _searchTerm;
    }

    getColumns(): any[] {
        return [{
            title: tlang`%%client%% Name`,
            width: "250px",
            data: "name",
            className: "client-name"
        }, {
            title: tlang`Primary %%contact%%`,
            width: "250px",
            data: "primaryContactName",
            className: "client-contact-name"
        }, {
            title: "%%contact%% Email", width: "250px", orderable: false, data: "primaryContactEmail", className: "client-contact-email"
        }, {
            title: "Address", width: "400px", data: "physicalAddress", orderable: false, className: "client-address", render: (value: Address) => {
                return `${[value.line1, value.locality, value.region, value.postcode, value.country].filter(Boolean)
                    .join(", ")}`;
            }
        },];
    }

    protected getPreFetched(_results: ResultBrowseClientSummary): Promise<void>[] {
        return [];
    }
}

