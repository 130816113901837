import { ApiCommunications } from "../api/api-communications";
import { V6FinishPicker } from "./v6-picker/v6-finish-picker";
import { Finish, V6FinishByFramePickerDataProvider, V6FinishPickerDataProvider } from "./v6-picker/v6-finish-picker-data-provider";
import { V6FrameData, V6ListItem, V6Property, V6PropertyGroup } from "./v6-quote-item/v6-quote-item-data";
import { FinishPickerOptions, V6PickerFactory, ResourcePickerOptions } from "./v6-picker/v6-pickers";
import "../components/FormInputView.ts";
import { GraphicalFamilyResourcePicker } from "../picker/graphical-family-resource-picker";
import { V6ResourceFamilyPickerDataProvider } from "./v6-picker/v6-resource-family-data-provider";
import { FamilyResourceTag, ResourceFamilyResults } from "../picker/family-resource-picker-data";
import { convertListItemsToFamilyData, V6StaticFamilyDataEndpoint } from "./v6-quote-item/v6-quote-item-view/v6-attribute-listitem-converter";
import { TemplateResultEvent } from "./common-types";
import { ApiProvider, getApi } from "../api/api-injector";
import { NullPromise } from "../null-promise";
import { tlang } from "../language/lang";
import { DevelopmentError } from "../development-error";
import { isEmptyOrSpace } from "../components/ui/helper-functions";


const familyFrame = "picker:family:frame";
const familyFill = "picker:family:fill";
const familyIGU = "picker:family:igu";
const familyQuoteIGU = "picker:family:igu:quote";
const familyLouvre = "picker:family:louvre";
const v6SupplierQuoteItemContentType = {
    CID_FRAM: 7,
    CID_COMP: 2,
    CID_EXTN: 3,
    CID_FILL: 4,
    CID_IGU: 90, //QTE_IGU = 52, but we dont need
    CID_LOUVRE: 100 //TODO - put real value
};


//this idenifies a v6 endpoint we wish to communicate with
export interface V6Supplier {
    id: string;
    name: string;
    code: string;
    parameters: any;
}


class V6PickerFactoryImpl implements V6PickerFactory {
    private api: ApiProvider;
    constructor(apiProvider: ApiProvider) {
        this.api = apiProvider;
    }
    //call on a finish picker. probably not required to be a public exposure, so we might hide it later.
    //takes all the options including the on click event.
    //if a bind click object is passed then a picker will be permanently bound to the "button"
    //otherwise it will be executed immediately as a single instance click, with no "memory" when reopened
    public async finishPicker(supplierId: string, options: FinishPickerOptions | null): NullPromise<Finish> {
        if (options?.name) {
            let p: V6FinishPicker | null;
            p = null;
            //apply defaults
            options.pageSize = options?.pageSize ?? 10;
            options.modal = true;
            options.name = options?.name ?? "finish";

            switch (options?.name.toLowerCase()) {
                case "finish": {
                    p = new V6FinishPicker(new V6FinishPickerDataProvider(this.api(), supplierId),
                        options);
                    break;
                }
                case "finish-by-frame": {
                    if (!options?.objectReference) throw Error("object reference required");
                    p = new V6FinishPicker(new V6FinishByFramePickerDataProvider(this.api(),
                        supplierId,
                        options?.optionType ?? 0,
                        options?.optionCode ?? "",
                        options?.objectReference),
                        options);
                    break;
                }
            }
            //either execute or bind ourselves.
            if (p) {
                await p.showModal();
                return p.selected;
            }
            else return null;


        }
        return null;
    }

    public async resourcePicker(supplierId: string, options: ResourcePickerOptions): NullPromise<FamilyResourceTag | null> {
        let resourceName: string | undefined = undefined;
        resourceName = resourceClassToName(options.resourceClassId);
        const endpoint = new V6ResourceFamilyPickerDataProvider(this.api(),
            supplierId,
            options.familyRef, options.resourceClassId, options.tags);
        const gp = new GraphicalFamilyResourcePicker(endpoint, {
            resourceName: resourceName,
            settingsName: options.familyRef,
            sideBar: options.sideBar,
            hideImages: options.hideImages,
            show: { tree: true }
        });
        await gp.showModal();
        return gp.selected;
    }

    public async listItemPicker(supplierId: string, items: V6ListItem[], sideBar: TemplateResultEvent): Promise<FamilyResourceTag | null> {
        let resourceName: string | undefined = undefined;
        resourceName = "Option";
        const endpoint = new V6StaticFamilyDataEndpoint(this.api(),
            supplierId,
            convertListItemsToFamilyData(items));
        const gp = new GraphicalFamilyResourcePicker(endpoint, {
            resourceName: resourceName,
            sideBar: sideBar,
            show: { tree: false }

        });
        await gp.showModal();
        return gp.selected;
    }
    public async familyDataPicker(supplierId: string, items: ResourceFamilyResults, sideBar: TemplateResultEvent, resourceName?: string, hideImages?: boolean): Promise<FamilyResourceTag | null> {
        resourceName = resourceName ?? "Option";
        const endpoint = new V6StaticFamilyDataEndpoint(this.api(),
            supplierId,
            items);
        const gp = new GraphicalFamilyResourcePicker(endpoint, {
            resourceName: resourceName,
            sideBar: sideBar,
            show: { tree: false },
            hideImages: hideImages

        });
        await gp.showModal();
        return gp.selected;
    }

}

interface SupplierQuoteDefaults {
    supplierId: string;
    values: V6PropertyGroup[];
}
// public exposure for the entire library. this is the front end that applications such as the dealer-franchisee will use
// to consume anything from the v6 service.
export class V6ConfigWrapper {
    private _suppliers: Array<V6Supplier> | null = null;
    private _quoteDefaults: SupplierQuoteDefaults[] = [];
    private _api: ApiCommunications;
    public pickerFactory: V6PickerFactory;
    supplierId?: string;

    constructor(api: ApiCommunications) {
        this._api = api;
        this.pickerFactory = new V6PickerFactoryImpl(() => this._api);
    }
    //public configuration method. intentionally using empty for flexible settings
    public options(options?: any) {
        if (options?.supplierId) this.supplierId = options?.supplierId;
    }

    public async fillPickerAvailable(supplierId: string): Promise<boolean> {
        return !isEmptyOrSpace(await this.getSupplierParameter(supplierId, familyFill));
    }
    //return all possible suppliers and their descriptions
    public async suppliers(): Promise<Array<V6Supplier> | null> {
        const path = `api/v6/configitem/suppliers`;
        const api = this._api;

        if (this._suppliers) return this._suppliers;

        const resultSuppliers = await api.post<{ items: Array<V6Supplier>; }>(path);
        if (resultSuppliers) {
            this._suppliers = resultSuppliers.items;
        }
        return this._suppliers;

    }
    public async getQuoteDefaults(supplierId: string): NullPromise<V6PropertyGroup[]> {
        const path = `api/v6/configitem/GetQuoteDefaultOptions`;
        const api = this._api;

        const values = this._quoteDefaults.find(x => x.supplierId === supplierId);
        if (values) return values.values;

        const resultOptions = await api.post<{ quoteOptions: V6PropertyGroup[]; }>(path, {
            supplierId: supplierId
        });
        if (resultOptions) {
            this._quoteDefaults.push({ supplierId: supplierId, values: resultOptions.quoteOptions });
            return resultOptions?.quoteOptions;
        }
        //an error has already been shown if we get here.


        return null;

    }

    async getSupplierParameter(supplierId: string, paramName: string): Promise<string> {
        const suppliers = await this.suppliers();
        const supplier = suppliers?.find(x => x.id === supplierId);
        if (!supplier) {
            throw new DevelopmentError(`getV6SupplierParameter, invalid supplierid "${supplierId}"`);
        }
        const p = supplier.parameters[paramName];
        if (!p) throw new DevelopmentError(`getV6SupplierParameter, supplierid "${supplierId}" invalid param "${paramName}"`);
        return p;

    }

    getResourceTagImgSrc(supplierId: string, tag: FamilyResourceTag): string {
        return getApi().fullUrl(`api/v6/ConfigItem/Resource/${supplierId}/${tag.resourceClassId}/${tag.libId}/${tag.id}/resfile.jpg`);
    }

    async pickFrame(supplierId: string): Promise<FamilyResourceTag | null> {
        if (supplierId) {
            const res = await this.getPickerFactory().resourcePicker(supplierId,
                {
                    resourceClassId: v6SupplierQuoteItemContentType.CID_FRAM,
                    familyRef: await this.getSupplierParameter(supplierId, familyFrame),
                    tags: "",
                    sideBar: null
                });
            if (res) {
                return res;
            }
        }
        return null;
    };

    async pickFill(supplierId: string): Promise<FamilyResourceTag | null> {
        if (supplierId) {
            const res = await this.getPickerFactory().resourcePicker(supplierId,
                {
                    resourceClassId: v6SupplierQuoteItemContentType.CID_FILL,
                    familyRef: await this.getSupplierParameter(supplierId, familyFill),
                    tags: "",
                    sideBar: null,
                    hideImages: true
                });
            if (res) {
                return res;
            }
        }
        return null;
    };

    async pickLouvre(supplierId: string): Promise<FamilyResourceTag | null> {
        if (supplierId) {
            const res = await this.getPickerFactory().resourcePicker(supplierId,
                {
                    resourceClassId: v6SupplierQuoteItemContentType.CID_LOUVRE,
                    familyRef: await this.getSupplierParameter(supplierId, familyLouvre),
                    tags: "",
                    sideBar: null,
                    hideImages: true

                });
            if (res) {
                return res;
            }
        }
        return null;
    };


    async pickIGU(supplierId: string): Promise<FamilyResourceTag | null> {
        if (supplierId) {
            const res = await this.getPickerFactory().resourcePicker(supplierId,
                {
                    resourceClassId: v6SupplierQuoteItemContentType.CID_IGU,
                    familyRef: await this.getSupplierParameter(supplierId, familyIGU),
                    tags: "",
                    sideBar: null,
                    hideImages: true
                });
            if (res) {
                return res;
            }
        }
        return null;
    };

    async pickIGUForQuoteIGU(supplierId: string): Promise<FamilyResourceTag | null> {
        if (supplierId) {
            const res = await this.getPickerFactory().resourcePicker(supplierId,
                {
                    resourceClassId: 90 /* CID_IGU */,
                    familyRef: await this.getSupplierParameter(supplierId, familyQuoteIGU),
                    tags: "",
                    sideBar: null,

                });
            if (res) {
                return res;
            }
        }
        return null;
    };


    getPickerFactory(): V6PickerFactory {
        return this.pickerFactory;
    }


}
function resourceClassToName(resourceClass: number): string | undefined {
    let resourceName: string | undefined;
    switch (resourceClass) {
        case 1: //assy
            resourceName = tlang`%%assembly%%`;
            break;
        case 2: //comp
            resourceName = tlang`%%component%%`;
            break;
        case 3: //extrusion
            resourceName = tlang`%%extrusion%%`;
            break;
        case 4: //extrusion
            resourceName = tlang`%%fill%%`;
            break;
        case 7: //frame
            resourceName = tlang`%%frame%%`;
            break;
        case 13: //attachment bag
            resourceName = tlang`%%attachment-bag%%`;
            break;
        case 90: //attachment bag
            resourceName = tlang`%%igu%%`;
            break;
        case 211://gasket
            resourceName = tlang`%%gasket%%`;
            break;

    }
    return resourceName;
}

let _v6Config: V6ConfigWrapper | null = null;

export function v6Config(): V6ConfigWrapper {
    if (!_v6Config) _v6Config = new V6ConfigWrapper(getApi());
    return _v6Config;
}



export function sanitizeFrameDataForSave(frame: V6FrameData) {
    sanitizePropertyGroupForSave(frame.attributeGroups);
    frame.nestedFrames.forEach(nf => sanitizeFrameDataForSave(nf));
}

export function sanitizePropertyGroupForSave(groups: V6PropertyGroup[], retainDisplayValue = true) {
    for (let i = 0; i < groups.length; i++) {
        groups[i].attributes = groups[i].attributes.map(a => {
            const newAttr: V6Property = {
                code: a.code,
                codeDescription: a.codeDescription,
                value: a.value,
                originalValue: a.originalValue,
                isReadonly: a.isReadonly,
                valueType: a.valueType,
                visible: a.visible,
                displayValue: retainDisplayValue ? a.displayValue : undefined
            };
            return newAttr;
        });
    }
}


