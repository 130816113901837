export const techincalDictionary = {};

export type DictionaryEntryValue = () => string;
export class DictionaryEntry {
    single: DictionaryEntryValue;
    plural: DictionaryEntryValue;
    constructor(single: DictionaryEntryValue, plural: DictionaryEntryValue) {
        this.single = single;
        this.plural = plural;
    }
}
