//this will contain the content of a quote-item tab
//which will include a v6/v7 config frame + surrounds for

// eslint-disable-next-line import/named
import { html } from "lit";
import { QuoteItem } from "../../api/dealer-api-interface-quote";
import { emptyGuid } from "../../api/guid";
import { DataTableWrapper, RequestPage, ResultPaginated } from "../../components/ui/datatable-view";
import { QuoteApi } from "../../api/quote-api";
import { EventQuoteItemContainerAction, quoteItemAction } from "../data/events";
import { QuoteItemContainer } from "../data/quote-item-container";
import { QuoteContainerManager } from "../data/quote-container";
import { quoteSupplierProvider } from "../data/quoteSupplierProvider";
import { getApiFactory } from "../../api/api-injector";
import { DevelopmentError } from "../../development-error";
import { isEmptyOrSpace } from "../../components/ui/helper-functions";
import { DataCacheGeneric } from "../../cache/generic-data-cache";
import { PromiseTemplate } from "../../components/ui/events";
import { supplierQuoteItemContentType } from "../data/supplier-quote-item-content-type";
import { quoteItemContentType } from "../data/default-quote-item-content-type";
import { ViewBase } from "../../components/ui/view-base";
import { validationStyles } from "../../v6config/v6-quote-item/v6-data-objects";
import { V7ItemProviderContent } from "../../dealer-franchisee/quotes/views/v7/v7-quote-item-frame-view";
import { base64ToObject } from "../../blob/converters";

import EllipseIcon from '../../../assets/icons/ellipse.svg';
import CopyIcon from '../../../assets/icons/copy.svg';
import CloseIcon from '../../../assets/icons/close.svg';
import BinIcon from '../../../assets/icons/bin.svg';
import AdjustIcon from '../../../assets/icons/adjust.svg';
import FreehandThumbnailImage from '../../../assets/images/freehand.svg';
import DeliveryThumbnailImage from '../../../assets/images/delivery.svg';

interface QuoteItemSummaryTableOptions {
    quoteManager: QuoteContainerManager;
    userProfileCache: DataCacheGeneric;
    eventRunQuoteItemAction: EventQuoteItemContainerAction;
}

export class QuoteItemTable extends DataTableWrapper<QuoteItem> {
    quoteManager: QuoteContainerManager;
    userCache: DataCacheGeneric;
    quoteApi: QuoteApi = getApiFactory().quote();

    eventRunQuoteItemAction: EventQuoteItemContainerAction;


    constructor(options: QuoteItemSummaryTableOptions) {
        super();
        this.quoteManager = options.quoteManager;
        this.userCache = options.userProfileCache;
        this.eventRunQuoteItemAction = options.eventRunQuoteItemAction;

    }

    verticalHeight(): string | undefined {
        return undefined;
    }

    usePaging(): boolean {
        return false;
    }

    useInfo(): boolean {
        return true;
    }

    pageLength(): number {
        return 10000;
    }

    afterRowCreated(_row: any, _data: QuoteItem, _dataIndex: number): void {
        // Set the data-status attribute, and add a class
        //        const rowClass = dataIndex % 2 === 0 ? "odd-row" : "even-row";
        //       $(row).addClass(rowClass);
    }

    async getRowsFromServer(_request: RequestPage): Promise<ResultPaginated<QuoteItem>> {
        //never do a force refresh here. if we need to reload all data for quote items
        //it should only be if all quote item view tabs are closed or we will end up
        //with data corruption
        await this.quoteManager.needsQuoteItems(false);
        const items = this.quoteManager.container.items;
        if (!items)
            return {
                count: 0,
                pageCount: 0,
                pageIndex: 0,
                pageSize: this.pageLength(),
                results: []
            };

        const userKeys = items.map(x => x.lastModifiedUserId ?? emptyGuid) ?? [];

        //if there are any other callbacks we need to do, add them to the
        //all array which will let them run at the same time
        await Promise.all([
            this.userCache.preFetch(userKeys)
        ]);
        return {
            count: items.length,
            pageCount: 1,
            pageIndex: 0,
            pageSize: items.length,
            results: items
        };
    }

    getDefaultSortAsc(): boolean {
        return false;
    }

    getDefaultSortFieldIndex(columns: any[]): number {
        return columns.findIndex(c => c.data === "lastModifiedDate");
    }

    isV7(quoteItem: QuoteItem) { return quoteItem.serviceProvider === quoteSupplierProvider.v7; }
    isShipping(quoteItem: QuoteItem) {
        return quoteItem.serviceProvider === "" && quoteItem.quoteItemContentType === quoteItemContentType.shipping;
    }

    isFreeHand(quoteItem: QuoteItem) {
        return quoteItem.serviceProvider === "" && quoteItem.quoteItemContentType === quoteItemContentType.freehand;
    }

    isFrame(quoteItem: QuoteItem) {

        return (quoteItem.serviceProvider === quoteSupplierProvider.v6 ||
            quoteItem.serviceProvider === quoteSupplierProvider.v7) && quoteItem.quoteItemContentType === supplierQuoteItemContentType.CID_FRAM;
    }

    getColumns(): any[] {
        //const readonly = this.quoteManager.isReadonly();

        const quoteItemLink = (value: string) => `<a class="quote-item-link" href="#" >${this.htmlEncode(value)}</a>`;
        //            !readonly
        //              ? `<a class="quote-item-link" href="#" >${this.htmlEncode(value)}</a>`
        //            : `<p>${this.htmlEncode(value)}</p>`;

        const cols = [
            {
                title: "Item#",
                width: "1px",
                data: "id",
                className: "quote-item-no",
                render: (_value: string, _type: never, _row: QuoteItem, meta: any) => {
                    return `${meta.row + 1}.`;
                }
            },
            {
                title: "Image",
                width: "50px",
                data: "id",
                className: "quote-item-image",
                render: (_value: string, _type: never, quoteItem: QuoteItem) => {
                    if (quoteItem.virtualThumbnailPath === "" && !this.isV7(quoteItem)) {
                        let src = "";
                        if (this.isShipping(quoteItem)) {
                            src = DeliveryThumbnailImage;
                        } else if (this.isFreeHand(quoteItem)) {
                            src = FreehandThumbnailImage;
                        }
                        return `<img class="quote-item-thumbnail" src='${src}' style="width:48px; height:48px" /> `;

                    }
                    ;

                    const src = this.quoteApi.api.fullUrl(`api/file/${quoteItem.virtualThumbnailPath}`);
                    if (_type === "filter") {
                        return "1";

                    } else if (_type === "display") {

                        let result = `<img class="quote-item-thumbnail" src='${src}' style="width:48px; height:48px" /> `;
                        if (this.isV7(quoteItem)) {
                            try {
                                const container = this.quoteManager.quoteItemContainer(quoteItem.id);
                                const itemConfigurationId = this.getConfigurationId(container);
                                result = `<configuration-thumbnail config-id="${itemConfigurationId}" style="width:48px; height:48px"></configuration-thumbnail> `;
                            } catch {
                                // the base64ToObject() can fail for quotes that just have the raw config id in providerData
                                // so we catch and absorb the exception to allow below code to have a go.
                                //NB this should not actually be a problem for prod. Just for testing site before 2022-08-15
                            }

                        }
                        return result;
                    } else return src;

                }

            },
            {
                title: "Title",
                width: "200px",
                data: "title",
                className: "quote-item-title",
                render: (data: any, _type: any, _row: any, _meta: any) => quoteItemLink(data)

            },
            {
                title: "Options",
                width: "200px",
                data: "description",
                className: "quote-item-description",
                render: (_data: any, _type: any, row: QuoteItem, _meta: any) => {
                    try {
                        const container = this.quoteManager.quoteItemContainer(row.id);

                        if (this.isFrame(row))
                            return this.generateQuoteItemOptionsView(container, this.dataTable.row(_meta.row).child.isShown());
                        else if (container.item.providerReferenceId === emptyGuid) {
                            return container.item.description;
                        } else return "";
                    } catch {
                        return "";
                    }
                }
            },
            {
                title: "Qty",
                width: "50px",
                data: "quantity",
                className: "quote-item-quantity"
            },
            {
                title: "Modified",
                width: "150px",
                data: "lastModifiedDate",
                className: "quote-item-modified",
                render: (value: string) => {
                    const dt = new Date(value);
                    return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`;
                }

            },
            {
                title: "Cost",
                width: "90px",
                data: "id",
                render: (_data: any, _type: any, row: QuoteItem, _meta: any) => {
                    return `<nominated-price-field field="GrossSellingPrice" item-id="${row.id}" />`;
                },
                className: "dt-right quote-item-price"
            },

            {
                title: "...",
                width: "32px",
                data: "id",
                orderable: false,
                className: "item-menu",
                render: (_value: string, _type: never, row: QuoteItem) => {
                    return this.ellipsisMenu(row);
                }
            }
        ];


        return cols;
    }

    useAutoWidthColumns(): boolean {
        return true;
    }

    ellipsisMenu(quoteItem: QuoteItem): string {
        // cannot delete if the quote is readonly, or the item is a shipping item
        const deleteMenu =
            this.quoteManager.isReadonly() || this.isShipping(quoteItem)
                ? ''
                : `<button type="button" class="multi-action-btn btn-circle">
                    <img class="icon  action-delete" src=${BinIcon}>
                   </button>`;
        const copyMenu =
            this.quoteManager.isReadonly() || this.isFreeHand(quoteItem) || this.isShipping(quoteItem)
                ? ''
                : `<button type="button" class="multi-action-btn btn-circle ">
                    <img class="icon action-copy" src=${CopyIcon}>
                   </button>`;

        const template = `
        <div class="multi-actions">
            <input type="checkbox" >
            <label>
                <button type="button" class="multi-action-btn btn-circle ">
                    <img class="icon action-properties" src=${AdjustIcon}>
                </button>

                ${copyMenu}
                ${deleteMenu}
                <span class="multi-action-btn btn-circle">
                    <img class="icon" src=${CloseIcon} onclick="checkClosest(this,false)">
                </span>
                <span class="icon" >
                    <img src=${EllipseIcon} onclick="checkClosest(this,true)">
                </span>
            </label>
        </div>`;
        return template;

    }

    generateQuoteItemOptionsView(quoteItemContainer: QuoteItemContainer, childVisible: boolean) {
        const validationIndicator = () => {
            const state = this.getItemValidationState(quoteItemContainer);
            switch (state) {
                case validationStyles.note:
                    return `<i class="fa-solid fa-message fa-2x me-3 text-primary"></i>`;
                case validationStyles.information:
                    return `<i class="fa-solid fa-circle-info fa-2x me-3 text-primary"></i>`;
                case validationStyles.warning:
                    return `<i class="fa-solid fa-triangle-exclamation fa-2x me-3 text-warning"></i>`;
                case validationStyles.critical:
                    return `<i class="fa-solid fa-circle-exclamation fa-2x me-3 text-danger"></i>`;
            }
            return ``;

        };
        if (childVisible)
            return `<span>${this.getOptionsOneLiner(quoteItemContainer)}</span><span class="float-end options-dropdown">${validationIndicator()}<i class="fa-solid fa-caret-up fa-2x"></i></span>`;
        else
            return `<span>${this.getOptionsOneLiner(quoteItemContainer)}</span><span class="float-end options-dropdown">${validationIndicator()}<i class="fa-solid fa-caret-down fa-2x"></i></span>`;
    }
    getItemValidationState(_quoteItemContainer: QuoteItemContainer): string {
        return "";
    }

    getOptionsOneLiner(_quoteItemContainer: QuoteItemContainer) {
        const configurationId = this.getConfigurationId(_quoteItemContainer);
        return `<st-quote-item-summary configuration-id="${configurationId}" ></st-quote-item-summary>`;
    }

    getFrameExtendedDetails(_quoteItemContainer: QuoteItemContainer) {
        const configurationId = this.getConfigurationId(_quoteItemContainer);
        return `<st-quote-item-summary configuration-id="${configurationId}" expanded ></st-quote-item-summary>`;
    }

    private getConfigurationId(_quoteItemContainer: QuoteItemContainer) {
        const providerData = _quoteItemContainer?.data?.providerData;
        let configurationId = -1;
        //configuration id should already exist here
        if (providerData) {
            try {
                const decodedProviderData = base64ToObject<V7ItemProviderContent>(providerData);
                if (decodedProviderData && decodedProviderData.configurationId) {
                    configurationId = decodedProviderData.configurationId;
                }
            } catch {
                // the base64ToObject() can fail for quotes that just have the raw config id in providerData
                // so we catch and absorb the exception to allow below code to have a go.
                //NB this should not actually be a problem for prod. Just for testing site before 2022-08-15
            }
        }
        return configurationId;
    }

    bindClickEvents($dataTable: any) {
        //Data tables uses JQuery click events to bind things. they dont have any other
        //special handler.

        // bind to all the quote-link classes to implement the quote open event
        $dataTable.on('click', '.quote-item-link',
            this.eventHandler(
                async (data: QuoteItem) => {
                    await this.openQuoteItem(data);
                }
            ));

        $dataTable.on('click', '.action-open',
            this.eventHandler(
                async (data: QuoteItem) => {
                    await this.openQuoteItem(data);
                }
            ));
        $dataTable.on('click', '.action-properties',
            this.eventHandler(
                async (data: QuoteItem) => {
                    await this.openQuoteItemProperties(data);
                }
            ));
        $dataTable.on('click', '.action-copy',
            this.eventHandler(
                async (data: QuoteItem) => {
                    await this.copyQuoteItem(data);
                }
            ));
        $dataTable.on('click', '.action-delete',
            this.eventHandler(
                async (data: QuoteItem) => {
                    await this.deleteQuoteItem(data);
                }
            ));

        $dataTable.on('click', '.options-dropdown',
            this.eventHandler(
                async (data: QuoteItem, _ev: any, row: any, tr: any) => {
                    if (row.child.isShown()) {
                        // This row is already open - close it
                        row.child.hide();
                        tr.removeClass('shown');
                        row.invalidate();
                    } else {
                        // Open this row
                        const container = this.quoteManager.quoteItemContainer(data.id);
                        const content = this.getFrameExtendedDetails(container);
                        if (!isEmptyOrSpace(content)) {
                            const className = (row.index() % 2 == 0) ? "odd" : "even";
                            row.child(content, className).show();
                            row.invalidate();
                            tr.addClass('shown');
                        }
                    }
                }
            ));


    }

    getUserDisplayValue(value: string) {
        if (value == emptyGuid) return "";
        //prefetch should already have been called
        return this.userCache.getLocal(value)?.displayValue ?? "";
    }

    protected ordering(): boolean {
        return false;
    }

    private async deleteQuoteItem(quoteItem: QuoteItem) {
        await this.eventRunQuoteItemAction?.(this.quoteManager.quoteItemContainer(quoteItem.id), quoteItemAction.delete);
    }

    private async copyQuoteItem(quoteItem: QuoteItem) {

        await this.eventRunQuoteItemAction?.(this.quoteManager.quoteItemContainer(quoteItem.id), quoteItemAction.copy);
    }

    private async openQuoteItem(quoteItem: QuoteItem) {
        await this.eventRunQuoteItemAction?.(this.quoteManager.quoteItemContainer(quoteItem.id), quoteItemAction.edit);
    }

    private async openQuoteItemProperties(quoteItem: QuoteItem) {
        await this.eventRunQuoteItemAction?.(this.quoteManager.quoteItemContainer(quoteItem.id), quoteItemAction.properties);
    }
}

export interface QuoteItemsViewOptions {
    quoteManager: QuoteContainerManager;
    userProfileCache: DataCacheGeneric;
    eventRunQuoteItemAction: EventQuoteItemContainerAction;
}

//saving buttons etc.
export class QuoteItemsView extends ViewBase {

    protected readonly quoteManager: QuoteContainerManager;
    protected table: QuoteItemTable;
    protected readonly eventRunQuoteItemAction: EventQuoteItemContainerAction;

    constructor(options: QuoteItemsViewOptions) {
        super();
        this.quoteManager = options.quoteManager;
        this.eventRunQuoteItemAction = options.eventRunQuoteItemAction;
        this.table = this.quoteItemTableFactory(options);
    }

    public async prepareForSave() {
        throw new DevelopmentError("quote-items-view, Method not implemented.");
    }

    getValidationErrors(): string[] {
        return [];
    }

    public async refreshData(): Promise<void> {
        //this is a force reload of the data. This is not something that we want to do, if items are inuse
        //as we would end up with a bad loading of data.
        await this.table.refreshData();
    }

    public async invalidate(): Promise<void> {
        await this.render();
        this.table.render();
        this.table.invalidate();
    }

    protected quoteItemTableFactory(options: QuoteItemsViewOptions): QuoteItemTable {
        return new QuoteItemTable({
            quoteManager: this.quoteManager,
            userProfileCache: options.userProfileCache,
            eventRunQuoteItemAction: this.eventRunQuoteItemAction,
        });
    }

    protected async template(): PromiseTemplate {
        return html`
            <div>
                ${this.table.ui}
            </div>`;
    }

}

globalThis.checkClosest = function (item: any, checked: boolean) {
    $(item).closest('.multi-actions').find('input').prop('checked', checked);
};
