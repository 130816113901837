import { ModalPickerDialog, ModalPickerDialogOptions } from "../../components/ui/modal-picker-base";
// eslint-disable-next-line import/named
import { html } from "lit";
import { NullPromise } from "../../null-promise";
import { ViewClientSummary } from "../../api/dealer-api-interface-client";
import { tlang } from "../../language/lang";
import { ClientSummaryPickerBrowser } from "./client-summary-picker";
import { PromiseTemplate } from "../../components/ui/events";

export async function ClientPicker(): NullPromise<ViewClientSummary> {
    return new Promise<ViewClientSummary | null>((resolve, reject) => {
        const picker = new ClientBrowserPicker({
            onSelect: (selected: ViewClientSummary | null) => {
                resolve(selected);
            },
            onCancel: () => reject(),
            title: tlang`Select a %%client%%`
        });
        picker.showModal();
    });
}

class ClientBrowserPicker extends ModalPickerDialog<ViewClientSummary> {
    private comms: ClientSummaryPickerBrowser;

    constructor(options: ModalPickerDialogOptions<ViewClientSummary>) {
        super(options);

        this.comms = new ClientSummaryPickerBrowser({
            title: () => options.title ?? "",
            onClientSelect: clientSummary => this.select(clientSummary)
        });
    }

    protected async bodyTemplate(): PromiseTemplate {
        this.comms.render();
        return html`${this.comms.ui}`;
    }
}