import { QuoteItem, QuoteState } from "../../api/dealer-api-interface-quote";
import { quoteItemContentType } from "../../quotes/data/default-quote-item-content-type";
import { quoteSupplierProvider } from "../../quotes/data/quoteSupplierProvider";
import { supplierQuoteItemContentType } from "../../quotes/data/supplier-quote-item-content-type";
import {
    ReportCompanyDetails,
    ReportProjectDetails,
    ReportPurchaseOrderState,
    ReportQuoteState
} from "../../api/dealer-api-interface-report";
import { PurchaseOrderState } from "../../api/dealer-api-interface-franchisee";
import { Project } from "../../api/dealer-api-interface-project";
import { GoogleMapsApi } from "../../components/ui/maps/google-maps-api";
import { googleMapApiKey } from "../../api/google-api";
import { getProjectNumberFormatted } from "../projects/data/project-helper-functions";
import { addressToString, emptyAddress } from "../../components/ui/maps/map-helpers";
import { userDataStore } from "../common/current-user-data-store";

export function getReportProjectDetails(project?: Project | null): ReportProjectDetails {
    const gMaps = new GoogleMapsApi();
    gMaps.apiKey = googleMapApiKey;

    const mapUrl = gMaps.getStaticMapUrl(Number(project?.defaultAddress.latitude ?? 0),
        Number(project?.defaultAddress.longitude ?? 0));

    return {
        title: project?.title ?? "",
        description: project?.description ?? "",
        number: project != null ? getProjectNumberFormatted(project) : "",
        mapUrl: mapUrl,
        deliveryInstruction: project?.shippingNotes ?? "",
        deliveryAddress: project?.defaultAddress ?? emptyAddress()
    };
}

export function getReportCompanyDetails(logoUrl: string | null = null): ReportCompanyDetails {
    const company = userDataStore.franchisee;
    const branch = userDataStore.defaultBranch;

    return {
        address: addressToString(branch?.physicalAddress ?? emptyAddress()),
        email: "<TBD>",
        name: company?.name ?? "",
        logoUrl: logoUrl ?? "",
        phone: "<TBD>",
        websiteUrl: "<TBD>"
    };
}

export function isShipping(quoteItem: QuoteItem) {
    return quoteItem.serviceProvider === "" && quoteItem.quoteItemContentType === quoteItemContentType.shipping;
}

export function isFreeHand(quoteItem: QuoteItem) {
    return quoteItem.serviceProvider === "" && quoteItem.quoteItemContentType === quoteItemContentType.freehand;
}

export function isFrame(quoteItem: QuoteItem) {
    return (quoteItem.serviceProvider === quoteSupplierProvider.v6 ||
        quoteItem.serviceProvider === quoteSupplierProvider.v7) && quoteItem.quoteItemContentType === supplierQuoteItemContentType.CID_FRAM;
}

export function quoteStateToReportState(state: QuoteState): ReportQuoteState {
    switch (state) {
        case QuoteState.None:
            return ReportQuoteState.None;
        case QuoteState.Draft:
            return ReportQuoteState.Draft;
        case QuoteState.Active:
            return ReportQuoteState.Active;
        case QuoteState.Issued:
            return ReportQuoteState.Issued;
        case QuoteState.Accepted:
            return ReportQuoteState.Accepted;
        case QuoteState.Rejected:
            return ReportQuoteState.Rejected;
        case QuoteState.Lapsed:
            return ReportQuoteState.Lapsed;
        case QuoteState.Cancelled:
            return ReportQuoteState.Cancelled;
    }
}

export function purchaseOrderStateToReportState(state: PurchaseOrderState): ReportPurchaseOrderState {
    switch (state) {
        case PurchaseOrderState.None:
            return ReportPurchaseOrderState.None;
        case PurchaseOrderState.Draft:
            return ReportPurchaseOrderState.Draft;
        case PurchaseOrderState.Issued:
            return ReportPurchaseOrderState.Issued;
        case PurchaseOrderState.Completed:
            return ReportPurchaseOrderState.Completed;
        case PurchaseOrderState.Cancelled:
            return ReportPurchaseOrderState.Cancelled;
    }
}
