const DBL_CLICK_RESPONSE_TIME = 350;

export class ClickActuator {
    private clickCount = 0;
    dblClickEvent: () => Promise<void>;
    clickEvent: () => Promise<void>;
    interval: NodeJS.Timeout | null = null;
    actuating = false;
    constructor(clickEvent: () => Promise<void>, dblclickEvent: () => Promise<void>) {
        this.clickEvent = clickEvent;
        this.dblClickEvent = dblclickEvent;
    }

    async click() {
        if (this.actuating) return;
        const actuateEvent = async () => {
            this.actuating = true;
            try {
                if (this.clickCount === 1) {
                    await this.clickEvent();
                    //
                } else {
                    await this.dblClickEvent();
                }
            } finally {
                this.clickCount = 0;
                this.interval = null;
                this.actuating = false;
            }
        };

        if (this.interval === null && this.clickCount === 0) {
            this.interval = setTimeout(actuateEvent, DBL_CLICK_RESPONSE_TIME);
        }
        this.clickCount++;
        if (this.clickCount == 2) {
            if (this.interval)
                clearTimeout(this.interval);
            this.interval = setTimeout(actuateEvent, 1);
        }
    }
}