import { NullPromise } from "../null-promise";
import { ApiCommunications } from "./api-communications";
import { PurchaseOrderApi } from "./purchase-order-api";
import {
    InputCreatePurchaseOrder,
    InputUpdatePurchaseOrder,
    RequestGetPurchaseOrder,
    RequestGetPurchaseOrders, RequestGetPurchaseOrderStateAudit,
    RequestGetPurchaseOrderTotalsByState,
    ResultCreatePurchaseOrder,
    ResultGetPurchaseOrder,
    ResultGetPurchaseOrders, ResultGetPurchaseOrderStateAudit,
    ResultGetPurchaseOrderTotalsByState,
    ResultUpdatePurchaseOrder
} from "./dealer-api-interface-franchisee";
import { tenantId } from "./current-user";

export class PurchaseOrderApiImplementation implements PurchaseOrderApi {
    api: ApiCommunications;
    private readonly purchaseOrderApiPath = "api/Franchisee/";

    constructor(api: ApiCommunications) {
        this.api = api;
    }

    async getPurchaseOrders(request: RequestGetPurchaseOrders): NullPromise<ResultGetPurchaseOrders> {
        return await this.post("GetPurchaseOrders", request);
    }

    async getPurchaseOrder(request: RequestGetPurchaseOrder): NullPromise<ResultGetPurchaseOrder> {
        return await this.post("GetPurchaseOrder", request);
    }

    async createPurchaseOrder(input: InputCreatePurchaseOrder): NullPromise<ResultCreatePurchaseOrder> {
        return await this.post("CreatePurchaseOrder", input);
    }

    async updatePurchaseOrder(input: InputUpdatePurchaseOrder): NullPromise<ResultUpdatePurchaseOrder> {
        return await this.post("UpdatePurchaseOrder", input);
    }

    async getTotalsByState(request: RequestGetPurchaseOrderTotalsByState): NullPromise<ResultGetPurchaseOrderTotalsByState> {
        return await this.post("GetPurchaseOrderTotalsByState", request);
    }

    private async post<T>(endpoint: string, request: any): NullPromise<T> {
        return await this.api.post(`${this.purchaseOrderApiPath}${endpoint}`, request);
    }

    async getPurchaseOrderStateAudit(request: RequestGetPurchaseOrderStateAudit): NullPromise<ResultGetPurchaseOrderStateAudit> {
        return await this.post("GetPurchaseOrderStateAudit", request);
    }

    createPurchaseOrderReportPath(purchaseOrderId: string): string {
        return `T${tenantId()}/services/franchisee/purchase-order/${purchaseOrderId}/report.pdf`;
    }
}