import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';


export const DimensionDirection = {
    leftOrDown: 0,
    both: 1,
    rightOrUp: 2,
    none: 3
};


@customElement('st-v6-dimension-setter')
export class V6DimensionPropertySelector extends LitElement {
    @property({ type: Number, reflect: true })
    direction = DimensionDirection.none;

    @property({ type: String, reflect: true })
    dimensionName = "";

    @property({ type: Boolean, reflect: true })
    allowLeftOrDown = true;
    @property({ type: Boolean, reflect: true })
    allowRightOrUp = true;
    @property({ type: Boolean, reflect: true })
    allowBoth = true;
    @property({ type: Boolean, reflect: true })
    allowLock = true;
    @property({ type: Boolean, reflect: true })
    locked = true;
    @property({ type: Boolean, reflect: true })
    readonly = true;

    changeDimensionEvent?: (e: Event) => void;

    protected createRenderRoot(): Element | ShadowRoot {
        return this;
    }
    private get isVertical(): boolean {
        return this.dimensionName.toLowerCase().includes("height");
    }
    render() {

        const disabled = (dir: number): boolean =>
            this.readonly ||
            (dir === DimensionDirection.leftOrDown && !this.allowLeftOrDown)
            || (dir === DimensionDirection.both && !this.allowBoth)
            || (dir === DimensionDirection.rightOrUp && !this.allowRightOrUp);
        const getButtonClass = (dir: number): string => {
            const state = disabled(dir)
                ? dir === this.direction ? " btn-secondary " : " btn-outline-secondary "
                : dir === this.direction ? " btn-primary " : " btn-outline-primary ";

            return `btn btn-v6-dimension ${state} me-1 ms-1`;
        };

        const lockTemplate = () => {
            const getLockButtonClass = () => {
                const state = this.locked ? "btn-primary" : "btn-outline-primary";
                return `btn btn-v6-dimension-lock btn-v6-dimension ${state} me-1 ms-1`;
            };
            const clickEvent = (e: Event) => {
                this.locked = !this.locked;
                this.changeDimensionEvent?.(e);
            };
            const disabled = !this.allowLock || this.readonly;
            return this.locked
                ? html`<button @click=${clickEvent} class=${getLockButtonClass()} ?disabled=${disabled}><i
        class="fa-solid fa-lock"></i></button>`
                : html`<button @click=${clickEvent} class=${getLockButtonClass()} ?disabled=${disabled}><i
        class="fa-solid fa-unlock"></i></button>`;
        };

        const setDirection = (_e: Event, value: number) => {
            this.direction = value;
            //  this.changeDimensionEvent?.(e);
        };
        return !this.isVertical
            ? html`
            <button @click=${(e)=> setDirection(e, DimensionDirection.leftOrDown)}
                class=${getButtonClass(DimensionDirection.leftOrDown)} ?disabled=${disabled(DimensionDirection.leftOrDown)}><i
                    class="fa-solid fa-arrow-left"></i></button>
            <button @click=${(e)=> setDirection(e, DimensionDirection.both)}
                class=${getButtonClass(DimensionDirection.both)} ?disabled=${disabled(DimensionDirection.both)}><i
                    class="fa-solid fa-arrows-left-right"></i></button>
            <button @click=${(e)=> setDirection(e, DimensionDirection.rightOrUp)}
                class=${getButtonClass(DimensionDirection.rightOrUp)} ?disabled=${disabled(DimensionDirection.rightOrUp)}><i
                    class="fa-solid fa-arrow-right"></i></button>${lockTemplate()}`

            : html`
            <button @click=${(e)=> setDirection(e, DimensionDirection.leftOrDown)}
                class=${getButtonClass(DimensionDirection.leftOrDown)} ?disabled=${disabled(DimensionDirection.leftOrDown)}><i
                    class="fa-solid fa-arrow-down"></i></button>
            <button @click=${(e)=> setDirection(e, DimensionDirection.both)}
                class=${getButtonClass(DimensionDirection.both)} ?disabled=${disabled(DimensionDirection.both)}><i
                    class="fa-solid fa-arrows-up-down"></i></button>
            <button @click=${(e)=> setDirection(e, DimensionDirection.rightOrUp)}
                class=${getButtonClass(DimensionDirection.rightOrUp)} ?disabled=${disabled(DimensionDirection.rightOrUp)}><i
                    class="fa-solid fa-arrow-up"></i></button>${lockTemplate()}`;
    }
    runClick(e: Event) {
        this.changeDimensionEvent?.(e);
    }
}
