// eslint-disable-next-line import/named
import { html, TemplateResult } from "lit";
import { ModalDialog } from "./modal-base";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { marked } from "marked";
import { tlang } from "../../language/lang";
import { PromiseTemplate } from "./events";


export interface OptionButton {
    caption: string;
    value: string;
    btnClass?: string;
}

export async function Information(message: string) {
    await AskOption(message, [{ caption: tlang`Ok`, value: "" }], tlang`Information`);
}

export async function AskOption(message: string, buttons: OptionButton[], title?: string): Promise<string> {
    const modal: OptionDialog = new OptionDialog(message, buttons, title);
    await modal.showModal();
    return modal.result;
}


// noinspection HtmlUnknownAttribute
class OptionDialog extends ModalDialog {
    message: string;
    result = "";
    buttons: OptionButton[];
    title: string;

    constructor(message: string, buttons: OptionButton[], title?: string) {
        super();
        this.title = title ?? tlang`Confirm`;
        this.message = message;
        this.buttons = buttons;

    }

    protected async getTitle(): Promise<string> {
        return this.title;
    }

    footerTemplate(): TemplateResult {
        return html`
        ${this.buttons.map(b => this.buttonTemplate(b))}
        `;
    }
    buttonTemplate(b: OptionButton): TemplateResult {
        const event = () => {
            this.result = b.value;
            this.hideModal();
        };
        const btnClass = "btn " + (b.btnClass ?? "btn-primary");
        return html`<button @click=${event} type="button" class=${btnClass}>${b.caption}</button>`;
    }

    async bodyTemplate(): PromiseTemplate {
        return html`
            ${unsafeHTML(marked.parse(this.message))}
        `;
    }

    protected modalSize(): string {
        return "modal-md";
    }
}