import { EventNotify } from "../components/ui/events";


export interface UserPublicInfo {
    id: string;
    tenantId: number;
    userName: string;
    friendlyName: string;
    emailAddress: string;
    tokenDateExpires: string; // of date
    Is2FAEnabled?: boolean;
    isSupplier?: boolean;
}

export interface TenantLoginPasswordChangeRequired {
    token: string;
}

export interface ResultTenantLogin {
    authenticationToken: string;
    publicInfo: UserPublicInfo;
    passwordChangeRequired?: TenantLoginPasswordChangeRequired;
    requires2FA: boolean;
    token2FA?: string;
}


let _profile: UserPublicInfo | null = null;
let _eventApplyUser: EventNotify | null = null;

export function setAfterApplyUserEvent(event: EventNotify | null) {
    _eventApplyUser = event;
}

export function tenantId(): number {
    return getCurrentUser()?.tenantId ?? 0;
}
export function getCurrentUser(): UserPublicInfo | null {
    if (!_profile) {
        const s = localStorage.getItem(apiUserName);
        if (!s) return null;
        _profile = JSON.parse(s) as UserPublicInfo;
    }
    return _profile;
}

export type UserStateNotifierEvent = (user: UserPublicInfo | null) => void | Promise<void>;

export interface CurrentUserStateNotifier {
    userStateChanged(user: UserPublicInfo | null): void | Promise<void>;
}
let callbackEvents: CurrentUserStateNotifier[] = [];

export function addCurrentUserEventListener(notifer: CurrentUserStateNotifier) {
    if (!callbackEvents.find(x => x === notifer)) {
        callbackEvents.push(notifer);
        //moving this to make the call in a timeout so that it is not happening in a constructor
        setTimeout(() =>
            notifer.userStateChanged(getCurrentUser()), 5);
    }
}

export function removeCurrentUserEventListener(notifer: CurrentUserStateNotifier) {
    if (callbackEvents.find(x => x === notifer)) {
        callbackEvents = callbackEvents.filter(x => x !== notifer);
    }
}


export async function setCurrentUser(user: UserPublicInfo | null): Promise<void> {
    _profile = user;
    if (_profile) {
        localStorage.setItem(apiUserName, JSON.stringify(_profile));
    }
    else {
        localStorage.removeItem(apiUserName);
        setApiToken(null);
    }
    await _eventApplyUser?.();
    callbackEvents.forEach(x => x.userStateChanged(user));
}

const apiTokenName = "dealer:v1:authentication-token";
const apiUserName = "dealer:v1:user-profile";

(function flushOldData() {
    //this routine can be deleted after a few builds. it is just to clean out old data
    localStorage.removeItem("dealer:authentication-token");
    localStorage.removeItem("dealer:user-profile");

})();


//Authentication is handled via an embedded inline authentication system which will apply any token recoved via authentication into
//this local location.
export function setApiToken(token: string | null) {

    if (token){
        localStorage.setItem(apiTokenName, token);
        window.softtech.siteConfig.authToken = token;
    }
    else
        localStorage.removeItem(apiTokenName);
}

export function getApiToken(): string {
    //always load token to ensure responsiveness to cache deletions
    const token = localStorage.getItem(apiTokenName);
    return token ?? "";

}
