import { TryAuthenticate } from "../authenticate-modal";
import { EventNotify } from "../components/ui/events";
import { NullPromise } from "../null-promise";
import { ServiceResponse, ServiceResponseInvalid, ServiceResponseType } from "../service_response";
import { ApiCommunications } from "./api-communications";
import { getApiToken, getCurrentUser, ResultTenantLogin, setApiToken, setCurrentUser } from "./current-user";
import { ValidationError } from "./validation-error";
import { FileResponse } from "./api-type-base-interface";

export type ServiceResponseHandler = (response: ServiceResponseInvalid | ValidationError[]) => Promise<void>;
export type ValidationErrorHandler = (errors: ValidationError[]) => Promise<void>;

export abstract class AuthApiCommunications implements ApiCommunications {
    protected redirectToLoginPage: EventNotify;
    protected invalidErrorResponseHandler?: ServiceResponseHandler;

    constructor(responseHandler: ServiceResponseHandler, redirectToLoginPage: EventNotify) {
        this.invalidErrorResponseHandler = responseHandler;
        this.redirectToLoginPage = redirectToLoginPage;
    }

    abstract get<T>(path: string): Promise<T | null>;
    abstract post<T>(path: string, data?: any): Promise<T | null>;
    abstract put<T>(path: string, data?: any): Promise<T | null>;
    abstract postFileDownload(path: string, data?: any): Promise<FileResponse | null>;
    abstract postRaw<T>(path: string, data?: any, authenticate?: boolean | undefined, baseUrl?: string | undefined): Promise<ServiceResponse<T>>;
    abstract fullUrl(path: string): string;

    protected getToken(): string {
        return getApiToken();
    }

    //the authentication workflow attempts to make a call as a passthrough. if a failure is based on being unauthenticated
    //then it will attempt to authentication invisibly to the caller and if succeeded retry the attempt for a clean workflow.
    //Any messages that expect to get validation errors back should pass in a local validation callback handler.
    protected async authenticationWorkflow<T>(callback: () => Promise<ServiceResponse<T>>): NullPromise<T> {
        let complete = false;
        while (!complete) {
            //run the actual api call as passed in
            const serviceResponse = await callback();

            //unauthorized calls are handled via the callback.
            if (serviceResponse.responseType == ServiceResponseType.UnAuthorized) {
                //for now, we will let the unauthorized state be globally propagated
                if (this.invalidErrorResponseHandler) {
                    await this.invalidErrorResponseHandler(serviceResponse);
                }
                return null;
            }
            else if (serviceResponse.responseType == ServiceResponseType.UnAuthenticated) {
                //run an asynchronous callback that can perform a login, and if it works
                //loop and try again.
                if (await this.performAuthenticationProcess()) {
                    // noinspection UnnecessaryContinueJS -- Added for clarity
                    continue;
                }
                else {
                    //throw the unauthenticated error back as a global presentation
                    if (this.invalidErrorResponseHandler) {
                        await this.invalidErrorResponseHandler(serviceResponse);
                    }
                    return null;
                }
            }
            else {
                if (serviceResponse.responseType === ServiceResponseType.Ok) {
                    complete = true;
                    return serviceResponse.result;
                } else if (serviceResponse.responseType === ServiceResponseType.ValidationFailure) {
                    //perform special error handling if there were validation errors coming back, which should
                    //only be used for validating data saves.
                    const errors = (serviceResponse as unknown as ServiceResponse<ValidationError[]>).result ?? [];
                    //call the validation handler if there is one.
                    if (this.invalidErrorResponseHandler) {
                        //pass the errors to the global handler, this is unexpected,
                        //but better than them being lost.
                        await this.invalidErrorResponseHandler(errors);
                    }
                }
                return null;
            }
        }
        return null;
    }

    public async performAuthenticationProcess(): Promise<boolean> {
        try {
            setApiToken(null);
            const result: ResultTenantLogin | null = await TryAuthenticate(this, getCurrentUser());
            if (getApiToken() == "") {
                //double testing as 2 asyncs can be stacking this.
                if (result && result.authenticationToken !== "" && result?.authenticationToken) {
                    setApiToken(result.authenticationToken);
                    result.publicInfo.Is2FAEnabled = result.requires2FA;
                    await setCurrentUser(result.publicInfo);
                    return true;
                }
            } else
                return true;
        } catch {
            setTimeout(() => {
                this.redirectToLoginPage();
            }, 5);
            return false;
        }
        return false;
    }

    protected async handleError<T>(response: ServiceResponse<T>): Promise<void> {
        if (response.responseType == ServiceResponseType.Error
            || response.responseType == ServiceResponseType.ModifyNotFound
            || response.responseType == ServiceResponseType.TransientDbError)
            if (this.invalidErrorResponseHandler) {
                await this.invalidErrorResponseHandler(response);
            }
    }
}


