import { ApiCommunications } from "./api-communications";
import { ReportApi } from "./report-api";
import { RequestGetPurchaseOrderReport, RequestGetQuoteReport, RequestGetCutPlanReport, RequestGetCutPlan2DReport, RequestGetCostedBomReport, RequestGetFabReport, RequestGetQuoteReportV2 } from "./dealer-api-interface-report";
import { NullPromise } from "../null-promise";
import { FileResponse } from "./api-type-base-interface";

export class ReportApiImplementation implements ReportApi {
    api: ApiCommunications;

    constructor(api: ApiCommunications) {
        this.api = api;
    }

    public async getPurchaseOrderReport(request: RequestGetPurchaseOrderReport): NullPromise<FileResponse> {
        return await this.api.postFileDownload('api/Reporting/GetPurchaseOrderReport', request);
    }

    public async getQuoteReport(request: RequestGetQuoteReport): NullPromise<FileResponse> {
        return await this.api.postFileDownload('api/Reporting/GetQuoteReport', request);
    }

    public async getCutPlanReport(request: RequestGetCutPlanReport): NullPromise<FileResponse> {
        return await this.api.postFileDownload('api/Reporting/GetCutPlanReport', request);
    }

    public async getCutPlan2DReport(request: RequestGetCutPlan2DReport): NullPromise<FileResponse> {
        return await this.api.postFileDownload('api/Reporting/GetCutPlan2DReport', request);
    }

    public async getCostedBomReport(request: RequestGetCostedBomReport): NullPromise<FileResponse> {
        return await this.api.postFileDownload('api/Reporting/GetCostedBomReport', request);
    }

    public async getFabReport(request: RequestGetFabReport): NullPromise<FileResponse> {
        return await this.api.postFileDownload('api/Reporting/GetFabReport', request);
    }

    public async getQuoteReportV2(request: RequestGetQuoteReportV2): NullPromise<FileResponse> {
        return await this.api.postFileDownload('api/Reporting/GetQuoteReportV2', request);
    }
}
