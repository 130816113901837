/**
 * Simple wrapper around structuredClone
 * @param item an object of any basic type to clone
 * @returns
 */
export function clone<ItemType>(item: ItemType): ItemType {
    return globalThis.structuredClone(item);
}

/**
 * Check if 2 objects are the same for comparing and original and a backup
 * TODO - NEEDS TESTING
 * @param obj1
 * @param obj2
 * @returns
 */
export function compare(obj1, obj2): boolean {
    function isLikeNull(o: any) {
        return o === undefined || o === null;
    }

    if (isLikeNull(obj1) && isLikeNull(obj2)) return true;
    if (isLikeNull(obj1) && !isLikeNull(obj2)) return false;
    if (!isLikeNull(obj1) && isLikeNull(obj2)) return false;
    const keys: string[] = [];
    for (const key in obj1) {
        keys.push(key);
    }
    for (const key in obj2) {
        if (!keys.find(v => v === key))
            keys.push(key);
    }

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const val = obj1[key];
        const val2 = obj2[key];
        if (Array.isArray(val)) {
            const failed = (val2.length !== val.length) || val.some((item, index) => {
                const same = compare(item, val2[index]);
                if (same)
                    return false;
                else
                    return true;
            });
            if (failed) return false;
        } else if (typeof val === "object" || typeof val2 === "object") {
            if (!compare(val, val2))
                return false;
        } else {
            const same = (obj1[key] === obj2[key]);
            if (!same)
                return false;
        }
    };
    return true;
}


export class Backup<TItem> {
    original: TItem | null;
    item: TItem | null;
    constructor(item: TItem | null = null) {
        this.original = item;
        this.item = clone(item);
    }
    backup(item: TItem | null = null) {
        this.original = item;
        this.item = clone(item);
    }
    commit() {
        if (this.changed() && this.original)
            Object.assign(this.original, this.item);
    }
    changed(): boolean { return !compare(this.original, this.item); }
}

export class DataProvider<TItem> {
    provider: TItem | (() => TItem);
    constructor(provider: TItem | (() => TItem)) {
        this.provider = provider;
    }
    get value(): TItem {
        if (typeof this.provider === "function")
            return (this.provider as any)();
        else
            return this.provider;
    }
}
export class ProviderBackup<TItem extends object> {

    provider: DataProvider<TItem>;
    item: TItem;
    constructor(provider: DataProvider<TItem>) {
        this.provider = provider;
        this.item = clone(this.provider.value);
    }
    get changed(): boolean {
        return !compare(this.provider.value, this.item);
    }
    resetProvider() {
        Object.assign(this.provider.value, this.item);
    }
    replaceItem(item: TItem, updateProvider = false) {
        this.item = item;
        if (updateProvider) this.resetProvider();
    }
    reset() {
        this.item = clone(this.provider.value);
    }
}