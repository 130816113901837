import { repeat } from 'lit/directives/repeat.js';
import { html } from 'lit';
import { V6FrameAttributeView } from './v6-frame-attribute';
import { PromiseTemplate } from '../../../components/ui/events';

export class V6AttributeDropDown extends V6FrameAttributeView {
    public async editTemplate(): PromiseTemplate {
        const d = this.attribute;
        const event = (e) => this.changeEvent(e);
        const label = this.fallback(d.codeDescription, d.code);
        //NOTE- the repeat clause is required when using SELECT objects because the select object once created keeps an internal state
        //not reflected in its attributes, so secondary renderings, are ignored and the correct value wont display
        //the repeat directive rebuilds the dom objects each render to fix this
        const readonly = this.viewOptions.readonly || this.attribute.isReadonly;
        const str = html`
        <select @focus=${() => this.editControlFocused()} ?readonly=${readonly}
            ?disabled=${this.readonly} class="form-select"
            @change=${event} .aria-label=${label} value=${d.value}>
            ${repeat(d.list?.listValues ?? [{ code: d.code, description: d.displayValue, imageReference: "" }], e => e,
            (input) =>
                html`<option value="${input.code}" ?selected=${input.code === d.value}>
                ${input.description}</option>`)}
        
        </select>`;
        return str;
    }
    protected isTarget(e: Event) { return e.target instanceof HTMLSelectElement; }

    //override in subclass
    protected getValue(e: Event): string {
        const select = (e.target as HTMLSelectElement);
        if (select.selectedIndex === -1) return this.attribute.defaultCode ?? "";
        return select.options[select.selectedIndex].value;
    }
}