// eslint-disable-next-line import/named
import { html, TemplateResult } from "lit";
import { getInternalId } from "./databinding/databinding";
import { PromiseSnippet, PromiseTemplate } from "./events";
import { hideModalDialog, showModalDialog } from "./modal-helper";
import { ViewBase } from "./view-base";


export class ModalDialog extends ViewBase {
    protected _hiding = false;
    protected _showing = false;
    protected _elementId = getInternalId();
    protected _modalSize = "modal-lg";

    async afterConstruction(): Promise<void> {
        //override to do something
    }

    /**
     *
     * @returns the name of this class, to be used to form a unique element id for building children in templates
     */
    protected name() {
        return "modal-dialog";
    }

    protected get elementId() {
        return `${this.name()}-${this._elementId}`;
    }

    protected onHideModal() {
        //override to do stuff
    }
    protected async onShowModal(): Promise<void> {
        //override to do stuff
    }

    public async showModal(): Promise<void> {
        await this.onShowModal();
        await this.render();
        this._showing = true;
        this._hiding = false;
        await showModalDialog(this.ui, this.ZIndex());
    }

    public async hideModal(): Promise<void> {
        this._hiding = true;
        if (this._showing) {
            this._showing = false;
            this.onHideModal();
            await hideModalDialog(this.ui);
        }
    }

    protected modalSize(): string {
        return this._modalSize;
    }

    protected ZIndex(): number | undefined {
        //Override as needed
        return undefined;
    }

    protected setFocusOnInputElementAndSelect(anyParentElementClass, elementPartialId : string){
        const parentSelector = '.'+ anyParentElementClass +'';
        const elementSelector = 'input[id^="' + elementPartialId + '"]';
        const parentElement = document.querySelector(parentSelector);
        if (parentElement) {
            const firstInputChild = parentElement.querySelector(elementSelector) as HTMLInputElement;

            if (firstInputChild) {
                firstInputChild.focus();
                firstInputChild.select();
            }
        }
    }

    protected closeButtonTemplate(): TemplateResult {
        const eventClose = async (e: Event) => {
            e.preventDefault();
            e.stopPropagation();
            await this.closeIfAllowed();
        };

        return html`<button type="button" class="btn-close" @click=${eventClose} aria-label="Close"></button>`;
    }
    protected async canClose(): Promise<boolean> {
        return true;
    }

    protected async closeIfAllowed(): Promise<boolean> {
        if (await this.canClose()) {
            await this.hideModal();
            return true;
        }
        return false;
    }
    protected renderFooterTemplate(): boolean {
        return this.footerTemplate() != null;
    }

    protected modalClasses(): string {
        return 'modal-dialog';
    }
    protected async template(): PromiseTemplate {
        const modalContentClasses = `modal-content shadow-lg border border-${this.borderType()}`;
        const modalClasses = `${this.modalClasses()} ${this.modalSize()}`;
        const footerToBeRendered = !this.renderFooterTemplate() ? html`` :
            html`
                <div class="modal-footer">
                    ${this.footerTemplate()}
                </div>`;

        return html`
            <div class="modal " data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
                <div class=${modalClasses}>
                    <div class=${modalContentClasses}>
                        <div class="modal-header">
                            <h2 class="modal-title">${await this.getTitle()}</h2>
                            <p class="btn-close-message">${this.getCloseMessage()}</p>
                            ${this.closeButtonTemplate()}
                        </div>
                        <div class="modal-body">
                            ${await this.bodyTemplate()}
                        </div>
                        ${footerToBeRendered}
                    </div>
                </div>
            </div>`;

    }
    borderType() {
        return "basic";
    }

    protected footerTemplate(): TemplateResult | null {
        return null;
    }
    protected async bodyTemplate(): PromiseTemplate {
        throw new Error("Method not implemented.");
    }
    protected async getTitle(): PromiseSnippet {
        return "Title";
    }
    protected getCloseMessage(): string {
        return '';
    }
}
