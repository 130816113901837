import { Project, ProjectState, ViewProjectSummary } from "../../../api/dealer-api-interface-project";

export const getProjectNumberFormatted = (p: ViewProjectSummary | Project) => {
    return `${p.number.toString().padStart(6, "0")}`;
};

export const getProjectTitle = (p: any) => {
    const project = p as Project;
    const projectNumber = getProjectNumberFormatted(project);
    return `${projectNumber} ${project.title} - ${ProjectState[project.state]}`;
};