import { ClientApi } from "../../api/client-api";
import { Client, Contact } from "../../api/dealer-api-interface-client";
import { clone, compare } from "../../components/clone";
import { EventNotify, EventSnippet } from "../../components/ui/events";
import { tlang } from "../../language/lang";
import { fireQuickSuccessToast } from "../../toast-away";
import { showDevelopmentError } from "../../development-error";
import { validId } from "../../components/ui/helper-functions";
import { NullPromise } from "../../null-promise";
import { newGuid } from "../../api/guid";
import { localDateToServer } from "../../components/datetime-converter";

export class ClientContainer {
    clientId: string;
    client: Client | null;
    primaryContactName: string;
    primaryContactEmail: string;

    constructor(clientId: string, client: Client | null, primaryContactName: string, primaryContactEmail: string) {
        this.clientId = clientId;
        this.client = client;
        this.primaryContactEmail = primaryContactEmail;
        this.primaryContactName = primaryContactName;
    }
}

export class ClientContainerManager {
    public container: ClientContainer;
    protected backup: ClientContainer;
    protected clientApi: ClientApi;
    protected title: EventSnippet;
    afterSave: EventNotify[] = [];

    constructor(clientContainer: ClientContainer, clientApi: ClientApi, title: EventSnippet) {
        if (clientContainer.client && clientContainer.client.id !== clientContainer.clientId) throw new Error(`Parameter mismatch between client and clientId`);

        this.container = clientContainer;
        this.clientApi = clientApi;
        this.backup = clone(this.container);
        this.title = title;
    }

    /**
     * the id for this managed container
     */
    public get clientId(): string {
        return this.container.clientId;
    }

    public get isNew(): boolean {
        return !validId(this.client.id);
    }
    /**
     * returns the client object after needsClient is called, or throws an error if the client is unavailable.
     */
    public get client(): Client {
        if (!this.container.client) {
            throw new Error(tlang`Client is null`);
        }
        return this.container.client;
    }

    public changed(): boolean {
        return !compare(this.container, this.backup);
    }

    public isReadonly(): boolean {
        return false;
    }

    public async saveClient(silently: boolean): Promise<boolean> {
        if (this.isReadonly()) {
            await showDevelopmentError("Trying to save readonly client");
            return false;
        }

        if (await this.internalSave()) {
            if (!silently)
                fireQuickSuccessToast(tlang`%%client%% Saved "${this.title()}"`);

            await this.doAfterSave();
            return true;
        }
        return false;
    }

    /**
     * Override this to customize the save logic.
     * @returns True if the save operations have succeeded, false otherwise.
     */
    protected async internalSave(): Promise<boolean> {
        const isNew = this.isNew;
        const result =
            isNew
                ? (await this.clientApi.createClient({ client: this.client }))?.client
                : (await this.clientApi.updateClient({ client: this.client }))?.client;
        let contactResult: Contact | null = null;
        if (result && isNew) {
            contactResult = (await this.clientApi.createContact({
                contact: {
                    id: newGuid(),
                    name: this.container.primaryContactName,
                    email: this.container.primaryContactEmail,
                    dateCreated: localDateToServer(new Date()),
                    clientId: result.id,
                    recordVersion: "",
                    title: tlang`New %%contact%%`,
                    businessPhone: "",
                    mobile: ""
                },
                isPrimary: true
            }))?.contact ?? null;
        }
        if (result) {
            this.resetClient(result, contactResult ?? (await this.getPrimaryContact(result.id)));
            return true;
        }
        return false;
    }

    public async needsClient() {
        if (!this.container.client) {
            const result = await this.clientApi.getClient({ clientId: this.clientId });
            if (result) {

                this.resetClient(result.client, await this.getPrimaryContact(result.client.id));
            } else return false;
        }
        return true;
    }
    protected async getPrimaryContact(_clientId: string): NullPromise<Contact> {
        return null;
    }

    private resetClient(client: Client, contact: Contact | null) {
        this.container.client = client;
        this.container.primaryContactName = contact?.name ?? "";
        this.container.primaryContactEmail = contact?.email ?? "";
        this.backup.client = clone(client);
        this.backup.primaryContactName = contact?.name ?? "";
        this.backup.primaryContactEmail = contact?.email ?? "";
    }

    /**
     * execute all bound events after any save operation to allow for re-rendering and refreshing of state
     */
    private async doAfterSave(): Promise<void> {
        this.afterSave.forEach(async (afterSaveEvent) => await afterSaveEvent());
    }
}