import {
    PurchaseOrderContainer,
    PurchaseOrderContainerManager
} from "../../purchase-orders/data/purchase-order-container";
import { PurchaseOrderView, PurchaseOrderViewOptions } from "../../purchase-orders/views/purchase-order-view";
import { constructAsync } from "../../async-constructor";
import { FranchiseeV6PurchaseOrderView } from "./views/v6/franchisee-v6-purchase-order-view";
import { getQuoteContainerManager } from "../quotes/quote-ui-adapter";
import { FranchiseeV6SupplierQuote } from "../quotes/data/v6/v6-franchisee-supplier-quote";
import { currentQuoteSupplierProvider, quoteSupplierProvider } from "../../quotes/data/quoteSupplierProvider";
import { DevelopmentError } from "../../development-error";
import { getProjectContainerManager } from "../projects/project-ui-adapter";
import { cache } from "../cache/cache-registry";

export async function createSupplierQuote(quoteId: string): Promise<string> {
    const quoteContainerManager = getQuoteContainerManager(quoteId);
    const prlcache = cache().projectResourceLink;
    const datalink = (await prlcache.getData(quoteId));
    if (!datalink) return "";
    const projectCM = getProjectContainerManager(datalink.projectId);
    const orderCM = getPurchaseOrderContainerManager(datalink.purchaseOrderId);


    if (currentQuoteSupplierProvider === quoteSupplierProvider.v6) {
        const creator = await constructAsync(new FranchiseeV6SupplierQuote(projectCM, orderCM, quoteContainerManager)); // todo, add the home base supplier
        //const _result =
        await creator.run();
        return "THIS SHOULD BE A UNIQUE TOKEN TO LISTEN FOR A RESPONSE";
    } else throw new DevelopmentError("createSupplierQuote not implemented");

}

export function getPurchaseOrderContainer(id: string): PurchaseOrderContainer {
    return new PurchaseOrderContainer(id,
        null, null);
}

export function getPurchaseOrderContainerManager(id: string | PurchaseOrderContainer): PurchaseOrderContainerManager {
    const container = id instanceof PurchaseOrderContainer ? id : getPurchaseOrderContainer(id);
    return new PurchaseOrderContainerManager(container);
}

export async function purchaseOrderViewFactory(options: PurchaseOrderViewOptions): Promise<PurchaseOrderView> {
    // Need to Add V7 views
    return await constructAsync(new FranchiseeV6PurchaseOrderView(options));
}