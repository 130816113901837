import { ResourceType, ViewResourceLink } from "../../api/dealer-api-interface-project";
import { emptyGuid } from "../../api/guid";

export class ProjectResourceLink {
    link: ViewResourceLink;
    constructor(link: ViewResourceLink) {
        this.link = link;
    }

    get projectId(): string {
        return this.link.projectId;
    }
    get hasParent(): boolean {
        const nullParent = !this.link.parent;
        return !nullParent;
    }
    get parentId(): string {
        return this.link.parent?.resourceId ?? emptyGuid;
    }
    get parentType(): ResourceType {
        return this.link.parent?.resourceType ?? ResourceType.None;
    }
    get resourceId(): string {
        return this.link.resourceId;
    }
    get resourceType(): ResourceType {
        return this.link.resourceType;
    }
    childId(type: ResourceType): string {
        return this.link.childResources.find(x => x.resourceType === type)?.resourceId ?? emptyGuid;
    }

    get purchaseOrderId(): string {
        if (this.resourceType === ResourceType.PurchaseOrder) return this.resourceId;
        if (this.hasParent && this.parentType == ResourceType.PurchaseOrder) return this.parentId;
        return this.childId(ResourceType.PurchaseOrder);
    }
    get quoteId(): string {
        if (this.resourceType == ResourceType.Quote) return this.resourceId;
        if (this.hasParent && this.parentType == ResourceType.Quote) return this.parentId;
        return this.childId(ResourceType.Quote);
    }
}

export function projectLink(v: ViewResourceLink): ProjectResourceLink {
    return new ProjectResourceLink(v);
}