import { NullPromise } from "../null-promise";
import { ApiCommunications } from "./api-communications";
import { FrameEngineApi } from "./frame-engine-api";
import { ResultAttributeSummary } from "./frame-engine-api-interface";

export class FrameEngineApiImplementation implements FrameEngineApi {
    readonly api: ApiCommunications;

    constructor(api: ApiCommunications) {
        this.api = api;
    }

    async getConfigurationAttributeSummary(configurationId: any): NullPromise<ResultAttributeSummary> {
        const url = `v1/Configurations/${configurationId}/Elements/Frames/Attributes/Summary`;
        return await this.api.get<ResultAttributeSummary>(url);
    }


}
