import { BranchQuote } from "../../../api/dealer-api-interface-franchisee";
import {
    Quote,
    QuoteItem,
    QuoteItemPrice,
    QuoteItemProviderData,
    QuotePresentation,
    QuotePrice
} from "../../../api/dealer-api-interface-quote";
import { QuoteContainer } from "../../../quotes/data/quote-container";
import { QuoteProviderData } from "../../../quotes/data/quote-provider-data";

export class FranchiseeQuoteContainer extends QuoteContainer {
    branchQuote: BranchQuote | null;

    constructor(quoteId: string,
        quote: Quote | null,
        quotePrice: QuotePrice | null,
        quotePresentation: QuotePresentation | null,
        items: QuoteItem[] | null,
        itemsData: QuoteItemProviderData[] | null,
        itemPrices: QuoteItemPrice[] | null,
        branchQuote: BranchQuote | null,
        quoteProviderData: QuoteProviderData | null = null,
        isNewQuote: boolean = false) {
        super(quoteId, quote, quotePrice, quotePresentation, items, itemsData, itemPrices, quoteProviderData, isNewQuote);
        this.branchQuote = branchQuote;
    }
}
