import { ApiCommunications } from "../api/api-communications";
import { DataCache } from "./data-cache";
import { NullPromise } from "../null-promise";
import { isEmptyOrSpace } from "../components/ui/helper-functions";
import { DevelopmentError } from "../development-error";
import { DateTime } from "luxon";
import { ItemReference } from "./definitions/cache-item-reference";


export class DataCacheBase<DataType> implements DataCache<DataType> {
    api: ApiCommunications;
    cache: ItemReference<DataType>[] = [];
    expiryMinutes: number;
    constructor(api: ApiCommunications, cacheItemExpiryMinutes = 10) {
        this.api = api;
        this.expiryMinutes = cacheItemExpiryMinutes;
    }

    async getData(id: string | null): NullPromise<DataType> {
        const item = await this.get(id);
        return item?.data as DataType;
    }

    async get(id: string | null): NullPromise<ItemReference<DataType>> {
        if (isEmptyOrSpace(id) || !id) return null;
        const item = this.getLocal(id);
        if (item) return item;
        const items = await this.getMany([id]);
        if (items && items.length === 1) return items[0];
        return null;
    }
    getLocalData(id: string | null | undefined): DataType | null {
        const item = this.getLocal(id);
        return item?.data as DataType;
    }

    getLocal(id: string | null | undefined): ItemReference<DataType> | null {
        if (!id) return null;
        const result = this.cache.find(x => x.id === id);
        if (result) {
            if ((result.cacheInsertionDate?.diffNow().minutes ?? 0) > this.expiryMinutes) {
                this.removeLocal(id);
                return null;
            }
        }
        return result ?? null;
    }

    flush(ids: string[] | null) {
        if (!ids)
            this.cache = [];
        else
            ids.forEach(id => this.removeLocal(id));
    }

    async getMany(ids: string[]): NullPromise<ItemReference<DataType>[]> {
        const result: ItemReference<DataType>[] = [];
        const requestIds: string[] = [];
        ids.forEach(id => {
            if (!isEmptyOrSpace(id)) {
                const item = this.getLocal(id);
                if (!item)
                    requestIds.push(id);
                else
                    result.push(item);
            }
        });
        if (requestIds.length > 0) {
            const items = await this.internalFetch(requestIds);
            if (items) {
                this.addToCache(items);
                result.push(...items);
            }
        }
        return result;
    }
    protected addToCache(items: ItemReference<DataType>[] | null | undefined) {
        if (items) {
            const now = DateTime.now();
            items.forEach(x => x.cacheInsertionDate = now);
            this.cache.push(...items);
        }
    }
    //override this to get and transform the data
    protected async internalFetch(_requestIds: string[]): NullPromise<ItemReference<DataType>[]> {
        throw new Error("Method not implemented.");
    }

    getManyLocal(ids: string[]): ItemReference<DataType>[] | null {
        const result: ItemReference<DataType>[] = [];
        ids.forEach(id => {
            const item = this.getLocal(id);
            if (item)
                result.push(item);
            else
                throw new DevelopmentError("Cache.getManyLocal called and item is missing");
        });
        return result;
    }

    async preFetch(ids: string[]): Promise<void> {
        await this.getMany(ids);
    }

    removeLocal(id: string) {
        const index = this.cache.findIndex(x => x.id == id);

        if (index >= 0)
            this.cache.splice(index, 1);
    }

    async updateLocal(id: string) {
        const index = this.cache.findIndex(x => x.id == id);

        // If index is -1, then we do not have it in local cache, and need to fetch it
        if (index < 0)
            await this.get(id);
        else {
            // Internal fetch gets the item, but doesn't add it to the cache yet.
            const item = await this.internalFetch([id]);

            // We should only get one back (we are requesting 1 ID
            if (item?.length == 1) {
                this.cache[index] = item[0];
            }
        }
    }
}