//this will be the view for a single quote, which
//will have many tabs for details quote items, etc.
//as a multi tabbed layout

// eslint-disable-next-line import/named
import { html } from "lit";
import { MenuIconOption, PageControl, PageControlOptions, PageManager } from "../../components/ui/page-control";
import { QuoteApi } from "../../api/quote-api";
import { QuoteDetailView, QuoteDetailViewOptions } from "./quote-detail-view";
import { QuoteItemsView as QuoteItemsView, QuoteItemsViewOptions } from "./quote-items-view";
import { EventGetReference, EventSnippet, PromiseSnippet, PromiseTemplate } from "../../components/ui/events";
import { QuoteItemView, QuoteItemViewOptions } from "./quote-item-view";
import { QuoteItemContainer } from "../data/quote-item-container";
import { QuoteItemFrameViewForV6 } from "../v6/v6-quote-item-frame-view";
import { QuoteContainer, QuoteContainerManager } from "../data/quote-container";
import { NullPromise } from "../../null-promise";
import { fireQuickErrorToast, fireQuickSuccessToast, fireQuickWarningToast } from "../../toast-away";
import { DevelopmentError, showDevelopmentError } from "../../development-error";
import { tlang } from "../../language/lang";
import { quoteItemAction } from "../data/events";
import { showError } from "../../components/ui/show-error";
import { quoteItemContentType } from "../data/default-quote-item-content-type";
import { ShippingLineItemView } from "./shipping-line-item-view";
import { FreehandLineItemView } from "./freehand-line-item-view";
import { currentQuoteSupplierProvider, quoteSupplierProvider } from "../data/quoteSupplierProvider";
import { getApiFactory } from "../../api/api-injector";
import { QuoteSupplierDefaultsView, QuoteSupplierDefaultsViewOptions } from "./quote-supplier-defaults.view";
import { QuoteState } from "../../api/dealer-api-interface-quote";
import { isAutoSaving } from "../../components/save-workflow";
import { DataEntryOwner, DataEntryPageControlView, ModalViewBase } from "../../components/ui/data-entry-screen-base";
import { constructAsync } from "../../async-constructor";
import { DataCacheGeneric } from "../../cache/generic-data-cache";
import { QuoteSupplierGlassView, QuoteSupplierGlassViewOptions } from "./quote-supplier-glass-view";
import { supplierQuoteItemContentType } from "../data/supplier-quote-item-content-type";
import { WaitPatientlyLoading } from "../../components/ui/modal-loading";


export interface QuoteViewOptions {
    quoteContainerManager: QuoteContainerManager;
    title: EventSnippet;
    customerCache: DataCacheGeneric;
    userCache: DataCacheGeneric;
    ownerId: EventGetReference;
}

export interface QuoteViewChildFactory {
    getDetailView(options: QuoteDetailViewOptions): QuoteDetailView;
    getItemsView(options: QuoteItemsViewOptions): QuoteItemsView;
    getSupplierDefaultsView(options: QuoteSupplierDefaultsViewOptions): QuoteSupplierDefaultsView;
    getSupplierGlassView(options: QuoteSupplierGlassViewOptions): QuoteSupplierGlassView;
    getSupplierQuoteItemView(contentType: number, options: QuoteItemViewOptions): QuoteItemView;
}

export class QuoteViewChildFactoryImpl implements QuoteViewChildFactory {
    parent: QuoteDataEntryView;
    constructor(parent: QuoteDataEntryView) {
        this.parent = parent;
    }
    getDetailView(options: QuoteDetailViewOptions): QuoteDetailView {
        return new QuoteDetailView(options);
    }
    getItemsView(options: QuoteItemsViewOptions): QuoteItemsView {
        return new QuoteItemsView(options);
    }
    getSupplierDefaultsView(options: QuoteSupplierDefaultsViewOptions): QuoteSupplierDefaultsView {
        return new QuoteSupplierDefaultsView(options);
    }
    getSupplierGlassView(options: QuoteSupplierGlassViewOptions): QuoteSupplierGlassView {
        return new QuoteSupplierGlassView(options);
    }
    getSupplierQuoteItemView(_contentType: number, options: QuoteItemViewOptions): QuoteItemView {
        return new QuoteItemView(options);
    }

}
//Quote view encloses the entire Quote edit and view process
// contains detail view
// will contained default settings view
// quote items list.

export class QuoteDataEntryView extends DataEntryPageControlView {
    quoteApi: QuoteApi = getApiFactory().quote();
    public get quoteContainer(): QuoteContainer {
        return this.quoteContainerManager.container;
    };
    quoteContainerManager: QuoteContainerManager;
    initPromises: Promise<any>[] = [];
    ownerId: EventGetReference;
    protected title: EventSnippet;
    public userProfileCache: DataCacheGeneric;
    protected blobApi = getApiFactory().blob();
    protected clientApi = getApiFactory().client();
    protected quoteViewChildFactory: QuoteViewChildFactory;
    protected detailView: QuoteDetailView;
    protected itemsView: QuoteItemsView;
    protected supplierQuoteDefaultsView: QuoteSupplierDefaultsView;
    protected readonly maxItemPageAllowed: number = 1;
    protected supplierGlassView: QuoteSupplierGlassView | null;

    constructor(options: QuoteViewOptions, owner: DataEntryOwner) {
        super(owner);
        this.quoteViewChildFactory = this.getQuoteViewChildFactory();
        this.quoteContainerManager = options.quoteContainerManager;
        this.title = options.title;
        this.userProfileCache = options.userCache;
        this.ownerId = options.ownerId;

        //UI only created after building quoteContainerManager
        this.detailView = this.quoteViewChildFactory.getDetailView({
            quoteManager: this.quoteContainerManager,
            canGenerateReport: async () => {
                // The same checking which should be performed for allowPageSwitch, should also be performed
                // to allow generating a report.
                return await this.allowPageSwitch();
            }
        });
        this.itemsView = this.quoteViewChildFactory.getItemsView({
            quoteManager: this.quoteContainerManager,
            userProfileCache: this.userProfileCache,
            eventRunQuoteItemAction: async (quoteItemContainer: QuoteItemContainer, action: number) => await this.runQuoteItemAction(quoteItemContainer, action)
        });
        this.supplierQuoteDefaultsView = this.quoteViewChildFactory.getSupplierDefaultsView({ quoteManager: this.quoteContainerManager });

        this.supplierGlassView = this.hasSupplierGlassView()
            ? this.quoteViewChildFactory.getSupplierGlassView({
                quoteManager: this.quoteContainerManager,
                forceReadonly: () => this.glassViewReadonly()
            })
            : null;

        this.quoteContainerManager.afterSave.push(async () => {
            await this.detailView.render();
            await this.itemsView.refreshData();
            await this.itemsView.render();
            await this.render();
        });
    }
    protected glassViewReadonly(): boolean {
        return this.itemPagesOpenCount > 0;
    }
    protected getQuoteViewChildFactory(): QuoteViewChildFactory {
        return new QuoteViewChildFactoryImpl(this);
    }

    protected hasSupplierGlassView(): boolean {
        return false;
    }
    protected get itemPagesOpenCount(): number {
        let count = 0;
        this._pageControl?.pages.forEach(page => {
            if (page.data instanceof QuoteItemView) {
                count++;
            }
        });
        return count;
    }

    async closeQuoteItemPages(): Promise<void> {
        for (const page of this.pageControl.pages) {
            if (page.data instanceof QuoteItemView) {
                await this.pageControl.closePage(page);
            }
        }
    }

    protected isNew(): boolean {
        return this.quoteContainer.isNewQuote;
    }

    /**
     * inherited
     */
    public async afterConstruction() {
        await this.quoteContainerManager.needsQuote();
        if (this.supplierGlassView)
            this.initPromises.push(constructAsync(this.supplierGlassView));

        this.initPromises.push(constructAsync(this.supplierQuoteDefaultsView));
        //call the sub renders so that save routines will work, even if we have not viewed that page
        await this.detailView.render();
        await this.itemsView.render();
        //this will create the page control
        await super.afterConstruction();
        this.buildQuoteActionMenu();

    }
    async waitInit(): Promise<void> {
        const w = new WaitPatientlyLoading(() => tlang`Initializing %%quote%% data`);
        try {
            await Promise.allSettled(this.initPromises);
        } finally {
            await w.hideModal();
        }
    }

    async addNewQuoteItemFreehand(): Promise<boolean> {
        if (this.isReadonly()) return false;
        if (!(await this.allowPageSwitch())) return false;
        const providerType = "";
        const quoteItemView = await this.quoteItemViewFactory(null, providerType, quoteItemContentType.freehand);
        try {
            if (quoteItemView) {
                await quoteItemView.prepareEditor();
                if (quoteItemView.readyToEdit) {
                    await this.launchQuoteItemView(quoteItemView);
                    return true;
                }
                return false;
            }
        } catch (e) {
            await showError(e as Error);
            return false;
        }
        return false;
    }

    async addNewQuoteItemCustom(): Promise<boolean> {
        if (this.isReadonly()) return false;
        if (!(await this.allowPageSwitch())) return false;
        const providerType = quoteSupplierProvider.v7;
        await this.closeQuoteItemPages();

        const quoteItemView = await this.quoteItemViewFactory(null, providerType, supplierQuoteItemContentType.CID_V7_CUSTOM_FRAM);
        try {
            if (quoteItemView) {
                await quoteItemView.prepareEditor();
                if (quoteItemView.readyToEdit) {
                    await this.launchQuoteItemView(quoteItemView);
                    return true;
                }
            }
        } catch (e) {
            await showError(e as Error);
            return false;
        }
        return false;
    }

    /**
     * inherited;
     * @returns
     */
    public async prepareForSave(): Promise<void> {
        await this.waitInit();
        if (this.isReadonly()) return;
        await this.supplierQuoteDefaultsView.prepareForSave();
        await this.detailView.prepareForSave();
        await this.itemsView.prepareForSave();
        await this.supplierGlassView?.prepareForSave();

    }

    async performQuoteItemsViewRefresh(): Promise<void> {
        if (this.itemPagesOpenCount == 0) {
            await this.quoteContainerManager.needsQuoteItems(true);
            await this.itemsView.refreshData();
            fireQuickSuccessToast(tlang`!!quote-item!! refreshed`);
        } else
            fireQuickWarningToast(tlang`Please close all open %%quote-item%% pages before refreshing this page`);
    }

    createQuoteItemSummaryPage(): PageManager {
        return {
            caption: () => tlang`!!quote-item!!`,
            canClose: () => Promise.resolve(false),
            canLeave: async () => await this.allowPageSwitch(),
            hasDelete: () => false,
            onEnter: async () => {
                await this.itemsView.invalidate();
            },
            content: () => {
                return this.itemsView.ui;
            },
            buttonMenu: () => {
                return this.detailView.buttonMenu();
            },

            data: this.itemsView
        };
    }

    async getQuoteItemViewOptions(quoteItemContainer: QuoteItemContainer | null): Promise<QuoteItemViewOptions> {
        if (!this.quoteContainer.quote) {
            throw new DevelopmentError("quote-view, quote is null");
        }
        return {
            supplierId: this.quoteContainer.quote.supplierId,
            quoteManager: this.quoteContainerManager,
            quoteItemContainer: quoteItemContainer,
        };
    }

    /**
     * inherited;
     * @returns
     */
    public internalDataChanged(): boolean {
        return this.quoteContainerManager.changed();
    }

    /**
     * inherited
     * @returns
     */
    public getDataDictionaryName(): string {
        return '%%quote%%';
    }

    /**
     * inherited
     * @returns
     */
    public isReadonly(): boolean {
        return this.quoteContainerManager.isReadonly();
    }

    public async getTitle(): PromiseSnippet {
        return this.title(this.quoteContainer.quote);
    }

    protected async bodyTemplate(): PromiseTemplate {
        this.buildQuoteActionMenu();
        return super.bodyTemplate();
    }



    public async runQuoteItemAction(quoteItemContainer: QuoteItemContainer, action: number): Promise<void> {
        //add action switch
        switch (action) {
            case quoteItemAction.edit:
                await this.internalOpenQuoteItemForEdit(quoteItemContainer);
                break;
            case quoteItemAction.properties:
                await this.internalOpenQuoteItemPropertyDialog(quoteItemContainer);
                break;
            case quoteItemAction.copy:
                await this.internalCopyQuoteItem(quoteItemContainer);
                break;
            case quoteItemAction.delete:
                await this.internalDeleteQuoteItem(quoteItemContainer);
                break;
            default:
                fireQuickWarningToast(tlang`${action} is not supported yet`);

        }
    }

    /**
     * inherited;
     * @returns
     */
    protected createPageControl(): PageControl {
        // build static pages for each of the configured table settings
        const getInitialPageManagers = (): PageManager[] => {
            const pages: PageManager[] = [];
            pages.push(this.createDetailPage());
            if (this.hasSupplierGlassView())
                pages.push(this.createSupplierGlassPage());
            pages.push(this.createQuoteItemSummaryPage());
            return pages;

        };

        const options: PageControlOptions = {
            defaultTabIndex: 0,
            menuIcons: undefined,
            pageInitializer: () => getInitialPageManagers(),
            events: { onDeleteTab: async (page: PageManager, index: number) => await this.tabDeletedEvent(page, index) }
        };
        return new PageControl(options);
    }
    async tabDeletedEvent(_page: PageManager, _index: number): Promise<void> {
        //override if needed
    }

    protected createSupplierGlassPage(): PageManager {
        return {
            caption: () => tlang`!!quote-igu!!`,
            canClose: () => Promise.resolve(false),
            canLeave: async () => await this.allowPageSwitch(),
            hasDelete: () => false,
            onEnter: async () => {
                await this.waitInit();
                if (!this.supplierGlassView) throw new DevelopmentError("supplierGlassView is null");

                //just display a message. we will make it readonly
                await this.checkAllFramesClosed();
                await this.supplierGlassView?.render();
            },
            content: () => {
                if (!this.supplierGlassView) throw new DevelopmentError("supplierGlassView is null");
                return this.supplierGlassView.ui;
            },
            buttonMenu: () => {
                if (!this.supplierGlassView) throw new DevelopmentError("supplierGlassView is null");
                return this.supplierGlassView.buttonMenu();
            },
            data: this.supplierGlassView
        };
    }
    protected async checkAllFramesClosed(): Promise<boolean> {
        if (this.itemPagesOpenCount > 0) {
            fireQuickWarningToast(tlang`please close all !!quote-item!! before editing !!quote-igu!!`);
            return false;
        }
        return true;
    }

    /**
     * inherited
     * @returns
     */
    protected async internalSaveData(): Promise<boolean> {
        //this will populate the managed quote with data from the UI
        return await this.quoteContainerManager.saveQuote(isAutoSaving());
    }

    /**
     *
     * @returns inherited
     */
    protected getValidationErrors(): string[] {
        //prepare for save should always be called first
        return [...this.detailView.getValidationErrors(),
        ...this.itemsView.getValidationErrors(),
        ...(this.supplierGlassView?.getValidationErrors() ?? [])];
    }

    protected async addNewQuoteItemFrame(): Promise<boolean> {
        if (this.isReadonly()) return false;
        if (!(await this.allowPageSwitch())) return false;
        if (!(await this.canAddItemFrame())) return false;
        if (this.itemPagesOpenCount >= this.maxItemPageAllowed) {
            fireQuickWarningToast(tlang`a maximum of ${this.maxItemPageAllowed} pages are allowed open, please close some before creating a new %%quote-item%%`);
            return false;
        }

        const providerType = await this.selectService();
        const quoteItemView = await this.quoteItemViewFactory(null, providerType, supplierQuoteItemContentType.CID_FRAM);
        try {
            if (quoteItemView) {
                await quoteItemView.prepareEditor();
                if (quoteItemView.readyToEdit) {
                    await this.launchQuoteItemView(quoteItemView);
                    return true;
                }
                return false;
            }
        } catch (e) {
            await showError(e as Error);
            return false;
        }

        return false;
    }

    protected async canAddItemFrame(): Promise<boolean> {
        return true;
    }

    protected async launchQuoteItemView(quoteItemView: QuoteItemView | null): Promise<void> {
        if (quoteItemView) {
            const page = quoteItemView.isTab() ? quoteItemView.createPageManager() : null;
            if (page) {
                if (this.itemPagesOpenCount >= this.maxItemPageAllowed) {
                    fireQuickWarningToast(tlang`a maximum of ${this.maxItemPageAllowed} pages are allowed open`);
                    return;
                }
                this.pageControl.addPage(page);
            } else if (quoteItemView.hasModalEditDialog) {
                // a fallback for items that have a property dialog, but no page control.
                await quoteItemView.executeModalEditDialog();
            } else if (quoteItemView.hasPropertyDialog) {
                // a fallback for items that have a property dialog, but no page control.
                await quoteItemView.executePropertyDialog();
            } else
                fireQuickWarningToast(tlang`This %%quote-item%% cannot be edited`);
        }
    }

    protected async createQuoteItemViewForV6(quoteItemContainer: QuoteItemContainer | null): Promise<QuoteItemFrameViewForV6> {
        return new QuoteItemFrameViewForV6(await this.getQuoteItemViewOptions(quoteItemContainer));
    }

    protected async quoteItemViewFactory(quoteItemContainer: QuoteItemContainer | null, providerType: string, contentType: number): NullPromise<QuoteItemView> {
        if (providerType !== "") {
            const options = await this.getQuoteItemViewOptions(quoteItemContainer);
            return await constructAsync(this.quoteViewChildFactory.getSupplierQuoteItemView(contentType, options));

        } else if (providerType === "") {
            //these are the default non-provider line items
            switch (contentType) {
                case quoteItemContentType.shipping:
                    return await constructAsync(new ShippingLineItemView(await this.getQuoteItemViewOptions(quoteItemContainer)));
                case quoteItemContentType.freehand:
                    return await constructAsync(new FreehandLineItemView(await this.getQuoteItemViewOptions(quoteItemContainer)));
                default:
                    await showDevelopmentError(`Content Type ${contentType} is not supported`);
                    break;
            }
        } else
            await showDevelopmentError(`Provider Type "${providerType}" is not supported`);
        return null;
    }

    protected async selectService(): Promise<string> {
        return await Promise.resolve(currentQuoteSupplierProvider);
    }

    /**
     * update the state and save the quote. this will work even if in readonly state.
     * @param state the new quote state.
     * @returns
     */
    protected async internalSetQuoteState(state: QuoteState): Promise<boolean> {
        this.quoteContainerManager.quote.state = state;
        return await this.performAutoSave();
    }

    /**
     * this is used to change state, and ensures that anything is saved that needs to be
     * saved on an active item that might be open before saving the quote
     * @param state
     * @returns
     */
    private async setQuoteState(state: QuoteState): Promise<boolean> {
        try {
            if (await this.saveActiveQuoteItem()) {
                await this.prepareForSave();
                return await this.internalSetQuoteState(state);
            } else
                return false;
        } finally {
            await this.render();
        }
    }

    private isV7Quote() { return this.quoteContainer.quote?.serviceProvider === quoteSupplierProvider.v7; }

    private buildQuoteActionMenu() {
        const menuIcons: MenuIconOption[] = [];

        const cancel = {
            caption: () => tlang`Cancel %%quote%%`,
            event: async () => await this.internalSetQuoteState(QuoteState.Cancelled)
        };
        const issue = {
            caption: () => tlang`Issue %%quote%%`,
            event: async () => {
                if (!(this.quoteContainer.quote?.state == QuoteState.Active)) {
                    await this.render();
                    return false;
                }
                const result = await this.setQuoteState(QuoteState.Issued);
                const controller = window["pricingEngineController"];
                controller?.UpdatePrice();
                return result;
            },
            childEvents: [cancel]
        };
        const activate = {
            caption: () => tlang`Make %%quote%% Active`,
            event: async () => {
                if (!(this.quoteContainer.quote?.state == QuoteState.Draft)) {
                    await this.render();
                    return false;
                }
                return await this.setQuoteState(QuoteState.Active);
            },
            childEvents: [cancel]
        };
        const accept = {
            caption: () => tlang`Accept %%quote%%`,
            event: async () => {
                if (!(this.quoteContainer.quote?.state == QuoteState.Issued)) {
                    await this.render();
                    return false;
                }
                return await this.internalSetQuoteState(QuoteState.Accepted);
            },
            classList: "btn btn-primary"
        };
        const reject = {
            caption: () => tlang`Reject %%quote%%`,
            event: async () => {
                if (!(this.quoteContainer.quote?.state == QuoteState.Issued)) {
                    await this.render();
                    return false;
                }
                return await this.setQuoteState(QuoteState.Rejected);
            },
            classList: "btn btn-secondary"
        };

        const addFrame = {
            caption: () => tlang`Add %%frame%%`,
            event: async () => await this.addNewQuoteItemFrame(),
        };
        const addFreehand = {
            caption: () => tlang`Add %%freehand%%`,
            event: async () => await this.addNewQuoteItemFreehand()
        };

        const addCustomFrame = {
            caption: () => tlang`Add %%custom-frame%%`,
            event: async () => await this.addNewQuoteItemCustom()
        };

        const save = {
            event: async () => {
                if (this.isReadonly())
                    return false;
                if (await this.saveActiveQuoteItem())
                    return await this.performAutoSave();
                else
                    return false;

            },
            caption: () => tlang`Save %%quote%%`
        };

        switch (this.quoteContainerManager.quote.state) {
            case QuoteState.Draft:
                menuIcons.push(activate);
                menuIcons.push(addFrame);
                menuIcons.push(addFreehand);
                if (this.shouldShowAddCustomFrame()) {
                    menuIcons.push(addCustomFrame);
                }
                break;
            case QuoteState.Active:
                menuIcons.push(issue);
                menuIcons.push(addFrame);
                menuIcons.push(addFreehand);
                if (this.shouldShowAddCustomFrame()) {
                    menuIcons.push(addCustomFrame);
                }
                break;
            case QuoteState.Issued:
                menuIcons.push(accept);
                menuIcons.push(reject);
                break;
        }

        if (!isAutoSaving())
            menuIcons.push(save);

        //menuIcons.push(close);

        this.pageControl.setMenuIcons(menuIcons);
    }

    private shouldShowAddCustomFrame() {
        return this.isV7Quote() && globalThis.softtech.siteConfig.showCustomFrame;
    }

    private async saveActiveQuoteItem(): Promise<boolean> {
        //if we are readonly, we never save anything, even if the UI accidentally things we should.
        if (!this.quoteContainerManager.isReadonly() && this.pageControl.activePage?.data instanceof QuoteItemView) {
            const view = this.pageControl.activePage?.data as QuoteItemView;
            if (await view.dataNeedsSaving())
                return await view.saveQuoteItem();
            else
                return true;
        }
        return true;
    }

    private async internalCreateQuoteItemView(quoteItemContainer: QuoteItemContainer, needsPrepareEditor: boolean): NullPromise<QuoteItemView> {
        const providerType = quoteItemContainer.item.serviceProvider;
        const quoteItemView = await this.quoteItemViewFactory(quoteItemContainer, providerType, quoteItemContainer.item.quoteItemContentType);
        if (quoteItemView)
            try {
                if (needsPrepareEditor) await quoteItemView.prepareEditor();
                if (!needsPrepareEditor || quoteItemView.readyToEdit)
                    return quoteItemView;
            } catch {
                return null;
            }
        return null;
    }

    /**
     * this is the generic item edit launch routine that checks a number of things.
     * first it checks if there is a page tab open for this item, and focus it if available
     * next check is that we only allow a certain count of tabs to be open at any time
     * to restrict resources and focus work. if this limit is going to exceed we cancel
     * if the item is not a page control item, we check and lauch its modal editor if it has one,
     * finally if not, launch the property editor if it has one.
     * @param quoteItemContainer the item to open for editing
     * @returns
     */
    private async internalOpenQuoteItemForEdit(quoteItemContainer: QuoteItemContainer): Promise<void> {
        if (await this.focusIfPageAvailable(quoteItemContainer)) return;
        await this.closeQuoteItemPages();
        const quoteItemView = await this.internalCreateQuoteItemView(quoteItemContainer, true);
        await this.launchQuoteItemView(quoteItemView);
    };

    /**
     * Tries to find an open QuoteItemView for the given quote item.
     * @param quoteItemContainer
     * @returns
     */
    private findIfPageAvailable(quoteItemContainer: QuoteItemContainer): PageManager | undefined {
        return this.pageControl.pages.find(page => {
            if (page.data instanceof QuoteItemView) {
                if ((page.data as QuoteItemView).quoteItemContainer?.item.id === quoteItemContainer.item.id) {
                    return true;
                }
            }
            return false;
        });
    }

    /**
     * before we create and open a page item for editing something, we always want to test if it already exists
     * and focus it instead
     * @param quoteItemContainer
     * @returns
     */
    private async focusIfPageAvailable(quoteItemContainer: QuoteItemContainer): Promise<boolean> {
        const foundPage = this.findIfPageAvailable(quoteItemContainer);
        if (foundPage) {
            await this.pageControl.setActivePage(foundPage);
            return true;
        }
        return false;
    }

    private async closePageIfAvailable(quoteItemContainer: QuoteItemContainer): Promise<boolean> {
        const foundPage = this.findIfPageAvailable(quoteItemContainer);
        if (foundPage) {
            await this.pageControl.closePage(foundPage);
            return true;
        }
        return false;
    }

    private async internalOpenQuoteItemPropertyDialog(quoteItemContainer: QuoteItemContainer): Promise<void> {
        if (await this.focusIfPageAvailable(quoteItemContainer)) {
            fireQuickWarningToast(tlang`Please save and close the %%quote-item%% before editing its properties`);
            return;
        }

        const quoteItemView = await this.internalCreateQuoteItemView(quoteItemContainer, false);
        if (quoteItemView) {
            if (quoteItemView.hasPropertyDialog) {
                // a fallback for items that have a property dialog, but no page control.
                await quoteItemView.executePropertyDialog();
            }
        }
    }

    private async internalCopyQuoteItem(quoteItemContainer: QuoteItemContainer): Promise<void> {
        await this.quoteContainerManager.copyQuoteItem(quoteItemContainer);
    }

    private async internalDeleteQuoteItem(quoteItemContainer: QuoteItemContainer): Promise<void> {
        const qm = this.quoteContainerManager;
        await this.closePageIfAvailable(quoteItemContainer);
        if (await qm.deleteQuoteItem(quoteItemContainer)) {
            fireQuickSuccessToast(tlang`%%quote-item%% has been deleted`);
        }
        else {
            fireQuickErrorToast(tlang`Unable to delete the selected %%quote-item%%`);
        }
    }

    private createDetailPage(): PageManager {
        return {
            caption: () => tlang`Information`,
            canClose: () => Promise.resolve(false),
            canLeave: async () => await this.allowPageSwitch(),
            hasDelete: () => false,
            onEnter: async () => {
                await this.detailView.loadOrRefresh();
            },
            content: () => {
                return this.detailView.ui;
            },
            buttonMenu: () => {
                return this.detailView.buttonMenu();
            },
            data: this.detailView
        };
    }
}

export class QuoteView extends ModalViewBase {
    view: QuoteDataEntryView | null = null;
    options: QuoteViewOptions;

    constructor(options: QuoteViewOptions) {
        super();
        this.options = options;
    }

    /**
     * inherited
     * @returns
     */
    async canClose(): Promise<boolean> {
        return await this.view?.canClose() ?? true;
    }

    async afterConstruction(): Promise<void> {
        this.view = await constructAsync(this.createView());
        this.view.onRender = async () => await this.render();
        this.view.render();
        this.ui.addEventListener('shown.bs.modal', this.setFocusAndSelect);
    }

    private setFocusAndSelect(_event: Event) {
        setTimeout(() => {
            super.setFocusOnInputElementAndSelect("frm-quote-details", "quote-title-");
          }, 0);
      }

    /**
     * inherited
     * @returns
     */
    protected async getTitle(): PromiseSnippet {
        return await this.view?.getTitle() ?? "";
    }

    /**
     * inherited
     * @returns
     */
    protected modalSize(): string {
        return "modal-fullscreen";
    }

    protected createView(): QuoteDataEntryView {
        return new QuoteDataEntryView(this.options, this);
    }

    protected async bodyTemplate(): PromiseTemplate {
        return html`${this.view?.ui}`;
    }
}
