// eslint-disable-next-line import/named
import { html } from "lit";
import { Quote, QuoteState } from "../../api/dealer-api-interface-quote";
import { ClientApi } from "../../api/client-api";
import { DataBinding } from "../../components/ui/databinding/databinding";
import { DataTracker, FieldType } from "../../components/ui/databinding/data-tracker";
import { FormInputAssistant } from "../../components/ui/templateresult/form-input-assistant";
import { QuoteContainerManager } from "../data/quote-container";
import { tlang } from "../../language/lang";
import { isEmptyOrSpace } from "../../components/ui/helper-functions";
import { getApiFactory } from "../../api/api-injector";
import { PromiseTemplate, Snippet } from "../../components/ui/events";
import { ViewBase } from "../../components/ui/view-base";

export interface QuoteDetailViewOptions {
    quoteManager: QuoteContainerManager;
    canGenerateReport: () => Promise<boolean>;
}

export class QuoteDetailView extends ViewBase {


    clientApi: ClientApi = getApiFactory().client();
    quoteManager: QuoteContainerManager;
    dataBinding: DataBinding;
    dataTracker: DataTracker;
    canGenerateReport: (() => Promise<boolean>);


    constructor(options: QuoteDetailViewOptions) {
        super();
        this.quoteManager = options.quoteManager;
        this.canGenerateReport = options.canGenerateReport;
        this.dataBinding = new DataBinding(this.ui, undefined, (input: string, internalId: string) => `quote-${input}-${internalId}`);
        this.dataTracker = new DataTracker(this.dataBinding);

        const addField = (fieldName: string, propertyType?: FieldType, nullable?: boolean, editorFieldName?: string, data?: () => any) => {
            this.dataTracker.addObjectBinding(data ?? (() => this.quote), fieldName, editorFieldName ?? fieldName, propertyType ?? FieldType.string, nullable ?? false);
        };

        addField("title");
        addField("description");
        addField("dateCreated", FieldType.dateTime);
        addField("lastModifiedDate", FieldType.dateTime);
        addField("validUntilDate", FieldType.localDate, true);
        addField("installationDate", FieldType.date, true);

    }

    get quote(): Quote { return this.quoteManager.quote; }
    public getValidationErrors() {
        const errors: string[] = [];

        if (this.quote.state != QuoteState.Draft) {
            if (isEmptyOrSpace(this.quote.title))
                errors.push(tlang`Please provide a %%quote%% Title`);
        }

        return errors;
    }


    public async prepareForSave(): Promise<void> {
        if (this.dataTracker.modified)
            this.dataTracker.applyChangeToValue();
    }

    public resetEditControls() {
        // do nothing now.
    }

    public async loadOrRefresh(): Promise<void> {
        await this.quoteManager.needsQuote();
        await this.render();
    }

    public buttonMenu(): Snippet {
        return html``;
    }

    protected async template(): PromiseTemplate {
        const forms = new FormInputAssistant(this.dataTracker);
        return html`
            <form class="py-3 px-0 frm-quote-details form-two-col">
                <div class="row">
                    <div>
                        ${forms.text("title", tlang`%%quote%% Title`)}
                        ${forms.intReadonly("quoteNumber", tlang`%%quote%% No`)}
                        ${forms.note("description", tlang`%%quote%% Description`)}
                    </div>

                    <div>
                        ${forms.enumPicker("state", QuoteState, tlang`Status`)}
                        ${forms.dateReadonly("dateCreated", tlang`Created`)}
                        ${forms.dateReadonly("lastModifiedDate", tlang`Last Modified`)}
                        ${forms.date("validUntilDate", tlang`Valid To`, "date")}
                        ${forms.date("installationDate", tlang`Installation Date`, "date")}
                    </div>
                </div>
            </form>
        `;
    }
}
