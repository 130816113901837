import { Address, ViewClientSummary } from "../../api/dealer-api-interface-client";
import { tlang } from "../../language/lang";
import { ClientSummaryTableBase, ClientSummaryTableBaseOptions } from "./client-list-table";

interface ClientSummaryPickerOptions extends ClientSummaryTableBaseOptions {
    onClientSelect?: (clientSummary: ViewClientSummary) => void;
}

export class ClientSummaryPickerBrowser extends ClientSummaryTableBase {
    onClientSelect?: (clientSummary: ViewClientSummary) => void;

    constructor(options: ClientSummaryPickerOptions) {
        super(options);

        this.onClientSelect = options.onClientSelect;
    }

    getColumns(): any[] {
        return [{
            title: tlang`%%client%%`,
            width: "250px",
            data: "name"
        }, {
            title: tlang`Primary %%contact%%`,
            width: "250px",
            data: "primaryContactName"
        }, {
            title: tlang`Company Email`, width: "250px", orderable: false, data: "primaryContactEmail"
        }, {
            title: tlang`Address`, width: "400px", data: "physicalAddress", orderable: false, render: (value: Address) => {
                return `${[value.line1, value.locality, value.region, value.postcode, value.country].filter(Boolean)
                    .join(", ")}`;
            }
        },];
    }

    bindClickEvents($dataTable: any) {
        //Data tables uses JQuery click events to bind things. they don't have any other
        //special handler.
        // bind to all the quote-link classes to implement the quote open event
        $dataTable.on('click', 'tr', this.eventHandler(async (data: ViewClientSummary) => {
            if (this.onClientSelect)
                this.onClientSelect?.(data);
        }));
    }
}
