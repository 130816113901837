// eslint-disable-next-line import/named
import { html } from "lit";
import { tlang } from "../../language/lang";
import { EventSnippet, PromiseSnippet, PromiseTemplate } from "./events";
import { ModalDialog } from "./modal-base";

export async function showValidations(items: string[], title?: EventSnippet) {
    if (!title) title = () => tlang`Validation Report`;
    await (new ValidationDialog(title, items)).showModal();
}
export class ValidationDialog extends ModalDialog {
    title: EventSnippet;
    issues: string[];

    constructor(title: EventSnippet, issues: string[]) {
        super();
        this.title = title;
        this.issues = issues;


    }
    protected modalClasses(): string {
        return "modal-dialog modal-dialog-scrollable";
    }

    protected modalSize(): string {
        return "modal-xl";
    }

    protected ZIndex(): number | undefined {
        return 99993;
    }

    protected async getTitle(): PromiseSnippet {
        return this.title();
    }
    protected async bodyTemplate(): PromiseTemplate {
        const warnings = this.issues.map(issue =>
            html`
            <div class="alert alert-warning" role="alert">
                ${issue}
            </div>`);
        return html`${warnings}`;
    }
}