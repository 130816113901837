//copyright Kyley Harris (2021) - all rights to reuse and change allowed

import { DictionaryEntry, techincalDictionary } from "./technical-dictionary-data";

let language = "eng";
const supportedLang =
    [
        "eng",
        "us"
    ];
let event: ((language: string) => void) | null = null;
export function getLang() { return language; }
export function isLang(lang: string) { return language === lang; }
export function onLanguageChange(e: (language: string) => void) {
    event = e;
}
export function setLang(name: string) {
    if (supportedLang.filter(x => x === name).length > 0) {
        language = name;
        if (event) event(name);
    } else
        throw new Error(`${name} is not a supported language yet`);
}

export function lang(text: string, namedResource?: string, doNotAddMissing?: boolean) {
    const languageTransform = globalThis.dealerConfiguration.lang;
    //if (language === "eng") return text;
    const finalText = (): string => {
        const newTextObj: object = languageTransform[namedResource ?? text];
        if (!newTextObj) {
            if (!doNotAddMissing) {
                languageTransform[namedResource ?? text] = {};
                languageTransform[namedResource ?? text][language] = text;
            }
            return text;
        } else {
            const newText = newTextObj[language];
            if (!newText) {
                newTextObj[language] = text;
                return text;
            } else
                return newText;
        }
    };

    let txt = finalText();

    const matches = txt.matchAll(/%%([\w|-]+)%%/g);
    for (const match of matches) {
        const matchFunction = techincalDictionary[match[1]];
        if (!matchFunction || !(matchFunction instanceof DictionaryEntry)) {
            alert(`${match[1]} is not a dictionary function`);
        } else
            txt = txt.replace(match[0], matchFunction.single());
    }
    const matchesPlural = txt.matchAll(/!!([\w|-]+)!!/g);
    for (const match of matchesPlural) {
        const matchFunction = techincalDictionary[match[1]];
        if (!matchFunction || !(matchFunction instanceof DictionaryEntry)) {
            alert(`${match[1]} is not a dictionary function`);
        } else
            txt = txt.replace(match[0], matchFunction.plural());
    }
    return txt;
}

export function tlang(template: TemplateStringsArray, ...args) {


    let namedResource: string | undefined = undefined;
    if (args.length > 0) {
        const arg1 = args[0];
        if (typeof arg1 === "string") {
            if (arg1.startsWith("ref:")) {
                namedResource = arg1.toLowerCase();
                args.splice(0, 1);
            }
        }
    }
    let result = "";
    let i = 0;
    for (let loop = namedResource ? 1 : 0; loop < template.length; loop++) {
        result += `${template[loop]}` + (i < args.length ? `{${i++}}` : '');
    }
    let transformedResult = lang(result, namedResource);
    i = 0;
    args.forEach((value, index) => {
        transformedResult = transformedResult.replace(`{${index}}`, value);
    });

    return transformedResult;

}