import { getApiToken, getCurrentUser } from '../api/current-user';
import { tlang } from '../language/lang';
import { NotificationHub, notificationBus } from '../toast-away';

//use constants everywhere. no typo, 1 source of update
export const pages = {
    home: "home",
    quotes: "quotes",
    projects: "projects",
    orders: "orders",
    purchaseOrders: "purchase-orders",
    clients: "clients",
    settings: "settings",
    supplier: "supplier",
    pricing: "pricing",
    catalogs: "catalogs",
};



export interface AppConfig {
    isSupplier: boolean;
    menuPageItems: MenuItem[];
    routes: Route[];
}

export interface MenuItem {
    name: string;
    title: string;
}

export interface Route {
    path: string;
    name: string;
    component: string;
    action: () => Promise<void>;
}

class AppConfigImpl implements AppConfig {
    private fabricatorMenuPages: MenuItem[] = [
        /* Hiding tab (not removing) because it is recommended by PBI 214673 (there is probability that it will be needed again) */
        // {
        //     name: pages.home,
        //     title: tlang`Dashboard`
        // },
        {
            name: pages.quotes,
            title: tlang`!!quote!!`
        },
        {
            name: pages.projects,
            title: tlang`!!project!!`
        },
        {
            name: pages.orders,
            title: tlang`!!order!!`
        },
        /* Hiding tab (not removing) because it is recommended by PBI 215283  */
        // {
        //     name: pages.purchaseOrders,
        //     title: tlang`!!purchase-order!!`
        // },
        {
            name: pages.clients,
            title: tlang`!!client!!`
        },
        {
            name: pages.settings,
            title: tlang`Settings`
        }
    ];
    private supplierMenuItems = [
        {
            name: pages.supplier,
            title: tlang`%%supplier%%`
        },
        {
            name: pages.pricing,
            title: tlang`%%pricing%%`
        },
        {
            name: pages.catalogs,
            title: tlang`%%catalogs%%`
        },
    ];

    get isSupplier() {
        return getCurrentUser()?.isSupplier ?? false;
    }

    get menuPageItems() {
        if (this.isSupplier) {
            return this.supplierMenuItems;

        } else {
            return this.fabricatorMenuPages;

        }
    }

    routes: Route[] = [
        /* Hiding tab (not removing) because it is recommended by PBI 214673 (there is probability that it will be needed again) */
        // {
        //     path: '/',
        //     name: pages.home,
        //     component: 'app-home',
        //     action: async () => {
        //         await import('./pages/app-home');
        //     },
        // },
        {
            path: '/clients',
            name: pages.clients,
            component: 'app-client',
            action: async () => {
                await import('./pages/app-client');
            },
        },
        {
            path: '/settings',
            name: pages.settings,
            component: 'app-settings',
            action: async () => {
                await import('./pages/app-settings');
            }
        },
        {
            path: '/quotes',
            name: pages.quotes,
            component: 'app-quote',
            action: async () => {
                await import('./pages/app-quote');
            }
        },
        {
            path: '/projects',
            name: pages.projects,
            component: 'app-project',
            action: async () => {
                await import('./pages/app-project');
            }
        },
        {
            path: '/orders',
            name: pages.orders,
            component: 'app-order',
            action: async () => {
                await import('./pages/app-order');
            }
        },
        {
            path: '/supplier',
            name: pages.supplier,
            component: 'app-supplier',
            action: async () => {
                await import('./pages/app-supplier');
            }
        },
        {
            path: '/pricing',
            name: pages.pricing,
            component: 'app-pricing',
            action: async () => {
                await import('./pages/app-pricing');
            }
        },
        {
            path: '/catalogues',
            name: pages.catalogs,
            component: 'app-catalogs',
            action: async () => {
                await import('./pages/app-catalogs');
            }
        },
        /* Hiding tab (not removing) because it is recommended by PBI 215283  */
        // {
        //     path: '/purchase-orders',
        //     name: pages.purchaseOrders,
        //     component: 'app-purchase-orders',
        //     action: async () => {
        //         await import('./pages/app-purchase-order');
        //     }
        // },
        {
            path: '(.*)',
            name: 'not-found',
            component: 'page-not-found',
            action: async () => {
                await import('./pages/page-not-found');
            },
        },
    ];

}
export function appConfig(): AppConfig {
    return new AppConfigImpl();
}

interface SiteConfig {
    authToken: string;
    notificationHub: NotificationHub;
}

export interface PriceController {
    initialize(priceId: string): void;
}

export interface OrderServiceConfig {
    backendUrl: string;
}


declare global {
    interface Window {
        softtech: {
            siteConfig: SiteConfig;
            notificationHub: NotificationHub;
            pricingEngineController: PriceController;
            orderServiceConfig: OrderServiceConfig;
        }
    }
}

window.softtech = {
    ...window.softtech ?? {},
    siteConfig: {
        ...window.softtech?.siteConfig ?? {},
        get authToken(): string {
            return getApiToken();
        },
    } as SiteConfig,
    notificationHub: notificationBus
}

