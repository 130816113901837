// eslint-disable-next-line import/named
import { html, TemplateResult } from "lit";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { tlang } from "../../language/lang";
import { ServiceResponseInvalid, ServiceResponseType } from "../../service_response";
import { EventSnippet, PromiseSnippet, PromiseTemplate } from "./events";
import { ModalDialog } from "./modal-base";

export class ErrorDialog extends ModalDialog {
    title: EventSnippet;
    error: ServiceResponseInvalid;
    displayCopiedButton = false;

    constructor(title: EventSnippet, message: ServiceResponseInvalid | Error) {
        super();
        this.title = title;
        if (message instanceof Error) {
            this.error = {
                responseType: ServiceResponseType.Error,
                responseTypeCaption: "Local UI Error",
                responseError: {
                    message: `${message.name} - ${message.cause} - ${message.message}`,
                    stackTrace: message.stack
                }
            };
        } else
            this.error = message;

    }

    protected footerTemplate(): TemplateResult {
        const copyEvent = () => {
            const text = tlang`${"ref:error_clipboard"}Dealer Error:
${this.error.responseError?.message}
-------------------------------------------------
${this.error.responseError?.stackTrace}
        `;

            navigator.clipboard.writeText(text);
            this.displayCopiedButton = true;
            this.render(); // no wait
            setTimeout(() => {
                this.displayCopiedButton = false;
                this.render(); //no wait
            }, 2000);
        };
        if (this.displayCopiedButton)
            return html`
                <button class="btn btn-success">${tlang`Copied to Clipboard`}</button>`;
        else
            return html`
                <button @click=${copyEvent} class="btn btn-primary">${tlang`Copy to Clipboard`}</button>`;
    }

    protected modalClasses(): string {
        return "modal-dialog modal-dialog-scrollable";
    }

    protected modalSize(): string {
        return "modal-xl";
    }

    protected ZIndex(): number | undefined {
        return 99999;
    }

    protected async getTitle(): PromiseSnippet {
        return this.title();
    }

    protected async bodyTemplate(): PromiseTemplate {
        return html`
            <h3>${tlang`Please submit an IT Ticket to your support with the following details`}</h3>
            <h4>${tlang`Note that continuing what you are doing may result in corruption or loss of data`}</h4>
            <div class="alert alert-danger" role="alert">
                ${this.error.responseError?.message}
            </div>
            <div class="alert alert-info" role="alert">
                ${unsafeHTML(this.error.responseError?.stackTrace?.replaceAll("\n", "<br />"))}
            </div>

        `;

    }
}


