import {
    PurchaseOrderDataEntryView,
    PurchaseOrderView,
    PurchaseOrderViewChildFactory,
    PurchaseOrderViewChildFactoryImpl
} from "../../../purchase-orders/views/purchase-order-view";
import {
    PurchaseOrderDetailView,
    PurchaseOrderDetailViewOptions
} from "../../../purchase-orders/views/purchase-order-detail-view";
import { FranchiseePurchaseOrderDetailView } from "./franchisee-purchase-order-detail-view";
import { validId } from "../../../components/ui/helper-functions";
import { launchProject } from "../../../projects/ui/launcher";
import { PromiseSnippet } from "../../../components/ui/events";
import { html } from "lit";
import { getPurchaseOrderTitle } from "../../../purchase-orders/data/purchase-order-helper-functions";
import { cache } from "../../cache/cache-registry";
import { emptyGuid } from "../../../api/guid";
import { launchQuote } from "../../../quotes/ui/launcher";
import { DevelopmentError } from "../../../development-error";
import { SupplierApi } from "../../../api/supplier-api";
import { getApiFactory } from "../../../api/api-injector";
import { PurchaseOrderState } from "../../../api/dealer-api-interface-franchisee";
import { AskConfirmation } from "../../../components/ui/modal-confirmation";
import { tlang } from "../../../language/lang";
import { FileResponse } from "../../../api/dealer-api-interface-report";
import { NullPromise } from "../../../null-promise";
import { BlobApi } from "../../../api/blob-api";
import { fileToBase64 } from "../../../blob/converters";
import { fireQuickErrorToast } from "../../../toast-away";
import { PurchaseOrderApi } from "../../../api/purchase-order-api";
import { WaitPatiently } from "../../../components/ui/modal-spinner";

export class FranchiseePurchaseOrderViewChildFactoryImpl extends PurchaseOrderViewChildFactoryImpl {
    protected get view(): FranchiseePurchaseOrderDEView {
        return this.parent as FranchiseePurchaseOrderDEView;
    }

    override getDetailView(options: PurchaseOrderDetailViewOptions): PurchaseOrderDetailView {
        return new FranchiseePurchaseOrderDetailView({
            purchaseOrderManager: options.purchaseOrderManager,
            purchaseOrderCache: options.purchaseOrderCache,
            //generateReportEvent: async () => await this.view.generateReport()
        })
    }
}

export class FranchiseePurchaseOrderDEView extends PurchaseOrderDataEntryView {
    quoteCache = cache().quote;
    projectCache = cache().project;
    prlCache = cache().projectResourceLink;
    supplierApi: SupplierApi = getApiFactory().supplier();
    blobApi: BlobApi = getApiFactory().blob();
    purchaseOrderApi: PurchaseOrderApi = getApiFactory().purchaseOrder();

    public override async afterConstruction(): Promise<void> {
        await super.afterConstruction();

        if (this.purchaseOrderContainer.purchaseOrder) {
            await this.purchaseOrderCache.preFetch([this.purchaseOrderContainerManager.purchaseOrderId]);

            await Promise.all([this.quoteCache.preFetch([await this.quoteId()]), this.projectCache.preFetch([await this.projectId()])]);
        }
    }

    public override async getTitle(): PromiseSnippet {
        //override and do not use this.title
        const navProjectEvent = () => {
            this.closeAndNavigateToProject();
        };

        const navQuoteEvent = () => {
            this.closeAndNavigateToQuote();
        };

        const po = this.purchaseOrderContainer.purchaseOrder;
        if (!po) return html``; // will never happen

        const quoteId = await this.quoteId();
        const projectId = await this.projectId();

        const projectCacheItem = await this.projectCache.get(projectId);
        const quoteCacheItem = await this.quoteCache.get(quoteId);

        const projectTemplate = (validId(projectId) && projectCacheItem)
            ? html`<a href="#" @click=${navProjectEvent}
                      class="btn btn-sm btn-light">${projectCacheItem.displayValue}</a>`
            : html``;

        const quoteTemplate = (validId(quoteId) && quoteCacheItem)
            ? html`<a href="#" @click=${navQuoteEvent} class="btn btn-sm btn-light">${quoteCacheItem?.displayValue}</a>`
            : html``;

        return html`
            ${getPurchaseOrderTitle(po)}
            <div class="title-links">
                ${projectTemplate}
                ${quoteTemplate}
            </div>
        `;
    }

    public async generateReport(): NullPromise<FileResponse> {
        throw new DevelopmentError("Report Override not implemented.");
    }

    protected async closeAndNavigateToProject(): Promise<void> {
        const projectId = await this.projectId();
        if (validId(projectId)) {
            //no awaiting. we want this workflow to exit
            launchProject({id: projectId, navigateIfAllowed: async () => await this.tryClose()});
        }
    }

    protected async closeAndNavigateToQuote(): Promise<void> {
        const quoteId = await this.quoteId();
        if (validId(quoteId)) {
            launchQuote({id: quoteId, navigateIfAllowed: async () => await this.tryClose()});
        }
    }

    protected async quoteId(): Promise<string> {
        if (!this.purchaseOrderContainer.purchaseOrder) return emptyGuid;
        const data = (await this.prlCache.getData(this.purchaseOrderContainer.purchaseOrder.id));
        return data?.quoteId ?? emptyGuid;
    }

    protected async projectId(): Promise<string> {
        if (!this.purchaseOrderContainer.purchaseOrder) return emptyGuid;
        const data = (await this.prlCache.getData(this.purchaseOrderContainer.purchaseOrder.id));
        return data?.projectId ?? emptyGuid;
    }

    protected override getPurchaseOrderViewChildFactory(): PurchaseOrderViewChildFactory {
        return new FranchiseePurchaseOrderViewChildFactoryImpl(this);
    }

    protected override async internalSetState(state: PurchaseOrderState): Promise<boolean> {
        let doStateChange = false;
        const initialState = this.purchaseOrder.state;

        switch (state) {
            case PurchaseOrderState.Issued:
                doStateChange = await this.issueConfirmationAndGenerateReport();
                break;
            case PurchaseOrderState.Completed:
                doStateChange = await this.completeConfirmation();
                break;
            case PurchaseOrderState.Cancelled:
                doStateChange = await this.cancelConfirmation();
                break;
            case PurchaseOrderState.None:
            case PurchaseOrderState.Draft:
                break;
        }

        if (!doStateChange)
            return false;

        //super is setting the state and calling auto save.
        let saved = await super.internalSetState(state);

        //If the PO is issued, generate the report
        if (saved && this.purchaseOrder.state == PurchaseOrderState.Issued) {
            const w = new WaitPatiently(() => tlang`Generating report`, () => tlang`Generating %%purchase-order%% Report`);
            try {
                const reportUploaded = await this.generateAndUploadReport();

                if (reportUploaded)
                    return reportUploaded;
            } finally {
                w.hideModal();
            }

            // Getting here means that report wasn't generated and uploaded. Reset the PO state to original
            saved = await super.internalSetState(initialState);
        }

        return saved
    }

    private async generateAndUploadReport(): Promise<boolean> {
        const pdf = await this.generateReport();

        if (!pdf) {
            fireQuickErrorToast(tlang`Unable to generate %%purchase-order%% report.`);
            return false;
        }

        try {
            //Get the base64 string + metadata
            const fileBase64 = await fileToBase64(new File([pdf.data], "upload.pdf"));
            //Content is composed of metadata and file content seperated with a comma.
            const separatorIndex = fileBase64.indexOf(',');
            //Get everything from beyond the comma. If there is no comma (index -1) this will return the whole string
            const fileContent = fileBase64.substring(separatorIndex + 1);

            await this.blobApi.updateFileByVirtualPath({
                data: fileContent,
                oldVirtualPath: "",
                newVirtualPath: this.purchaseOrderApi.createPurchaseOrderReportPath(this.purchaseOrder.id)
            });
        } catch {
            fireQuickErrorToast(tlang`Unable to upload %%purchase-order%% report.`);
            return false;
        }

        return true;
    }

    private async issueConfirmationAndGenerateReport(): Promise<boolean> {
        const terms = await this.supplierApi.getSupplierTerms({supplierId: this.purchaseOrder.supplierId});

        return await AskConfirmation(terms?.TermsAndConditions ??
            tlang`Are you sure you wish to Issue the %%purchase-order%%?`, {
            ok: tlang`Issue`,
            cancel: tlang`Cancel`
        }, "modal-lg");
    }

    private async completeConfirmation(): Promise<boolean> {
        return await AskConfirmation(tlang`Are you sure you wish to Complete the %%purchase-order%%?`, {
            ok: tlang`Yes`,
            cancel: tlang`No`
        });
    }

    private async cancelConfirmation(): Promise<boolean> {
        const msg = this.purchaseOrder.state == PurchaseOrderState.Issued ?
            tlang`The %%purchase-order%% has already been issued and cancelling can have financial implications. <br />
Cancelling the %%purchase-order%% here has no effect on the order at the %%supplier%%.
Contact the %%supplier%% to get the order cancelled <br/>
Do you want to Cancel the %%purchase-order%%?` :
            tlang`Are you sure you wish to Cancel the %%purchase-order%%?`;

        return await AskConfirmation(msg, {
            ok: tlang`Yes`,
            cancel: tlang`No`
        });
    }
}

export class FranchiseePurchaseOrderView extends PurchaseOrderView {
    protected createView(): PurchaseOrderDataEntryView {
        return new FranchiseePurchaseOrderDEView(this.options, this);
    }
}