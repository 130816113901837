export interface JsonResult<T> {
    value: T | null;
    status: number;
    statusText: string;
}

export async function jsonRequest<T>(url: string, data: any, host: string, token=""): Promise<JsonResult<T>> {
    let headers: HeadersInit;

    if (token == "") {
        headers = {
            'Content-Type': 'application/json',
        };
    } else {
        headers = {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${token}`,
        };
    }

    // Default options are marked with *
    const response = await fetch(`${host}/${url}`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        headers: headers,
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    if (response.status != 200) return { value: null, status: response.status, statusText: response.statusText + ' ' + await response.text() };
    const r: JsonResult<T> = { value: await response.json() as T, status: 200, statusText: "" };
    return r;
}
