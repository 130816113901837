import { registerUINavigator } from "../components/ui/navigator";
import { quoteNav } from "./uinavigators/quote-navigator";
import { purchaseOrderNav } from "./uinavigators/purchase-order-navigator";
import { projectNav } from "./uinavigators/project-navigator";

export function registerUINavigators() {
    registerUINavigator(quoteNav());
    registerUINavigator(purchaseOrderNav());
    registerUINavigator(projectNav());
}

