import { html } from "lit";

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencySign: "accounting",
    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

const currencyFormatter4dp = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencySign: "accounting",
    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 4, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 4, // (causes 2500.99 to be printed as $2,501)
});

/**
 *
 * @param str a server side money string without formatting that might be of unknown decimal places.
 * @param digits force cast and round to 2 or 4 dp
 * @returns  return the new number
 */
export function strToMoney(str: string, digits = 2): number {
    const val = parseFloat(str);
    if (!isNaN(val))
        return money(val, digits);
    else
        return val;

}

/**
 *
 * @param value a number that we want to ensure is money compliant 2 or 4 dp accuracy
 * @param digits 2 or 4
 * @returns a new rounded number
 */
export function money(value: number, digits = 2) {
    return parseFloat(value.toFixed(digits));
}

export function moneyToStr(value: number): string {
    return currencyFormatter.format(value);
}

export function money4dpToStr(value: number): string {
    return currencyFormatter4dp.format(value);
}


export function moneyToHtml(value: number) {
    return `<span class="${value < 0 ? 'text-danger' : ""}">${moneyToStr(value)}</span>`;
}
export function money4dpToHtml(value: number) {
    return `<span class="${value < 0 ? 'text-danger' : ""}">${money4dpToStr(value)}</span>`;
}
export function moneyToTemplateResult(value: number) {
    return html`<span class="tab-value${value < 0 ? ' text-danger' : ""}">${moneyToStr(value)}</span>`;
}
export function money4dpToTemplateResult(value: number) {
    return html`<span class="${value < 0 ? 'text-danger' : ""}">${money4dpToStr(value)}</span>`;
}