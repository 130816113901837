// eslint-disable-next-line import/named
import { html } from 'lit';
import { ContactSummaryPickerBrowser } from "./contact-list-table";
import { ViewContactSummary } from "../../api/dealer-api-interface-client";
import { NullPromise } from "../../null-promise";
import { ModalPickerDialog, ModalPickerDialogOptions } from "../../components/ui/modal-picker-base";
import { tlang } from '../../language/lang';
import { PromiseTemplate } from '../../components/ui/events';

interface ContactBrowserPickerOptions extends ModalPickerDialogOptions<ViewContactSummary> {
    clientId?: string;
}

export async function ContactPicker(clientId?: string): NullPromise<ViewContactSummary> {
    return new Promise<ViewContactSummary | null>((resolve, reject) => {
        const picker = new ContactBrowserPicker({
            onSelect: (viewContactSummary: ViewContactSummary | null) => { resolve(viewContactSummary); },
            onCancel: () => reject(),
            title: tlang`Select a %%contact%%`,
            clientId: clientId
        });
        picker.showModal();
    });
}

class ContactBrowserPicker extends ModalPickerDialog<ViewContactSummary>{
    comms: ContactSummaryPickerBrowser;

    constructor(options: ContactBrowserPickerOptions) {
        super(options);
        this.comms = new ContactSummaryPickerBrowser({
            title: () => options.title ?? "",
            clientId: options.clientId,
            onContactSelect: contactSummary => this.contactSelected(contactSummary)
        });
    }

    protected async bodyTemplate(): PromiseTemplate {
        this.comms.render();
        return html`${this.comms.ui}`;
    }

    protected async contactSelected(contactSummary: ViewContactSummary): Promise<void> {
        this.selected = contactSummary;
        await this.select(contactSummary);
    }
}
