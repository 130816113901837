//this will be a wrapper to the different logical areas... if needed.
//in reality it may not be necessary due to the layered design

export interface QuoteSupplier {
    //GUID which is from GlobalSupplier on license server
    supplierId: string;
    description: string;
}

export type QuoteSupplierEvent = () => Promise<QuoteSupplier[]>;
let _callback: QuoteSupplierEvent = async () => [];
export function setQuoteSupplierProviderCallback(callback: QuoteSupplierEvent) {
    _callback = callback;
}

export async function getQuoteSuppliers(): Promise<QuoteSupplier[]> {
    return await _callback();
}