import { Router } from "@vaadin/router";
import { DevelopmentError, showDevelopmentError } from "../../development-error";
import { init } from "../app-global-init";
import { EventVoidAsync } from "./events";

export type EventUIDataNavigation = (id: string, data?: any, eventInfo?: any) => Promise<boolean>;

export interface UINavigator {
    code: string;
    event: EventUIDataNavigation;
    isModal: boolean;
}

let _navigations: UINavigator[] | null = null;
init(() => {
    _navigations = [];
});

export function registerUINavigator(nav: UINavigator) {
    if (_navigations?.find(x => x.code === nav.code)) throw new DevelopmentError(`UINavigator ${nav.code} already registered`);
    _navigations?.push(nav);
}

export interface NavigationOptions {
    code: string;
    id: string;
    data?: any;
    eventInfo?: any;
    onAllowNavigation?: () => Promise<boolean>;
    onAfterNavigationEvent?: EventVoidAsync;
    onError?: EventVoidAsync;
    onFinally?: EventVoidAsync;
}

/**
 * navigate using the built in vaadin router without reloading the page
 * @param path
 * @returns
 */
export async function navigate(path: string): Promise<boolean> {
    return new Promise(resolve => {
        if (!Router.go(path)) resolve(false);
        // give time for the router to update before resolving
        setTimeout(() => resolve(true), 560);
    });
}
globalThis.navigate = (path: string) => {
    return Router.go(path);
};
export async function launchUI(options: NavigationOptions): Promise<boolean> {
    const nav = _navigations?.find(x => x.code === options.code);
    if (!nav) {
        await showDevelopmentError(`UINavigator ${options.code} not found`);
        return false;
    }
    if (options.onAllowNavigation) {
        if (!(await options.onAllowNavigation())) return false;
    }
    try {
        const success = (await nav.event(options.id, options.data, options.eventInfo));
        if (options.onAfterNavigationEvent) await options.onAfterNavigationEvent();
        return success;

    } catch (e) {
        if (e instanceof Error)
            await showDevelopmentError(e);
        else
            await showDevelopmentError((e as any).toString());
        if (options.onError) await options.onError();
        return false;
    } finally {
        if (options.onFinally) await options.onFinally();
    }
}