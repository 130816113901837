import { ProjectDetailView, ProjectDetailViewOptions } from "../../../projects/views/project-detail-view";
import { ClientApi } from "../../../api/client-api";
import { FieldType } from "../../../components/ui/databinding/data-tracker";
// eslint-disable-next-line import/named
import { html} from "lit";
import { ClientPicker } from "../../../clients/views/client-picker";
import { emptyGuid } from "../../../api/guid";
import { ContactPicker } from "../../../clients/views/contact-picker";
import { fireQuickWarningToast } from "../../../toast-away";
import { tlang } from "../../../language/lang";
import { PaymentProfileApi } from "../../../api/payment-profile-api";
import { isEmptyOrSpace } from "../../../components/ui/helper-functions";
import { getApiFactory } from "../../../api/api-injector";
import { Place } from "../../../components/ui/maps/place";
import { googleMapApiKey } from "../../../api/google-api";
import "../../../components/ui/maps/google-place-autocomplete";
import "../../../components/ui/maps/google-map";
import { mapTemplate, updateAddress } from "../../../components/ui/maps/map-helpers";
import { cache } from "../../cache/cache-registry";
import { PromiseTemplate } from "../../../components/ui/events";
import { FormInputAssistant } from "../../../components/ui/templateresult/form-input-assistant";
import { DevelopmentError } from "../../../development-error";
import { ProjectState } from "../../../api/dealer-api-interface-project";


export class FranchiseeProjectDetailView extends ProjectDetailView {
    clientApi: ClientApi = getApiFactory().client();
    paymentProfileApi: PaymentProfileApi = getApiFactory().paymentProfile();

    clientCache = cache().client;
    contactCache = cache().contact;

    clientPickerDisplay = "";
    contactPickerDisplay = "";

    private lng?: number;
    private lat?: number;

    constructor(options: ProjectDetailViewOptions) {
        super(options);

        const addField = (fieldName: string, propertyType?: FieldType, nullable?: boolean, editorFieldName?: string, data?: () => any) => {
            this.dataTracker.addObjectBinding(data ?? (() => this.project), fieldName, editorFieldName ?? fieldName, propertyType ?? FieldType.string, nullable ?? false);
        };

        addField("clientId");
        addField("contactId");

        addField("line1", FieldType.string, false, undefined, () => this.project.defaultAddress);
        addField("locality", FieldType.string, true, undefined, () => this.project.defaultAddress);
        addField("region", FieldType.string, true, undefined, () => this.project.defaultAddress);
        addField("postcode", FieldType.string, false, undefined, () => this.project.defaultAddress);
        addField("country", FieldType.string, true, undefined, () => this.project.defaultAddress);
        addField("shippingNotes", FieldType.string, true);
    }

    async resetEditControls() {
        await super.resetEditControls();
        await this.loadPickerDisplaysFromCache();

        await this.render();
    }

    async loadOrRefresh(): Promise<void> {
        await super.loadOrRefresh();

        await this.loadPickerDisplaysFromCache();

        this.lat = this.project.defaultAddress.latitude ? parseFloat(this.project.defaultAddress.latitude) : undefined;
        this.lng = this.project.defaultAddress.longitude ? parseFloat(this.project.defaultAddress.longitude) : undefined;


        await this.render();
    }

    getValidationErrors(): string[] {
        const errors = super.getValidationErrors();

        if (!this.project) {
            throw new DevelopmentError("Project is null");
        }
        if (this.project.state == ProjectState.Active) {
            const client = this.project.clientId;
            const contact = this.project.contactId;

            if (isEmptyOrSpace(client))
                errors.push(tlang`Please select a %%client%%`);

            if (isEmptyOrSpace(contact))
                errors.push(tlang`Please select a %%contact%%`);

        }

        return errors;
    }

    protected updateAddress(address: Place) {
        const latlng = updateAddress(address, this.dataBinding);

        if (latlng) {
            if (this.project) {
                //Updating the client values so it gets saved
                this.project.defaultAddress.latitude = latlng.lat.toString();
                this.project.defaultAddress.longitude = latlng.lng.toString();

                //Update the render values
                this.lat = latlng.lat;
                this.lng = latlng.lng;
            }

            this.render();//no wait
        }
    }

    protected async template(): PromiseTemplate {
        const forms = new FormInputAssistant(this.dataTracker, this.projectManager.isReadonly());
        return html`
            <form class="form-two-col">
                <div class="row">
                    <div>
                        ${forms.textRequired("title", tlang`%%project%% Title`, 100)}

                        ${forms.picker("clientId", this.clientPickerDisplay, async () =>
                                await this.selectClient(), tlang`%%client%%`)}
                        ${forms.picker("contactId", this.contactPickerDisplay, async () =>
                                await this.selectContact(), tlang`%%contact%%`)}
                    </div>
                    <div>
                        ${forms.note("description", tlang`Description`)}
                    </div>
                </div>
                <h2>${tlang`Shipping`}</h2>
                <div class="row">
                    <div>
                        <google-place-autocomplete class="left right" data-id="clientauto" outline
                                                   searchType=${"address"}
                                                   .apiKey=${googleMapApiKey} label=${tlang`Lookup`}
                                                   @place-changed=${_ => this.updateAddress(_.detail.place)}>
                        </google-place-autocomplete>

                        ${forms.text("line1", tlang`Street Address`, 120)}
                        ${forms.text("locality", tlang`City`, 120)}
                        ${forms.text("region", tlang`State`, 120)}
                        ${forms.text("postcode", tlang`Post Code`, 20)}
                        ${forms.text("country", tlang`Country`, 80)}
                        ${forms.note("shippingNotes", tlang`Shipping Notes`, 100)}
                    </div>
                    <div>
                        ${mapTemplate(this.lat, this.lng)}
                    </div>
                </div>
            </form>
        `;
    }

    private async loadPickerDisplaysFromCache() {
        this.clientPickerDisplay = (await this.clientCache.get(this.project.clientId))?.displayValue ?? "";
        this.contactPickerDisplay = (await this.contactCache.get(this.project.contactId))?.displayValue ?? "";
    }

    private async selectClient() {
        const selectedClient = await ClientPicker();

        if (!selectedClient) {
            return;
        }

        this.dataBinding.setValue("clientId", selectedClient.id);
        const clientData = (await this.clientCache.get(selectedClient.id));
        this.clientPickerDisplay = clientData?.displayValue ?? "";

        this.dataBinding.setValue("contactId", selectedClient.primaryContactId ?? emptyGuid);
        this.contactPickerDisplay = selectedClient.primaryContactName ?? "";

        await this.render();
    }

    private async selectContact() {
        const selectedClient = this.dataBinding.getValue("clientId");

        if (selectedClient == emptyGuid) {
            this.dataBinding.setValue("contactId", emptyGuid);
            this.contactPickerDisplay = "";

            fireQuickWarningToast(tlang`Please select a %%client%% first.`, 1000);

        } else {
            const selectedContact = await ContactPicker(selectedClient);

            if (selectedContact) {
                this.dataBinding.setValue("contactId", selectedContact.id);
                this.contactPickerDisplay = selectedContact.name ?? "";
            }
        }

        await this.render();
    }
}
