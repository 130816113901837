import { html, render } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { ServiceResponseInvalid, ServiceResponseType } from './service_response';
import { Toast } from 'bootstrap';
import { getInternalId } from './components/ui/databinding/databinding';
import { tlang } from './language/lang';
import { EventVoid } from './components/ui/events';
import { disableUI, enableUI } from './ui-lock';
import { EventBus } from './event-bus';

const readableDelayTime = 5000;

export enum ToastType {
    Error, Warning, Success,
}

export interface toastOptions {
    container?: HTMLElement;
    title: string;
    message: string;
    autoHide?: boolean;
    type: ToastType;
    delay?: number;
}

export function fireQuickSuccessToast(message: string, delay = 1500) {
    fireToast({
        message: message,
        title: "Success",
        autoHide: true,
        delay: delay,
        type: ToastType.Success
    });
}
export function fireQuickWarningToast(message: string, delay = readableDelayTime) {
    fireToast({
        message: message,
        title: "Information",
        autoHide: true,
        delay: delay,
        type: ToastType.Warning
    });
}
export function fireQuickErrorToast(message: string, delay = readableDelayTime) {
    fireToast({
        message: message,
        title: "Error",
        autoHide: true,
        delay: delay,
        type: ToastType.Error
    });
}

export interface AutoSaveTag {
    saving: EventVoid;
    completed: (success: boolean) => void;
}


export async function getAutoSaveNotifier(exitAsSaved?: boolean): Promise<AutoSaveTag> {
    return new Promise(resolve => {
        const toastId = `toast_${getInternalId()}`;
        const theToast: HTMLDivElement = document.createElement('div');


        const container = (document.querySelector('.toast-container') as HTMLElement) ?? document.body;
        let success = true;

        let template = html`<i class="fa-solid fa-floppy-disk"></i> ${tlang`Auto-Save In Progress`}`;

        const doRender = () => {
            const toastTemplate = success
                ? html`
                <div class="toast-body text-success">
                    ${template}
                </div>
                `
                : html`
                <div class="toast-body text-danger">
                    ${template}
                </div>
                `;
            render(html`
                <div id=${toastId} role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="${false}"
                    class="toast save-notification-toast align-items-center">
                    ${toastTemplate}
                </div>`, theToast);
        };
        const result = {
            saving: () => {
                disableUI();
                template = html`<i class="fa-solid fa-floppy-disk"></i> ${tlang`Auto-Save In Progress`}`;
                doRender();
            },
            completed: (completed: boolean) => {
                try {
                    enableUI();
                } catch {
                    //
                }
                success = completed;
                if (success)
                    template = html`<i class="fa-solid fa-check"></i> ${tlang`Auto-Save Complete`}`;
                else {
                    template = html`<i class="fa-solid fa-circle-exclamation"></i> ${tlang`Auto-Save Failed`}`;
                }

                doRender();
                setTimeout(() => toastInstance.dispose(), 1500);
            }
        };

        if (exitAsSaved)
            result.completed(true);
        else
            result.saving();

        const toast = theToast.querySelector('#' + toastId) as HTMLDivElement;
        toast.addEventListener("hidden.bs.toast", () => {
            theToast.remove();
        });
        toast.addEventListener("shown.bs.toast", () => {
            resolve(result);
        });

        container.appendChild(theToast);
        const toastInstance = Toast.getOrCreateInstance(toast);
        toastInstance.show();

    });

}
/**
 * this is intentioanlly duplicated from above, as im not sure how different it might become
 * @param exitAsSaved
 * @returns
 */
export async function getSaveNotifier(exitAsSaved?: boolean): Promise<AutoSaveTag> {
    return new Promise(resolve => {
        const toastId = `toast_${getInternalId()}`;
        const theToast: HTMLDivElement = document.createElement('div');

        const container = (document.querySelector('.toast-container') as HTMLElement) ?? document.body;
        let success = true;

        let template = html`<i class="fa-solid fa-floppy-disk"></i> ${tlang`Save In Progress`}`;

        const doRender = () => {
            const toastTemplate = success
                ? html`
                <div class="toast-body text-success">
                    ${template}
                </div>
                `
                : html`
                <div class="toast-body text-danger">
                    ${template}
                </div>
                `;
            render(html`
                <div id=${toastId} role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="${false}"
                    class="toast save-notification-toast align-items-center">
                    ${toastTemplate}
                </div>`, theToast);
        };
        const result = {
            saving: () => {
                disableUI();
                template = html`<i class="fa-solid fa-floppy-disk"></i> ${tlang`Save In Progress`}`;
                doRender();
            },
            completed: (completed: boolean) => {
                success = completed;
                enableUI();
                if (success)
                    template = html`<i class="fa-solid fa-check"></i> ${tlang`Save Complete`}`;
                else
                    template = html`<i class="fa-solid fa-circle-exclamation"></i> ${tlang`Save Failed`}`;
                doRender();
                setTimeout(() => toastInstance.dispose(), 1500);
            }
        };

        if (exitAsSaved)
            result.completed(true);
        else
            result.saving();

        const toast = theToast.querySelector('#' + toastId) as HTMLDivElement;
        toast.addEventListener("hidden.bs.toast", () => {
            theToast.remove();
        });
        toast.addEventListener("shown.bs.toast", () => {
            resolve(result);
        });

        container.appendChild(theToast);
        const toastInstance = Toast.getOrCreateInstance(toast);
        toastInstance.show();

    });

}


//We can add helper functions to for each type that will set defaults, and we do not have to specify all options.
function fireToast(options: toastOptions) {
    options.container = options.container ? options.container : (document.querySelector('.toast-container') as HTMLElement) ?? document.body;
    options.autoHide = options.autoHide ? options.autoHide : false;
    options.delay = options.delay ? options.delay : 1000;

    const toastId = `toast_${getInternalId()}`;
    const theToast: HTMLDivElement = document.createElement('div');

    let classType: string;

    switch (options.type) {
        case ToastType.Error:
            classType = "bg-danger text-white";
            break;
        case ToastType.Warning:
            classType = "bg-warning text-black";
            break;
        case ToastType.Success:
            classType = "bg-success text-white";
            break;
        default:
            classType = "bg-primary text-white";
            break;
    }


    //TODO: do we want to allow unsave html in the message?
    render(html`
        <div id=${toastId} role="alert" aria-live="assertive" aria-atomic="true"
            class="toast align-items-center  ${classType} border-0" data-bs-delay="${options.delay}"
            data-bs-autohide="${options.autoHide}">
            <div class="toast-header">
                <strong class="me-auto">${options.title}</strong>
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div class="toast-body">
                ${unsafeHTML(options.message)}
            </div>
        </div>`, theToast);

    const toast = theToast.querySelector('#' + toastId) as HTMLDivElement;
    toast.addEventListener("hidden.bs.toast", () => {
        theToast.remove();
    });
    options.container.appendChild(theToast);
    Toast.getOrCreateInstance(toast).show();
}

export function fireToastError(response: ServiceResponseInvalid | Error | string, container?: HTMLElement) {
    let title = "Error";
    if (typeof response === "string") response = new Error(response);

    let message = "";
    if (response instanceof Error) {
        message = `${response.name} ${response.message} ${response.stack}`;
    } else {
        if (response.responseType == ServiceResponseType.UnAuthenticated) {
            title = "UnAuthenticated";
            message = "Please contact admin if you are having trouble";
        } else if (response.responseType == ServiceResponseType.UnAuthorized) {
            title = "UnAuthorized";
            message = "you do not have the permission for this process";

        } else message = `${response.responseError?.message ?? "Unknown Error"}`;
    }

    fireToast({
        title: title, container: container, type: ToastType.Error, autoHide: false, message: message
    });
}

export enum messageType {
    default,
    info,
    warn,
    error
}

export enum hideDelay {
    default,
    short,
    normal,
    long,
    extraLong,
    infinite
}

export interface Notification {
    message: string;
    messageType: messageType;
    title?: string;
    detail?: string;
    hideDelay: hideDelay;
}


// this is an interface specifically for publishing on siteConfig for other services.
// Those services only need to know how to notify, not how to respond to notifications
export interface NotificationHub {
    notify(notification: Notification);
    error(message: string);
    warn(message: string);
    info(message: string);
}


class NotificationSpecificEventBus extends EventBus<Notification> {

    constructor() {
        super('notification event bus');
    }

    notify(notification: Notification) {
        this.emit('notify', notification);
    }

    error(message: string) {
        this.notify({
            message,
            messageType: messageType.error
        } as Notification)
    }

    warn(message: string) {
        this.notify({
            message,
            messageType: messageType.warn
        } as Notification)
    }

    info(message: string) {
        this.notify({
            message,
            messageType: messageType.info
        } as Notification)
    }
}
const notificationBus = new NotificationSpecificEventBus();
export { notificationBus }



function wireupNotifierEventBus() {
    notificationBus.on('notify', (event) => {
        const notifyInfo = event.detail as Notification;
        switch (notifyInfo.messageType) {
            case messageType.error:
                fireQuickErrorToast(notifyInfo.message);
                break;
            case messageType.warn:
                fireQuickWarningToast(notifyInfo.message);
                break;
            case messageType.info:
            case messageType.default:
                fireQuickSuccessToast(notifyInfo.message);
                break;
        }
    })
}

wireupNotifierEventBus();
