import { getApiFactory } from "../../api/api-injector";
import { constructAsync } from "../../async-constructor";
import { EventSnippet } from "../../components/ui/events";
import { ProjectContainer, ProjectContainerManager } from "../../projects/data/project-container";
import { ProjectView } from "../../projects/views/project-view";
import { cache } from "../cache/cache-registry";
import { FranchiseeProjectView } from "./views/franchisee-project-view";

export function getProjectContainer(id: string): ProjectContainer {
    return new ProjectContainer(id,
        null, null);
}
export function getProjectContainerManager(id: string | ProjectContainer): ProjectContainerManager {
    const container = id instanceof ProjectContainer ? id : getProjectContainer(id);
    return new ProjectContainerManager(container, getApiFactory().project());
}

export async function projectViewFactory(projectContainer: ProjectContainer, title: EventSnippet): Promise<ProjectView> {
    return await constructAsync(new FranchiseeProjectView({
        title: title,
        projectContainerManager: getProjectContainerManager(projectContainer),
        quoteCache: cache().quote,
        purchaseOrderCache: cache().purchaseOrder
    }));
}
