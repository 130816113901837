import { FranchiseePurchaseOrderDEView, FranchiseePurchaseOrderView } from "../franchisee-purchase-order-view";
import { Project } from "../../../../api/dealer-api-interface-project";
import {
    FileResponse,
    PurchaseOrderReport,
    ReportItemGroup,
    ReportPurchaseOrderDetails,
    ReportQuoteItem,
    ReportQuoteItemType,
    ReportSupplierDetails
} from "../../../../api/dealer-api-interface-report";
import { userDataStore } from "../../../common/current-user-data-store";
import { validId } from "../../../../components/ui/helper-functions";
import { Quote, QuoteItem, QuoteItemPrice, QuoteItemProviderData } from "../../../../api/dealer-api-interface-quote";
import {
    getReportCompanyDetails,
    getReportProjectDetails,
    isFrame,
    isShipping,
    purchaseOrderStateToReportState,
} from "../../../Reporting/report-helpers";
import { quoteSupplierProvider } from "../../../../quotes/data/quoteSupplierProvider";
import { base64ToObject } from "../../../../blob/converters";
import { V6QuoteItem } from "../../../../v6config/v6-quote-item/v6-quote-item-data";
import { frameOptionV6Template } from "../../../Reporting/v6/v6-report-helpers";
import { FranchiseeApi } from "../../../../api/franchisee-api";
import { getApiFactory } from "../../../../api/api-injector";
import { QuoteApi } from "../../../../api/quote-api";
import { ProjectApi } from "../../../../api/project-api";
import { ReportApi } from "../../../../api/report-api";
import { cache } from "../../../cache/cache-registry";
import { PurchaseOrderDataEntryView } from "../../../../purchase-orders/views/purchase-order-view";
import { PurchaseOrderApi } from "../../../../api/purchase-order-api";
import { PurchaseOrder, PurchaseOrderItem, PurchaseOrderState } from "../../../../api/dealer-api-interface-franchisee";
import { getPurchaseOrderNumberFormatted } from "../../../../purchase-orders/data/purchase-order-helper-functions";
import { getQuoteSuppliers } from "../../../../quotes/quote-service";
import { ResultGetSupplierTerms } from "../../../../api/supplier-api";
import { NullPromise } from "../../../../null-promise";

export class FranchiseeV6PurchaseOrderDEView extends FranchiseePurchaseOrderDEView {
    userCache = cache().userProfile;
    franchiseeApi: FranchiseeApi = getApiFactory().franchisee();
    quoteApi: QuoteApi = getApiFactory().quote();
    projectApi: ProjectApi = getApiFactory().project();
    reportApi: ReportApi = getApiFactory().reporting();
    purchaseOrderApi: PurchaseOrderApi = getApiFactory().purchaseOrder();

    public override async generateReport(): NullPromise<FileResponse> {
        const projectId = await this.projectId();
        let project: Project | null = null;

        if (validId(projectId)) {
            const projectResult = await this.projectApi.getProject({ projectId: projectId });

            if (projectResult) {
                project = projectResult.project;
            }
        }

        const quoteId = await this.quoteId();
        let quote: Quote | null = null;

        if (validId(quoteId)) {
            const quoteResult = await this.quoteApi.getQuote({quoteId: quoteId});

            if (quoteResult) {
                quote = quoteResult.quote;
            }
        }

        const createdUser = await this.userCache.get(this.purchaseOrder.creationUserId);

        await this.purchaseOrderContainerManager.needsPurchaseOrder();

        let issuedDate: string | null = null;
        const auditStateResults = await this.purchaseOrderApi.getPurchaseOrderStateAudit({
            purchaseOrderId: this.purchaseOrder.id,
            state: PurchaseOrderState.Issued
        });

        if (auditStateResults) {
            issuedDate = auditStateResults.purchaseOrderStateAudit.sort((a, b) => {
                return a.dateModified.localeCompare(b.dateModified, "en", { sensitivity: "base" });
            })[0]?.dateModified;
        }

        const itemKeys = this.purchaseOrderContainerManager.container.items?.map(item => item.quoteItemId) ?? [];

        const itemSummaries = await this.quoteApi.getQuoteItemsSummaryById(
            { quoteItemId: itemKeys });

        const lineItems = itemSummaries != null ? itemKeys.map(x => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const item = itemSummaries.items.find(y => y.id == x)!;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const data = itemSummaries.data.find(y => y.id == x)!;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const price = itemSummaries.prices.find(y => y.id == x)!;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const purchaseOrderItem = this.purchaseOrderContainerManager.container.items!.find(y => y.quoteItemId == x)!;

            return {
                purchaseOrderItem,
                item,
                data,
                price
            };
        }) : [];

        const company = userDataStore.franchisee;
        const supplierDetails = await this.getReportSupplierDetails(this.purchaseOrder.supplierId);

        const reportRequest: PurchaseOrderReport = {
            projectDetails: getReportProjectDetails(project),
            supplierDetails: supplierDetails,
            purchaseOrderDetails: {
                ...this.getReportPurchaseOrderDetails(this.purchaseOrder, lineItems, quote),
                ...{
                    staffMemberName: createdUser?.displayValue ?? "",
                    staffMemberEmail: createdUser?.data.emailAddress ?? "",
                    issuedDate: issuedDate
                }
            },
            companyDetails: getReportCompanyDetails(this.franchiseeApi.api.fullUrl(`api/file/${company?.negativeLogoVirtualPath}`))
        };

        return await this.reportApi.getPurchaseOrderReport({ purchaseOrderReport: reportRequest });
    }

    private async getReportSupplierDetails(supplierId: string): Promise<ReportSupplierDetails> {
        //1 PO is mapped to 1 quote, thus we can get the quote suppliers and filter to the correct one using the id.
        const suppliers = await getQuoteSuppliers();
        const quoteSupplier = suppliers.find(x => x.supplierId == supplierId);
        let terms: ResultGetSupplierTerms | null = null;

        if (quoteSupplier) {
            terms = await this.supplierApi.getSupplierTerms({ supplierId: supplierId });

            return { name: quoteSupplier.description, termAndConditions: terms?.TermsAndConditions ?? "" };
        }

        return { name: "", termAndConditions: "" };
    }

    private getReportPurchaseOrderDetails(purchaseOrder: PurchaseOrder,
                                          lineItems:
                                              {
                                                  item: QuoteItem;
                                                  data: QuoteItemProviderData;
                                                  price: QuoteItemPrice;
                                                  purchaseOrderItem: PurchaseOrderItem
                                              }[],
                                          quote: Quote | null):
        ReportPurchaseOrderDetails {

        const reportItems: ReportQuoteItem[] | null = lineItems?.map(li => {
            let thumbnail: string | null = null;
            let itemType: ReportQuoteItemType | null = null;

            if (isShipping(li.item)) {
                thumbnail = this.franchiseeApi.getFranchiseeImagePath("delivery", "svg");
                itemType = ReportQuoteItemType.Shipping;
            } else if (isFrame(li.item)) {
                thumbnail = this.quoteApi.api.fullUrl(`api/file/${li.item.virtualThumbnailPath}`);
                itemType = ReportQuoteItemType.ProviderContent;
            }

            let options: ReportItemGroup | null = null;
            if (li.item.serviceProvider === quoteSupplierProvider.v6) {
                const itemData = li.data;

                if (itemData) {
                    const v6QuoteItem = (base64ToObject(itemData.providerData)) as V6QuoteItem;
                    options = frameOptionV6Template(v6QuoteItem);
                }
            }

            return {
                title: li.item.title, description: li.item.description,
                imageUrl: thumbnail ?? "",
                quantity: li.item.quantity,
                options: options,
                price: li.price?.supplierGrossQuantityCost ?? 0,
                optionTree: "",
                quoteItemType: itemType ?? ReportQuoteItemType.ProviderContent,
                contentType: null,
                itemNumber: null,
                cost: li.price?.supplierGrossSingleUnitCost ?? null,
                priceAdjustment: li.price?.priceAdjustment ?? null,
                margin: li.price?.marginPercentage ?? null,
                imageData: null,
                configurationId: null,
                dynamicPriceValues: null,
            } as ReportQuoteItem;
        }) ?? null;

        return {
            issuedDate: null,
            items: reportItems ?? [],
            deliveryDate: purchaseOrder.installationDate,
            notesToVendor: purchaseOrder.notesToVendor,
            purchaseOrderNumber: getPurchaseOrderNumberFormatted(purchaseOrder),
            state: purchaseOrderStateToReportState(purchaseOrder.state),
            projectDescription: purchaseOrder.projectDescription,
            quoteDescription: purchaseOrder.quoteDescription,
            supplierReference: purchaseOrder.reference,
            staffMemberEmail: "",
            staffMemberName: "",
            priceSummary: {
                tax: purchaseOrder.calculatedTaxAmount,
                subTotal: purchaseOrder.calculatedNetTotal,
                total: purchaseOrder.calculatedGrossTotal
            },
            quoteTitle: quote?.title ?? ""
        }
    }
}

export class FranchiseeV6PurchaseOrderView extends FranchiseePurchaseOrderView {
    protected createView(): PurchaseOrderDataEntryView {
        return new FranchiseeV6PurchaseOrderDEView(this.options, this);
    }
}
