//extra information

export interface FamilyResourceTag {
    objectReference: string;
    code: string;
    libId?: number;
    id?: number;
    description?: string;
    resourceClassId?: number;
}

export interface ResourceFamily {
    libId: number;
    id: number;
    name: string;
    items: FamilyResourceTag[];
    families: ResourceFamily[];
}

export interface ResourceFamilyResults {
    family: ResourceFamily;
}
export interface ResourceFamilyEndpoint {
    getResourceClass(): number;
    //    supplier: data.V6Supplier;
    getData(): Promise<ResourceFamilyResults | null>;
    getResourceImageUrl(resourceClass: number, libId: number, id: number): string;
    getFamilyImageUrl(resourceClass: number, libId: number, id: number): string;
}

export class ResourceFamilyPickerDataProviderBase implements ResourceFamilyEndpoint {
    getResourceClass(): number {
        throw new Error("Method not implemented.");
    }
    getData(): Promise<ResourceFamilyResults | null> {
        throw new Error("Method not implemented.");
    }
    getResourceImageUrl(_resourceClass: number, _libId: number, _id: number): string {
        throw new Error("Method not implemented.");
    }
    getFamilyImageUrl(_resourceClass: number, _libId: number, _id: number): string {
        throw new Error("Method not implemented.");
    }
}