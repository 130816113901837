import { DateTime } from "luxon";

const rfc3339Date = "yyyy-MM-dd";
const rfc3339DateTime = "yyyy-MM-dd'T'HH:mm:ss";

/**
 * NOTE-> Server interfaces say the type is Date, but infact it is a string date
 * @param serverDateTime a string representation of a date time as sent by server in '2022-12-01T14:56:00.333Z'
 *
 */
export function serverDateTimeToLocal(serverDateTime: string): Date {
    return DateTime.fromISO(serverDateTime).toLocal().toJSDate();
}

/**
 *
 * @param value local time Javascript date
 * @returns property formatted UTC date
 */
export function localDateTimeToServer(value: Date): string {
    return DateTime.fromJSDate(value).toUTC().toISO();
}

/**
 *
 * @param plusDays add days to today
 * @returns a date truncated to start of day, plus any days
 */
export function today(plusDays = 0): Date {
    return DateTime.local().startOf("day").plus({ days: plusDays }).toJSDate();
}

/**
 *
 * @param value local date
 * @returns local datetime/date truncated to start of day and returned as UTC
 */
export function localDateToServer(value: Date): string {
    return DateTime.fromJSDate(value).startOf("day").toUTC().toISO();
}

/**
 * https://www.rfc-editor.org/rfc/rfc3339
 * @param localDateTime
 * @returns a formatted date suitable for using in Input[type=date].value
 */
export function localDateToRFC3339(localDateTime: Date): string {
    return DateTime.fromJSDate(localDateTime).toLocal().toFormat(rfc3339Date);
}

/**
 * https://www.rfc-editor.org/rfc/rfc3339
 * @param localDateTime
 * @returns a formatted datetime suitable for using in Input[type=date].value
 */
export function localDateTimeToRFC3339(localDateTime: Date): string {
    return DateTime.fromJSDate(localDateTime).toLocal().toFormat(rfc3339DateTime);
}

/**
 *
 * @param serverDateTime
 * @returns a formatted date suitable for using in Input[type=date].value
 */
export function serverDateToLocalRFC3339(serverDateTime: string): string {
    return localDateToRFC3339(serverDateTimeToLocal(serverDateTime));
}

export function serverDateTimeToLocalRFC3339(serverDateTime: string): string {

    return localDateTimeToRFC3339(serverDateTimeToLocal(serverDateTime));
}

export function localRFC3339DateToServer(value: string): string {
    return DateTime.fromFormat(value, rfc3339Date).toUTC().toISO();
}

export function localRFC3339DateTimeToServer(value: string): string {
    return DateTime.fromFormat(value, rfc3339DateTime).toUTC().toISO();
}

export function localToISOStringWithTimezoneOffset(value: string): string {
    const date = new Date(value);
    // date.getTimezoneOffset() gives offset in minutes, so we need to convert it to miliseconds
    // by multiplying it with 60000
    const timeZoneOffsetInMiliseconds = date.getTimezoneOffset() * 60000;
    const localDate = new Date(date.getTime() - timeZoneOffsetInMiliseconds);
    return localDate.toISOString();
}


(function test() {
    const serverDate = '2022-05-27T12:00:00.000Z';
    const testDate = serverDateTimeToLocal(serverDate);
    console.log('TEST Date Formatters');
    console.log(`server="${serverDate}" roundtrip="${localDateTimeToServer(testDate)}"`);
    console.log(`local date = "${testDate.toString()}"`);
    console.log(`local as Date to Server = "${localDateToServer(testDate)}" "${serverDateTimeToLocal(localDateToServer(testDate))}`);
    console.log(`serverDate to Editor Value = "${serverDateToLocalRFC3339(serverDate)}"`);
    console.log(`serverDateTime to Editor Value = "${serverDateTimeToLocalRFC3339(serverDate)}"`);
    console.log(`localDate to Editor Value = "${localDateToRFC3339(testDate)}"`);
    console.log(`localDateTime to Editor Value = "${localDateTimeToRFC3339(testDate)}"`);

    console.log(`serverDateTime to Editor Value = "${serverDateTimeToLocalRFC3339(serverDate)}"`);
    console.log(`serverDate to Editor Value = "${serverDateToLocalRFC3339(serverDate)}"`);
    console.log(`Editor Date Value to Server = "${localRFC3339DateToServer(serverDateToLocalRFC3339(serverDate))}"`);
    console.log(`Editor DateTime Value to Server = "${localRFC3339DateTimeToServer(serverDateTimeToLocalRFC3339(serverDate))}"`);



    console.log((testDate));
})();
