import { AutoSaveTag, getAutoSaveNotifier } from "../../toast-away";
import { WaitPatientlySaving } from "./modal-saving";

export const SaveNotifier = {
    auto: 1,
    normal: 2
};
export async function getSaveIndicator(autoSaving?: boolean): Promise<AutoSaveTag> {
    if (autoSaving)
        return await getAutoSaveNotifier();
    else {
        const waitNotifier = (): AutoSaveTag => {
            const wait = new WaitPatientlySaving();
            const tag: AutoSaveTag = {
                saving: () => {
                    wait.showModal();
                },
                completed: (_completed: boolean) => {
                    wait.hideModal();
                }
            };
            return tag;
        };
        return waitNotifier();
    }
}

