import { clone } from "../../../components/clone";
import { QuoteProviderData } from "../../../quotes/data/quote-provider-data";
import { quoteSupplierProvider } from "../../../quotes/data/quoteSupplierProvider";

import { V6PropertyGroup, V6QuoteIGU } from "../../../v6config/v6-quote-item/v6-quote-item-data";
import { sanitizePropertyGroupForSave, v6Config } from "../../../v6config/v6config";



export interface V6FranchiseeQuoteProviderData extends QuoteProviderData {
    defaultOptions?: V6PropertyGroup[] | null;
    igu?: V6QuoteIGU[] | null;
}

// This should always be updated to provide the correct new item matching the version.
// any changes here should be reflected in a change of version
async function createV6QuoteProviderData(supplierId: string): Promise<V6FranchiseeQuoteProviderData> {
    const qDefaults = clone(await v6Config().getQuoteDefaults(supplierId));
    if (qDefaults)
        sanitizePropertyGroupForSave(qDefaults, true);
    const obj: V6FranchiseeQuoteProviderData = {
        supplierType: quoteSupplierProvider.v6,
        version: v6QuoteDataProviderVersion,
        defaultOptions: qDefaults
    };
    return obj;
}


//any time any changes are made to any part of the FranchiseeQuoteProviderData interface at any
//level then this version should be increased, and migration code should be added to take
//the object from one version to another.
export const v6QuoteDataProviderVersion = 2;
export const v7QuoteDataProviderVersion = 1;

export async function createFranchiseeQuoteProviderData(supplierType: string, supplierId: string): Promise<QuoteProviderData> {
    if (supplierType === quoteSupplierProvider.v6)
        return await createV6QuoteProviderData(supplierId);
    return { supplierType: quoteSupplierProvider.v7, version: v6QuoteDataProviderVersion };
}

type EventProviderData = (data: QuoteProviderData) => Promise<QuoteProviderData>;

/**
 *
 * @param data data before migrations were used that we want to convert
 * @returns a proper quote provider data object
 */
async function v6ConvertToVersion1(data: QuoteProviderData): Promise<QuoteProviderData> {
    const version = data.version ?? 0;
    if (version !== 0) return data;
    //if we are here we are at a pre-version were the data isn't reallyu a quoteproviderdata
    const v: V6FranchiseeQuoteProviderData = {
        supplierType: quoteSupplierProvider.v6,
        version: 1,
        defaultOptions: (data as any).defaultOptions ?? null
    };
    return v;
}

async function v6ConvertToVersion2_AddIGU(data: QuoteProviderData): Promise<QuoteProviderData> {
    const version = data.version ?? 0;
    if (version !== 1) return data;
    const newData = data as V6FranchiseeQuoteProviderData;
    if (!newData.igu) newData.igu = [];
    return data;
}
const v6Upgrades: { ver: number, event: EventProviderData; }[] = [
    { ver: 1, event: v6ConvertToVersion1 },
    { ver: 2, event: v6ConvertToVersion2_AddIGU }
];

/**
 * migrating of quote provider data.
 * @param data the data that might need migration
 * @returns
 */
async function upgradeV6(data: QuoteProviderData): Promise<QuoteProviderData> {
    for (let i = 0; i < v6Upgrades.length; i++) {
        const upgrade = v6Upgrades[i];
        const version = data.version ?? 0;
        if (upgrade.ver > version) data = await upgrade.event(data);
    }
    return data;
}

/**
 *
 * @param data an object to check and convert and passthrough
 * @returns
 */
export async function validateAndUpdateFranchiseeQuoteProviderData(data: QuoteProviderData): Promise<QuoteProviderData> {
    //Keep this at the very bottom of the file.
    const currentVersion = data.version ?? 0;
    const supplierType = data.supplierType ?? quoteSupplierProvider.v6;

    if (supplierType === quoteSupplierProvider.v6)
        if (currentVersion !== v6QuoteDataProviderVersion) {
            return await upgradeV6(data);
        } else
            return data;

    //add v7 stuff here
    return data;
}