// eslint-disable-next-line import/named
import { html, TemplateResult } from "lit";
import { DataBinding } from "../databinding/databinding";
import { DataTracker } from "../databinding/data-tracker";
import { ifDefined } from "lit/directives/if-defined.js";

export type FormDisplayFormat = (value: any) => string;

//TODO It might be saver to use an Enum for the type (will show dev the options then and limit spelling mistakes)
export function formInput(fieldName: string, title: string, dataBinding: DataBinding, dataTracker: DataTracker,
    type = "text", readOnly = false, required = false, maxLength?: number, decimalPlace?: number,
    classes?: string): TemplateResult {

    switch (type) {
        case "area":
            return html`
                <bs-form-area class=${classes ?? ""} data-id=${dataBinding.field(fieldName)} type=${type}
                    .value=${dataTracker.getObjectDisplayValue(fieldName)} ?required=${required} data-label=${title}
                    ?readonly=${readOnly} maxlength="${ifDefined(maxLength)}">
                </bs-form-area>`;
        case "checkbox":
            return html`
                <bs-form-checkbox class=${classes ?? ""} data-id=${dataBinding.field(fieldName)} ?readonly=${readOnly}
                    ?checked=${dataTracker.getObjectValue(fieldName)} ?required=${required} data-label=${title}>
                </bs-form-checkbox>`;
        case "hidden":
            return html`<input class=${classes ?? "" } type="hidden" id=${dataBinding.field(fieldName)}
    value="${dataTracker.getObjectDisplayValue(fieldName)}">`;
        case "money":
            return html`
                <bs-form-money class=${classes ?? "" } data-id=${dataBinding.field(fieldName)} dp=${decimalPlace ?? 2}
                    maxlength="${ifDefined(maxLength)}" ?readonly=${readOnly} .value=${dataTracker.getObjectDisplayValue(fieldName)}
                    ?required=${required} data-label=${title}>
                </bs-form-money>`;
        default:
            return html`
                <bs-form-input class=${classes ?? "" } data-id=${dataBinding.field(fieldName)} maxlength="${ifDefined(maxLength)}"
                    type=${type} ?readonly=${readOnly} .value=${dataTracker.getObjectDisplayValue(fieldName)} ?required=${required}
                    data-label=${title}>
                </bs-form-input>`;
    }
}