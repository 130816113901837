import { isEmptyOrSpace } from "../../components/ui/helper-functions";
import { lang } from "../../language/lang";
import { V6QuoteItem, V6Validation } from "./v6-quote-item-data";


export interface V6QuoteItemPrice {
    code: string;
    resourceId: string;
    netSellPrice: number;
    totalSellPrice: number;
    qty: number;
}

export const validationStyles = {
    note: "Note",
    information: "Information",
    warning: "Warning",
    critical: "Critical"
};

export function highestValidationRank(issues: V6Validation[] | null) {
    let rank = 0;
    issues?.forEach(x => {
        rank = Math.max(rank, validationStyleToRank(x.style));
    });
    return rank;
}
export function validationStyleToRank(style: string) {

    switch (style) {
        case validationStyles.note:
            return 1;
        case validationStyles.information:
            return 2;
        case validationStyles.warning:
            return 3;
        case validationStyles.critical:
            return 4;
    }
    return 0;

};
export function rankToValidationStyle(rank: number) {

    switch (rank) {
        case 0:
            return "";
        case 1:
            return validationStyles.note;
        case 2:
            return validationStyles.information;

        case 3:
            return validationStyles.warning;

        case 4:
            return validationStyles.critical;

    }
    return "";

};

export function getValidationIssueHeading(issue: V6Validation): string {
    switch (issue.displayName) {
        case validationDisplayName.always:
            return `${issue.style} - ${lang(issue.name)}`;
        case validationDisplayName.never:
            return issue.style;
        case validationDisplayName.notEmpty:
            return isEmptyOrSpace(issue.name.trim()) ? issue.style : `${issue.style} - ${lang(issue.name)}`;
    }
    return issue.style;
};

export const validationDisplayName = {
    always: "Always",
    never: "Never",
    notEmpty: "TextNotEmpty"

};

export const messageType = {
    information: 0,
    warning: 1,
    error: 2,
    success: 3 // client side only
};

export interface V6Message {
    messageType: number;
    message: string;
}


export interface ResultGetV6ItemPrice {
    v6DataSessionId: number;
    v6DataTrackingId: string;
    quoteItemPrice: V6QuoteItemPrice;
}

export interface ResultGetV6ItemValidation {
    v6DataSessionId: number;
    v6DataTrackingId: string;
    messages: V6Message[];
    quoteItemPrice: V6QuoteItemPrice | null;
}

export interface ResultQuoteItem {
    //the full set of data incase the service needs to rebuild the entire frame
    //the back end can scan for "changes"
    item: V6QuoteItem | null;
    //this is a tracking id the service can use to retain state and make things faster.
    //the first time data is updated these values can be null and the system will provide
    //a value back to be used next time for improved performance
    v6DataSessionId?: number | null;
    v6DataTrackingId?: string | null;
}

export interface V6ValidationAndPricing {
    validation: V6Message[] | null;
    pricing: V6QuoteItemPrice | null;
}


export interface ResultProcessV6QuoteItem {
    quoteItem: V6QuoteItem;
    extendedInfo: V6ValidationAndPricing;
}


