// eslint-disable-next-line import/named
import { html } from "lit";
import { PromiseSnippet, PromiseTemplate } from "../../../../components/ui/events";
import { ModalDialog } from "../../../../components/ui/modal-base";

const isBetaResourceFilterEnabled = false;
export class Picker extends ModalDialog {
    selectedResource = '';
    interval: NodeJS.Timeout | null = null;
    pickerType: string;
    protected showBetaResources = false;

    pickList = {
        'finish': FinishList,
        'fill': FillList,
        'frametype': FrameTypes,
        'frame': Frames
    };
    constructor(pickerType: string) {
        super();
        this.pickerType = pickerType
    }
    protected async canClose(): Promise<boolean> {
        return true;
    }
    protected async getTitle(): PromiseSnippet {
        return `${this.pickerType} Picker`;
    }
    protected modalSize(): string {
        return "modal-md";
    }
    protected save() {
        this.hideModal();
    }

    private onChange = (ev) => {
        this.selectedResource = ev.target.value;
        this.save();
    }

    private toggleCheck = async () => {
        this.showBetaResources = !this.showBetaResources;
        this.render();
    }

    protected async bodyTemplate(): PromiseTemplate {
        let rawPickList = this.pickList[this.pickerType]
        if (!this.showBetaResources) {
            rawPickList = rawPickList.filter(i => !i.isBeta)
        }
        const groupedList = groupBy(rawPickList, finish => finish.library);
        const libraries = Object.keys(groupedList);
        const maxSize = 25;
        const size = rawPickList.length > maxSize ? maxSize : rawPickList.length + 2;
        return html`
            <select size="${size}" style="height: inherit;" @change=${this.onChange}>
                ${libraries.map(lib => html`<optgroup label="${lib}">
                    ${groupedList[lib]
                    .sort(finish => finish.group)
                    .map(({ group, code, resourceCode, isBeta }: PickerResource) =>
                    html`<option style="${!isBeta ? "" : 'color:red;'}" value="${resourceCode}">${group ? `${group} - ` : ''}${code}
                    </option>`)}
                </optgroup>` )}
            </select>
            ${this.addBetaResourcesToggle()}
        `;
    }

    private addBetaResourcesToggle() {
        if (isBetaResourceFilterEnabled) {
            return html`
            <form>
                <label for="maincheck">Show beta resources</label>
                <input type="checkbox" id="maincheck" value="" .checked=${this.showBetaResources} @click=${this.toggleCheck} />
            </form>`;
        }
        return html``;
    }
}

function groupBy(arr: any[], getter: (any) => any) {
    return arr.reduce((group, product) => {
        const category = getter(product);
        group[category] = group[category] ?? [];
        group[category].push(product);
        return group;
    }, {});
}



class PickerResource {
    library = '';
    group = '';
    code = '';
    resourceCode = '';
    isBeta = false;
    constructor(resourceCode: string, isBeta = false) {
        const match = (/\[...:(.+):(.+)( \((.+)\))?\]/).exec(resourceCode);
        if (match) {
            this.library = match[1];
            this.group = match[4];
            this.code = match[2];
            this.resourceCode = resourceCode;
        }
        if (isBetaResourceFilterEnabled) {
            this.isBeta = isBeta;
        } else {
            this.isBeta = false;
        }
    }
}

const FillList = [
    new PickerResource('[FIL:ALUMINIUM:CLR030]', true),
    new PickerResource('[FIL:ALUMINIUM:CLR040]', true),
    new PickerResource('[FIL:DEMO:1/4X]', true),
    new PickerResource('[FIL:EFS:06.38GRNL]', true),
    new PickerResource('[FIL:EFS:06CT]', true),
    new PickerResource('[FIL:EFS:07.52CL]', true),
    new PickerResource('[FIL:EFS:08CT]', true),
    new PickerResource('[FIL:GLASS:10.00MM FLOAT BO]', true),
    new PickerResource('[FIL:GLASS:10.00MM FLOAT SP]', true),
    new PickerResource('[FIL:GLASS:10.00MM FLOAT UN]', true),
    new PickerResource('[FIL:GLASS:10.00MM OB TOUGH BO]', true),
    new PickerResource('[FIL:GLASS:10.00MM OB TOUGH SP]', true),
    new PickerResource('[FIL:GLASS:10.00MM OB TOUGH UN]', true),
    new PickerResource('[FIL:GLASS:10.00MM TOUGH BO]', true),
    new PickerResource('[FIL:GLASS:10.00MM TOUGH SP]', true),
    new PickerResource('[FIL:GLASS:10.00MM TOUGH UN]', true),
    new PickerResource('[FIL:GLASS:10.38/8/4CRT]', true),
    new PickerResource('[FIL:GLASS:10.38/8/4MM OB TO BO]', true),
    new PickerResource('[FIL:GLASS:10.38/8/4MM TOUGH BO]', true),
    new PickerResource('[FIL:GLASS:10.38/8/4MM TOUGH SP]', true),
    new PickerResource('[FIL:GLASS:10.38/8/4MM TOUGH UN]', true),
    new PickerResource('[FIL:GLASS:10.38AA]', true),
    new PickerResource('[FIL:GLASS:10.38BZ]', true),
    new PickerResource('[FIL:GLASS:10.38BZSPSL]', true),
    new PickerResource('[FIL:GLASS:10.38CLBE]', true),
    new PickerResource('[FIL:GLASS:10.38CPCR]', true),
    new PickerResource('[FIL:GLASS:10.38CPGN]', true),
    new PickerResource('[FIL:GLASS:10.38CPGY]', true),
    new PickerResource('[FIL:STATE:ARCH/CURV 10MM GLASS]'),
    new PickerResource('[FIL:STATE:ARCH/CURVE 6MM GLASS]'),
    new PickerResource('[FIL:STATE:ARCH/CURVE DG GLASS]'),
    new PickerResource('[FIL:STATE:ARGON GAS]'),
    new PickerResource('[FIL:STATE:BLACK SPACERS]'),
    new PickerResource('[FIL:STATE:CLEAR STEP]'),
    new PickerResource('[FIL:STATE:CLEARSTEP]'),
    new PickerResource('[FIL:STATE:DOG/CAT DOOR]'),
    new PickerResource('[FIL:STATE:HEAT SOAK]'),
    new PickerResource('[FIL:STATE:POLISH - 0]'),
    new PickerResource('[FIL:STATE:POLISH - 250]'),
    new PickerResource('[FIL:STATE:POLISH - 270]'),
    new PickerResource('[FIL:STATE:POLISH - 3]')
];

const FinishList = [
    new PickerResource('[COL:ALUMINIUM:BLACK20 (ANOD/AA20)]', true),
    new PickerResource('[COL:ALUMINIUM:BRNZE15 (ANOD/AA15)]', true),
    new PickerResource('[COL:ALUMINIUM:PP (TIMBER_REVEALS)]', true),
    new PickerResource('[COL:DEMO:BLACK (PAINT2)]', true),
    new PickerResource('[COL:EC:20CLEAR (20)]', true),
    new PickerResource('[COL:EC:25LIGHTBRZ (22)]', true),
    new PickerResource('[COL:EC:25MEDBRZ (22)]', true),
    new PickerResource('[COL:EC:2721139S (05)]', true),
    new PickerResource('[COL:EC:BLACK (GASKETS)]', true),
    new PickerResource('[COL:FLPR:BLUE (TEST GROUP)]', true),
    new PickerResource('[COL:K:10 (10)]', true),
    new PickerResource('[COL:NOPE:NOPE (DOES_NOT_EXIST)]', true),
    new PickerResource('[COL:ANEETA:1110G (DULUX WARRANTY)]'),
    new PickerResource('[COL:ANEETA:1114G (DULUX STANDARD)]'),
    new PickerResource('[COL:ANEETA:11393 (DULUX STANDARD)]'),
    new PickerResource('[COL:ANEETA:15UM CLEAR (15UM CLEAR)]'),
    new PickerResource('[COL:ANEETA:18796 (DULUX STANDARD)]'),
    new PickerResource('[COL:ANEETA:19143 (DULUX STANDARD)]'),
    new PickerResource('[COL:ANEETA:19268 (DULUX STANDARD)]'),
    new PickerResource('[COL:ANEETA:19319 (DULUX STANDARD)]'),
    new PickerResource('[COL:ANEETA:19930 (DULUX STANDARD)]'),
    new PickerResource('[COL:ANEETA:19941 (DULUX STANDARD)]'),
    new PickerResource('[COL:ANEETA:19959 (DULUX STANDARD)]'),
    new PickerResource('[COL:ANEETA:19990 (DULUX STANDARD)]'),
    new PickerResource('[COL:ANEETA:2015G (DULUX STANDARD)]'),
    new PickerResource('[COL:ANEETA:GA030A (INTERPON STANDARD)]'),
    new PickerResource('[COL:ANEETA:GA057A (INTERPON STANDARD)]'),
    new PickerResource('[COL:ANEETA:GA078A (INTERPON STANDARD)]'),
    new PickerResource('[COL:ANEETA:GA113A (INTERPON STANDARD)]'),
    new PickerResource('[COL:ANEETA:GA160A (INTERPON STANDARD)]'),
    new PickerResource('[COL:ANEETA:GA211A (INTERPON STANDARD)]'),
    new PickerResource('[COL:ANEETA:GA228A (INTERPON STANDARD)]'),
    new PickerResource('[COL:ANEETA:GA236A (INTERPON STANDARD)]'),
    new PickerResource('[COL:ANEETA:LIGHT BRONZE (BRONZE)]'),
    new PickerResource('[COL:ANEETA:LIGHT BRONZE (UNIVERSAL ANODISERS)]'),
    new PickerResource('[COL:ANEETA:MEDIUM BRONZE (BRONZE)]'),
    new PickerResource('[COL:ANEETA:MEDIUM BRONZE (UNIVERSAL ANODISERS)]'),
    new PickerResource('[COL:ANEETA:SANDSTONE (UNIVERSAL ANODISERS)]'),
    new PickerResource('[COL:ANEETA:SILVER GREY (UNIVERSAL ANODISERS)]'),
    new PickerResource('[COL:ANEETA:SLATE GREY (UNIVERSAL ANODISERS)]'),
    new PickerResource('[COL:STATE:25UM DARK BRONZE (25UM DARK BRONZE)]'),
    new PickerResource('[COL:STATE:25UM LIGH BRONZE (25UM LIGHT BRONZE)]'),
    new PickerResource('[COL:STATE:25UM LIGHT BRONZE (25UM LIGHT BRONZE)]'),
    new PickerResource('[COL:STATE:25UM MEDALLIONBRONZE (25UM MEDALLIONBRONZE)]'),
    new PickerResource('[COL:STATE:25UM MEDIALLIONBRONZ (25UM MEDALLIONBRONZE)]'),
    new PickerResource('[COL:STATE:25um MEDIUM BRONZE (25UM MEDIUM BRONZE)]'),
    new PickerResource('[COL:STATE:25UM UMBER (25UM UMBER)]'),
];


const FrameTypes = [
    new PickerResource('[FTY:ALUMINIUM:ASWFRAME]', true),
    new PickerResource('[FTY:ALUMINIUM:PWD]', true),
    new PickerResource('[FTY:ANEETA:AH]'),
    new PickerResource('[FTY:ANEETA:AV]'),
    new PickerResource('[FTY:ANEETA:MISC]'),
    new PickerResource('[FTY:EFS:101SGC]', true),
    new PickerResource('[FTY:EFS:150SGF]', true),
    new PickerResource('[FTY:EFS:256DGF]', true),
    new PickerResource('[FTY:EFS:AC35]', true),
    new PickerResource('[FTY:EFS:AC50]', true),
    new PickerResource('[FTY:EFS:ACTB50]', true),
    new PickerResource('[FTY:EFS:HD PANELS]', true),
    new PickerResource('[FTY:FLPR:16LR-5 3/4-VRT]', true),
    new PickerResource('[FTY:FLPR:PWD]', true)
];


const Frames = [
    new PickerResource('[FRA:FLPR EFS:AC35 SASH]', true),
    new PickerResource('[FRA:FLPR EFS:AC50 SASH]', true),
    new PickerResource('[FRA:FLPR EFS:ACTB50 SASH]', true),
    new PickerResource('[FRA:FLPR EFS:HINGED DOOR]', true),
    new PickerResource('[FRA:FLPR:NESTABLE]', true),
    new PickerResource('[FRA:FLPR:WIREFRAME ANNOTATED]', true),
    new PickerResource('[FRA:ANEETA:HS 2P FS A TB]'),
    new PickerResource('[FRA:ANEETA:HS 2P SF A]'),
    new PickerResource('[FRA:ANEETA:HS 2P SS A]'),
    new PickerResource('[FRA:ANEETA:HS 2P SS C]'),
    new PickerResource('[FRA:ANEETA:VS 2P SF A]'),
    new PickerResource('[FRA:ANEETA:VS 2P SS A]'),
    new PickerResource('[FRA:ANEETA:VS GLASS]')
];
