import { html, TemplateResult } from "lit";
import { QuoteItemPrice, QuotePrice } from "../../api/dealer-api-interface-quote";
import { clone, compare } from "../../components/clone";
import { isAutoSaving } from "../../components/save-workflow";
import { checkValidations, inRange } from "../../components/ui/data-entry-screen-helpers";
import { DataTracker, DynamicValueBinder, EventValue, EventValueGetter, EventValueSetter, FieldType } from "../../components/ui/databinding/data-tracker";
import { DataBinding } from "../../components/ui/databinding/databinding";
import { PromiseSnippet, PromiseTemplate } from "../../components/ui/events";
import { ModalDialog } from "../../components/ui/modal-base";
import { FormInputAssistant } from "../../components/ui/templateresult/form-input-assistant";
import { tlang } from "../../language/lang";
import { PricingStrategy } from "./pricing-strategy";

export class QuoteItemPriceAdjustmentV7 extends ModalDialog{
    itemId : string;
    quoteId : string;
    description: string;

    constructor(itemId : string, quoteId : string, description: string)
    {
        super();
        this.itemId = itemId;
        this.quoteId = quoteId;
        this.description = description;
    }

    protected modalSize(): string {
        return "modal-md";
    }

    protected async template(): PromiseTemplate {
        const modalId = `modal${this.itemId}`;
        return html`
            <div id="${modalId}" class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Item ${this.description} Pricing</h5>
                    </div>
                    <div class="modal-body">
                        <item-price-table item-id="${this.itemId}"/>
                    </div>
                    <div class="modal-footer">
                        ${this.closeButtonTemplate()}
                    </div>
                </div>
            </div>
        </div>
     `;
    }
}

export class QuoteItemPriceAdjustment extends ModalDialog {
    quoteItemPrice: QuoteItemPrice;
    dataTracker: DataTracker;
    quotePrice: QuotePrice;
    ok = false;
    originalQuoteItemPrice: QuoteItemPrice;
    forceReadonly: boolean;

    constructor(quotePrice: QuotePrice, quoteItemPrice: QuoteItemPrice, forceReadonly: boolean) {
        super();
        this.forceReadonly = forceReadonly;
        this.quotePrice = quotePrice;
        this.quoteItemPrice = clone(quoteItemPrice);
        this.originalQuoteItemPrice = quoteItemPrice;
        this.dataTracker = new DataTracker(new DataBinding(this.ui));

        const addDynamicMoney = (fieldName: string, getter: EventValueGetter, setter: EventValueSetter, readonly?: () => boolean) => {
            this.dataTracker.addBinding(new DynamicValueBinder(FieldType.money, true,
                getter, setter, readonly), fieldName, fieldName, FieldType.money, true);
        };
        const addDynamicFloat = (fieldName: string, getter: EventValueGetter, setter: EventValueSetter, readonly?: () => boolean) => {
            this.dataTracker.addBinding(new DynamicValueBinder(FieldType.float, true,
                getter, setter, readonly), fieldName, fieldName, FieldType.float, true);
        };

        addDynamicFloat(
            "marginPercentage",
            () => {
                return this.quoteItemPrice.marginPercentage ?? this.quotePrice.marginPercentage;
            },
            (value: EventValue) => {
                if (value === this.quotePrice.marginPercentage)
                    this.quoteItemPrice.marginPercentage = null;
                else
                    this.quoteItemPrice.marginPercentage = value as number;
            },
            () => false);
        addDynamicMoney(
            "supplierCost",
            () => this.quoteItemPrice.quantityCost,
            (_value: EventValue) => {
                console.log("suppilerCost readonly");
            },
            () => true);
        addDynamicMoney(
            "subTotal",
            () => this.quoteItemPrice.calculatedNetSellingPrice,
            (_value: EventValue) => {
                console.log("suppilerCost readonly");
            },
            () => true);
        this.dataTracker.addObjectBinding(() => this.quoteItemPrice, "priceAdjustment", "priceAdjustment", FieldType.money, false);
        addDynamicMoney(
            "priceAdjustment",
            () => this.quoteItemPrice.priceAdjustment,
            (value: EventValue) => this.quoteItemPrice.priceAdjustment = value as number,
            () => true);
        this.dataTracker.addObjectBinding(() => this.quoteItemPrice, "calculatedGrossSellingPrice", "calculatedGrossSellingPrice", FieldType.money, false);

        const triggerEvent = (e: Event) => {
            const elem = e.currentTarget as HTMLInputElement;
            if (elem.id == this.dataTracker.binder.field("priceAdjustment")) {
                const p = this.quoteItemPrice;
                const value = (this.dataTracker.getEditorValue("priceAdjustment") as number) ?? 0;
                if (p.priceAdjustment === value) return;
                console.log("PriceAdjustment Changing");
                p.priceAdjustment = value;
                p.calculatedNetSellingPrice = PricingStrategy.default.GetMarginPriceWithAdjustment(p.quantityCost, p.marginPercentage ?? quotePrice.marginPercentage, null);
                p.calculatedGrossSellingPrice = p.calculatedNetSellingPrice + p.priceAdjustment;
                setTimeout(async () => await this.render(), 25);
            }
            else if (elem.id == this.dataTracker.binder.field("marginPercentage")) {
                const p = this.quoteItemPrice;
                //anything over 100 will be treated as 100.
                //100 will result in an infinity error, which will throw a validation on trying to close.
                let marginPerc: number | null = (this.dataTracker.getEditorValue("marginPercentage") as number) ?? this.quotePrice.marginPercentage;
                if (marginPerc === this.quotePrice.marginPercentage)
                    marginPerc = null;

                if (p.marginPercentage === marginPerc) return;

                p.marginPercentage = marginPerc;
                //we need to do this because lit doesnt recognize the change when we want empty string to default
                this.dataTracker.setEditorValue("marginPercentage", marginPerc);

                p.calculatedNetSellingPrice = PricingStrategy.default.GetMarginPriceWithAdjustment(
                    p.quantityCost,
                    Math.min(marginPerc ?? this.quotePrice.marginPercentage, 100), null);
                p.calculatedGrossSellingPrice = p.calculatedNetSellingPrice + p.priceAdjustment;
                setTimeout(async () => await this.render(), 25);
            }
        };

        $(this.ui).on("keyup", "input", (e: Event) => {
            const elem = e.currentTarget as HTMLInputElement;
            const event = e as KeyboardEvent;
            if (elem.id == this.dataTracker.binder.field("priceAdjustment") || elem.id == this.dataTracker.binder.field("marginPercentage"))
                if (event.code === "Enter")
                    triggerEvent(e);
        });
        $(this.ui).on("blur", "input", (e: Event) => {
            const elem = e.currentTarget as HTMLInputElement;
            if (elem.id == this.dataTracker.binder.field("priceAdjustment") || elem.id == this.dataTracker.binder.field("marginPercentage"))
                triggerEvent(e); //this.timedTrigger.triggerEarly(e);
        });



    }

    protected async isValid(): Promise<boolean> {
        this.prepareForSave();
        return await checkValidations(this.getValidationErrors());
    }
    protected async canClose(): Promise<boolean> {
        if (isAutoSaving()) {
            if (!(await this.isValid())) return false;
            this.save();
        }
        return true;
    }
    getValidationErrors(): string[] {
        const result: string[] = [];
        if (!inRange(this.quoteItemPrice.marginPercentage, 0, 99))
            result.push(tlang`Please set a valid margin from 0 to 99`);
        return result;
    }

    protected async getTitle(): PromiseSnippet {
        return html`Line Item Price`;
    }
    protected modalSize(): string {
        return "modal-md";
    }

    protected prepareForSave() {
        if (this.forceReadonly) return;
        this.dataTracker.applyChangeToValue();
    }
    protected save() {
        this.ok = !compare(this.originalQuoteItemPrice, this.quoteItemPrice);
        Object.assign(this.originalQuoteItemPrice, this.quoteItemPrice);
    }

    protected renderFooterTemplate(): boolean {
        return !isAutoSaving();
    }

    protected footerTemplate(): TemplateResult {
        const saveEvent = async () => {
            if (await this.isValid()) {
                this.save();
                this.hideModal();
            }
        };
        const closeEvent = () => this.hideModal();

        const template = this.forceReadonly
            ? html`
                <button @click=${closeEvent} class="btn btn-secondary">Close</button>`
            : html`
                <button @click=${closeEvent} class="btn btn-secondary">Close</button>
                <button @click=${saveEvent} class="btn btn-primary">Save</button>
        `;
        return template;
    }
    protected async bodyTemplate(): PromiseTemplate {
        const forms = new FormInputAssistant(this.dataTracker, this.forceReadonly);
        return html`
            <form class="line-item-price-adjustment form-one-col">
                <div class="row">
                    <div class="form-column">
                        ${forms.moneyReadonly("supplierCost", tlang`%%supplier%% Cost`)}
                        ${forms.float("marginPercentage", this.getMarginLabel())}
                        ${forms.moneyReadonly("subTotal", tlang`Sub Total`)}
                        ${forms.money("priceAdjustment", tlang`Price Adjustment`)}
                        ${forms.moneyReadonly("calculatedGrossSellingPrice", tlang`Sell Price`)}
                    </div>
                </div>
            </form>
     `;
    }
    getMarginLabel(): string {
        return tlang`Margin (default ${this.quotePrice.marginPercentage}%)`;
    }
}
