/// <summary>
/// examples
///   QuoteItemType.Provider (reserved 1000+)
///     1000 = frame
///     1001 = component DataProvider.Data = " { objectreference: "bla", libid, id, classid }
///     1002 = extn
///   QuoteItemType.Basic (reserved 1)
///     1 = Shipping
///     2 = Freehand
/// </summary>

//properties for QuoteItemType on the quote item, when the provider is v6
export const quoteItemContentType = {
    shipping: 1,
    freehand: 2
};