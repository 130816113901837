export async function multiCastPromise<TResult>(
    promise: Promise<TResult> | null,
    assignment: (p: Promise<TResult> | null) => void,
    execution: () => Promise<TResult>,): Promise<TResult> {
    if (promise) return await promise;
    const newPromise = new Promise<TResult>((resolve, reject) => {
        execution()
            .then(resolve)
            .catch(reject)
            .finally(() => assignment(null));
    });
    assignment(newPromise);
    return await newPromise;
}