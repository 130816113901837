/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BlobApi } from "../../api/blob-api";
import {
    ExternalProviderDefinition,
    InitialPriceDefinition,
    InputCreateQuoteItem,
    Quote,
    QuoteItem,
    QuoteItemPrice,
    QuoteItemProviderData,
    QuotePresentation,
    QuotePrice,
    ResultGetQuoteItemsSummary
} from "../../api/dealer-api-interface-quote";
import { newGuid } from "../../api/guid";
import { QuoteApi } from "../../api/quote-api";
import { base64ToObject, objectToBase64 } from "../../blob/converters";
import { clone, compare } from "../../components/clone";
import { money } from "../../components/currency-formatter";
import { isAutoSaving, saveWithIndicator } from "../../components/save-workflow";
import { tlang } from "../../language/lang";
import { NullPromise } from "../../null-promise";
import { fireQuickSuccessToast } from "../../toast-away";
import { DevelopmentError, showDevelopmentError } from "../../development-error";
import { QuoteItemContainer } from "./quote-item-container";
import { createQuoteProviderData, QuoteProviderData, validateAndUpgradeQuoteProviderData } from "./quote-provider-data";
import {V7ItemProviderContent} from '../../dealer-franchisee/quotes/views/v7/v7-quote-item-frame-view';

export interface ParamCreateQuoteItem {
    id: string | null,
    title: string | null;
    description: string | null;
    quantity: number | null;
    comment: string | null;
    externalProvider: ExternalProviderDefinition | null;
    quoteItemContentType: number;
    thumbnail: string | null;
    price: InitialPriceDefinition | null;
}
export class QuoteContainer {
    quoteId: string;
    quote: Quote | null;
    quotePrice: QuotePrice | null;
    quoteProviderData: QuoteProviderData | null = null;
    quotePresentation: QuotePresentation | null = null;
    items: QuoteItem[] | null;
    itemsData: QuoteItemProviderData[] | null;
    itemPrices: QuoteItemPrice[] | null;
    isNewQuote: boolean;

    constructor(quoteId: string,
        quote: Quote | null,
        quotePrice: QuotePrice | null,
        quotePresentation: QuotePresentation | null,
        items: QuoteItem[] | null,
        itemsData: QuoteItemProviderData[] | null,
        itemPrices: QuoteItemPrice[] | null,
        quoteProviderData: QuoteProviderData | null = null,
        isNewQuote: boolean = false) {
        this.quote = quote;
        this.quotePrice = quotePrice;
        this.quotePresentation = quotePresentation;
        this.items = items;
        this.itemsData = itemsData;
        this.itemPrices = itemPrices;
        this.quoteId = quoteId;
        this.quoteProviderData = quoteProviderData;
        this.isNewQuote = isNewQuote;
    }

}

export type EventNotify = (() => Promise<void>);

// a manager that is used to find and collect quote item information
// and keep a comparison backup
export class QuoteContainerManager {
    backup: QuoteContainer;
    container: QuoteContainer;
    api: QuoteApi;
    afterSave: EventNotify[] = [];
    blobApi: BlobApi;
    numberSeed: string | null = null;

    constructor(original: QuoteContainer, quoteApi: QuoteApi, blobApi: BlobApi) {
        this.api = quoteApi;
        this.blobApi = blobApi;
        if (original.quote && original.quote.id !== original.quoteId) throw new Error(`invalid argument Quote ID must match quoteId`);
        this.container = original;
        this.backup = this.clone(this.container);
    }

    isReadonly(): boolean {
        return false;
    }

    /**
     * the quoteId for this managed container
     */
    get quoteId(): string {
        return this.container.quoteId;
    }

    get quoteItemPriceTotal(): number {
        let total = 0;
        //if we dont sum this to 2dp, then the UI wont always add up if someone checking.
        //the server will be doing the real calculations anyway
        this.container.itemPrices?.forEach(x => total += money(x.calculatedGrossSellingPrice, 2));
        return total;
    }

    /**
     *
     * @param id the quoteItemId
     */
    public itemPosition(id: string): number {
        //TODO - implement a quote item position tracker, managed at the quote level and use this for rendering
        return (this.container.items?.findIndex(x => x.id === id) ?? -1) + 1;
    }
    /**
     * replaces the backups and originals of the quote objects with this new set of objects to become the master
     * @param quote
     * @param quotePrice
     */
    protected async resetQuote(quote: Quote, quotePrice: QuotePrice, quotePresentation: QuotePresentation | null) {
        this.container.quote = quote;
        const providerData = base64ToObject<QuoteProviderData>(quote.serviceProviderData) ?? (await createQuoteProviderData(quote.serviceProvider, quote.supplierId));
        this.container.quoteProviderData = await validateAndUpgradeQuoteProviderData(providerData);
        if (quotePresentation)
            this.container.quotePresentation = quotePresentation;
        else if (!this.container.quotePresentation)
            throw new DevelopmentError("Missing QuotePresentation"); //should only occur on updates

        this.container.quotePrice = quotePrice;

        this.backup.quote = this.clone(quote);
        this.backup.quoteProviderData = this.clone(this.container.quoteProviderData);
        this.backup.quotePrice = this.clone(quotePrice);
        if (quotePresentation)
            this.backup.quotePresentation = this.clone(quotePresentation);
    }
    /**
     * Simple wrapper around structuredClone
     * @param item an object of any basic type to clone
     * @returns
     */
    clone<ItemType>(item: ItemType): ItemType {
        return clone(item);
    }

    quoteItemPrice(id: string): number {
        const price = this.container.itemPrices?.find(x => x.id == id);
        if (price) return price.calculatedGrossSellingPrice;
        return 0;
    }

    quoteItemConfigurationId(id: string): number{
        const itemData = this.container.itemsData?.find(x => x.id == id);
        if (itemData === null || itemData?.providerData === null)
            return 0;
        const v7ItemProviderData = base64ToObject<V7ItemProviderContent>(itemData?.providerData);
        return v7ItemProviderData?.configurationId ?? 0;
    }

    /**
     * this will ensure at an async level that the quote propery is valid, before accessing the property synchronously
     * @returns true if the quote property is now valid
     */
    async needsQuote(): Promise<boolean> {
        if (!this.container.quote) {
            const result = await this.api.getQuote({ quoteId: this.quoteId });
            if (result) {
                await this.resetQuote(result.quote, result.quotePrice, result.quotePresentation);
            } else return false;
        }

        //This should only execute if the container is constructed with the quote passed in and the provider data not,
        //meaning that the quote is fetched outside the container and passed in.
        //This will fetch the data for the service provider. We are not using the value(s) from this.container.quote.serviceProvider
        //as it may be out of date.
        if (this.container.quote && !this.container.quoteProviderData) {
            this.container.quoteProviderData =
                await createQuoteProviderData(this.container.quote.serviceProvider, this.container.quote.supplierId);
        }

        return true;
    }

    /**
     * execute all bound events after any save operation to allow for re-rendering and refreshing of state
     */
    protected async doAfterSave(): Promise<void> {
        for (let i = 0; i < this.afterSave.length; i++) {
            const event = this.afterSave[i];
            await event();
        }
    }

    public get quoteTitle(): string {
        if (this.quote.quoteNumber != 0)
            return tlang`#${this.quote.quoteNumber} - ${this.quote.title}`;
        else
            return tlang`Draft - ${this.quote.title}`;
    }

    public generateQuoteNumberOnSave() {
        this.numberSeed = this.quote.quoteOwnerId;
    }

    /**
     * this is called to send the current quote and quote price objects to update the server.
     * on sucessful update, the internal quote and price objects will be replaced with the new copies
     * from the server
     * when updating prices we also refresh all pricing for all items at the same time that may have been
     * reliant on the pricing.
     * calling this may result in some quoteItemContainers holding outdated objects that need replacement
     */
    async saveQuote(silently?: boolean): Promise<boolean> {
        if (this.isReadonly()) {
            await showDevelopmentError("Trying to save readonly  quote ");
            return false;
        }
        this.quote.serviceProviderData = objectToBase64(this.container.quoteProviderData);
        const result = await this.api.updateQuote({
            quote: this.quote,
            quotePrice: this.quotePrice,
            numberSeed: this.numberSeed
        });
        if (result) {
            //quote update doesn't alter presentation, so we keep what we have
            await this.resetQuote(result.quote!, result.quotePrice!, null);
            this.container.itemPrices = result.quoteItemPrices;
            this.backup.itemPrices = this.clone(result.quoteItemPrices);
            if (!silently)
                fireQuickSuccessToast(tlang`Quote Saved "${this.quoteTitle}"`);
            await this.doAfterSave();
            return true;
        }
        return false;
    }

    /**
     * this will find the backup of a quote item and related data, and replace the items with a clone
     * of the backup. this will make any quoteItemContainers to this id stale and need replacing
     *
     * @param id the quote item id to replace
     * @returns a reference to the fresh copy of the data
     */
    restoreQuoteItemFromBackup(id: string | undefined): QuoteItemContainer | null {
        if (!id) return null;
        const backup = this.quoteItemContainer(id, true);
        this.replaceQuoteItem(backup, false);
        return this.quoteItemContainer(id);
    }

    changedItem(id: string | undefined): boolean {
        if (!id) return false;
        const backup = this.quoteItemContainer(id, true);
        const current = this.quoteItemContainer(id, false);

        return !compare(backup, current);
    }

    /**
     * this should be called any time before accessing the quote items list, which may yet be unpopulated.
     * calling this makes all references to data invalid so it should not be called while there are active
     * items being used or edited.
     * @param forceRefresh force a reload of the data even if not required.
     * @returns true if suceeded
     */
    async needsQuoteItems(forceRefresh = false): Promise<boolean> {
        const sortQuoteItems = async (result: ResultGetQuoteItemsSummary) => {
            if (!this.container.quotePresentation)
                await showDevelopmentError("QuotePresentation missing, cannot sort items");
            const sortedItems: QuoteItem[] = [];
            const displayOrder = this.container.quotePresentation?.itemDisplayOrder ?? [];
            //create a list ordered by the quotepresentation
            displayOrder.forEach(commonId => {
                const item = result.items.find(item => item.commonId === commonId);
                if (item) {
                    sortedItems.push(item);
                }
            });
            if (sortedItems.length !== result.items.length) {
                sortedItems.push(...result.items.filter(item => !displayOrder.includes(item.commonId)));
            }
            return sortedItems;
        };

        if (!this.container.items || forceRefresh) {
            const result = await this.api.getQuoteItemsSummary({
                quoteId: this.quoteId
            });
            if (result) {

                const sortedItems: QuoteItem[] = await sortQuoteItems(result);

                this.container.items = sortedItems;
                this.container.itemsData = result.data;
                this.container.itemPrices = result.prices;
                this.backup.items = this.clone(sortedItems);
                this.backup.itemsData = this.clone(result.data);
                this.backup.itemPrices = this.clone(result.prices);
            } else return false;
        }
        return true;

    }

    /**
     * returns the quote object after needsQuote is called, or throws an error if the quote is unavailable.
     */
    get quote(): Quote {
        if (!this.container.quote) {
            throw new Error("Quote is null");
        }
        return this.container.quote;
    }
    /**
     * returns the quote price object after needsQuote is called, or throws an error if the quote price is unavailable.
     */
    get quotePrice(): QuotePrice {
        if (!this.container.quotePrice) {
            throw new Error("QuotePrice is null");
        }
        return this.container.quotePrice;
    }

    quoteProviderData(): QuoteProviderData | null {
        return this.container.quoteProviderData;
    }

    /**
     * create and return a container for quote item data references
     * @param quoteItemId the quote item to return
     * @param useBackup if true, return the backup copy, if false the working copy
     * @returns a quoteItemContainer with the data parts combined
     */
    quoteItemContainer(quoteItemId: string, useBackup = false): QuoteItemContainer {
        const container = useBackup ? this.backup : this.container;
        const item = container.items?.find(x => x.id === quoteItemId);
        const data = container.itemsData?.find(x => x.id == quoteItemId);
        const price = container.itemPrices?.find(x => x.id == quoteItemId);
        if (!item || !price) throw new Error(`${quoteItemId} is not a valid quote item id`);
        return {
            item: item,
            data: data ?? null,
            price: price
        };
    }

    public static async createQuoteServiceProviderData(supplierType: string, supplierId: string): NullPromise<QuoteProviderData> {
        return await createQuoteProviderData(supplierType, supplierId);
    }

    /**
     * Create a new quote item and add it to this quote instance. will ensure the quote items list is valid first before adding
     *
     * @param input parameters for the new quote item
     * @returns a valid object on success
     */
    async createQuoteItem(input: ParamCreateQuoteItem): NullPromise<QuoteItemContainer> {
        if (this.isReadonly()) {
            await showDevelopmentError("Trying to save readonly  quote ");
            return null;
        }

        const newId = input.id ?? newGuid();
        const virtualThumbnailPath = input.thumbnail
            ? this.api.createQuoteItemSVGThumbnailPath(this.quoteId, newId)
            : "";
        //ensure we have a correct listing before adding more items
        await this.needsQuoteItems();
        const inputParam: InputCreateQuoteItem = { quoteId: this.quoteId, quoteItemId: newId, virtualThumbnailPath: virtualThumbnailPath, ...input };
        const result = await this.api.createQuoteItem(inputParam);
        if (result) {
            this.addQuoteItem(result.quoteItem, result.quoteItemPrice, result.quoteItemProviderData);

            await this.resetQuote(this.quote, result.resultFullQuotePrice!.quotePrice, result.quotePresentation);
            if (result.resultFullQuotePrice!.itemPrices)
                this.updateItemPrices(result.resultFullQuotePrice!.itemPrices);

            if (input.thumbnail) {
                this.postImageBlob("", virtualThumbnailPath, input.thumbnail);
            }
            await this.doAfterSave();
            return this.quoteItemContainer(result.quoteItem.id);
        }
        return null;
    }

    public async copyQuoteItem(quoteItemContainer: QuoteItemContainer): NullPromise<QuoteItemContainer> {
        const copyResult = await this.api.duplicateQuoteItem({ quoteItemId: quoteItemContainer.item.id });
        if (copyResult) {
            const updateResult = await this.api.updateQuoteItem({
                quoteItem: copyResult.quoteItem,
                quoteItemProviderData: copyResult.quoteItemProviderData,
                quoteItemPrice: copyResult.quoteItemPrice
            });
            if (updateResult && updateResult.quoteItem && updateResult.quoteItemPrice && updateResult.resultFullQuotePrice) {
                this.addQuoteItem(updateResult.quoteItem, updateResult.quoteItemPrice, updateResult.quoteItemProviderData);
                await this.resetQuote(this.quote, updateResult.resultFullQuotePrice.quotePrice, copyResult.quotePresentation);
                await this.doAfterSave();
                return this.quoteItemContainer(copyResult.quoteItem.id);
            }
        }
        return null;
    }

    public async deleteQuoteItem(quoteItemContainer: QuoteItemContainer): Promise<boolean> {
        //reload if needed
        await this.needsQuoteItems();
        const result = await this.api.deleteQuoteItem({ quoteItemId: quoteItemContainer.item.id });
        if (result) {
            //overkill to reload everthing because of a single entry removal
            //await this.needsQuoteItems(true);
            this.internalDeleteQuoteItemReference(quoteItemContainer.item.id);
            await this.resetQuote(this.quote, result.resultFullQuotePrice.quotePrice, result.quotePresentation);
            await this.doAfterSave();
            return true;
        }
        return false;
    }
    private internalDeleteQuoteItemReference(quoteItemId: string) {
        function remove(list: { id: string; }[]) {
            const idx = list.findIndex(x => x.id === quoteItemId) ?? -1;
            if (idx >= 0)
                list.splice(idx, 1);
        }
        if (this.container.items) remove(this.container.items);
        if (this.container.itemPrices) remove(this.container.itemPrices);
        if (this.container.itemsData) remove(this.container.itemsData);

        if (this.backup.items) remove(this.backup.items);
        if (this.backup.itemPrices) remove(this.backup.itemPrices);
        if (this.backup.itemsData) remove(this.backup.itemsData);
    }

    protected addQuoteItem(quoteItem: QuoteItem, quoteItemPrice: QuoteItemPrice, quoteItemProviderData: QuoteItemProviderData | null) {
        this.container.items?.push(quoteItem);
        if (quoteItemProviderData)
            this.container.itemsData?.push(quoteItemProviderData);
        this.container.itemPrices?.push(quoteItemPrice);

        this.backup.items?.push(this.clone(quoteItem));
        if (quoteItemProviderData)
            this.backup.itemsData?.push(this.clone(quoteItemProviderData));
        this.backup.itemPrices?.push(this.clone(quoteItemPrice));
    }

    /**
     * perform an insert of text based blob data such as svg on the server
     * @param oldItemPath an old name to delete when uploading
     * @param newItemPath a new name path to use for this insert
     * @param imageData text based data such as svg
     */
    protected async postImageBlob(oldItemPath: string, newItemPath: string, imageData: string | null) {
        if (imageData)
            await this.blobApi.updateFileByVirtualPath({
                oldVirtualPath: oldItemPath,
                newVirtualPath: newItemPath,
                data: btoa(imageData)
            });
    };

    /**
     * find and replace the data elements of a quoteItem with a new refreshed set of data
     * uses a clone of the data passed in
     * @param updatedItemContainer
     * @param useBackup
     */
    private replaceQuoteItem(updatedItemContainer: QuoteItemContainer, useBackup: boolean) {
        const container = useBackup ? this.backup : this.container;
        const idxItem = container.items?.findIndex(x => x.id === updatedItemContainer.item.id) ?? -1;
        let idxData = -1;
        if (updatedItemContainer.data)
            idxData = container.itemsData?.findIndex(x => x.id === updatedItemContainer.data?.id) ?? -1;
        const idxPrice = container.itemPrices?.findIndex(x => x.id == updatedItemContainer.price.id) ?? -1;
        if (idxItem >= 0 && container.items)
            container.items[idxItem] = this.clone(updatedItemContainer.item);
        if (updatedItemContainer.data && idxData >= 0 && container.itemsData)
            container.itemsData[idxData] = this.clone(updatedItemContainer.data);
        if (idxPrice >= 0 && container.itemPrices)
            container.itemPrices[idxPrice] = this.clone(updatedItemContainer.price);
    };

    private _lastSaveSuccessful = true;
    public get lastSaveSuccessful(): boolean {
        return this._lastSaveSuccessful;
    }


    async saveAndUpdateQuoteItemWithIndicator(quoteItemContainer: QuoteItemContainer, thumbnail: string): Promise<QuoteItemContainer> {
        if (!this.changedItem(quoteItemContainer.item.id)) {
            this._lastSaveSuccessful = true;
            return quoteItemContainer;
        }

        let result: QuoteItemContainer = quoteItemContainer;
        this._lastSaveSuccessful = await saveWithIndicator(async () => {
            if (!quoteItemContainer) return false;
            try {
                const newContainer = await this.saveAndUpdateQuoteItem(quoteItemContainer, thumbnail);
                if (newContainer.item.recordVersion !== quoteItemContainer.item.recordVersion) {
                    result = newContainer;
                    return true;
                }
            } catch {
                return false;
            }
            return false;
        });
        return result;
    }

    /**
     * update a quote item on the server and its thumbnail svg image
     * after update refreshes the quote and quote price object, and all item prices
     * @param quoteItemContainer
     * @param thumbnail
     * @returns the new refreshed copy for the quoteitem
     */
    async saveAndUpdateQuoteItem(quoteItemContainer: QuoteItemContainer, thumbnail: string): Promise<QuoteItemContainer> {
        this._lastSaveSuccessful = false;

        if (this.isReadonly()) {
            await showDevelopmentError("Trying to save readonly  quote ");
            return quoteItemContainer;
        }


        //if thumbnail is an empty string, then we are not changing anything
        let currentImagePath = "";
        let newImagePath = "";
        if (thumbnail !== "") {
            currentImagePath = quoteItemContainer.item.virtualThumbnailPath ?? "";
            //generate a new image path to refer to when saving the quote item
            newImagePath = this.api.createQuoteItemSVGThumbnailPath(this.quoteId, quoteItemContainer.item.id);
            quoteItemContainer.item.virtualThumbnailPath = newImagePath;
        }

        const update = (updatedItemContainer: QuoteItemContainer, useBackup: boolean) => {
            const container = useBackup ? this.backup : this.container;
            const idxItem = container.items?.findIndex(x => x.id === updatedItemContainer.item.id) ?? -1;
            let idxData = -1;
            if (updatedItemContainer.data)
                idxData = container.itemsData?.findIndex(x => x.id === updatedItemContainer.data?.id) ?? -1;
            const idxPrice = container.itemPrices?.findIndex(x => x.id === updatedItemContainer.price.id) ?? -1;
            if (idxItem >= 0 && container.items)
                container.items[idxItem] = useBackup ? this.clone(updatedItemContainer.item) : updatedItemContainer.item;
            if (idxData >= 0 && container.itemsData && updatedItemContainer.data)
                container.itemsData[idxData] = useBackup ? this.clone(updatedItemContainer.data) : updatedItemContainer.data;
            if (idxPrice >= 0 && container.itemPrices)
                container.itemPrices[idxPrice] = useBackup ? this.clone(updatedItemContainer.price) : updatedItemContainer.price;
        };
        const result = await this.api.updateQuoteItem({
            quoteItem: quoteItemContainer.item,
            quoteItemProviderData: quoteItemContainer.data,
            quoteItemPrice: quoteItemContainer.price
        });
        if (result) {

            const newContainer: QuoteItemContainer = { item: result.quoteItem!, data: result.quoteItemProviderData!, price: result.quoteItemPrice! };
            update(newContainer, false);
            update(newContainer, true);

            //saving item doesn't change presentation layer, pass in null
            await this.resetQuote(this.quote, result.resultFullQuotePrice!.quotePrice, null);
            if (result.resultFullQuotePrice!.itemPrices)
                this.updateItemPrices(result.resultFullQuotePrice!.itemPrices);

            if (thumbnail !== "")
                if (isAutoSaving())
                    await this.postImageBlob(currentImagePath, newImagePath, thumbnail);
                else
                    this.postImageBlob(currentImagePath, newImagePath, thumbnail);

            await this.doAfterSave();
            this._lastSaveSuccessful = true;
            return this.quoteItemContainer(quoteItemContainer.item.id);
        }
        return quoteItemContainer;
    }

    async saveAndUpdateQuoteItemPrice(quoteItemPrice: QuoteItemPrice): Promise<QuoteItemContainer> {
        if (this.isReadonly()) {
            await showDevelopmentError("Trying to save readonly  quote ");
            return this.quoteItemContainer(quoteItemPrice.id);
        }

        const update = (updatedItemPrice: QuoteItemPrice, useBackup: boolean) => {
            const container = useBackup ? this.backup : this.container;
            const idxPrice = container.itemPrices?.findIndex(x => x.id == updatedItemPrice.id) ?? -1;
            if (idxPrice >= 0 && container.itemPrices)
                container.itemPrices[idxPrice] = useBackup ? this.clone(updatedItemPrice) : updatedItemPrice;
        };
        const result = await this.api.updateQuoteItem({
            quoteItem: null,
            quoteItemProviderData: null,
            quoteItemPrice: quoteItemPrice
        });
        if (result && result.quoteItemPrice && result.resultFullQuotePrice) {
            const newPrice = result.quoteItemPrice;
            update(newPrice, false);
            update(newPrice, true);
            //presentation not changing, pass null
            await this.resetQuote(this.quote, result.resultFullQuotePrice.quotePrice, null);
            if (result.resultFullQuotePrice.itemPrices)
                this.updateItemPrices(result.resultFullQuotePrice.itemPrices);

            await this.doAfterSave();
            const container = this.quoteItemContainer(quoteItemPrice.id);
            fireQuickSuccessToast(tlang`Item Price Updated "${container?.item.title}"`);
            return container;
        }

        return this.quoteItemContainer(quoteItemPrice.id);
    }


    /**
     * replaces all item price objects with a fresh set from the server.
     * @param prices a new set of item prices
     */
    updateItemPrices(prices: QuoteItemPrice[]) {
        this.container.itemPrices = prices;
        this.backup.itemPrices = this.clone(prices);
    }

    quoteChanged(): boolean {
        return !compare(this.backup.quote, this.container.quote);
    }
    changed(): boolean {
        return !compare(this.backup, this.container);
    }

    public async makeCopy(): NullPromise<QuoteContainer> {
        return null;
    }
    public async makeAlternative(): NullPromise<QuoteContainer> {
        return null;
    }
    public async deleteQuote(): Promise<boolean> {
        return false;
    }

}
