import { ClientApi } from "../../../api/client-api";
import { Client, ClientState, Contact } from "../../../api/dealer-api-interface-client";
import { FranchiseeClient } from "../../../api/dealer-api-interface-franchisee";
import { PaymentProfileApi } from "../../../api/payment-profile-api";
import { ClientContainer, ClientContainerManager } from "../../../clients/data/client-container-manager";
import { clone } from "../../../components/clone";
import { EventSnippet } from "../../../components/ui/events";
import { isEmptyOrSpace } from "../../../components/ui/helper-functions";
import { tlang } from "../../../language/lang";
import { DevelopmentError } from "../../../development-error";
import { NullPromise } from "../../../null-promise";
import { emptyGuid } from "../../../api/guid";
import { emptyAddress } from "../../../components/ui/maps/map-helpers";
import { localDateToServer, today } from "../../../components/datetime-converter";
import { cache } from "../../cache/cache-registry";
import { fireQuickWarningToast } from "../../../toast-away";

export class FranchiseeClientContainer extends ClientContainer {
    franchiseeClient: FranchiseeClient | null;

    constructor(clientId: string, client: Client | null, franchiseeClient: FranchiseeClient | null,
        primaryContactName: string, primaryContactEmail: string) {
        super(clientId, client, primaryContactName, primaryContactEmail);
        this.franchiseeClient = franchiseeClient;
    }
}

export class FranchiseeClientContainerManager extends ClientContainerManager {
    paymentProfileApi: PaymentProfileApi;

    constructor(clientContainer: ClientContainer, clientApi: ClientApi, paymentProfileApi: PaymentProfileApi, title: EventSnippet) {
        super(clientContainer, clientApi, title);

        this.paymentProfileApi = paymentProfileApi;

        if (!(clientContainer instanceof FranchiseeClientContainer))
            throw new DevelopmentError("FranchiseeClientContainerManager only supports FranchiseeClientContainer");
    }

    public get franchiseeClient(): FranchiseeClient {
        if (this.franchiseeContainer.franchiseeClient) {
            return this.franchiseeContainer.franchiseeClient;
        }
        throw new DevelopmentError(tlang`FranchiseeClient is null`);
    }

    public get franchiseeContainer(): FranchiseeClientContainer {
        return this.container as FranchiseeClientContainer;
    }

    public get franchiseeBackup(): FranchiseeClientContainer {
        return this.backup as FranchiseeClientContainer;
    }

    public hasPaymentProfile(): boolean {
        return !isEmptyOrSpace(this.franchiseeClient.paymentProfileId);
    }

    protected async internalSave(): Promise<boolean> {
        const isNew = this.isNew;
        const result1 = await super.internalSave();
        if (result1 && this.franchiseeClient) {

            const result2 = isNew
                ? (await this.paymentProfileApi.createFranchiseeClient({
                    id: this.client.id,
                    paymentProfileId: this.franchiseeClient.paymentProfileId,
                    externalCustomerReference: null
                }))?.franchiseeClient
                : (await this.paymentProfileApi.updateFranchiseeClient({
                    clientId: this.franchiseeClient.id,
                    primaryProfileId: this.franchiseeClient.paymentProfileId,
                    rowVersion: this.franchiseeClient.recordVersion
                }))?.franchiseeClient;
            if (result2 && result2) {
                this.resetFranchiseeClient(result2);
                return true;
            }

        }
        return false;
    }

    public async needsClient(): Promise<boolean> {
        if (await super.needsClient()) {
            if (!this.franchiseeContainer.franchiseeClient) {
                const result = await this.paymentProfileApi.getFranchiseeClientListByIds({ clientIds: [this.clientId] });
                if (result && result.clients.length > 0) {
                    this.resetFranchiseeClient(result.clients[0]);
                    return true;
                } else {
                    const fclient = (await this.paymentProfileApi.createFranchiseeClient({
                        id: this.client.id,
                        paymentProfileId: null,
                        externalCustomerReference: null
                    }))?.franchiseeClient;
                    if (!fclient) return false;
                    this.resetFranchiseeClient(fclient);
                    fireQuickWarningToast(tlang`%%client%% %%payment-profile%% corrupt and recovered`);
                    return true;
                }
            }

        }
        return false;
    }

    protected async getPrimaryContact(clientId: string): NullPromise<Contact> {
        const link = await cache().primaryContact.getData(clientId);
        if (!link || !link.contactId) return null;
        return (await this.clientApi.getContact({ contactId: link.contactId }))?.contact ?? null;
    }

    protected resetFranchiseeClient(franchiseeClient: FranchiseeClient) {
        this.franchiseeContainer.franchiseeClient = franchiseeClient;
        this.franchiseeBackup.franchiseeClient = clone(franchiseeClient);
    }
}

export async function createNewClient(clientOwnerId: string): NullPromise<ClientContainer> {
    const newClient: Client =
    {
        id: emptyGuid,
        name: tlang`New %%client%%`,
        businessPhoneNumber: "",
        physicalAddress: emptyAddress(),
        billingAddress: emptyAddress(),
        shipToPhysicalAddress: false,
        state: ClientState.Active,
        clientOwnerId: clientOwnerId,
        dateCreated: localDateToServer(today()),
        externalProvidedDataId: emptyGuid,
        recordVersion: ""
    };

    const newFranchiseeClient: FranchiseeClient = {
        id: emptyGuid,
        externalCustomerReference: "",
        paymentProfileId: null,
        dateCreated: localDateToServer(new Date()),
        recordVersion: ""

    };

    return new FranchiseeClientContainer(emptyGuid, newClient, newFranchiseeClient, "", "");
}
