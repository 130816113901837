import { ProjectView, ProjectViewOptions } from "../../../projects/views/project-view";
import { FranchiseeProjectDetailView } from "./franchisee-project-detail-view";
import { ClientApi } from "../../../api/client-api";
import { getApiFactory } from "../../../api/api-injector";
import { ProjectResourceView } from "../../../projects/views/project-resource-view";
import { FranchiseeProjectResourceView } from "./franchisee-project-resource-view";
import { cache } from "../../cache/cache-registry";
import { createNewQuote, getSupplierId } from "../../quotes/quote-creator";
import { emptyGuid } from "../../../api/guid";
import { ResourceType } from "../../../api/dealer-api-interface-project";
import { getQuoteContainerManager } from "../../quotes/quote-ui-adapter";
import { fireQuickErrorToast, fireQuickSuccessToast } from "../../../toast-away";
import { tlang } from "../../../language/lang";
import { launchQuote } from "../../../quotes/ui/launcher";
import { userDataStore } from "../../common/current-user-data-store";
import { FranchiseeQuoteContainerManager } from "../../quotes/data/franchisee-quote-manager";
import { QuoteState } from "../../../api/dealer-api-interface-quote";
import { DevelopmentError } from "../../../development-error";
import { showValidations } from "../../../components/ui/modal-validationhandler";
import { ResultGetFranchiseeConfiguration } from "../../../api/dealer-api-interface-franchisee";
import { FranchiseeApi } from "../../../api/franchisee-api";

export class FranchiseeProjectView extends ProjectView {
    clientApi: ClientApi = getApiFactory().client();
    private projectCache = cache().project;
    private clientCache = cache().client;
    private clientTypeCache = cache().paymentProfile;
    private contactCache = cache().contact;
    private franchiseeApi: FranchiseeApi = getApiFactory().franchisee();
    private franchiseeConfiguraiton : ResultGetFranchiseeConfiguration | null = null;

    constructor(options: ProjectViewOptions) {
        super(options);
        // this.projectContainerManager.afterSave.push(async () => {
        //  -> Maybe refresh some data
        // });
    }

    protected projectDetailViewFactory(_options: ProjectViewOptions) {
        return new FranchiseeProjectDetailView({
            projectManager: this.projectContainerManager,
        });
    }

    protected projectResourceViewFactory(): ProjectResourceView {
        return new FranchiseeProjectResourceView({
            projectManager: this.projectContainerManager,
            quoteCache: cache().quote,
            purchaseOrderCache: cache().purchaseOrder,
            canClose: async () => {
                if (await this.canClose()) {
                    await this.hideModal();
                    return true;
                }
                return false;
            }
        });
    }

    protected async internalCreateQuote(): Promise<boolean> {
        if (!this.franchiseeConfiguraiton)
            this.franchiseeConfiguraiton = await this.franchiseeApi.getFranchiseeConfiguration();
        const termsAndConditions = this.franchiseeConfiguraiton?.franchiseeConfiguration.defaultTermsAndConditions ?? "";
        const validity = this.franchiseeConfiguraiton?.franchiseeConfiguration.quoteValidity ?? 28;
        const quoteContainer = await createNewQuote({
            supplierId: await getSupplierId(),
            owner: userDataStore.defaultBranch?.id ?? emptyGuid,
            defaultTermsAndConditions: termsAndConditions,
            quoteValidityInDays: validity
        });
        if (quoteContainer) {
            const quoteManager = getQuoteContainerManager(quoteContainer) as FranchiseeQuoteContainerManager;
            quoteManager.quote.state = QuoteState.Active;
            quoteManager.generateQuoteNumberOnSave();

            const branchQuote = quoteManager.branchQuote;
            branchQuote.clientId = this.projectContainerManager.project.clientId;
            branchQuote.clientName = this.clientCache.getLocal(branchQuote.clientId)?.displayValue ?? "";
            branchQuote.clientTypeId = this.projectContainerManager.project.clientTypeId;
            branchQuote.clientTypeName = this.clientTypeCache.getLocal(branchQuote.clientTypeId)?.displayValue ?? "";
            branchQuote.contactId = this.projectContainerManager.project.contactId;
            branchQuote.contactName = this.contactCache.getLocal(branchQuote.contactId)?.displayValue ?? "";

            const updatedQuote = await quoteManager.saveQuote(true);
            if (updatedQuote) {
                const resource = await this.projectApi.createProjectResourceReference({
                    resourceId: quoteContainer.quoteId,
                    projectId: this.projectContainerManager.projectId,
                    typeOf: ResourceType.Quote,
                    parentResourceId: emptyGuid,
                    parentTypeOf: ResourceType.None
                });
                if (resource) {
                    this.projectCache.updateLocal(this.projectContainerManager.projectId);

                    fireQuickSuccessToast(tlang`Created %%quote%% for ${this.projectContainerManager.projectTitle}`);
                    return this.openQuote(quoteContainer.quoteId, quoteContainer.quote?.title ?? "");
                }
            }
            // if we get here A) The quote update has failed and B) the project-quote link wasn't created
            fireQuickErrorToast(tlang`Unable to create %%quote%% for ${this.projectContainerManager.projectTitle}`);
            // if we were unable to create the project link, remove the alternative quote
            return await quoteManager.deleteQuote();
        }
        fireQuickErrorToast(tlang`Unable to create %%quote%%`);
        return false;
    }

    private async openQuote(id: string, title: string): Promise<boolean> {
        return await launchQuote({ id: id, title: title, navigateIfAllowed: async () => await this.canClose(), after: async () => {
            // todo: refresh data and project resources view.
            await this.refreshResources();
        }});
    }

    protected async canCreateQuote(): Promise<boolean> {
        const project = this.projectContainer.project;
        if (!project) {
            throw new DevelopmentError(tlang`%%project%% container is empty`);
        }

        const errors = this.detailView.getValidationErrors();

        if (errors.length != 0) {
            await showValidations(errors, () => tlang`Unable to issue %%quote%%, correct the following and try again.`);
            return false;
        }

        return true;
    }
}