// eslint-disable-next-line import/named
import { unsafeCSS, css, html, LitElement } from "lit";
import { ref, createRef, Ref } from 'lit/directives/ref.js';
import { customElement, property } from "lit/decorators.js";
import styles from '../../../../../styles/custom.lit.scss' assert { type: 'css' };

// st-modal is a simple dialog web component that should work for many one off dialog requirements.
// It is modelled on the bootstrap modal dialog and therefore does not support raising multiples.
// example usage:
//     <st-modal modal-size="modal-lg">
//         <span slot="title">slotted title</span>
//         <div slot="body">
//             <button >This is a button in the body slot</button>
//         </div>
//         <div slot="footer">
//             <button >Save</button>
//         </div>
//     </st-modal>
//
// Notice the slot="<area>" attributes. This means the element will be inserted into that area in the dialog.
// Events:
//    close: a child component requests that the modal dialog should close and remove itself from the DOM.
@customElement('st-modal')
export class StModalElement extends LitElement  {
    static styles = [unsafeCSS(styles),
        css`
            .modal {
                z-index: 10000;
                display: block;
            }
            .modal-backdrop {
                z-index: 9999;
            }

            .modal-dialog {
                max-height: 90vh;
            }

            .modal {
                overflow: hidden;
            }
    	`];

    // available values are modal-sm, modal-md, modal-lg, modal-xl
    private _modalSize: string = 'modal-md';
    @property({attribute:'modal-size'})
    get modalSize() {return this._modalSize;}
    set modalSize(value:string){
        const oldVal = this._modalSize;
        this._modalSize = value.toLowerCase();
        this.requestUpdate('ModalSize', oldVal);
    }

    private modalRootRef: Ref<HTMLElement> = createRef();

    render() {
        const modalContentClasses = `modal-content shadow-lg border border-${this.borderType()}`;
        const modalClasses = `${this.modalClasses()} ${this.modalSize}`;

        return html`
            <div class="modal-backdrop show managed-backdrop"></div>
            <div class="modal show modal-dialog-scrollable" data-bs-backdrop="false" data-bs-keyboard="false" tabindex="-1" ${ref(this.modalRootRef)}>
                <div class=${modalClasses}>
                    <div class=${modalContentClasses}>
                        <div class="modal-header">
                            <h2 class="modal-title"><slot name="title"></slot></h2>
                            <button type="button" class="btn-close" @click=${this.eventClose} aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <slot name="body"></slot>
                        </div>
                        <div class="modal-footer">
                            <slot name="footer"></slot>
                        </div>
                    </div>
                </div>
            </div>`;
    }

    connectedCallback(): void {
        super.connectedCallback?.();
        this.renderRoot.addEventListener('close', () => this.closeIfAllowed());
    }

    private eventClose = (e: Event) => {
        this.dispatchEvent(new Event('dialog-close', { bubbles:true}));
        e.preventDefault();
        e.stopPropagation();
        this.closeIfAllowed();
    };

    protected canClose(): boolean {
        // If we establish a mechanism to allow child components to interrupt a close operation then
        // this would be the place where we trigger it.
        return true;
    }

    borderType() {
        return "basic";
    }

    protected closeIfAllowed(): boolean {
        if (this.canClose()) {
            setTimeout(() => this.remove(), 0);
            return true;
        }
        return false;
    }

    protected modalClasses(): string {
        return 'modal-dialog';
    }
}



