import { base64ToObject } from "../../../../blob/converters";
import { QuoteItemContainer } from "../../../../quotes/data/quote-item-container";
import { V6SupplierQuote } from "../../../../quotes/data/v6/v6-supplier-quote";
import { V6PropertyGroup, V6QuoteIGU, V6QuoteItem } from "../../../../v6config/v6-quote-item/v6-quote-item-data";
import { V6FranchiseeQuoteProviderData } from "../franchisee-quote-provider-data";

export class FranchiseeV6SupplierQuote extends V6SupplierQuote {
    private get quoteData(): V6FranchiseeQuoteProviderData {
        return this.qcm.quoteProviderData() as V6FranchiseeQuoteProviderData;
    }
    getDefaultOptions(): V6PropertyGroup[] {
        return this.quoteData?.defaultOptions ?? [];
    }
    getQuoteIGUs(): V6QuoteIGU[] {
        return this.quoteData?.igu ?? [];
    }
    getV6QuoteItem(container: QuoteItemContainer): V6QuoteItem {
        return base64ToObject(container.data?.providerData ?? "") as V6QuoteItem;
    }
}