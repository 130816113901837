import { getApiFactory } from "../../../api/api-injector";
import { FranchiseeQuoteContainerManager } from "../data/franchisee-quote-manager";
import { Quote, QuoteState } from "../../../api/dealer-api-interface-quote";
import { ProjectState, ResourceType } from "../../../api/dealer-api-interface-project";
import { emptyGuid, newGuid } from "../../../api/guid";
import { fireQuickSuccessToast } from "../../../toast-away";
import { getProjectNumberFormatted } from "../../projects/data/project-helper-functions";
import { localDateTimeToServer, today } from "../../../components/datetime-converter";
import {
    QuoteDataEntryView,
    QuoteView,
  QuoteViewChildFactory,
} from "../../../quotes/views/quote-view";
import { emptyAddress } from "../../../components/ui/maps/map-helpers";
import { isEmptyOrSpace, validId } from "../../../components/ui/helper-functions";
import { Information } from "../../../components/ui/modal-option";
import { tlang } from "../../../language/lang";
import { cache } from "../../cache/cache-registry";
import { BranchQuote } from "../../../api/dealer-api-interface-franchisee";
import { PromiseSnippet } from "../../../components/ui/events";
import { getQuoteTitle } from "../../../quotes/data/quote-helper-functions";
import { html } from "lit";
import { DevelopmentError } from "../../../development-error";
import { launchProject } from "../../../projects/ui/launcher";
import { launchPurchaseOrder } from "../../../purchase-orders/ui/launcher";
import { FranchiseeApi } from "../../../api/franchisee-api";
import { FranchiseeQuoteContainer } from "../data/franchisee-quote-container";
import { PricingEngineApi } from "../../../api/pricing-engine-api";
import { FrameEngineApi } from "../../../api/frame-engine-api";

export class FranchiseeQuoteDEView extends QuoteDataEntryView {
    userCache = cache().userProfile;
    contactCache = cache().contact;
    clientCache = cache().client;

    franchiseeApi: FranchiseeApi = getApiFactory().franchisee();
    pricingEngineApi: PricingEngineApi = getApiFactory().pricingEngine();
    frameEngineApi: FrameEngineApi = getApiFactory().frameEngine();

    prlCache = cache().projectResourceLink;
    projectCache = cache().project;
    purchaseOrderCache = cache().purchaseOrder;

    protected projectApi = getApiFactory().project();
    protected purchaseOrderApi = getApiFactory().purchaseOrder();
    protected reportApi = getApiFactory().reporting();

    public get quote(): Quote {
        if (!this.quoteContainer.quote) throw new DevelopmentError("quote is null");
        return this.quoteContainer.quote;
    }

    protected get branchQuote(): BranchQuote | null {
        if (!(this.quoteContainerManager.container instanceof FranchiseeQuoteContainer))
            throw new DevelopmentError("FranchiseeQuoteView requires a container type FranchiseeQuoteContainer");
        return (this.quoteContainerManager.container as FranchiseeQuoteContainer).branchQuote;
    }

    public async afterConstruction(): Promise<void> {
        await super.afterConstruction();
        await this.prlCache.get(this.quote.id);
    }

    public async getTitle(): PromiseSnippet {
        //override and do not use this.title

        const navProjectEvent = () => {
            this.closeAndNavigateToProject();
        };
        const navOrderEvent = () => {
            this.closeAndNavigateToPurchaseOrder();
        };
        const quote = this.quoteContainer.quote;
        if (!quote) return html``; // will never happen

        const projectId = await this.projectId();
        const orderId = await this.purchaseOrderId();

        const projectTemplate = validId(projectId)
            ? html`<a href="#" @click=${navProjectEvent}
                      class="btn btn-sm btn-light">${this.projectCache.getLocal(projectId)?.displayValue}</a>`
            : html``;
        const orderTemplate = validId(orderId)
            ? html`<a href="#" @click=${navOrderEvent}
                      class="btn btn-sm btn-light">${this.purchaseOrderCache.getLocal(orderId)?.displayValue}</a>`
            : html``;

        return html`
            ${getQuoteTitle(quote)}
            <div class="title-links">
                ${projectTemplate}
                ${orderTemplate}
            </div>
        `;
    }

    public async generateReport(_internal = false): Promise<void> {
        throw new Error("Report Override not implemented.");
    }

    protected async purchaseOrderId(): Promise<string> {
        if (!this.quoteContainer.quote) return emptyGuid;
        const data = (await this.prlCache.getData(this.quoteContainer.quote.id));
        return data?.purchaseOrderId ?? emptyGuid;
    }

    protected async projectId(): Promise<string> {
        if (!this.quoteContainer.quote) return emptyGuid;
        const data = (await this.prlCache.getData(this.quoteContainer.quote.id));
        return data?.projectId ?? emptyGuid;
    }

    protected async closeAndNavigateToProject(): Promise<void> {
        const projectId = await this.projectId();
        if (validId(projectId)) {
            //no awaiting. we want this workflow to exit
            launchProject({id: projectId, navigateIfAllowed: async () => await this.tryClose()});
        }
    }

    protected async closeAndNavigateToPurchaseOrder(): Promise<void> {
        const orderId = await this.purchaseOrderId();
        if (validId(orderId)) {
            //no awaiting. we want this workflow to exit
            launchPurchaseOrder({id: orderId, navigateIfAllowed: async () => await this.tryClose()});
        }
    }

    protected getQuoteViewChildFactory(): QuoteViewChildFactory {
      throw new Error('abstract function call');
    }

    protected async internalSetQuoteState(state: QuoteState): Promise<boolean> {
        try {
            const qm = this.quoteContainerManager as FranchiseeQuoteContainerManager;
            const originalState = qm.quote.state;
            let saved = false;

            qm.quote.state = state;

            if (!(state == QuoteState.Active || state == QuoteState.Accepted)) {
                saved = await super.internalSetQuoteState(state);
                if (!saved) {
                    qm.quote.state = originalState;
                    return saved;
                }
            }

            const owner = await this.ownerId();

            if (state == QuoteState.Active) {
                if (!(await this.checkValidations())) {
                    qm.quote.state = originalState;
                    return false;
                }

                saved = await this.internalCreateProject(qm, saved, owner);
            }

            if (state == QuoteState.Accepted) {
                // Disabling CreatePurchaseOrder because it is recommended by PBI 215283
                // Instead, jus change quote state.
                //saved = await this.internalCreatePurchaseOrder(qm, saved, owner);
                saved = await super.internalSetQuoteState(state);
                if (!saved) {
                    qm.quote.state = originalState;
                    return saved;
                }
            }

            if (!saved) {
                //If we get here, it means that we haven't saved anything and need to reset the state.
                //Maybe use the qm reset?
                qm.quote.state = originalState;
            }

            return saved;
        } finally {
            await this.render();
        }
    }

    protected async canAddItemFrame(): Promise<boolean> {
        const qm = this.quoteContainerManager as FranchiseeQuoteContainerManager;
        if (isEmptyOrSpace(qm.branchQuote.clientTypeId)) {
            await Information(tlang`Pricing is based on the %%payment-profile%% which is not set <br>Please set the %%payment-profile%% before adding !!frame!!`);
            return false;
        }
        return true;
    }

    protected hasSupplierGlassView(): boolean {
        return true;
    }

    // Leaving this commented out because it is not used but the usage of it is commented out
    // referencing:
            // Disabling CreatePurchaseOrder because it is recommended by PBI 215283
            // Instead, jus change quote state.
    // private async internalCreatePurchaseOrder(qm: FranchiseeQuoteContainerManager, saved: boolean, _owner: string): Promise<boolean> {
    //     //State is set to Active
    //     const state = QuoteState.Accepted;

    //     const mergeById = (a1: QuoteItem[] | null, a2: QuoteItemPrice[] | null) =>
    //         a1?.map(item => ({
    //             ...item,
    //             ...a2?.find((item2) => item2.id === item.id)
    //         }));

    //     const quoteFromCache = await this.prlCache.getData(qm.quoteId);
    //     if (!quoteFromCache) return false;
    //     //Maybe we can use the project cache here?
    //     const projectResult = await this.projectApi.getProject({projectId: quoteFromCache?.projectId ?? emptyGuid});

    //     //We should not get an empty project since the project would have been created earlier in the workflow (when
    //     //a quote is issued)
    //     if (projectResult == null)
    //         return false;

    //     await qm.needsQuoteItems(true);
    //     const items = mergeById(qm.container.items, qm.container.itemPrices);

    //     const lineItems: InputLineItem[] = items?.filter((item) => (item.itemType == 1 && item.quoteItemContentType == 1) || item.itemType == 2).map((item) => {
    //         return {
    //             id: item.id,
    //             grossQuantityCost: item.quantityCost ?? 0,
    //             grossSupplierQuantityCost: item.supplierGrossQuantityCost ?? 0
    //         };
    //     }) ?? [];

    //     const purchaseOrderResult = await this.purchaseOrderApi.createPurchaseOrder({
    //         numberSeed: "",
    //         purchaseOrder: {
    //             state: PurchaseOrderState.Draft,
    //             purchaseOrderNumber: 0,
    //             id: newGuid(),
    //             branchQuoteId: qm.branchQuote.id,
    //             creationUserId: emptyGuid,
    //             dateCreated: localDateTimeToServer(today()),
    //             calculatedGrossTotal: 0,
    //             calculatedTaxAmount: 0,
    //             calculatedNetTotal: 0,
    //             installationDate: qm.quote.installationDate,
    //             notesToVendor: "",
    //             recordVersion: "",
    //             quoteDescription: qm.quote.description,
    //             projectDescription: projectResult.project.description ?? "",
    //             title: `${qm.quoteTitle}`,
    //             reference: "",
    //             supplierId: qm.quote.supplierId
    //         },
    //         lineItems: lineItems
    //     });

    //     if (!purchaseOrderResult) {
    //         fireQuickErrorToast(tlang`Unable to create %%purchase-order%%. %%quote%% has not been accepted.`);
    //         saved = false;
    //     } else {
    //         await this.purchaseOrderCache.updateLocal(purchaseOrderResult.purchaseOrder.id);
    //         fireQuickSuccessToast(
    //             tlang`%%purchase-order%% ${getPurchaseOrderNumberFormatted(purchaseOrderResult.purchaseOrder)} created!`,
    //             1000);

    //         const resource = await this.projectApi.createProjectResourceReference({
    //             resourceId: purchaseOrderResult.purchaseOrder.id,
    //             projectId: projectResult.project.id,
    //             typeOf: ResourceType.PurchaseOrder,
    //             parentResourceId: this.quote.id,
    //             parentTypeOf: ResourceType.Quote

    //         });

    //         if (resource)
    //             saved = await super.internalSetQuoteState(state);
    //     }

    //     return saved;
    // }

    private async internalCreateProject(qm: FranchiseeQuoteContainerManager, saved: boolean, owner: string): Promise<boolean> {
        //State is set to Active
        const state = QuoteState.Active;

        //When quote is changed to Active, we need to generate a quote number;
        qm.generateQuoteNumberOnSave();

        const project = await this.projectApi.createProject({
            project: {
                state: ProjectState.Active,
                title: qm.quote.title,
                number: 0,
                budget: qm.quote.budget ?? 0,
                id: newGuid(),
                clientId: qm.branchQuote.clientId,
                contactId: qm.branchQuote.contactId,
                clientTypeId: qm.branchQuote.clientTypeId,
                description: qm.quote.description,
                recordVersion: "",
                dateCreated: localDateTimeToServer(today()),
                lastModifiedDate: localDateTimeToServer(today()),
                shippingNotes: null,
                defaultAddress: emptyAddress(),
                projectOwnerId: owner,
                creationUserId: emptyGuid, //set on server
                lastModifiedUserId: emptyGuid //set on server
            },
            numberSeed: ""
        });

        if (project) {
            const resource = await this.projectApi.createProjectResourceReference({
                resourceId: qm.quote.id,
                projectId: project.project.id,
                typeOf: ResourceType.Quote,
                parentResourceId: emptyGuid,
                parentTypeOf: ResourceType.None
            });

            if (resource) {
                await this.projectCache.updateLocal(project.project.id);
                saved = await super.internalSetQuoteState(state);
            } else {
                //TODO - DELETE the BLANK PROJECT????
            }

            fireQuickSuccessToast(`Project ${getProjectNumberFormatted(project.project)} created!`, 1000);
        }
        return saved;
    }

}

export class FranchiseeQuoteView extends QuoteView {
    protected createView(): QuoteDataEntryView {
        return new FranchiseeQuoteDEView(this.options, this);
    }
}
