import { ApiCommunications } from "../../api/api-communications";
import { NullPromise } from "../../null-promise";
import { ItemReference } from "../../cache/definitions/cache-item-reference";
import { DataCacheBase } from "../../cache/data-cache-base";
import { RequestGetQuoteSummaryListByIds, ResultGetQuoteSummaryListByIds, ViewQuoteSummary } from "../../api/dealer-api-interface-quote";
import { getQuoteNumberFormatted } from "../../quotes/data/quote-helper-functions";
import { QuoteCacheData } from "./cache-data";

export class QuoteCache extends DataCacheBase<QuoteCacheData> {
    constructor(api: ApiCommunications) {
        super(api);
        //depending on what high level controller api we create we could swap this to ClientApi etc.
    }

    public async updateFromSummaries(input: ViewQuoteSummary[]) {
        //flush the cache
        input.forEach(x => {
            this.removeLocal(x.id);
        });
        const resultQuotes: ResultGetQuoteSummaryListByIds = { quoteSummaries: input };
        const items = await this.processInternalFetch(resultQuotes);
        this.addToCache(items);
        return items;
    }
    //override;

    protected async processInternalFetch(resultQuotes: ResultGetQuoteSummaryListByIds | null): NullPromise<ItemReference<QuoteCacheData>[]> {
        if (!resultQuotes) return null;

        return resultQuotes.quoteSummaries.map(q => {
            const data: QuoteCacheData = {
                quoteSummary: q
            };
            const qvs: ItemReference<QuoteCacheData> = {
                id: q.id,
                displayValue: `${getQuoteNumberFormatted(q)}`,
                data: data,
                onClick: null
            };
            return qvs;
        });
        //
    }
    protected async internalFetch(requestIds: string[]): NullPromise<ItemReference<QuoteCacheData>[]> {
        const input: RequestGetQuoteSummaryListByIds = {
            quoteIds: requestIds
        };

        //these should just be added to the API Exposures so that they are reuseable
        const resultQuotes = await this.api.post<ResultGetQuoteSummaryListByIds>(`api/Quote/GetQuoteSummaryListByIds`, input);


        return await this.processInternalFetch(resultQuotes);
    }
}

