
import { html } from "lit";
import { Quote } from "../../api/dealer-api-interface-quote";
import { EventBoolean, PromiseTemplate, Snippet } from "../../components/ui/events";
import { ViewBase } from "../../components/ui/view-base";
import { QuoteContainerManager } from "../data/quote-container";

export interface QuoteSupplierGlassViewOptions {
    quoteManager: QuoteContainerManager;
    forceReadonly: EventBoolean;
}
export class QuoteSupplierGlassView extends ViewBase {
    quoteManager: QuoteContainerManager;
    forceReadonly: EventBoolean;

    constructor(options: QuoteSupplierGlassViewOptions) {
        super();
        this.quoteManager = options.quoteManager;
        this.forceReadonly = options.forceReadonly;
    }
    async afterConstruction(): Promise<void> {
        await this.quoteManager.needsQuote();
    }

    getValidationErrors(): string[] {
        return [];
    }

    async prepareForSave() {
        //nothing to do
    }

    buttonMenu(): Snippet {
        return html``;
    }

    get supplierId(): string {
        return this.quoteManager.quote.supplierId;
    }
    get quote(): Quote {
        return this.quoteManager.quote;
    }

    protected async template(): PromiseTemplate {
        return html``;
    }

    public async refresh(): Promise<void> {
        //override;
    }


}