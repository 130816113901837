import { ClientDetailView, ClientDetailViewOptions } from "../../../clients/views/client-detail-view";
import { PaymentProfileApi } from "../../../api/payment-profile-api";
import { ClientState } from "../../../api/dealer-api-interface-client";
// eslint-disable-next-line import/named
import { html } from "lit";
import "../../../components/ui/maps/google-place-autocomplete";
import "../../../components/ui/maps/google-map";
import { tlang } from "../../../language/lang";
import { googleMapApiKey } from "../../../api/google-api";
import { getApiFactory } from "../../../api/api-injector";
import { bsFormRadioGroupYesNoOptions, displayYesNo } from "../../../components/ui/helper-functions";
import { mapTemplate } from "../../../components/ui/maps/map-helpers";
import { FranchiseeClientContainerManager } from "../data/franchisee-client-container";
import { DevelopmentError } from "../../../development-error";
import { cache } from "../../cache/cache-registry";
import { DataCacheGeneric } from "../../../cache/generic-data-cache";
import { PromiseTemplate } from "../../../components/ui/events";
import { DataEntryPageControlView } from "../../../components/ui/data-entry-screen-base";

export class FranchiseeClientDetailView extends ClientDetailView {
    paymentProfileApi: PaymentProfileApi = getApiFactory().paymentProfile();
    paymentProfileCache: DataCacheGeneric = cache().paymentProfile;
    paymentProfileDisplay = "";

    constructor(owner:DataEntryPageControlView, options: ClientDetailViewOptions) {
        super(owner, options);

        if (!(options.clientContainerManager instanceof FranchiseeClientContainerManager))
            throw new DevelopmentError("FranchiseeClientDetailView only supports FranchiseeClientContainerManager");
    }

    protected get franchiseeClientManager(): FranchiseeClientContainerManager {
        return this.clientContainerManager as FranchiseeClientContainerManager;
    }

    public async loadOrRefresh(): Promise<void> {
        await super.loadOrRefresh();
        await this.render();
    }

    public getValidationErrors(): string[] {
        const errors = super.getValidationErrors();
        return errors;
    }

    protected async bodyTemplate(): PromiseTemplate {
        return html`
            <form class="frm-client-details form-two-col">
                <div class="row">
                    <div>
                        <h2>${tlang`%%client%% Details`}:</h2>
                        ${this.forms.textRequired("name", tlang`Name`, 100)}
                        ${this.forms.text("businessPhoneNumber", tlang`Phone`, 30)}
                        ${this.forms.radioGroup("state", ClientState, tlang`Status`)}
                    </div>

                    <div>
                        <h2>${tlang`Contact Details`}:</h2>
                        ${this.primaryContactTemplate()}
                    </div>
                </div>

                <h2>${tlang`Physical Address`}:</h2>
                <div class="row">
                    <div>
                        <google-place-autocomplete class="left right" data-id="clientauto" outline
                                                   searchType=${"address"}
                                                   .apiKey=${googleMapApiKey}
                                                   label=${tlang`Quick Find`}
                                                   @place-changed=${_ => this.updateAddress(_.detail.place)}>
                        </google-place-autocomplete>

                        ${this.forms.text("line1", tlang`Street Address`, 120)}
                        ${this.forms.text("locality", tlang`City`, 120)}
                        ${this.forms.text("region", tlang`State`, 120)}
                        ${this.forms.text("postcode", tlang`Zip Code`, 20)}
                        ${this.forms.text("country", tlang`Country`, 80)}
                        <bs-form-checkbox data-label=${tlang`Default Shipping Address`}
                                          data-id=${this.dataBinding.field("shipToPhysicalAddress")} ?readonly=false
                                          .checked=${this.client.shipToPhysicalAddress}
                                          @checkbox-changed=${_ => this.changeDefaultShipping(_.detail)}>
                        </bs-form-checkbox>
                    </div>

                    <div>
                        ${mapTemplate(this.latPhysical, this.lngPhysical)}
                    </div>
                </div>

                <h2>${tlang`Billing Address`}:</h2>
                <div class="row">
                    <div>
                        <bs-form-radio-group data-id=${`client-billing-physical-${this.internalId}`}
                                             data-label=${tlang`Same as Physical Address`}
                                             value=${displayYesNo(this.billingAsPhysicalAddress)}
                                             options=${bsFormRadioGroupYesNoOptions()}
                                             @radio-changed=${_ => this.hideAndUpdateBilling(_.detail)}>
                        </bs-form-radio-group>

                        ${this.getBillingAddressTemplate()}
                    </div>
                    <div>
                        ${this.billingAsPhysicalAddress ? html`` : mapTemplate(this.latBilling, this.lngBilling)}
                    </div>
                </div>
            </form>
        `;
    }

    private changeDefaultShipping(value: boolean) {
        this.clientContainerManager.client.shipToPhysicalAddress = value;
        this.render(); //no wait
    }
}
