// eslint-disable-next-line import/named
import { html, TemplateResult } from "lit";
import { newGuid } from "../../../../api/guid";
import { DataTracker, FieldType } from "../../../../components/ui/databinding/data-tracker";
import { DataBinding, getInternalId } from "../../../../components/ui/databinding/databinding";
import { PromiseTemplate } from "../../../../components/ui/events";
import { PageManager } from "../../../../components/ui/page-control";
import { FormInputAssistant } from "../../../../components/ui/templateresult/form-input-assistant";
import { tlang } from "../../../../language/lang";
import { QuoteItemContainer } from "../../../../quotes/data/quote-item-container";
import { quoteSupplierProvider } from "../../../../quotes/data/quoteSupplierProvider";
import { supplierQuoteItemContentType } from "../../../../quotes/data/supplier-quote-item-content-type";
import { QuoteItemView, QuoteItemViewOptions } from "../../../../quotes/views/quote-item-view";
import gridFrameWizardDialog, { framePickerDialog } from "./v7-quote-line-custom-frame-view";
//TODO Jero 2022-07-26 this is a web component so should be loaded somewhere a bit more global.
import './V7-minimal-picker-wrapper';
import './st-modal.ts';

import { DevelopmentError } from "../../../../development-error";
import { base64ToObject, objectToBase64 } from "../../../../blob/converters";
import { isEmptyOrSpace } from "../../../../components/ui/helper-functions";

export enum CustomOrStandardFrame {
    None,
    Standard,
    Custom
}

export interface V7ItemProviderContent {
    configurationId: number;
}

export class QuoteItemFrameViewForV7 extends QuoteItemView {
    configurationId?: number;
    dataTracker: DataTracker;
    newFrameIsCustom: CustomOrStandardFrame;

    constructor(options: QuoteItemViewOptions, newFrameIsCustom?: CustomOrStandardFrame) {
        super(options);
        this.newFrameIsCustom = newFrameIsCustom ?? CustomOrStandardFrame.None;
        this.dataTracker = new DataTracker(new DataBinding(this.ui, getInternalId()));
        const addField = (fieldName: string, propertyType?: FieldType, nullable?: boolean, editorFieldName?: string, data?: () => any) => {
            this.dataTracker.addObjectBinding(data ?? (() => this.quoteItemContainer?.item), fieldName, editorFieldName ?? fieldName, propertyType ?? FieldType.string, nullable ?? false);
        };

        addField("title");
        addField("description");
        addField("quantity", FieldType.int);
        addField("comment");
    }

    public async prepareEditor(): Promise<void> {
        this._readyToEdit = await this.v6CreateOrBindQuoteItem(this.quoteItemContainer || undefined);
    }

    public internalDataChanged(): boolean {
        return this.quoteManager.changedItem(this.quoteItemContainer?.item.id);
    }

    public isTab(): boolean {
        return true;
    }

    protected getUIValidationErrors(): string[] {
        const errorResult : string[] = [];

        const quantityValue = this.dataTracker.getEditorValue("quantity");
        const title = this.dataTracker.getEditorValue("title");

        if (quantityValue == null) errorResult.push("Please provide a value for Quantity");
        if (isEmptyOrSpace(title?.toString())) errorResult.push(tlang`Please provide a Quote Item Title`);

        return errorResult;
    }

    /**
    * inherited
    * @returns
    */
    public async prepareForSave(): Promise<void> {
        //Implementation note: this is just a pruned down version the V6 version of this file
        if (this.quoteItemContainer) {
            if (this.dataTracker.modified){
                this.dataTracker.applyChangeToValue();
            }
        }
    }

    async v6CreateOrBindQuoteItem(container?: QuoteItemContainer): Promise<boolean> {
        const needToCreateNewFrame = !container;
        if (needToCreateNewFrame && !container) {
            const isCustomFrame = this.newFrameIsCustom === CustomOrStandardFrame.Custom;
            let configurationId: number | undefined = 0;
            let title = '';
            if (isCustomFrame) {
                configurationId = await gridFrameWizardDialog();
                title = tlang`New %%custom-frame%%`;
            } else {
                configurationId = await framePickerDialog();
                title = tlang`New %%frame%%`;
            }
            if (configurationId === undefined) {
                return false;
            }

            await this.createQuoteItem(title, configurationId);
        }

        if (!this.quoteItemContainer) return false;

        const providerData = this.quoteItemContainer?.data?.providerData;

        //configuration id should already exist here
        if (providerData) {
            try{
                const decodedProviderData = base64ToObject<V7ItemProviderContent>(providerData);
                if (decodedProviderData && decodedProviderData.configurationId) {
                    this.configurationId = decodedProviderData.configurationId;
                }
            } catch {
                // the base64ToObject() can fail for quotes that just have the raw config id in providerData
                // so we catch and absorb the exception to allow below code to have a go.
                //NB this should not actually be a problem for prod. Just for testing site before 2022-08-15
            }
        }

        if (!this.configurationId) {
            throw new DevelopmentError(tlang`The %%quote-item%% does not have any providerData in its container`);
        }

        return !!this.configurationId;
    }

    async createQuoteItem(title: string, frameEngineConfigurationId: number): Promise<boolean> {
        const result = await this.quoteManager.createQuoteItem({
            id: newGuid(),
            title: title,
            description: "",
            quantity: 1,
            comment: "",
            quoteItemContentType: supplierQuoteItemContentType.CID_FRAM,
            externalProvider: {
                service: quoteSupplierProvider.v7,
                referenceId: newGuid(),
                data: objectToBase64({ configurationId: frameEngineConfigurationId } as V7ItemProviderContent) ?? ''
            },
            price: null,
            thumbnail: null
        });
        if (result) {
            this.quoteItemContainer = result;
            return true;
        }
        return false;
    }

    protected async internalSaveData(): Promise<boolean> {
        if (this.quoteItemContainer) {
            this.quoteItemContainer = await this.quoteManager.saveAndUpdateQuoteItem(this.quoteItemContainer, '');
            return this.quoteManager.lastSaveSuccessful;
        }
        return false;
    }

    protected async bodyTemplate(): PromiseTemplate {
        return html`<configuration-page configuration-id="${this.configurationId}">
    ${this.quoteItemPropertiesTemplate()}
    <span slot="quote-item-title">${this.quoteItemContainer?.item.title}</span>
</configuration-page>`;
    }

    quoteItemPropertiesTemplate(): TemplateResult {
        const forms = new FormInputAssistant(this.dataTracker, this.quoteManager.isReadonly());
        if (this.quoteItemContainer?.item) {
            return html`
                <div slot="quote-item-properties" class="form-one-col quoteItemFrameProperties">
                    <div class="row attributeContents">
                        <div class="v6config-group">
                            <div class="v6config-group-header">
                                ${tlang`Properties`}
                            </div>
                            ${forms.textRequired("title")}
                            ${forms.text("description")}
                            ${forms.intRequired("quantity")}
                            ${forms.note("comment")}
                        </div>
                    </div>
                </div>`;
        } else return html``;
    }

    public createPageManager(): PageManager {
        return {
            caption: () => {
                const title = "V7 Frame Item";
                return title;
            },
            canClose: async (_index: number, _page: PageManager): Promise<boolean> => {
                //check if we need to save anything here.. how? comparison? keep original copy?
                return await this.allowPageSwitch();
            },
            canLeave: async () => await this.allowPageSwitch(),
            hasDelete: () => true,
            content: async () => {
                await this.render();
                return this.ui;
            },
            data: this
        };
    }


}
