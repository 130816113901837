import apiHostJson from '../assets/apiserver.json';

const environmentOverrides = {};

const apiHostInfo = {
    ...apiHostJson,
    ...environmentOverrides
};

export default apiHostInfo
