import { Quote, QuoteState, ViewQuoteSummary } from "../../api/dealer-api-interface-quote";
import { tlang } from "../../language/lang";
import { html } from "lit";
import { isEmptyOrSpace } from "../../components/ui/helper-functions";

export const getQuoteNumberFormatted = (q: ViewQuoteSummary | Quote | null, includeState = true) => {
    if (!q)
        return "";
    return q.quoteNumber == 0
        ? (includeState ? tlang`Draft` : "")
        : `${q.quoteNumber.toString().padStart(6, "0")}`;
};

export const getQuoteTitle = (q: any) => {
    const quote = q as Quote;
    let quoteNumber = getQuoteNumberFormatted(quote, false);
    if (!isEmptyOrSpace(quoteNumber)) quoteNumber += " ";

    return html`${quoteNumber}${quote.title}
<span class="badge badge-${QuoteState[quote.state].toLowerCase()}">${QuoteState[quote.state]}</span>`;
};