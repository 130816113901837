/// <summary>
/// examples
///   QuoteItemType.Provider (reserved 1000+)
///     1000 = frame
///     1001 = component DataProvider.Data = " { objectreference: "bla", libid, id, classid }
///     1002 = extn
///   QuoteItemType.Basic (reserved 1)
///     1 = Shipping
///     2 = Freehand
/// </summary>

//properties for QuoteItemType on the quote item, when the provider is v6
//but we will expect other providers to map and use the same values for consistency
export const supplierQuoteItemContentType = {
    CID_FRAM: 7,
    CID_COMP: 2,
    CID_EXTN: 3,
    CID_FILL: 4,
    CID_IGU: 90, //QTE_IGU = 52, but we dont need
    CID_LOUVRE: 100, //TODO - put real value
    CID_V7_FRAM: 1000,
    CID_V7_CUSTOM_FRAM: 1001,
};
