import { emptyGuid } from "../../api/guid";
import { tlang } from "../../language/lang";

export function validId(value: string | null | undefined): boolean {
    return !isEmptyOrSpace(value);
}

export function isEmptyOrSpace(value: string | null | undefined): boolean {
    return value === null || value === undefined || value.trim() === "" || value.trim() == emptyGuid;
}

export function isValidEmail(mail: string): boolean {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(mail);
}


function displayBoolean(yes: string, no: string, value?: boolean) {
    return value ? yes : no;
}

export function displayYesNo(value?: boolean) {
    return displayBoolean(tlang`Yes`, tlang`No`, value);
}

export function bsFormRadioGroupYesNoOptions() {
    const value = JSON.stringify([{ value: "Yes", text: "Yes" }, { value: "No", text: "No" }]);
    return value;
}

export function firstValidString(...args) {
    for (let i = 0; i < args.length; i++)
        if (!isEmptyOrSpace(args[i]))
            return args[i];
    return "";
}