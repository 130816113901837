import { html, TemplateResult } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { PromiseSnippet } from '../../components/ui/events';
import { TimedTrigger } from '../../timed-trigger';
import { Finish, FinishFilter } from './v6-finish-picker-data-provider';
import { ResourcePicker, ResourcePickerOptions } from './v6-resource-picker';
import { ResourceEndpoint } from './v6-resource-picker-data-provider';

export class V6FinishPicker extends ResourcePicker<Finish, FinishFilter> {
    timedTrigger: TimedTrigger;
    constructor(comms: ResourceEndpoint<Finish, FinishFilter>, options: ResourcePickerOptions) {
        super(comms, options);

        //fire a change event only when the text triger fires
        const triggerEvent = (e) => {
            this.filterChangeEvent(e);
        };
        //set up a trigger on the filter that fires after 1second of idle time, or if a change event occurs
        this.timedTrigger = new TimedTrigger(1000, triggerEvent);
    }
    filterChangeEvent(e: Event) {
        //update the filter
        this.setFilter({ textFilter: (e.target as HTMLInputElement).value });
    }
    protected async getTitle(): PromiseSnippet {
        return "Select a Finish";
    }

    protected filterTemplate(): TemplateResult {
        const resetEvent = this.timedTrigger.getResetEvent();
        const triggerEvent = this.timedTrigger.getTriggerEarlyEvent();
        return html`
        <div class="row form-group filter-wrapper">
            <div class="col-1 filter-text">
                Filter:
            </div>
            <div class="col-4 filter-input">
                <input class="form-control" placeholder="Text Search" @oninput=${resetEvent} @blur=${triggerEvent}
                    @keyup=${resetEvent} />
            </div>
        </div>`;
    }
    protected tableHeaderTemplate(): TemplateResult {
        return html`
        <th>Description</th>
        <th>Group Description</th>
        <th>Color</th>`;
    }
    //override this to provide a single row of the view based on the item
    //this should be a template of <td> elements
    protected columnsTemplate(item: Finish): TemplateResult {
        const colorSwatch = `<div class="finish-color-block" style="background-color:#${item.hexColour};"></div>`;
        const r = html`
        <td>${item.description}</td>
        <td>${item.groupDescription}</td>
        <td>
            ${unsafeHTML(colorSwatch)}
        </td>`;

        return r;

    }

}