
import { getApiFactory } from "../../api/api-injector";
import { BranchQuote } from "../../api/dealer-api-interface-franchisee";
import { InputCreateQuote, QuoteType } from "../../api/dealer-api-interface-quote";
import { emptyGuid, newGuid } from "../../api/guid";
import { objectToBase64 } from "../../blob/converters";
import { localDateToServer, today } from "../../components/datetime-converter";
import { isEmptyOrSpace } from "../../components/ui/helper-functions";
import { showError } from "../../components/ui/show-error";
import { tlang } from "../../language/lang";
import { QuoteContainerManager } from "../../quotes/data/quote-container";
import { currentQuoteSupplierProvider } from "../../quotes/data/quoteSupplierProvider";
import { getQuoteSuppliers, QuoteSupplier } from "../../quotes/quote-service";
import { GraphicalFamilyResourcePicker } from "../../picker/graphical-family-resource-picker";

import { FamilyResourceTag, ResourceFamilyEndpoint, ResourceFamilyResults } from "../../picker/family-resource-picker-data";
import { userDataStore } from "../common/current-user-data-store";
import { FranchiseeQuoteContainer } from "./data/franchisee-quote-container";

export interface createFranchiseeQuoteOptions {
    supplierId: string,
    owner: string,
    defaultTermsAndConditions: string,
    quoteValidityInDays: number
}

export async function createNewQuote(options: createFranchiseeQuoteOptions): Promise<FranchiseeQuoteContainer | null> {
    if (!isEmptyOrSpace(options.owner)) {
        try {
            //Shane: For initial release no modal, just create a quote
            //Kyley - Defaults here dont matter. defaults will be updated
            //via ui changing client settings etc

            const serviceProviderData = await QuoteContainerManager.createQuoteServiceProviderData(currentQuoteSupplierProvider, options.supplierId);

            const inputCreateQuote: InputCreateQuote = {
              id: newGuid(), //change this to use server side guid fetch
              quoteType: QuoteType.Quote,
              title: tlang`New %%quote%%`,
              quoteOwnerId: options.owner,
              validUntilDate: localDateToServer(today(options.quoteValidityInDays)),
              termsAndConditions: options.defaultTermsAndConditions,
              supplierId: options.supplierId,
              serviceProvider: currentQuoteSupplierProvider,
              serviceProviderData: objectToBase64(serviceProviderData),
            };

            if (!userDataStore.defaultBranch) await userDataStore.loadCoreDetails();
            if (!userDataStore.defaultBranch) {
                await showError(tlang`cannot create %%quote%% because you are not assigned to a %%branch%%, please contact support`);
                return null;
            }

            const quoteResult = await getApiFactory().quote().createQuote(inputCreateQuote);


            //TODO - we are only going to allow one kind of provider per quote
            // and only allow one kind of supplier per quote
            // so at some point, we need to add to the Quote/or Branch Quote
            // some limitation fields.. is ProviderType "v6"/"v7" and "supplierref"

            if (!quoteResult) return null;
            const branchQuote: BranchQuote = {
                branchId: userDataStore.defaultBranch.id,
                //Use the exact same id for the branch quote. makes it easy to extend
                id: quoteResult.quote.id,
                clientId: emptyGuid,
                clientName: "",
                clientTypeId: emptyGuid,
                contactId: emptyGuid,
                clientTypeName: "",
                contactName: "",
                dateCreated: localDateToServer(new Date()),
                recordVersion: "",
            };

            //if there is any reason we can't create this, we wont worry about it now, if we already have the quote
            //created. we will just attempt to create it later. if it cant be done later, it is probably
            //a system failure.
            const branchQuoteResult = await getApiFactory().franchisee().createBranchQuote({ branchQuote: branchQuote });

            //construct new quote container for an empty new quote
            return new FranchiseeQuoteContainer(
                quoteResult.quote.id,
                quoteResult.quote,
                quoteResult.quotePrice,
                quoteResult.quotePresentation,
                null,//we want to force load any potential server generated items
                null,
                null,
                branchQuoteResult?.branchQuote ?? branchQuote,
                serviceProviderData,
                true
            );

        } catch {
            return null;
        }
    } else {
        //owner is defined as the Branch of the user.
        await showError(tlang`cannot create %%quote%% because you are not assigned to a %%branch%%, please contact support`);
        return null;
    }
}

export async function getSupplierId(): Promise<string> {
    const suppliers = await getQuoteSuppliers();
    //TODO - Handle Multi Supplier with a picker.
    if (suppliers.length == 1) {
        return suppliers[0].supplierId;
    } else {
        return await supplierPicker(suppliers) ?? emptyGuid;
    }
}


class QuoteSupplierDataEndpoint implements ResourceFamilyEndpoint {

    familyData: ResourceFamilyResults;
    constructor(familyData: ResourceFamilyResults) {
        this.familyData = familyData;
    }

    getResourceClass(): number {
        //is not used
        return -1;
    }
    async getData(): Promise<ResourceFamilyResults | null> {
        return this.familyData;
    }
    //incase the filter we have is not the same as what must be provided to the server
    public getResourceImageUrl(_resourceClass: number, _libId: number, _id: number): string {
        return "";
    }
    //incase the filter we have is not the same as what must be provided to the server
    public getFamilyImageUrl(_resourceClass: number, _libId: number, _id: number): string {
        return "";
    }

}

function convertQuoteSupplierToFamilyData(data: QuoteSupplier[]): ResourceFamilyResults {
    const result: ResourceFamilyResults = {
        family: {
            name: "",
            libId: 0,
            id: 0,
            families: [],
            items: data.map(x => {
                const rt: FamilyResourceTag = {
                    objectReference: x.supplierId,
                    code: "",
                    libId: -1,
                    id: -1,
                    description: x.description,
                    resourceClassId: -1
                };
                return rt;
            })
        }
    };
    return result;

}


async function supplierPicker(items: QuoteSupplier[]): Promise<string | null> {
    let resourceName: string | undefined = undefined;
    resourceName = tlang`Supplier`;
    const endpoint = new QuoteSupplierDataEndpoint(convertQuoteSupplierToFamilyData(items));
    const gp = new GraphicalFamilyResourcePicker(endpoint, {
        resourceName: resourceName,
        sideBar: null,
        show: { tree: false },
        hideImages: true,
        modalSize: ""


    });
    await gp.showModal();
    return gp.selected?.objectReference ?? emptyGuid;
}
