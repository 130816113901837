import { html } from 'lit';
import { PromiseTemplate } from '../../../components/ui/events';
import { V6FrameAttributeView } from './v6-frame-attribute';

//base class for an attribute editor
export class V6AttributeText extends V6FrameAttributeView {
    public async editTemplate(): PromiseTemplate {
        const d = this.attribute;
        const focusEvent = () => this.editControlFocused();
        const focusoutEvent = (e) => {
            this.changeEvent(e);
            this.editControlUnfocused();
        };

        const str = html`
                <input @focus=${focusEvent} ?readonly=${this.readonly} class="form-control" .type=${this.inputType} .value=${d.value}
                    @focusout=${focusoutEvent} @keydown=${(e) => this.keydownEvent(e)} />`;
        return str;
    }
    protected keydownEvent(e: KeyboardEvent) {
        if (e.key === "Enter") {
            e.preventDefault();
            this.changeEvent(e);
        }
    }
}
