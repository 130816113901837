import {
    ResultGetFranchisee,
    InputUpdateFranchisee,
    InputUpdateFranchiseeConfiguration,
    RequestGetBranches,
    ResultGetBranches,
    RequestGetBranch,
    ResultGetBranch,
    InputUpdateBranch,
    ResultUpdateBranch,
    ResultUpdateFranchisee,
    ResultUpdateFranchiseeConfiguration,
    ResultGetFranchiseeConfiguration,
    InputCreateBranchQuote,
    ResultCreateBranchQuote,
    ResultUpdateBranchQuote,
    InputUpdateBranchQuote,
    InputGetActiveUserInformation,
    ResultGetActiveUserInformation,
    RequestGetBranchQuotes,
    ResultGetBranchQuotes,
    ResultDeleteBranchQuote,
    InputDeleteBranchQuote
} from "./dealer-api-interface-franchisee";
import { NullPromise } from "../null-promise";
import { FranchiseeApi } from "./franchisee-api";
import { ApiCommunications } from "./api-communications";
import { tenantId } from "./current-user";

export class DealerFranchiseeApi implements FranchiseeApi {
    franchiseeApiPath = "api/Franchisee/";
    api: ApiCommunications;

    constructor(api: ApiCommunications) {
        this.api = api;
    }
    async getBranchQuotes(input: RequestGetBranchQuotes): NullPromise<ResultGetBranchQuotes> {
        return await this.api.post<ResultGetBranchQuotes>(`${this.franchiseeApiPath}GetBranchQuotes`, input);
    }
    async getActiveUserInformation(input: InputGetActiveUserInformation): NullPromise<ResultGetActiveUserInformation> {
        return await this.api.post<ResultGetActiveUserInformation>(`${this.franchiseeApiPath}GetActiveUserInformation`, input);
    }

    async getFranchisee(): NullPromise<ResultGetFranchisee> {
        return await this.api.post<ResultGetFranchisee>(`${this.franchiseeApiPath}GetFranchisee`, {});
    }

    async getFranchiseeConfiguration(): NullPromise<ResultGetFranchiseeConfiguration> {
        return await this.api.post<ResultGetFranchiseeConfiguration>(`${this.franchiseeApiPath}GetFranchiseeConfiguration`, {});
    }

    async updateFranchisee(body: InputUpdateFranchisee): NullPromise<ResultUpdateFranchisee> {
        return await this.api.post<ResultUpdateFranchisee>(`${this.franchiseeApiPath}UpdateFranchisee`, body);
    }

    async updateFranchiseeConfiguration(body: InputUpdateFranchiseeConfiguration): NullPromise<ResultUpdateFranchiseeConfiguration> {
        return await this.api.post<ResultUpdateFranchiseeConfiguration>(`${this.franchiseeApiPath}UpdateFranchiseeConfiguration`, body);
    }

    async getBranches(body: RequestGetBranches): NullPromise<ResultGetBranches> {
        return await this.api.post<ResultGetBranches>(`${this.franchiseeApiPath}GetBranches`, body);
    }

    async getBranch(body: RequestGetBranch): NullPromise<ResultGetBranch> {
        return await this.api.post<ResultGetBranch>(`${this.franchiseeApiPath}GetBranch`, body);
    }

    async updateBranch(body: InputUpdateBranch): NullPromise<ResultUpdateBranch> {
        return await this.api.post<ResultUpdateBranch>(`${this.franchiseeApiPath}UpdateBranch`, body);
    }

    createBrandingLogoImagePath(fileName: string, extension: string): string {
        const date = new Date().getTime();
        return `T${tenantId()}/services/franchisee/logo/${date}/${fileName}.${extension}`;
    }

    async createBranchQuote(input: InputCreateBranchQuote): NullPromise<ResultCreateBranchQuote> {
        return await this.api.post<ResultCreateBranchQuote>(`${this.franchiseeApiPath}CreateBranchQuote`, input);

    }
    async updateBranchQuote(input: InputUpdateBranchQuote): NullPromise<ResultUpdateBranchQuote> {
        return await this.api.post<ResultUpdateBranchQuote>(`${this.franchiseeApiPath}UpdateBranchQuote`, input);


    }
    async deleteBranchQuote(input: InputDeleteBranchQuote): NullPromise<ResultDeleteBranchQuote> {
        return await this.api.post<ResultUpdateBranchQuote>(`${this.franchiseeApiPath}DeleteBranchQuote`, input);
    }

    getFranchiseeImagePath(fileName: string, extension: string): string {
        return this.api.fullUrl(`api/file/T${tenantId()}/services/franchisee/images/${fileName}.${extension}`);
    }
}