// eslint-disable-next-line import/named
import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import {Picker} from './V7-minimal-picker';

@customElement('st-picker')
export class PickerWrapper extends LitElement  {

    private _pickerType = '';

    @property({attribute: 'picker-type'})
    get pickerType() {return this._pickerType;}

    set pickerType(value: string){
        const oldVal = this._pickerType;
        this._pickerType = value.toLowerCase();
        this.pickerModal.pickerType = this._pickerType;
        this.requestUpdate('pickerType', oldVal);
    }

    private pickerModal: Picker;
    constructor(){
        super();
        this.pickerModal = new Picker('');
    }

    protected render(): unknown {
        return html`<!-- just a comment -->`
    }

    connectedCallback(): void {
        this.pickerModal.showModal().then(() => {
            const selectedResource = this.pickerModal.selectedResource;
            this.resourceSelected(selectedResource);
        });
    }

    disconnectedCallback(): void {
        this.pickerModal.hideModal();
    }

    private resourceSelected(selectedResource: string) {
        this.dispatchEvent(new CustomEvent('resource-selected', {bubbles:true, detail: {selectedResource: selectedResource}}))
    }
}
