import {PaymentProfileApi} from "./payment-profile-api";
import {
    InputCreateFranchiseeClient,
    InputCreatePaymentProfile,
    InputDeletePaymentProfile,
    InputUpdateClientPrimaryProfile,
    InputUpdatePaymentProfile,
    RequestBrowsePaymentProfileSummary,
    RequestGetClientDefaultPaymentProfileById,
    RequestGetClientDefaultPaymentProfileListByIds,
    RequestGetClientDefaultPaymentType,
    RequestGetFranchiseeClientListByIds,
    RequestGetPaymentProfile,
    ResultBrowsePaymentProfileSummary,
    ResultCreateFranchiseeClient,
    ResultCreatePaymentProfile,
    ResultDeletePaymentProfile,
    ResultGetClientDefaultPaymentProfileById,
    ResultGetClientDefaultPaymentProfileListByIds,
    ResultGetClientDefaultPaymentType,
    ResultGetFranchiseeClientListByIds,
    ResultGetPaymentProfile,
    ResultUpdateFranchiseeClient,
    ResultUpdatePaymentProfile
} from "./dealer-api-interface-franchisee";
import {NullPromise} from "../null-promise";
import {ApiCommunications} from "./api-communications";

export class PaymentProfileApiImplementation implements PaymentProfileApi {
    api: ApiCommunications;

    constructor(api: ApiCommunications) {
        this.api = api;
    }

    async browsePaymentProfileSummary(body: RequestBrowsePaymentProfileSummary): NullPromise<ResultBrowsePaymentProfileSummary> {
        return await this.api.post<ResultBrowsePaymentProfileSummary>('api/Franchisee/BrowsePaymentProfileSummary', body);
    }

    async createFranchiseeClient(body: InputCreateFranchiseeClient): NullPromise<ResultCreateFranchiseeClient> {
        return await this.api.post<ResultCreateFranchiseeClient>('api/Franchisee/CreateFranchiseeClient', body);
    }

    async createPaymentProfile(body: InputCreatePaymentProfile): NullPromise<ResultCreatePaymentProfile> {
        return await this.api.post<ResultCreatePaymentProfile>('api/Franchisee/CreatePaymentProfile', body);
    }

    async deletePaymentProfile(body: InputDeletePaymentProfile): NullPromise<ResultDeletePaymentProfile> {
        return await this.api.post<ResultDeletePaymentProfile>('api/Franchisee/DeletePaymentProfile', body);
    }

    async getClientDefaultPaymentProfileById(body: RequestGetClientDefaultPaymentProfileById): NullPromise<ResultGetClientDefaultPaymentProfileById> {
        return await this.api.post<ResultGetClientDefaultPaymentProfileById>('api/Franchisee/GetClientDefaultPaymentProfileById', body);
    }

    async getClientDefaultPaymentProfileListByIds(body: RequestGetClientDefaultPaymentProfileListByIds): NullPromise<ResultGetClientDefaultPaymentProfileListByIds> {
        return await this.api.post<ResultGetClientDefaultPaymentProfileListByIds>('api/Franchisee/GetClientDefaultPaymentProfileListByIds', body);
    }

    async getClientsDefaultPaymentProfile(body: RequestGetClientDefaultPaymentType): NullPromise<ResultGetClientDefaultPaymentType> {
        return await this.api.post<ResultGetClientDefaultPaymentType>('api/Franchisee/GetClientsDefaultPaymentProfile', body);
    }

    async getFranchiseeClientListByIds(body: RequestGetFranchiseeClientListByIds): NullPromise<ResultGetFranchiseeClientListByIds> {
        return await this.api.post<ResultGetFranchiseeClientListByIds>('api/Franchisee/GetFranchiseeClientListByIds', body);
    }

    async getPaymentProfile(body: RequestGetPaymentProfile): NullPromise<ResultGetPaymentProfile> {
        return await this.api.post<ResultGetPaymentProfile>('api/Franchisee/GetPaymentProfile', body);
    }

    async updateFranchiseeClient(body: InputUpdateClientPrimaryProfile): NullPromise<ResultUpdateFranchiseeClient> {
        return await this.api.post<ResultUpdateFranchiseeClient>('api/Franchisee/UpdateFranchiseeClient', body);
    }

    async updatePaymentProfile(body: InputUpdatePaymentProfile): NullPromise<ResultUpdatePaymentProfile> {
        return await this.api.post<ResultUpdatePaymentProfile>('api/Franchisee/UpdatePaymentProfile', body);
    }
}