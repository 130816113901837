// eslint-disable-next-line import/named
import { html, TemplateResult } from "lit";
import { googleMapApiKey } from "../../../api/google-api";
import { Address } from "../../../api/dealer-api-interface-client";
import { Place } from "./place";
import { DataBinding } from "../databinding/databinding";

/**
 * Html template result for the display of Google Maps, based on the Lat and Lng values.
 * @param lat
 * @param lng
 */
export function mapTemplate(lat: number | undefined, lng: number | undefined): TemplateResult {
    return lat && lng ? html`
        <google-map api-key=${googleMapApiKey} zoom="16" fit-to-markers="true" .centerLatitude="${lat}"
            .centerLongitude="${lng}">
            <google-map-marker slot="markers" .latitude="${lat}" .longitude="${lng}">
            </google-map-marker>
        </google-map>
    ` : html``;
}

/**
 * Empty address object for the creation of clients, Projects, etc.
 */

export function emptyAddress(): Address {
    return {
        longitude: null,
        latitude: null,
        country: "",
        line1: "",
        line2: null,
        line3: null,
        line4: null,
        region: "",
        locality: "",
        postcode: ""
    };
};
export function addressToString(address: Address) {
    return [address.line1, address.line2, address.line3,
    address.line4, address.locality, address.region,
    address.postcode, address.country].filter(Boolean).join(", ");
}

/**
 * This helper function will update the Line1, Locality, Region, Postcode and Country fields on the data-binder.
 * It will also return the Lat and Lng values for the address.
 * @param address Updated map values from lookup.
 * @param databinding
 */
export function updateAddress(address: Place, databinding: DataBinding): null | { lat: number, lng: number; } {
    if (address) {
        const latPhysical = address.latLng.lat;
        const lngPhysical = address.latLng.lng;

        databinding.setValue("line1", address.basic.address);
        databinding.setValue("locality", address.basic.city);
        databinding.setValue("region", address.basic.state);
        databinding.setValue("postcode", address.basic.postalCode);
        databinding.setValue("country", address.basic.country);

        return { lat: latPhysical, lng: lngPhysical };
    }

    return null;
}