
//this distinguishes what the Attribute really is in the V6 scene.
export enum FrameAttributeOrigin {
	None = 0,
	//Frame Resource Attribute
	FrameAttribute = 1,
	//A Quote Item Option
	ItemOption = 2
}

//simplistic determination of the type of editor we require to
//view or edit the information.
export enum ValueEditorType {
	Text = 1,
	//creates a select dropdown
	Dropdown = 2,
	//this requires a picker to be executed to display the information
	Picker = 3,
	//why do we care about the different types of numbers? do we support them?
	Length = 4,
	Currency = 5,
	Number = 6,
	Boolean = 7,
	Dimension = 8,
	Glazing = 9,
	CombinationGlazing = 999,// Custom UI
	QuoteOptionProperty = 8000 // Custom UI only value
}

export enum ItemCategory {
	None = 0,
	DealerItem = 1,
	QuoteItem = 2,
	FrameItem = 3,
}
export interface V6Validation {
	display: boolean;
	text: string;
	//not currently used
	framePositionX: number;
	//not currently used
	framePositionY: number;
	//relative to the entire root frame
	globalPositionX: number;
	//relative to the entire root frame
	globalPositionY: number;
	//"Always","Never","TextNotEmpty"
	displayName: string;
	name: string;
	//"Note","Information","Warning","Critical"
	style: string;
}
export interface V6PickerProperty {
	resourceClassId: number;
	familyRef: string;
	tags: string;
}

export interface V6DimensionProperty {
	allowLock: boolean;
	allowLeftOrDown: boolean;
	allowRightOrUp: boolean;
	allowBoth: boolean;
}
export interface V6ListItem {
	code: string;
	description: string;
	imageReference: string;
}
export interface V6ListProperty {
	listValues: Array<V6ListItem>;
	listCode: string;
}
export interface V6Property {
	valueType: ValueEditorType;
	code: string;
	value: string;
	displayValue?: string;
	defaultCode?: string;
	codeDescription?: string;
	picker?: V6PickerProperty;
	dimension?: V6DimensionProperty;
	list?: V6ListProperty;
	visible?: boolean;
	//clientside only value
	originalValue: string;
	isReadonly?: boolean;
}

export interface V6PropertyGroup {
	name?: string;
	description?: string;
	attributes: Array<V6Property>;
}

export interface V6FrameData {
	objectReference: string;
	description: string;
	bounds: string; // "0,0,0|0,0,0|0,0,0|0,0,0"
	boundaryName: string;
	attributeGroups: Array<V6PropertyGroup>;
	nestedFrames: Array<V6FrameData>;
}
export interface BomItem {
	code?: string;
	description?: string;
	id?: number;
	quantity?: number;
	quoteItemId?: number | undefined;
	resourceCode?: string;
	cost?: number | undefined;
	bomItemType?: string;
}

export interface V6QuoteItem {
	supplierId: string;
	frameData: V6FrameData;
	quoteItemOptions: Array<V6PropertyGroup>;
	thumbnail?: string;
	messages: Array<string>;
	annotationIssues: V6Validation[];
	validationIssues: V6Validation[];
}

//This is still a draft concept and might need to change
export interface V6QuoteIGU {
	code: string;
	description: string;
	objectReference: string;
	iguReference: string;
	iguLibId: number;
	iguId: number;
	attributeGroups: V6PropertyGroup[];
}

export type V6PropertyMatchEvent = (attr: V6Property, group: V6PropertyGroup) => boolean;
export function v6MatchAllAttributesOnQuoteItem(v6QuoteItem: V6QuoteItem, event: V6PropertyMatchEvent): V6Property[] {
	const results: V6Property[] = [];
	findAllV6GroupAttributes(v6QuoteItem.quoteItemOptions, event, results);
	findV6FrameAttributes(v6QuoteItem.frameData, event, results, true);
	return results;
}

export function v6MatchAllAttributesOnFrame(frameData: V6FrameData, event: V6PropertyMatchEvent, includeNested = false) {
	const results: V6Property[] = [];
	findV6FrameAttributes(frameData, event, results, includeNested);
	return results;
}

export type V6FrameDataMatchEvent = (parentFrame: V6FrameData | null, frame: V6FrameData) => boolean;
export function v6MatchFrame(frameData: V6FrameData, event: V6FrameDataMatchEvent, includeNested = false): V6FrameData[] {
	const result: V6FrameData[] = [];
	const loop = (parent: V6FrameData | null, fd: V6FrameData) => {
		if (event(parent, fd)) result.push(fd);
		if (includeNested) fd.nestedFrames.forEach(nf => loop(fd, nf));
	};
	loop(null, frameData);
	return result;
}

export function v6MatchGroupsByProperty(frameData: V6FrameData, event: V6PropertyMatchEvent, includeNested = false): V6PropertyGroup[] {
	const result: V6PropertyGroup[] = [];

	const groupMatches = (group: V6PropertyGroup) => {
		const r: V6Property[] = [];
		findV6GroupAttributes(group, event, r);
		if (r.length > 0)
			return group;
		return null;
	};
	const checkFrame = (f: V6FrameData) => {
		f.attributeGroups.forEach(g => {
			const newG = groupMatches(g);
			if (newG) result.push(newG);
			if (includeNested) f.nestedFrames.forEach(nf => checkFrame(nf));
		});
	};
	checkFrame(frameData);
	return result;
}

function findV6FrameAttributes(frameData: V6FrameData, event: V6PropertyMatchEvent, results: V6Property[], includeNested = false) {
	findAllV6GroupAttributes(frameData.attributeGroups, event, results);
	if (includeNested)
		frameData.nestedFrames.forEach(nf => findV6FrameAttributes(nf, event, results, includeNested));
	return results;
}

function findAllV6GroupAttributes(groups: V6PropertyGroup[], event: V6PropertyMatchEvent, results: V6Property[]) {
	groups.forEach(g => findV6GroupAttributes(g, event, results));
}

function findV6GroupAttributes(g: V6PropertyGroup, event: V6PropertyMatchEvent, results: V6Property[]) {
	g.attributes.forEach(a => {
		if (event(a, g)) results.push(a);
	});
	return results;
}


export function getIGUSubsetInUse(v6QuoteItem: V6QuoteItem, igus: V6QuoteIGU[]) {
	if (igus.length === 0) return igus;
	const iguRefs = igus.map(igu => igu.objectReference);

	const iguReferences = v6MatchAllAttributesOnQuoteItem(v6QuoteItem,
		(p: V6Property) => iguRefs.includes(p.value)).map(p => p.value);
	return igus.filter(igu => iguReferences.includes(igu.objectReference));

}