// eslint-disable-next-line import/named
import {html} from "lit";
import {getApiFactory} from "../../../api/api-injector";
import {Client} from "../../../api/dealer-api-interface-client";
import {BranchQuote, PaymentProfile} from "../../../api/dealer-api-interface-franchisee";
import {QuoteState} from "../../../api/dealer-api-interface-quote";
import {emptyGuid} from "../../../api/guid";
import {PaymentProfileApi} from "../../../api/payment-profile-api";
import {ClientPicker} from "../../../clients/views/client-picker";
import {ContactPicker} from "../../../clients/views/contact-picker";
import {FieldType} from "../../../components/ui/databinding/data-tracker";
import {isEmptyOrSpace, validId} from "../../../components/ui/helper-functions";
import {FormInputAssistant} from "../../../components/ui/templateresult/form-input-assistant";
import {tlang} from "../../../language/lang";
import {QuoteDetailView, QuoteDetailViewOptions} from "../../../quotes/views/quote-detail-view";
import {fireQuickWarningToast} from "../../../toast-away";
import {DevelopmentError, showDevelopmentError} from "../../../development-error";
import {lockUIandExecute} from "../../../ui-lock";
import {FranchiseeClientView} from "../../clients/views/franchisee-client-view";
import {FranchiseeQuoteContainer} from "../data/franchisee-quote-container";
import {constructAsync} from "../../../async-constructor";
import {userDataStore} from "../../common/current-user-data-store";
import {createNewClient} from "../../clients/data/franchisee-client-container";
import {DataCacheGeneric} from "../../../cache/generic-data-cache";
import {cache} from "../../cache/cache-registry";
import {PromiseTemplate, Snippet} from "../../../components/ui/events";
import {WaitPatientlyLoading} from "../../../components/ui/modal-loading";

export enum QuoteReportType {
    QuoteReport = 1,
    CutPlanReport,
    CostedBomReport,
    FabReport,
    QuoteReportV2,
    CutPlan2DReport
}

export interface FranchiseeQuoteDetailViewOptions extends QuoteDetailViewOptions {
    generateReportEvent: (reportType: QuoteReportType, internal: boolean) => Promise<void>
}

export class FranchiseeQuoteDetailView extends QuoteDetailView {
    contactCache: DataCacheGeneric = cache().contact;
    clientCache = cache().client;
    primaryContactCache = cache().primaryContact;
    paymentProfileCache: DataCacheGeneric = cache().paymentProfile;
    paymentProfileApi: PaymentProfileApi = getApiFactory().paymentProfile();
    clientPickerDisplay = "";
    contactPickerDisplay = "";
    clientTypePickerDisplay = "";
    generateReportEvent: (reportType: QuoteReportType, internal: boolean) => Promise<void>;

    constructor(options: FranchiseeQuoteDetailViewOptions) {
        super(options);
        this.generateReportEvent = options.generateReportEvent;

        const addField = (fieldName: string, propertyType?: FieldType, nullable?: boolean, editorFieldName?: string, data?: () => any) => {
            this.dataTracker.addObjectBinding(data ?? (() => this.branchQuote), fieldName, editorFieldName ?? fieldName, propertyType ?? FieldType.string, nullable ?? false);
        };

        addField("clientId");
        addField("contactId");
    }

    public async prepareForSave(): Promise<void> {
        if (!this.branchQuote) {
            await showDevelopmentError(`BranchQuote is null`);
            return;
        }
        const oldClientTypeId = this.branchQuote.clientTypeId;
        this.dataTracker.applyChangeToValue();
        this.quote.quoteCustomerId = this.branchQuote.clientId ?? emptyGuid;

        if (oldClientTypeId != this.branchQuote.clientTypeId) {
            const paymentProfile = await this.paymentProfileCache.get(this.branchQuote?.clientTypeId);
            if (!isEmptyOrSpace(this.branchQuote.clientTypeId)) {
                if (!paymentProfile || !paymentProfile?.data) {
                    await showDevelopmentError(`PaymentProfile is null. cache entry "${this.branchQuote.clientTypeId}"`);
                    return;
                }

                const profile = paymentProfile.data as PaymentProfile;
                const price = this.quoteManager.quotePrice;
                price.marginPercentage = profile.margin;
            }
        }
    }

    async resetEditControls() {
        await super.resetEditControls();
        await this.loadPickerDisplaysFromCache();

        await this.render();
    }

    async loadOrRefresh(): Promise<void> {
        await super.loadOrRefresh();
        //need quote items to check the client type state
        await this.loadPickerDisplaysFromCache();

        await this.render();
    }

    protected get branchQuote(): BranchQuote | null {
        if (!(this.quoteManager.container instanceof FranchiseeQuoteContainer))
            throw new DevelopmentError("FranchiseeQuoteDetailView requires a container type FranchiseeQuoteContainer");
        return (this.quoteManager.container as FranchiseeQuoteContainer).branchQuote;
    }

    getValidationErrors(): string[] {
        const errors = super.getValidationErrors();

        if (!this.branchQuote) {
            throw new DevelopmentError("BranchQuote is null");
        }
        if (this.quote.state == QuoteState.Active) {
            const clientId = this.branchQuote.clientId;
            const contactId = this.branchQuote.contactId;

            if (isEmptyOrSpace(clientId))
                errors.push(tlang`Please select a %%client%%`);

            if (isEmptyOrSpace(contactId))
                errors.push(tlang`Please select a %%contact%%`);
        }
        return errors;
    }

    protected async template(): PromiseTemplate {

        const allowCreateClient = !this.quoteManager.isReadonly();
        const forms = new FormInputAssistant(this.dataTracker, this.quoteManager.isReadonly());
        if (allowCreateClient)
            forms.classes.push({id: "clientId", classes: "shortstop-edit"});

        const clientPicker = forms.picker("clientId", this.clientPickerDisplay,
            async () => await this.selectClient(), tlang`%%client%%`);
        const contactPicker = forms.picker("contactId", this.contactPickerDisplay,
            async () => await this.selectContact(), tlang`%%contact%%`);

        const createClientEvent = async () => lockUIandExecute(async () => await this.createNewClient());
        const createClientTemplate = allowCreateClient
            ? html`
                    <button @click=${createClientEvent} class="btn btn-primary shortstop-edit" type="button"
                            id=${forms.id("create-client")}>${tlang`Create
                %%client%%`}
                    </button>`
            : html``;

        return html`
            <form class="py-3 px-0 frm-quote-details form-two-col">
                <div class="row">
                    <div>
                        ${forms.text("title", tlang`%%quote%% Title`)}

                        ${clientPicker}
                        ${createClientTemplate}
                        ${contactPicker}
                        ${forms.note("description", tlang`%%quote%% Description`)}
                    </div>
                    <div>
                        ${forms.dateReadonly("dateCreated", tlang`Created`)}
                        ${forms.dateReadonly("lastModifiedDate", tlang`Last Modified`)}
                        ${forms.date("validUntilDate", tlang`Valid To`, "date")}
                        ${forms.date("installationDate", tlang`Installation Date`, "date")}

                    </div>
                </div>
            </form>
        `;
    }

    async createNewClient() {
        const title = tlang`Create %%client%%`;
        const container = await createNewClient(userDataStore.franchisee?.id ?? emptyGuid);
        if (!container) {
            await showDevelopmentError("Client could not be created");
            return;
        }
        const qv = await constructAsync(new FranchiseeClientView({
            title: () => title,
            clientContainer: container
        }));
        await qv.showModal();
        const client = qv.view?.clientContainer.client;
        if (client && validId(client.id)) {
            this.selectClient(client);
        }

    }

    private async loadPickerDisplaysFromCache() {
        if (!this.branchQuote) {
            this.clientPickerDisplay = "";
            this.contactPickerDisplay = "";
            this.clientTypePickerDisplay = "";
        } else {
            const clientData = (await this.clientCache.get(this.branchQuote.clientId));
            this.clientPickerDisplay = clientData?.displayValue ?? "";

            this.contactPickerDisplay = (await this.contactCache.get(this.branchQuote.contactId))?.displayValue ?? "";
        }
    }

    private async selectClient(client?: Client) {
        const selectedClient = client?.id ?? (await ClientPicker())?.id;

        if (!selectedClient) {
            return;
        }
        this.dataTracker.applyChangeToValue();

        this.dataBinding.setValue("clientId", selectedClient);
        const clientCacheItem = (await this.clientCache.get(selectedClient));
        const clientData = clientCacheItem?.data;
        if (!clientData) return;
        this.clientPickerDisplay = clientCacheItem?.displayValue ?? "";
        //const franchiseeClient = clientData.franchiseeClient as FranchiseeClient;

        const primaryContact = await this.primaryContactCache.getData(selectedClient);
        this.dataBinding.setValue("contactId", primaryContact?.contactId ?? emptyGuid);
        const contact = await this.contactCache.get(primaryContact?.contactId ?? emptyGuid);
        this.contactPickerDisplay = contact?.displayValue ?? "";

        await this.render();
    }

    private async selectContact() {
        const selectedClient = this.dataBinding.getValue("clientId");

        if (selectedClient == emptyGuid) {
            this.dataBinding.setValue("contactId", emptyGuid);
            this.contactPickerDisplay = "";

            fireQuickWarningToast(tlang`Please select a %%client%% first.`, 1000);

        } else {
            const selectedContact = await ContactPicker(selectedClient);

            if (selectedContact) {
                this.dataBinding.setValue("contactId", selectedContact.id);
                this.contactPickerDisplay = selectedContact.name ?? "";
            }
        }

        await this.render();
    }

    private async checkBeforeCreateReport() : Promise<void>{
        if (!await this.canGenerateReport()){
            await showDevelopmentError("Cannot generate report until quote is saved.");
        }
    }

    public override buttonMenu(): Snippet {
        const printCutReport = async (e: Event, internal:boolean) => {
            e.preventDefault();
            e.stopPropagation();

            await this.checkBeforeCreateReport();

            const w = new WaitPatientlyLoading(() => tlang`Generating cut plan report`);
            try {
                await this.generateReportEvent(QuoteReportType.CutPlanReport, internal);
            } finally {
                w.hideModal();
            }
        };

        const printCostedBomReport = async (e: Event, internal:boolean) => {
            e.preventDefault();
            e.stopPropagation();

            await this.checkBeforeCreateReport();

            const w = new WaitPatientlyLoading(() => tlang`Generating costed BOM report`);
            try {
                await this.generateReportEvent(QuoteReportType.CostedBomReport, internal);
            } finally {
                w.hideModal();
            }
        };

        const printFabReport = async (e: Event, internal:boolean) => {
            e.preventDefault();
            e.stopPropagation();

            await this.checkBeforeCreateReport();

            const w = new WaitPatientlyLoading(() => tlang`Generating fabrication sheet`);
            try {
                await this.generateReportEvent(QuoteReportType.FabReport, internal);
            } finally {
                w.hideModal();
            }
        };

        const printQuoteReport = async (e: Event, internal:boolean) => {
            e.preventDefault();
            e.stopPropagation();

            await this.checkBeforeCreateReport();

            const w = new WaitPatientlyLoading(() => tlang`Generating quote report`);
            try {
                await this.generateReportEvent(QuoteReportType.QuoteReportV2, internal);
            } finally {
                w.hideModal();
            }
        };

        const printCutPlan2DReport = async (e: Event, internal:boolean) => {
            e.preventDefault();
            e.stopPropagation();

            await this.checkBeforeCreateReport();

            const w = new WaitPatientlyLoading(() => tlang`Generating 2D cut plan`);
            try {
                await this.generateReportEvent(QuoteReportType.CutPlan2DReport, internal);
            } finally {
                w.hideModal();
            }
        };

        return html`
            <div class="btn-group dropup">
                <button type="button" class="btn secondary-btn" @click=${(e) => printQuoteReport(e, false)}>${tlang`%%quote%% Report`}</button>
                <button type="button" class="btn secondary-btn dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                    <span class="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#" @click=${(e) => printCutReport(e, false)}>${tlang`Cut Plan`}</a></li>
                    <li><a class="dropdown-item" href="#" @click=${(e) => printCutPlan2DReport(e, false)}>${tlang`Cut Plan 2D`}</a></li>
                    <li><a class="dropdown-item" href="#" @click=${(e) => printCostedBomReport(e, false)}>${tlang`Costed BOM`}</a></li>
                    <li><a class="dropdown-item" href="#" @click=${(e) => printFabReport(e, false)}>${tlang`Fabrication Sheet`}</a></li>
                </ul>
            </div>
        `;
    }
}
