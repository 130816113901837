import { EventNotify } from "../components/ui/events";
import { ServiceResponse, ServiceResponseInvalid, ServiceResponseType } from "../service_response";
import { setLastApiError } from "./api-communications";
import { ValidationError } from "./validation-error";
import { FileResponse } from "./api-type-base-interface";
import { AuthApiCommunications } from "./auth-api-communications";

export type ServiceResponseHandler = (response: ServiceResponseInvalid | ValidationError[]) => Promise<void>;
export type ValidationErrorHandler = (errors: ValidationError[]) => Promise<void>;
const enableVerboseLogging = false;
export class DealerApiCommunications extends AuthApiCommunications {
    private endpoint: string;

    constructor(endpoint: string, responseHandler: ServiceResponseHandler, redirectToLoginPage: EventNotify) {
        super(responseHandler, redirectToLoginPage);
        this.endpoint = endpoint;
        if (this.endpoint == "")
            this.endpoint = globalThis.dealerConfiguration.apiHost;
    }

    put<T>(_path: string, _data?: any): Promise<T | null> {
        throw new Error("Dealer Apis do not support PUT, Dealer only uses POST");
    }

    fullUrl(path: string): string {
        return `${this.endpoint}/${path}`;
    }

    public async postFileDownload(path: string, data?: any): Promise<FileResponse | null> {
        let response: ServiceResponse<FileResponse> | null = null;
        const token = this.getToken();

        try {
            // Default options are marked with *
            const httpresponse = await fetch(`${this.endpoint}/${path}`, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${token}`
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            });

            if (!(httpresponse.status == 200 || httpresponse.status == 206)) {
                const text = await httpresponse.text();
                response = {
                    responseType: ServiceResponseType.Error,
                    responseError: { message: text },
                    responseTypeCaption: "Error",
                    result: null
                };
                await this.handleError(response);
                return null;
            } else {
                const _headers: any = {};
                if (httpresponse.headers && httpresponse.headers.forEach) {
                    httpresponse.headers.forEach((v: any, k: any) => _headers[k] = v);
                }

                const contentDisposition = httpresponse.headers ? httpresponse.headers.get("content-disposition") : undefined;
                const fileNameMatch = contentDisposition ? /filename="?([^"]*?)"?(;|$)/g.exec(contentDisposition) : undefined;
                const fileName = fileNameMatch && fileNameMatch.length > 1 ? fileNameMatch[1] : undefined;

                setLastApiError(null);

                return httpresponse.blob().then(blob => {
                    return { fileName: fileName, data: blob, status: httpresponse.status, headers: _headers };
                });
            }
        } catch (e) {
            if (e instanceof Error) {
                await this.handleError({
                    responseType: ServiceResponseType.Error,
                    responseError: { message: `${e.name} ${e.message} ${e.cause}`, stackTrace: e.stack },
                    responseTypeCaption: "Error",
                    result: null
                });
                return null;

            } else {
                await this.handleError({
                    responseType: ServiceResponseType.Error,
                    responseError: { message: `${e}` },
                    responseTypeCaption: "Error",
                    result: null
                });
                return null;
            }
        }
    }

    protected async apiRequest<T>(url: string, data: any, authenticate?: boolean): Promise<ServiceResponse<T>> {
        let response: ServiceResponse<T> | null = null;
        const doAuthenticate = authenticate ?? true;
        const token = doAuthenticate ? this.getToken() : "";
        if (doAuthenticate && token === "") {
            const r: ServiceResponse<T> = {
                responseType: ServiceResponseType.UnAuthenticated,
                responseTypeCaption: "UnAuthenticated",
                responseError: null,
                result: null
            };
            return Promise.resolve(r);
        }

        try {
            // Default options are marked with *
            const httpresponse = await fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${token}`
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            });

            if (httpresponse.status !== 200) {
                const text = await httpresponse.text();
                response = {
                    responseType: ServiceResponseType.Error,
                    responseError: { message: `${httpresponse.status}:  ${text}` },
                    responseTypeCaption: "Error",
                    result: null
                };
                await this.handleError(response);
                return response;
            } else {
                const data = await httpresponse.json();
                response = (data as ServiceResponse<T>);

                //Set or clear last error, which can be analyzed by the caller of this routine if null was returned
                //higher up the chain.
                if (response.responseType === ServiceResponseType.Ok)
                    setLastApiError(null);
                else
                    setLastApiError(response);

                //All unexpected errors are passed through to the global handler for
                //presentation
                await this.handleError(response);

                return response;
            }
        } catch (e) {
            if (e instanceof Error) {
                console.log(e);
                response = {
                    responseType: ServiceResponseType.Error,
                    responseError: {
                        message: `url:${url}
                                  body:${JSON.stringify(data)}
                    ${e.name} ${e.message} ${e.cause}`, stackTrace: e.stack
                    },
                    responseTypeCaption: "Error",
                    result: null
                };
                await this.handleError(response);
                return response;

            } else {
                response = {
                    responseType: ServiceResponseType.Error,
                    responseError: { message: `${e}` },
                    responseTypeCaption: "Error",
                    result: null
                };
                await this.handleError(response);
                return response;
            }
        }
    }

    public postRaw<T>(path: string, data: any, authenticate?: boolean, baseUrl?: string): Promise<ServiceResponse<T>> {
        if (!data) data = {};
        return this.apiRequest<T>(`${baseUrl ?? this.endpoint}/${path}`, data, authenticate ?? true);
    }


    //only use this if a response Handler assigned
    public async post<T>(path: string, data?: any): Promise<T | null> {
        if (enableVerboseLogging) {
            console.log(`SEND MSG TO PATH ${path}`);
            console.log(`${JSON.stringify(data)}`);
        }
        if (!data) data = {};
        const retVal = await this.authenticationWorkflow<T>(() => this.apiRequest<T>(`${this.endpoint}/${path}`, data));
        if (enableVerboseLogging) {
            console.log(`RETURN`);
            console.log(`${JSON.stringify(retVal)}`);
        }
        return retVal;
    }

    get<T>(_path: string): Promise<T | null> {
        throw new Error("Method not implemented.");
    }

}


