// eslint-disable-next-line import/named
import { getApiFactory } from "../../api/api-injector";
import { BlobApi } from "../../api/blob-api";
import { QuoteApi } from "../../api/quote-api";
import { PageControlTabWithIndependantSaving } from "../../components/ui/data-entry-screen-base";
import { tlang } from "../../language/lang";
import { showDevelopmentError } from "../../development-error";
import { QuoteContainerManager } from "../data/quote-container";
import { QuoteItemContainer } from "../data/quote-item-container";
import { QuoteItemPriceAdjustment as QuoteItemPriceAdjustmentDialog } from "./quote-item-price-adjust-view";
import { QuoteItemPriceAdjustmentV7 as QuoteItemPriceAdjustmentV7Dialog} from "./quote-item-price-adjust-view";
import { quoteSupplierProvider } from "../../quotes/data/quoteSupplierProvider";
import { QuoteItemType } from "../../api/dealer-api-interface-quote";
import { quoteItemContentType } from "../data/default-quote-item-content-type";

export type EventQuoteItemView = (quoteItemContainer: QuoteItemContainer) => Promise<void>;

export interface QuoteItemViewOptions {
    quoteManager: QuoteContainerManager;
    quoteItemContainer: QuoteItemContainer | null;
    supplierId: string;
}

//base class to manage the intricacies of quoteitems that may be v6 or otherwise
export class QuoteItemView extends PageControlTabWithIndependantSaving {
    quoteItemContainer: QuoteItemContainer | null;
    quoteApi: QuoteApi = getApiFactory().quote();
    blobApi: BlobApi = getApiFactory().blob();
    supplierId: string;
    quoteManager: QuoteContainerManager;
    protected _readyToEdit = true;


    constructor(options: QuoteItemViewOptions) {
        super();
        this.quoteItemContainer = options.quoteItemContainer;
        this.supplierId = options.supplierId;
        this.quoteManager = options.quoteManager;
    }

    public async saveQuoteItem(): Promise<boolean> {
        return await this.allowPageSwitch();
    }
    //this should be called immediately after the constructor and if the return result is not
    //true then this page is not valid, and should be assumed to be cancelled
    public get readyToEdit(): boolean {
        return this._readyToEdit;
    }

    async prepareEditor(): Promise<void> {
        this._readyToEdit = true;
    }
    public get hasPropertyDialog(): boolean {
        return true;
    }
    public get hasModalEditDialog(): boolean {
        return false;
    }
    public isTab(): boolean {
        return false;
    }

    public async executeModalEditDialog(): Promise<void> {
        await showDevelopmentError(tlang`Quote Item Modal Editor is not implemented yet`);
    }
    public async executePropertyDialog(): Promise<void> {
        if (!this.quoteItemContainer) return;
        if (this.quoteItemContainer.item.serviceProvider == quoteSupplierProvider.v7 || this.isFreehandItem(this.quoteItemContainer))
        {
            const dlg = new QuoteItemPriceAdjustmentV7Dialog(this.quoteItemContainer.item.id.toString(), this.quoteManager.quoteId, this.quoteItemContainer.item.description);
            await dlg.showModal();
        }
        else
        {
            const dlg = new QuoteItemPriceAdjustmentDialog(this.quoteManager.quotePrice, this.quoteItemContainer.price, this.quoteManager.isReadonly());
            await dlg.showModal();
            if (dlg.ok) {
                //price has been updated by modal
                this.quoteItemContainer = await this.quoteManager.saveAndUpdateQuoteItemPrice(this.quoteItemContainer.price);
            }
        }
    }

    public getImg(): string {
        return "";
    }

    private isFreehandItem(quoteItemContainer: QuoteItemContainer): boolean {
        return quoteItemContainer.item.itemType === QuoteItemType.Basic && quoteItemContainer.item.quoteItemContentType === quoteItemContentType.freehand;
    }
}