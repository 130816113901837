import { tlang } from "../../language/lang";
import { html } from "lit";
import { PurchaseOrder, PurchaseOrderState } from "../../api/dealer-api-interface-franchisee";

export const getPurchaseOrderNumberFormatted = (p: PurchaseOrder | null) => {
    if (!p)
        return "";

    return p.purchaseOrderNumber == 0 ? tlang`Draft` : tlang`%%purchase-order-abbrev%%: ${p.purchaseOrderNumber.toString().padStart(6, "0")}`;
};

export const getPurchaseOrderTitle = (p: any) => {
    const po = p as PurchaseOrder;
    const poNumber = getPurchaseOrderNumberFormatted(po);

    return html`${poNumber} ${po.title} 
        <span class="badge badge-${PurchaseOrderState[po.state].toLowerCase()}">${PurchaseOrderState[po.state]}</span>`;
};