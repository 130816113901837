import { ApiCommunications } from "../../api/api-communications";
import { NullPromise } from "../../null-promise";
import { ItemReference } from "../../cache/definitions/cache-item-reference";
import { DataCacheBase } from "../../cache/data-cache-base";
import {
    RequestGetProjectSummaryListByIds,
    ResultGetProjectSummaryListByIds
} from "../../api/dealer-api-interface-project";
import { getProjectNumberFormatted } from "../projects/data/project-helper-functions";
import { ProjectCacheData } from "./cache-data";

export class ProjectCache extends DataCacheBase<ProjectCacheData> {
    constructor(api: ApiCommunications) {
        super(api);
        //depending on what high level controller api we create we could swap this to ClientApi etc.
    }

    //override;
    protected async internalFetch(requestIds: string[]): NullPromise<ItemReference<ProjectCacheData>[]> {
        const input: RequestGetProjectSummaryListByIds = {
            projectIds: requestIds
        };
        const projectPromise = this.api.post<ResultGetProjectSummaryListByIds>(`api/Project/GetProjectSummaryListByIds`, input);
        const resultProject = await projectPromise;
        if (!resultProject) return null;

        return resultProject.projectSummaries.map(q => {
            const qvs: ItemReference<ProjectCacheData> = {
                id: q.id,
                displayValue: `${getProjectNumberFormatted(q)}`,
                data: { projectSummary: q },
                onClick: null
            };
            return qvs;
        });
    }
}
