import { ApiCommunications } from "./api-communications";
import { NullPromise } from "../null-promise";
import { RequestGetSupplierTerms, ResultGetSupplierTerms, SupplierApi } from "./supplier-api";
import { tenantId } from "./current-user";

export class SupplierApiImplementation implements SupplierApi {
    api: ApiCommunications;

    constructor(api: ApiCommunications) {
        this.api = api;
    }

    // This needs to be updated once we have a supplier portal. For now, we just add the markdown file in the
    // dealer blob container and read the information from there.
    async getSupplierTerms(request: RequestGetSupplierTerms): NullPromise<ResultGetSupplierTerms> {
        const url = this.api.fullUrl(`api/file/T${tenantId()}/services/supplier/${request.supplierId}/tac.md`);

        const response = await fetch(`${url}`);

        if (response.status == 200 || response.status == 206) {
            const text = await response.text();

            return {TermsAndConditions: text};
        } else
            return null;
    }
}