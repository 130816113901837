import { EventNotify } from "../components/ui/events";
import { ServiceResponse, ServiceResponseInvalid, ServiceResponseType } from "../service_response";
import { ValidationError } from "./validation-error";
import { FileResponse } from "./api-type-base-interface";
import { AuthApiCommunications } from "./auth-api-communications";

export type ServiceResponseHandler = (response: ServiceResponseInvalid | ValidationError[]) => Promise<void>;
export type ValidationErrorHandler = (errors: ValidationError[]) => Promise<void>;

export class PricingEngineApiCommunications extends AuthApiCommunications {
    private endpoint: string;

    constructor(endpoint: string, responseHandler: ServiceResponseHandler, redirectToLoginPage: EventNotify) {
        super(responseHandler, redirectToLoginPage);
        this.endpoint = endpoint;
        if (this.endpoint == "")
            this.endpoint = globalThis.pricingEngineConfiguration.pricingApiUrl.origin;
    }

    public async put<T>(path: string, data?: any): Promise<T | null> {
        const retVal = await this.authenticationWorkflow<T>(() => this.apiRequest<T>(this.fullUrl(path), 'PUT', data));
        return retVal;
    }

    public fullUrl(path: string): string {
        return `${this.endpoint}/${path}`;
    }

    protected async apiRequest<T>(url: string, method: string, data: any, authenticate?: boolean): Promise<ServiceResponse<T>> {
        let response: ServiceResponse<T> | null = null;
        const doAuthenticate = authenticate ?? true;
        const token = doAuthenticate ? this.getToken() : "";
        if (doAuthenticate && token === "") {
            const r: ServiceResponse<T> = {
                responseType: ServiceResponseType.UnAuthenticated,
                responseTypeCaption: "UnAuthenticated",
                responseError: null,
                result: null
            };
            return r;
        }

        try {
            // Default options are marked with *
            const httpresponse = await fetch(url, {
                method: method, // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            });

            if (httpresponse.status !== 200) {
                const text = await httpresponse.text();
                response = {
                    responseType: ServiceResponseType.Error,
                    responseError: { message: `${httpresponse.status}:  ${text}` },
                    responseTypeCaption: "Error",
                    result: null
                };
                await this.handleError(response);
                return response;
            } else {
                const data = await httpresponse.json();
                const webmoduleStyledResponse = {
                    responseType: ServiceResponseType.Ok,
                    responseTypeCaption: "Ok",
                    responseError: null,
                    result: data
                } as ServiceResponse<T>;

                return webmoduleStyledResponse;
            }
        } catch (e) {
            if (e instanceof Error) {
                console.log(e);
                response = {
                    responseType: ServiceResponseType.Error,
                    responseError: {
                        message: `url:${url}
                                  body:${JSON.stringify(data)}
                    ${e.name} ${e.message} ${e.cause}`, stackTrace: e.stack
                    },
                    responseTypeCaption: "Error",
                    result: null
                };
                await this.handleError(response);
                return response;

            } else {
                response = {
                    responseType: ServiceResponseType.Error,
                    responseError: { message: `${e}` },
                    responseTypeCaption: "Error",
                    result: null
                };
                await this.handleError(response);
                return response;
            }
        }
    }

    public postRaw<T>(_path: string, _data: any, _authenticate?: boolean, _baseUrl?: string): Promise<ServiceResponse<T>> {
        throw new Error("Method not implemented.");
    }

    //only use this if a response Handler assigned
    public async post<T>(_path: string, _data?: any): Promise<T | null> {
        throw new Error("Method not implemented.");
    }

    public async get<T>(_path: string): Promise<T | null> {
        throw new Error("Method not implemented.");
    }

    public async postFileDownload(_path: string, _data?: any): Promise<FileResponse | null> {
        throw new Error("Method not implemented.");
    }

}


