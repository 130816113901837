import { getApiFactory } from "../../api/api-injector";
import { constructAsync } from "../../async-constructor";
import { DevelopmentError } from "../../development-error";
import { QuoteContainer, QuoteContainerManager } from "../../quotes/data/quote-container";
import { currentQuoteSupplierProvider, quoteSupplierProvider } from "../../quotes/data/quoteSupplierProvider";
import { QuoteView, QuoteViewOptions } from "../../quotes/views/quote-view";
import { FranchiseeQuoteContainer } from "./data/franchisee-quote-container";
import { FranchiseeQuoteContainerManager } from "./data/franchisee-quote-manager";
import { FranchiseeQuoteViewV7 } from "./views/v7/franchisee-quote-view-v7";

export function getQuoteContainer(id: string): QuoteContainer {
    return new FranchiseeQuoteContainer(id,
        null, null, null, null, null, null, null, null);
}
export function getQuoteContainerManager(id: string | QuoteContainer): QuoteContainerManager {
    const container = id instanceof QuoteContainer ? id : getQuoteContainer(id);
    return new FranchiseeQuoteContainerManager(container
        , getApiFactory().quote(), getApiFactory().blob(), getApiFactory().franchisee());
}

export async function quoteViewFactory(options: QuoteViewOptions): Promise<QuoteView> {
  if (currentQuoteSupplierProvider === quoteSupplierProvider.v7)
        return await constructAsync(new FranchiseeQuoteViewV7(options));
    else throw new DevelopmentError(`unsupported provider ${currentQuoteSupplierProvider}`);

}
