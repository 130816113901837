export interface notifyCallback {
    (result: any, error?: Error): void
}

interface Dictionary<T> {
    [Key: string]: T;
}

export class ScriptLoaderMap {
    private static instance: ScriptLoaderMap;
    private apiMap: Dictionary<ScriptLoader> = {};

    public static getInstance(): ScriptLoaderMap {
        if (!ScriptLoaderMap.instance) {
            ScriptLoaderMap.instance = new ScriptLoaderMap();
        }

        return ScriptLoaderMap.instance;
    }

    private static nameFromUrl(url: string): string {
        return url.replace(/[:/%?&.=\-,]/g, '_') + '_api';
    }

    public require(url: string, notifyCallback: notifyCallback, scriptLibraryElementCallbackName: string) {
        const name = ScriptLoaderMap.nameFromUrl(url);

        // create a loader as needed
        if (!this.apiMap[name])
            this.apiMap[name] = new ScriptLoader(name, url, scriptLibraryElementCallbackName);

        // ask for notification
        this.apiMap[name].requestNotify(notifyCallback);
    }
}

class ScriptLoader {
    error?: Error;
    result: any;
    notifiers: Array<notifyCallback>;
    callbackName = "";
    callbackMacro = '%%callback%%';
    loaded = false;
    script: HTMLScriptElement | null = null;

    constructor(name: string, url: string, callbackName: string) {
        this.notifiers = [];

        // callback is specified either as callback name
        // or computed dynamically if url has callbackMacro in it
        if (!callbackName) {
            if (url.indexOf(this.callbackMacro) >= 0) {
                callbackName = name + '_loaded';
                url = url.replace(this.callbackMacro, callbackName);
            } else {
                console.error('ScriptLoader class: a %%callback%% parameter is required in libraryUrl');
                return;
            }
        }

        this.callbackName = callbackName;

        (window as { [key: string]: any })[this.callbackName] = _ => this.success();

        this.addScript(url);
    }

    addScript(src: string) {
        const script = document.createElement('script');
        script.src = src;
        script.onerror = () => this.handleError(event => event);
        const s = document.querySelector('script') || document.body;
        s.parentNode?.insertBefore(script, s);
        this.script = script;
    }

    removeScript() {
        if (this.script?.parentNode) {
            this.script.parentNode.removeChild(this.script);
        }

        this.script = null;
    }

    handleError(_ev: OnErrorEventHandlerNonNull) {
        this.error = new Error('Library failed to load');
        this.notifyAll();
        this.cleanup();
    }

    success() {
        this.loaded = true;
        // eslint-disable-next-line prefer-rest-params
        this.result = Array.prototype.slice.call(arguments);
        this.notifyAll();
        this.cleanup();
    }

    cleanup() {
        delete (window as { [key: string]: any })[this.callbackName];
    }

    notifyAll() {
        this.notifiers.forEach((notifyCallback: notifyCallback) => {
            notifyCallback(this.result, this.error);
        })

        this.notifiers = [];
    }

    requestNotify(notifyCallback: notifyCallback) {
        if (this.loaded || this.error) {
            notifyCallback(this.result, this.error);
        } else {
            this.notifiers.push(notifyCallback);
        }
    }
}