import { PurchaseOrderContainerManager } from "../data/purchase-order-container";
import { PurchaseOrderItem } from "../../api/dealer-api-interface-franchisee";
import { DataTableWrapper, RequestPage, ResultPaginated } from "../../components/ui/datatable-view";
import { QuoteItem, QuoteItemPrice, QuoteItemProviderData } from "../../api/dealer-api-interface-quote";
import { getApiFactory } from "../../api/api-injector";
import { quoteSupplierProvider } from "../../quotes/data/quoteSupplierProvider";
import { emptyGuid } from "../../api/guid";
import { moneyToHtml } from "../../components/currency-formatter";
import { base64ToObject } from "../../blob/converters";
import { V6FrameData, V6Property, V6PropertyGroup, V6QuoteItem } from "../../v6config/v6-quote-item/v6-quote-item-data";
// eslint-disable-next-line import/named
import { html, render, TemplateResult } from "lit";
import { isEmptyOrSpace } from "../../components/ui/helper-functions";
import { DataCacheGeneric } from "../../cache/generic-data-cache";
import {DevelopmentError} from "../../development-error";
import {PromiseTemplate, Snippet} from "../../components/ui/events";
import {quoteItemContentType} from "../../quotes/data/default-quote-item-content-type";
import { supplierQuoteItemContentType } from "../../quotes/data/supplier-quote-item-content-type";
import { ViewBase } from "../../components/ui/view-base";

import DeliveryThumbnailImage from '../../../assets/images/delivery.svg';

interface PurchaseOrderItemTableOptions {
    purchaseOrderManager: PurchaseOrderContainerManager;
}

interface PurchaseOrderLineItem {
    purchaseOrderItem: PurchaseOrderItem,
    item: QuoteItem,
    data: QuoteItemProviderData,
    price: QuoteItemPrice;
}

export class PurchaseOrderItemTable extends DataTableWrapper<PurchaseOrderLineItem> {
    purchaseOrderManager: PurchaseOrderContainerManager;
    quoteApi = getApiFactory().quote();

    constructor(options: PurchaseOrderItemTableOptions) {
        super();
        this.purchaseOrderManager = options.purchaseOrderManager;
    }

    override verticalHeight(): string | undefined {
        return undefined;
    }

    override getDefaultSortAsc(): boolean {
        return false;
    }
    usePaging(): boolean {
        return false;
    }

    useInfo(): boolean {
        return true;
    }

    pageLength(): number {
        return 10000;
    }


    override getDefaultSortFieldIndex(_columns: any[]): number {
        return _columns.findIndex(c => c.data === "id");
    }

    isShipping(quoteItem: QuoteItem) {
        return quoteItem.serviceProvider === "" && quoteItem.quoteItemContentType === quoteItemContentType.shipping;
    }

    isFrame(quoteItem: QuoteItem) {
        return (quoteItem.serviceProvider === quoteSupplierProvider.v6 ||
            quoteItem.serviceProvider === quoteSupplierProvider.v7) && quoteItem.quoteItemContentType === supplierQuoteItemContentType.CID_FRAM;
    }

    override afterRowCreated(_row: any, _data: any, _dataIndex: any) {
        //        const rowClass = dataIndex % 2 === 0 ? "odd-row" : "even-row";
        //       $(row).addClass(rowClass);
    }

    override async getRowsFromServer(_request: RequestPage): Promise<ResultPaginated<PurchaseOrderLineItem>> {
        await this.purchaseOrderManager.needsPurchaseOrder();

        // NeedsPurchaseOrder will get the PO and its Items from the server.
        const itemKeys = this.purchaseOrderManager.container.items?.map(x => x.quoteItemId) ?? [];

        const quoteItemSummaries = await this.quoteApi.getQuoteItemsSummaryById({ quoteItemId: itemKeys });

        if (!quoteItemSummaries || !itemKeys)
            return {
                count: 0,
                pageCount: 0,
                pageIndex: 0,
                pageSize: this.pageLength(),
                results: []
            };

        const lineItems = itemKeys.map(x => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const item = quoteItemSummaries.items.find(y => y.id == x)!;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const data = quoteItemSummaries.data.find(y => y.id == x)!;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const price = quoteItemSummaries.prices.find(y => y.id == x)!;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const purchaseOrderItem = this.purchaseOrderManager.container.items!.find(y => y.quoteItemId == x)!;

            return {
                purchaseOrderItem,
                item,
                data,
                price
            };
        });

        const result = lineItems.filter(Boolean);

        return {
            count: result.length,
            pageCount: 1,
            pageIndex: 0,
            pageSize: result.length,
            results: result
        };
    }

    override getColumns(): any[] {
        return [
            {
                title: "Image",
                width: "50px",
                data: "id",
                className: "order-item-image",
                render: (_value: string, _type: never, row: PurchaseOrderLineItem) => {
                    if (row.item.virtualThumbnailPath === "") {
                        let src = "";
                        if (this.isShipping(row.item)) {
                            src = DeliveryThumbnailImage;
                        }
                        return `<img class="quote-item-thumbnail" src='${src}' style="width:48px; height:48px" alt="Shipping item thumbnail" /> `;

                    }

                    const src = this.quoteApi.api.fullUrl(`api/file/${row.item.virtualThumbnailPath}`);
                    if (_type === "filter") {
                        return "1";

                    } else if (_type === "display") {
                        return `<img class="quote-item-thumbnail" src='${src}' style="width:48px; height:48px" alt="Line item thumbnail" /> `;
                    } else return src;

                }

            },
            {
                title: "Title",
                width: "200px",
                data: "title",
                className: "order-item-title",
                render: (_data: string, _type: any, row: PurchaseOrderLineItem, _meta: any) => this.htmlEncode(row.item.title)
            },
            {
                title: "Options",
                width: "200px",
                data: "description",
                className: "order-item-description",
                render: (_data: any, _type: any, row: PurchaseOrderLineItem, meta: any) => {
                    try {
                        if (this.isFrame(row.item))
                            return this.generateQuoteItemOptionsView(row, this.dataTable.row(meta.row).child.isShown());
                        else if (row.item.providerReferenceId === emptyGuid) {
                            return row.item.description;
                        } else return "";
                    } catch {
                        return "";
                    }
                }
            },
            {
                title: "Qty",
                width: "50px",
                data: "quantity",
                className: "order-item-quantity",
                render: (_data: any, _type: any, row: PurchaseOrderLineItem, _meta: any) => row.item.quantity
            },
            {
                title: "Price",
                width: "90px",
                data: "id",
                render: (_data: any, _type: any, row: PurchaseOrderLineItem, _meta: any) => {
                    const price = row.price.quantityCost;
                    return moneyToHtml(price);
                },
                className: "dt-right order-item-price"
            },
        ];
    }

    protected bindClickEvents($dataTable: any) {
        $dataTable.on('click', '.options-dropdown',
            this.eventHandler(
                async (data: PurchaseOrderLineItem, _ev: any, row: any, tr: any) => {
                    if (row.child.isShown()) {
                        // This row is already open - close it
                        row.child.hide();
                        tr.removeClass('shown');
                        row.invalidate();
                    } else {
                        // Open this row
                        const content = this.getFrameExtendedDetails(data);
                        if (!isEmptyOrSpace(content)) {
                            const className = (row.index() % 2 == 0) ? "odd" : "even";
                            row.child(content, className).show();
                            row.invalidate();
                            tr.addClass('shown');
                        }
                    }
                }
            ));
    }

    generateQuoteItemOptionsView(lineItem: PurchaseOrderLineItem, childVisible: boolean) {
        if (childVisible)
            return `<span>${this.getOptionsOneLiner(lineItem)}</span><span class="float-end options-dropdown"><i class="fa-solid fa-caret-up"></i></span>`;
        else
            return `<span>${this.getOptionsOneLiner(lineItem)}</span><span class="float-end options-dropdown"><i class="fa-solid fa-caret-down"></i></span>`;
    }

    getOptionsOneLiner(lineItem: PurchaseOrderLineItem) {
        const frameDimensions = (frameData: V6FrameData): string => {
            const width = frameData.attributeGroups[0]?.attributes
                .filter(x => x.code?.toLowerCase() === "width")[0] ?? null;
            const height = frameData.attributeGroups[0]?.attributes
                .filter(x => x.code?.toLowerCase() === "height")[0] ?? null;
            const hasHeight = height && (height?.value !== "0\"");
            const hasWidth = width && (width?.value !== "0\"");

            const displayWidth = `${width?.value ?? 0} (W)`;
            const displayHeight = `${height?.value ?? 0} (H)`;

            if (hasHeight && hasWidth)
                return `${displayWidth} x ${displayHeight}`;
            else if (hasHeight)
                return `"${displayHeight}`;
            else if (hasWidth)
                return `${displayWidth}`;
            else
                return "";

        };

        //only here if we are a frame
        if (!(lineItem.item.serviceProvider === quoteSupplierProvider.v6)) return lineItem.item.description;

        const dataObject = base64ToObject(lineItem.data?.providerData);
        const data = dataObject as V6QuoteItem;
        return frameDimensions(data.frameData);
    }

    getFrameExtendedDetails(lineItem: PurchaseOrderLineItem) {
        if (!(lineItem.item.serviceProvider === quoteSupplierProvider.v6)) return "";
        const dataObject = base64ToObject(lineItem.data?.providerData);
        const data = dataObject as V6QuoteItem;
        const element = document.createElement('div');


        const template = html`
            <div class="extended-options-line">
                ${this.frameTemplate(data)}
            </div>`;
        render(template, element);
        return element.innerHTML;
    }

    protected override ordering(): boolean {
        return false;
    }

    private frameTemplate(frame: V6QuoteItem): TemplateResult {
        const displayVal = (main: string | null | undefined, next: string | null | undefined) => {
            if (!isEmptyOrSpace(main)) return main;
            if (!isEmptyOrSpace(next)) return next;
            return "";
        };
        const attributeTemplate = (a: V6Property) => {
            return html`
                <li>
                    <span class="attribute-label">${displayVal(a.codeDescription, a.code)}</span>
                    <span class="attribute-value">${displayVal(a.displayValue, a.value)}</span>
                </li>`;
        };

        const groupHasAttributes = (g: V6PropertyGroup) => {
            return g.attributes.filter(a => a.visible).length > 0;
        };
        const visibleGroups = (g: V6PropertyGroup[]) => g.filter(g => groupHasAttributes(g));
        const groupTemplate = (g: V6PropertyGroup): TemplateResult => {
            //we want to fit 3 columns per row??
            return html`
                <div class="col-4 extended-options-block">
                    <h5>${displayVal(g.description, g.name)}</h5>
                    <ul>
                        ${g.attributes.map(a => attributeTemplate(a))}
                    </ul>
                </div>
            `;
        };
        const frameDataTemplate = (frameData: V6FrameData, mergeGroups?: V6PropertyGroup[]) => {
            const stackHasAttributes = (nf: V6FrameData): boolean => {
                const processFrame = (frame: V6FrameData): boolean => {
                    return (frame.attributeGroups.some(g => groupHasAttributes(g)) ||
                        frame.nestedFrames.some(nf => processFrame(nf)));
                };
                return processFrame(nf);
            };

            //do not represent frames without useful information
            const frames = frameData.nestedFrames.filter(nf => stackHasAttributes(nf));

            const nestedFrameTemplate = () => {
                if (frames.length === 0) return html``;
                return html`
                    <div class="extended-options-level1">
                        ${frames.map(f => html`
                        <h4 class="extended-options-level-header">${f.description}</h4>
                        <div class="extended-options-level2">
                            ${frameDataTemplate(f)}`)}
                        </div>
                    </div>`;
            };

            const groups = mergeGroups
                ? [...visibleGroups(mergeGroups), ...visibleGroups(frameData.attributeGroups)]
                : visibleGroups(frameData.attributeGroups);
            //the layout here is to create a row element and fill it with columns spanning 3 cols per width
            //and then for nested embed and continue
            return html`
                <div class="row">
                    ${groups.map(g => groupTemplate(g))}
                </div>
                ${nestedFrameTemplate()}
            `;
        };

        return html`
            ${frameDataTemplate(frame.frameData, frame.quoteItemOptions)}`;

    }
}

export interface PurchaseOrderDetailViewOptions {
    purchaseOrderManager: PurchaseOrderContainerManager;
    purchaseOrderCache: DataCacheGeneric;
}

export class PurchaseOrderDetailView extends ViewBase {

    protected readonly purchaseOrderManager: PurchaseOrderContainerManager;
    protected table: PurchaseOrderItemTable;
    protected purchaseOrderCache: DataCacheGeneric;

    constructor(options: PurchaseOrderDetailViewOptions) {
        super();
        this.purchaseOrderManager = options.purchaseOrderManager;
        this.purchaseOrderCache = options.purchaseOrderCache;
        this.table = this.ItemTableFactory();
    }

    public async prepareForSave() {
        throw new DevelopmentError("items-view, Method not implemented.");
    }

    public getValidationErrors(): string[] {
        return [];
    }

    public async refreshData(): Promise<void> {
        //this is a force reload of the data. This is not something that we want to do, if items are inuse
        //as we would end up with a bad loading of data.
        await this.table.refreshData();
    }

    public async invalidate(): Promise<void> {
        await this.render();
        this.table.render();
        this.table.invalidate();
    }


    protected ItemTableFactory(): PurchaseOrderItemTable {
        return new PurchaseOrderItemTable({
            purchaseOrderManager: this.purchaseOrderManager
        });
    }

    public buttonMenu(): Snippet {
        return html``;
    }

    protected async template(): PromiseTemplate {
        return html`
            <div>
                ${this.table.ui}
            </div>`;
    }
}
