import { ApiCommunications } from "./api-communications";
import { PricingEngineApi } from "./pricing-engine-api";
import { ResultPutExposedDataClient } from "./pricing-engine-api-interface";
import {NullPromise} from "../null-promise";

export class PricingEngineApiImplementation implements PricingEngineApi {
    readonly api: ApiCommunications;

    constructor(api: ApiCommunications) {
        this.api = api;
    }

    async getPriceExposedData(priceId: string, exposureAnnotations: string[]): NullPromise<ResultPutExposedDataClient> {
        const url = `api/Prices/${priceId}/ExposedData?exposureAnnotations=${exposureAnnotations}`
        return await this.api.put<ResultPutExposedDataClient>(url);

    }
}
