
import { lang, tlang } from "../language/lang";
import { init } from "./app-global-init";
import { EventBooleanAsync } from "./ui/events";
import { AskConfirmation } from "./ui/modal-confirmation";
import { AskOption } from "./ui/modal-option";
import { getSaveIndicator } from "./ui/save-notifier";

let enableAutoSaveFeature = true;
export class ErrorAbandon implements Error {

    name: string;
    message: string;
    stack?: string | undefined;
    cause?: Error | undefined;
    constructor(name: string, message: string, stack?: string | undefined, cause?: Error | undefined) {
        this.name = name;
        this.message = message;
        this.stack = stack;
        this.cause = cause;
    }
}

export function isAutoSaving(): boolean {
    return enableAutoSaveFeature;
}

export function setAutoSaveOnDealer(value: boolean) {
    enableAutoSaveFeature = value;
}

export async function saveWithIndicator(saveEvent: EventBooleanAsync): Promise<boolean> {
    let saved = false;
    const indicator = await getSaveIndicator(isAutoSaving());
    try {
        saved = await saveEvent();
    }
    catch (e) {
        saved = false;
        throw e;
    }
    finally {
        indicator.completed(saved);
    }
    return saved;
}

init(() => {
    globalThis.dealerConfiguration.setAutoSave = setAutoSaveOnDealer;
});



export interface SavePromptOptions {
    isReadonly: boolean;
    autoSaveEvent: EventBooleanAsync;
    needsSaveEvent: EventBooleanAsync;
    dictionaryName: string;
}

export async function canClose(options: SavePromptOptions): Promise<boolean> {
    if (options.isReadonly) return true;
    const checkAbandon = async () => (await AskConfirmation(tlang`Save Failed`, {
        ok: tlang`Abandon Changes`,
        cancel: tlang`Stay On ${lang(options.dictionaryName)}`
    }));

    const saveOrAbandon = async () => {
        let success = false;
        try {
            success = await options.autoSaveEvent();
            if (success) return true;
        } catch (e) {
            if (e instanceof ErrorAbandon) return true;
            throw e;
        }
        return await checkAbandon();
    };
    if (enableAutoSaveFeature)
        return await saveOrAbandon();
    else {
        if (await options.needsSaveEvent()) {
            const btnOptions = {
                save: "save",
                close: "close",
                edit: "edit"
            };
            const option = await AskOption(tlang`You have unsaved changes`, [
                { caption: tlang`Save`, value: btnOptions.save, btnClass: "btn-primary" },
                { caption: tlang`Abandon`, value: btnOptions.close, btnClass: "btn-danger" },
                { caption: tlang`Keep Editing`, value: btnOptions.edit, btnClass: "btn-secondary" }
            ]);
            if (option === btnOptions.save) {
                return await saveOrAbandon();
            } else if (option === btnOptions.close)
                return true;
            else return false;

        }
    }
    return true;

}


export async function allowPageControlTabChange(options: SavePromptOptions): Promise<boolean> {

    if (options.isReadonly) return true;

    if (enableAutoSaveFeature)
        try {
            const success = await options.autoSaveEvent();
            if (success) return true;
        } catch (e) {
            if (e instanceof ErrorAbandon) return true;
            throw e;
        }

    else {
        if (await options.needsSaveEvent()) {
            if (!(await AskConfirmation(tlang`You have unsaved changes`, {
                ok: tlang`Save Changes`,
                cancel: tlang`Stay On Page`
            })))
                return false;
            else
                return await options.autoSaveEvent();
        } else
            return true;
    }
    return false;
}