import { ServiceResponse, ServiceResponseInvalid } from "../service_response";
import { FileResponse } from "./api-type-base-interface";

export type ServiceResponseHandler = (response: ServiceResponseInvalid) => void;

let _error: ServiceResponseInvalid | null = null;
export function getLastApiError(): ServiceResponseInvalid | null {
    return _error;
}
export function setLastApiError(error: ServiceResponseInvalid | null) {
    _error = error;
}
// in the long run we are prob better to expose just the behaviour we need to use to consume
export interface ApiCommunications {
    performAuthenticationProcess(): Promise<boolean>;
    get<T>(path: string): Promise<T | null>;
    post<T>(path: string, data?): Promise<T | null>;
    put<T>(path: string, data?): Promise<T | null>;
    postFileDownload(path: string, data?): Promise<FileResponse | null>;
    postRaw<T>(path: string, data?: any, authenticate?: boolean, baseUrl?: string): Promise<ServiceResponse<T>>;
    fullUrl(path: string): string;
}

