import { ClientDataEntryView, ClientView, ClientViewOptions } from "../../../clients/views/client-view";
import { FranchiseeClientDetailView } from "./franchisee-client-detail-view";

import { FranchiseeClientContainerManager } from "../data/franchisee-client-container";
import { getApiFactory } from "../../../api/api-injector";
import { ClientContainer } from "../../../clients/data/client-container-manager";
import { cache } from "../../cache/cache-registry";


export class FranchiseeClientDataEntryView extends ClientDataEntryView {
    constructor(options: ClientViewOptions) {
        super(options);
    }

    protected clientContainerManagerFactory(container: ClientContainer): FranchiseeClientContainerManager {
        const m = new FranchiseeClientContainerManager(container, this.clientApi, getApiFactory().paymentProfile(), this.getTitle);
        m.afterSave.push(() => cache().client.flush([this.clientContainer.clientId]));
        return m;
    }

    protected clientDetailViewFactory(): FranchiseeClientDetailView {
        return new FranchiseeClientDetailView(this, {
            clientContainerManager: this.clientContainerManager
        });
    }
}

export class FranchiseeClientView extends ClientView {
    options: ClientViewOptions;

    constructor(options: ClientViewOptions) {
        super(options);
        this.options = options;
    }

    protected createView(): ClientDataEntryView {
        return new FranchiseeClientDataEntryView(this.options);
    }
}