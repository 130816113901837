import { html, TemplateResult } from 'lit';
import { getInternalId } from '../../../components/ui/databinding/databinding';
import { PromiseTemplate } from '../../../components/ui/events';
import { isEmptyOrSpace } from '../../../components/ui/helper-functions';
import { tlang } from '../../../language/lang';
import { V6ListItem, V6Property, V6QuoteIGU, ValueEditorType } from '../v6-quote-item-data';
import { V6FrameAttributeView, V6FrameAttributeViewEventHandlers, V6FrameAttributeViewOptions } from './v6-frame-attribute';

export interface V6AttributePickerSelectValue {
    value: string | null | undefined;
    displayValue: string | null | undefined;
}
/*
export const v6NativePickerTag = {
    fill: "fill",
    igu: "igu",
    louvre: "louvre",
    iguQuote: "quote-igu",
    finishColor: "finish color",

};


*/
function optionTypeToPickerTag(value: number) {
    switch (value) {
        case 0:
        case 1:
        case 2:
            return v6NativePickerTag.finishColor;
        case 3:
            return v6NativePickerTag.fill;
    }
    return "";
}
export const v6NativePickerTag = {
    fill: "fill",
    igu: "igu",
    louvre: "louvre",
    iguQuote: "quote-igu",
    finishColor: "finish color",

};

//this is an exposure that lets the picker be provided the means of obtaining the different types of data it wants to select.
export interface V6AttributePickerSelectHandler {
    pickFrameFinish(optionCode: string): Promise<V6AttributePickerSelectValue>;
    pickFrameFill(resourceName: string): Promise<V6AttributePickerSelectValue>;
    pickFrameLouvre(resourceName: string): Promise<V6AttributePickerSelectValue>;
    pickFrameIGU(resourceName: string): Promise<V6AttributePickerSelectValue>;
    pickQuoteIGU(resourceName: string): Promise<V6AttributePickerSelectValue>;
    pickResource(classId: number, family: string, tags: string): Promise<V6AttributePickerSelectValue>;
    pickItemFromList(items: V6ListItem[]): Promise<V6AttributePickerSelectValue>;
    getIGUs(): Promise<V6QuoteIGU[]>;
}

export class V6AttributePicker extends V6FrameAttributeView {
    lastValuePicked: V6AttributePickerSelectValue | null = null;
    activeDisplayValue: string | null = null;
    selectHandler: V6AttributePickerSelectHandler;

    constructor(attribute: V6Property, viewOptions: V6FrameAttributeViewOptions,
        viewEvents: V6FrameAttributeViewEventHandlers,
        selectHandler: V6AttributePickerSelectHandler) {
        super(attribute, viewOptions, viewEvents);
        this.selectHandler = selectHandler;
    }

    protected getInput(e: Event): HTMLInputElement {
        return $(e.target as HTMLElement).closest('.v6config-attribute').find('input')[0] as HTMLInputElement;
    }
    public async editTemplate(): PromiseTemplate {

        const d = this.attribute;
        const event = (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (this.readonly) return;
            this.editControlFocused();
            this.doBtnClick(e);
        };


        if (d.valueType === ValueEditorType.Dropdown) {
            if (isEmptyOrSpace(d.displayValue)) {
                const selected = d.list?.listValues.filter(x => x.code === d.value);
                if (selected)
                    d.displayValue = selected[0].description ?? selected[0].code;
            }
        }
        if (!this.readonly) this.activeDisplayValue = null;
        const displayVal = this.activeDisplayValue ?? d.displayValue;

        const thisValue = this.fallback(this.fallback(displayVal, d.code), d.value);
        const isFill = this.getConvertedTag(this.attribute.picker?.tags ?? "") === v6NativePickerTag.fill;

        const inputId = `picker-${getInternalId()}`;

        const mouseEnterEvent = () =>
            this.editControlFocused(true);
        const mouseLeaveEvent = () => this.editControlUnfocused(true);
        const keydownEvent = (e: Event) =>
            e.preventDefault();
        const igus = isFill ? (await this.selectHandler.getIGUs()) : [];

        const quoteIGUTemplate = () => {
            if (igus.length === 0) return html``;
            const templates: TemplateResult[] = [];
            templates.push(html`
                <li>
                    <hr class="dropdown-divider">
                    <p class="fw-bold ms-3 mb-0">${tlang`!!quote-igu!!`}</p>
                    <hr class="dropdown-divider">
                </li>`);
            for (let i = 0; i < igus.length; i++) {
                const igu = igus[i];
                const clickEvent = (e: Event) => {
                    e.preventDefault();
                    const input = this.getInput(e);
                    this.afterPickSomething(e, input, {
                        displayValue: this.fallback(igu.description, igu.code),
                        value: igu.objectReference
                    });

                };
                templates.push(html`
                    <li>
                        <a class="dropdown-item picker-target" @click=${clickEvent} href="#">${this.fallback(igu.description, igu.code)}</a>
                    </li>
                `);

            }
            return templates;
            /*
            return html`
            <li>
                <a class="dropdown-item picker-target" data-bs-toggle="dropdown" @click=${(e)=> this.doBtnClick(e,
                    v6NativePickerTag.iguQuote)}
                    href="#">${tlang`%%quote-igu%%`}
                </a>
            `;
            */
        };

        return isFill && !this.readonly
            ? html`
            <input ?readonly=${this.readonly} class="form-control form-select" type="text" .value=${thisValue}
                @mouseenter=${mouseEnterEvent} @mouseleave=${mouseLeaveEvent} @keydown=${keydownEvent} data-bs-toggle="dropdown"
                id=${inputId} />
            <ul class="dropdown-menu" aria-labelledby=${inputId}>
                <!--
                                                <li><a class="dropdown-item picker-target" @click=${(e) => this.doBtnClick(e, v6NativePickerTag.fill)}
                                                            href="#">${tlang`%%fill%%`}</a></li>
                                        -->
                <li><a class="dropdown-item picker-target" @click=${(e) => this.doBtnClick(e, v6NativePickerTag.igu)}
                        href="#">${tlang`%%igu%%`}</a></li>
                <li><a class="dropdown-item picker-target disabled" disabled @click=${(e) => this.doBtnClick(e,
                v6NativePickerTag.louvre)} href="#">${tlang`%%louvre%%`}</a></li>
                ${quoteIGUTemplate()}
            </ul>

        `
            : html`
        <input ?readonly=${this.readonly} class="form-control form-select picker-target" type="text" .value=${thisValue}
            @mouseenter=${()=>
        this.editControlFocused(true)} @mouseleave=${() => this.editControlUnfocused(true)}
        @click=${event} @keydown=${(e: Event) =>
                e.preventDefault()}
        />`;

    }

    protected isTarget(e: Event) {
        return e.target instanceof HTMLElement && e.target.classList.contains('picker-target');
    }
    protected afterPickSomething(e: Event, input: HTMLInputElement, pickedValue: V6AttributePickerSelectValue) {
        this.editControlUnfocused();
        if (pickedValue.value) {
            this.lastValuePicked = pickedValue;
            //this line is based on the exact template above.
            input.value = this.fallback(pickedValue.displayValue, pickedValue.value);
            this.activeDisplayValue = pickedValue.displayValue ?? null;
            this.changeEvent(e, () => {
                this.attribute.displayValue = this.activeDisplayValue ?? undefined;
            });
            this.lastValuePicked = null;
            this.lastValue = null;
        }
    }
    protected doBtnClick(e: Event, tag?: string) {
        if (!this.isTarget(e)) return;
        //do some asyncronous picker behavior
        //why the page reloads when i click this i dont know
        e.preventDefault();

        const input = this.getInput(e);


        this.pickSomething(this.attribute.picker?.resourceClassId ?? null, this.getConvertedTag(tag ?? this.attribute.picker?.tags ?? ""))
            .then((pickedValue) => this.afterPickSomething(e, input, pickedValue))
            .catch(e => {
                alert(`replace this alert on :${e}`);
            });
    }
    getConvertedTag(tag: string): string {
        return isNaN(parseInt(tag)) ? tag.toLowerCase() : optionTypeToPickerTag(parseInt(tag));
    }
    pickSomething(resourceClassId: number | null, tag: string): Promise<V6AttributePickerSelectValue> {
        //TODO - a lot of work cleaning up the bindings. standardize what the browser types are etc etc.

        if (this.attribute.valueType === ValueEditorType.Dropdown) {
            return this.selectHandler.pickItemFromList(this.attribute.list?.listValues ?? []);
        }
        //TODO - full list of browser types. currently just fill and finish
        //TODO - change this to use the finishPickerByFrame
        if (resourceClassId === -1) {
            switch (this.getConvertedTag(tag)) {
                case v6NativePickerTag.finishColor: {
                    return this.selectHandler.pickFrameFinish(this.attribute.code);
                    break;
                }
                case v6NativePickerTag.fill: {
                    return this.selectHandler.pickFrameFill(tlang`%%fill%%`);
                    break;
                }
                case v6NativePickerTag.louvre: {
                    return this.selectHandler.pickFrameLouvre(tlang`%%louvre%%`);
                    break;
                }
                case v6NativePickerTag.igu: {
                    return this.selectHandler.pickFrameIGU(tlang`%%igu%%`);
                    break;
                }
                case v6NativePickerTag.iguQuote: {
                    return this.selectHandler.pickQuoteIGU(tlang`%%quote-igu%%`);
                    break;
                }
                default: {
                    return new Promise((resolve) => {
                        resolve({ value: this.attribute.value, displayValue: this.attribute.displayValue ?? "" });
                    });
                }
            }
        } else {
            return this.selectHandler.pickResource(resourceClassId ?? 0,
                this.attribute.picker?.familyRef ?? "",
                this.getConvertedTag(this.attribute.picker?.tags ?? ""));
        }


    }

    protected getValue(): string {
        return this.lastValuePicked?.value ?? "";
    }
}