import { ClientApi } from "../../api/client-api";
import { DataTableWrapper, RequestPage, ResultPaginated } from "../../components/ui/datatable-view";
import { ViewClientSummary, ViewContactSummary } from "../../api/dealer-api-interface-client";
import { EventClientOpen, EventContactOpen } from "../data/events";
// eslint-disable-next-line import/named
import { EventSnippet } from "../../components/ui/events";
import { emptyGuid } from "../../api/guid";
import { tlang } from "../../language/lang";
import { getApiFactory } from "../../api/api-injector";

interface ContactSummaryTableBaseOptions {
    title: EventSnippet;
}

class ContactSummaryTableBase extends DataTableWrapper<ViewContactSummary> {
    title: EventSnippet;
    api: ClientApi = getApiFactory().client();
    filter: string | null;
    clientIdFilter: string | null;

    constructor(options: ContactSummaryTableBaseOptions) {
        super();
        this.title = options.title;
        this.filter = null;
        this.clientIdFilter = null;
    }

    enableFiltering(): boolean {
        return true;
    }

    updateFilter(_searchTerm: string | null) {
        this.filter = _searchTerm;
    }

    updateClientFilter(_clientId: string | null) {
        this.clientIdFilter = _clientId;
    }

    async getRowsFromServer(request: RequestPage): Promise<ResultPaginated<ViewContactSummary>> {
        const results = await this.api.browseContactSummary({
            pageIndex: request.pageIndex,
            pageSize: request.pageSize,
            sortField: request.sortField,
            sortAsc: !request.sortAsc,
            clientOwnerId: null,
            filter: this.filter,
            clientId: this.clientIdFilter
        });
        if (!results) return {
            count: 0, pageCount: 0, pageIndex: 0, pageSize: this.pageLength(), results: []
        };

        return results.contactSummary;
    }

    getDefaultSortAsc(): boolean {
        return false;
    }

    useAutoWidthColumns(): boolean {
        return false;
    }

    getColumns(): any[] {
        return [{
            title: tlang`%%contact%% Name`, width: "250px", data: "name", className: "contact-name"
        }, {
            title: tlang`Primary`,
            width: "250px",
            data: "isPrimary",
            orderable: false,
            className: "contact-isprimary",
            render: (data: boolean) => this.isPrimary(data)
        }, {
            title: tlang`%%client%%`, width: "250px", data: "clientName", className: "contact-client-name"
        }, {
            title: tlang`Email`, width: "250px", orderable: false, data: "email", className: "contact-email"
        }, {
            title: tlang`Role`, width: "250px", orderable: false, data: "title", className: "contact-title"
        }, {
            title: tlang`Phone`, width: "250px", orderable: false, data: "mobile", className: "contact-mobile"
        },];
    }

    protected isPrimary(data: boolean) {
        return data ? `<icon-checked />` : "";
    }
}

interface ContactSummaryTableOptions extends ContactSummaryTableBaseOptions {
    openClientEvent: EventClientOpen;
    openContactEvent: EventContactOpen;
    addButtonTitle: EventSnippet;
}

export class ContactSummaryTable extends ContactSummaryTableBase {
    openClientEvent: EventClientOpen;
    openContactEvent: EventContactOpen;
    addButtonTitle: EventSnippet;

    constructor(options: ContactSummaryTableOptions) {
        super({ title: options.title });
        this.openClientEvent = options.openClientEvent;
        this.openContactEvent = options.openContactEvent;
        this.addButtonTitle = options.addButtonTitle;
    }

    bindClickEvents($dataTable: any) {
        //Data tables uses JQuery click events to bind things. they don't have any other
        //special handler.

        $dataTable.on('click', '.client-link', this.eventHandler(async (data: ViewContactSummary) => {
            await this.openClient({
                id: data.clientId,
                name: data.clientName,
                primaryContactId: data.id,
                primaryContactName: data.name,
                clientOwnerId: emptyGuid,
                primaryContactEmail: data.email,
                physicalAddress: {
                    line1: "",
                    line2: null,
                    line3: null,
                    line4: null,
                    region: null,
                    country: null,
                    postcode: "",
                    locality: null,
                    latitude: null,
                    longitude: null
                }
            });
        }));

        $dataTable.on('click', '.contact-link', this.eventHandler(async (data: ViewContactSummary) => {
            await this.openContact(data);
        }));
    }

    getColumns(): any[] {
        return [{
            title: tlang`%%contact%% Name`,
            width: "250px",
            data: "id",
            className: "contact-name",
            render: (data: string, _type: string, row: ViewContactSummary) => {
                return `<a class="contact-link" data-contact-id=${data} href="#">${row.name}</a>`;
            }
        }, {
            title: tlang`Primary`,
            width: "250px",
            data: "isPrimary",
            orderable: false,
            className: "contact-is-primary",
            render: (data: boolean) => this.isPrimary(data)
        }, {
            title: tlang`%%client%%`,
            width: "250px",
            data: "clientId",
            className: "contact-client-name",
            render: (data: string, _type: string, row: ViewContactSummary) => {
                return `<a class="client-link" data-contact-id=${data} href="#">${row.clientName}</a>`;
            }
        }, {
            title: tlang`Email`, width: "250px", orderable: false, data: "email", className: "contact-email"
        }, {
            title: tlang`Role`, width: "250px", orderable: false, data: "title", className: "contact-title"
        }, {
            title: tlang`Phone`, width: "250px", orderable: false, data: "mobile", className: "contact-mobile"
        },];
    }

    private async openClient(clientSummary: ViewClientSummary): Promise<void> {
        await this.openClientEvent?.(clientSummary);
    }

    private async openContact(viewContactSummary: ViewContactSummary): Promise<void> {
        await this.openContactEvent?.(viewContactSummary);
    }
}

//We can extend this to add options like pick immediately, and what buttons to show.
interface ContactSummaryPickerOptions extends ContactSummaryTableBaseOptions {
    clientId?: string;
    onContactSelect?: (contactSummary: ViewContactSummary) => void;
}

export class ContactSummaryPickerBrowser extends ContactSummaryTableBase {
    onContactSelect?: (contactSummary: ViewContactSummary) => void;

    constructor(options: ContactSummaryPickerOptions) {
        super({ title: options.title });
        this.clientIdFilter = options.clientId ?? null;
        this.onContactSelect = options.onContactSelect;
    }

    bindClickEvents($dataTable: any) {

        $dataTable.on('click', 'tr', this.eventHandler(async (data: ViewContactSummary) => {
            if (this.onContactSelect)
                this.onContactSelect?.(data);
        }));
    }

    getColumns(): any[] {
        return [{
            title: tlang`%%contact%%`,
            width: "250px",
            data: "id",
            render: (_data: string, _type: string, row: ViewContactSummary) => {
                return `${row.name}`;
            }
        }, {
            title: tlang`Primary`,
            width: "250px",
            data: "isPrimary",
            orderable: false,
            render: (data: boolean) => this.isPrimary(data)
        }, {
            title: tlang`%%client%%`,
            width: "250px",
            data: "clientName"
        }];
    }
}