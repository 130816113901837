import { EventNotify } from "./components/ui/events";

export interface AsyncConstructor {
    afterConstructionExecuted?: boolean;
    afterConstruction(): Promise<void>;
}

let onBefore: EventNotify | null = null;
export function setBeforeConstructionEvent(event: EventNotify) {
    onBefore = event;
}

export async function constructAsync<TResult extends AsyncConstructor>(obj: TResult): Promise<TResult> {
    if (obj.afterConstructionExecuted) return obj;
    if (onBefore) await onBefore();
    await obj.afterConstruction();
    obj.afterConstructionExecuted = true;
    return obj;
}