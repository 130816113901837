//this will contain the class which is a page control container for consistent design
// eslint-disable-next-line import/named
import { html, TemplateResult } from "lit";
import { DevelopmentError } from "../../development-error";
import { disableUI, enableUI } from "../../ui-lock";
import { EAbort } from "../abort";
import { getInternalId } from "./databinding/databinding";
import { EventSnippet, EventVoidAsync, PromiseTemplate } from "./events";
import { showError } from "./show-error";
import { ViewBase } from "./view-base";

//used to return the current active content of a page
export type PageElementProvider = ((pageIndex: number) => Promise<HTMLElement>) | ((pageIndex: number) => HTMLElement);
//an event triggered before changing to a page, which returns true if the page change is allowed
//asynchronous to allow conditional testing that might take time
export type PageConfirmationEvent = (pageIndex: number, page: PageManager) => Promise<boolean>;
export type PageEnteredEvent = (pageIndex: number, page: PageManager) => Promise<void>;

export type MenuIconEvent = () => Promise<boolean>;

export interface MenuIconAction {
    caption?: EventSnippet;
    disabled?: boolean;
    event?: MenuIconEvent;
}

export type MenuIconEventList = MenuIconAction[];

export interface MenuIconOption {
    caption?: EventSnippet;
    disabled?: boolean;
    classList?: string;
    event?: MenuIconEvent;
    childEvents?: MenuIconEventList;
}

export interface PageManager {
    //the content provider will execute after successful pageChanging, and before pageChange
    //if the content is immutable, then it is up to the provider to ensure this.
    //the content manager will check if the existing content matches (by ref) the new one
    //and will swap out if nesessary
    content: PageElementProvider;
    caption: EventSnippet;
    hasDelete?: () => boolean;
    onEnter?: PageEnteredEvent;
    canLeave?: PageConfirmationEvent;
    canClose?: PageConfirmationEvent;
    buttonMenu?: EventSnippet;
    data: any | null;
    callbacks?: PageCallback;
}

interface PageControlEvents {
    onDeleteTab?: (page: PageManager, index: number) => Promise<void> | void;
}
export interface PageControlOptions {
    //build and return a list of pages
    pageInitializer?: () => PageManager[];
    defaultTabIndex?: number;
    plusPage?: MenuIconOption;
    menuIcons?: MenuIconOption[];
    events?: PageControlEvents;



}

export interface PageCallback {
    setNeedsRefreshEvent(event: EventVoidAsync);
}

export class PageControl extends ViewBase {
    pages: PageManager[];

    elementId: string;
    private plusPage?: MenuIconOption;
    private menuIcons?: MenuIconOption[];
    protected events: PageControlEvents | null;

    constructor(options: PageControlOptions) {
        super();
        //TODO - maybe we want to inject an API.. but i dont think we need to
        this.elementId = `pagecontrol-${getInternalId()}`;
        this.events = options.events ?? null;
        //extend this array as needed and adjust each filter
        this.pages = options.pageInitializer?.() ?? [];
        this.pages.forEach(page => {
            if (page.callbacks)
                page.callbacks.setNeedsRefreshEvent(async () => await this.render());
        });
        this.plusPage = options.plusPage;
        this.menuIcons = options.menuIcons;
        this._activeTabIndex = -1;
        const firstTabIndex = options.defaultTabIndex ?? 0;
        if (firstTabIndex >= 0 && firstTabIndex < this.pageCount) this._activeTabIndex = firstTabIndex;
        this.render(); // no wait
        setTimeout(async () => {
            try {
                await this.activePage?.onEnter?.(this._activeTabIndex, this.activePage);
            } catch (e) {
                await showError(e as Error);
            }
        }, 1);
    }

    private _activeTabIndex: number;

    tabReadonly = 0;
    private async lockUI(lock: boolean) {
        if (lock) {
            disableUI();
            this.tabReadonly++;
        }
        else {
            enableUI();
            this.tabReadonly--;
        }
        if (this.tabReadonly < 0) throw new DevelopmentError("Page Control Readonly Lock out of sync");

        await this.render();
    }
    private get uiLocked(): boolean {
        return this.tabReadonly > 0;
    }

    get activeTabIndex(): number {
        return this._activeTabIndex;
    }

    get activePage(): PageManager | null {
        if (this._activeTabIndex >= 0 && this._activeTabIndex < this.pageCount)
            return this.pages[this._activeTabIndex];
        else
            return null;
    }

    get pageCount(): number {
        return this.pages.length;
    }

    addPage(page: PageManager) {
        this.pages.push(page);
        this.setActiveTabIndex(this.pageCount - 1);
        if (page.callbacks)
            page.callbacks.setNeedsRefreshEvent(async () => await this.render());
    }

    public async closePage(page: PageManager) {
        const pageIndex = this.pages.indexOf(page);
        if (pageIndex >= 0) {
            await this.deleteTab(pageIndex);
        }
    }

    async setMenuIcons(menuIcons: MenuIconOption[]) {
        this.menuIcons = menuIcons;
        await this.render();
    }

    async setActivePage(page: PageManager) {
        const idx = this.pages.findIndex(p => p === page);
        if (idx === -1) throw new Error(`invalid page object passed to setActivePage`);
        await this.setActiveTabIndex(idx);
    }

    async setActiveTabIndex(value: number, force?: boolean): Promise<void> {
        this.pageRangeTest(value, true);
        const currentIndex = this._activeTabIndex;
        if (value !== this._activeTabIndex || force) {
            await this.lockUI(true);
            try {
                const canLeave = value === this._activeTabIndex || await this.canLeavePage(this._activeTabIndex, this.activePage);
                if (canLeave) {
                    this._activeTabIndex = value;
                    try {
                        await this.activePage?.onEnter?.(this.activeTabIndex, this.activePage);
                    } catch (e) {
                        if (e instanceof EAbort) {
                            this._activeTabIndex = currentIndex;
                        } else throw e;
                    }
                }
            }
            finally {
                await this.lockUI(false);
            }
        }
    }

    async template(): PromiseTemplate {
        return html`
            <div id=${this.elementId} class="page-control">
                <div class="page-control-container">
                    <ul class="nav nav-tabs nav-fill page-control-tabs" role="tablist">
                        ${await this.tabTitleTemplate()}
                    </ul>
                    <div class="tab-content page-control-tab-content">
                        ${await this.tabContentTemplate()}
                    </div>
                </div>
            </div>`;

    }

    async tabContentTemplate(): Promise<TemplateResult[]> {
        const count = this.pageCount;
        const result: TemplateResult[] = [];
        for (let i = 0; i < count; i++) {
            const tabid = `"item${i}-tab"`;
            const contentid = `itemtab-${i}`;
            const classes = `tab-pane ${this.activeTabIndex === i ? "show active" : ""}`;
            const template = html`
                <div class=${classes} id=${contentid} role="tabpanel" aria-labelledby=${tabid}>
                    ${await this.pages[i].content(i)}
                </div>`;
            result.push(template);
        }
        return result;
    }

    async tabTitleTemplate(): Promise<TemplateResult[]> {
        //dynamically build the list of tabs for the items in memory
        const count = this.pageCount;

        const result: TemplateResult[] = [];

        if (this.plusPage || this.menuIcons) {
            const plusPageEvent = async (e: Event) => {
                e.stopPropagation();
                e.preventDefault();
                if (this.plusPage?.disabled || this.uiLocked) return;
                await this.lockUI(true);
                try {
                    await this.runPlusPage();
                }
                finally {
                    await this.lockUI(false);
                }

            };
            const specificIconEvent = (icon) => {
                const iconEvent = async (e: Event) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (icon.disabled || this.uiLocked) return;
                    icon.disabled = true;
                    await this.lockUI(true);
                    try {
                        await this.render();
                        if (!Array.isArray(icon.event))
                            await icon.event?.();
                    } finally {
                        icon.disabled = false;
                        await this.lockUI(false);
                    }
                };
                return iconEvent;
            };

            const buttonMenuTemplate = (icon: MenuIconOption): TemplateResult => {
                const classList = icon.classList ?? "btn btn-primary";

                if (icon.event && !icon.childEvents) {
                    return topLevelButtonTemplate(icon);
                }
                else if (icon.childEvents) {
                    const buttonInner = !icon.event ?
                        html`
                            <button type="button" class="${classList} dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                ${icon.caption?.()}
                            </button>
                        ` :
                        html`
                            ${topLevelButtonTemplate(icon)}
                            <button type="button" class="${classList} dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown"
                                aria-expanded="false">
                                <span class="visually-hidden">Toggle Dropdown</span>
                            </button>
                        `;

                    const subButtons: TemplateResult[] = [];
                    icon.childEvents?.forEach(child => subButtons.push(subLevelMenuTemplate(child)));

                    return html`
                        <div class="btn-group dropup">
                            ${buttonInner}
                            <ul class="dropdown-menu">
                                ${subButtons}
                            </ul>
                        </div>
                    `;
                }

                return html``;
            };

            const topLevelButtonTemplate = (icon: MenuIconOption): TemplateResult => {
                const classList = icon.classList ?? "btn btn-primary";
                return html`
                    <button class="${classList}" @click=${specificIconEvent(icon)} ?disabled=${icon?.disabled || this.uiLocked}>
                        ${icon.caption?.()}
                    </button>`;
            };

            const subLevelMenuTemplate = (icon: MenuIconOption): TemplateResult => {
                return html`
                    <li><a class="dropdown-item" href="#" @click=${specificIconEvent(icon)} ?disabled=${icon?.disabled ||
                    this.uiLocked}>${icon.caption?.()}</a></li>`;
            };

            const buttons: TemplateResult[] = [];
            if (this.plusPage) {
                if (this.plusPage.childEvents) {
                    const subButtons: TemplateResult[] = [];
                    this.plusPage.childEvents?.forEach(icon => subButtons.push(subLevelMenuTemplate(icon)));
                    buttons.push(html`
                        <div class="dropdown d-inline">
                            <button class="btn btn-primary dropdown-toggle " type="button" id=${this.elementId + '-plus' }
                                data-bs-toggle="dropdown" aria-expanded="false">
                                ${this.plusPage?.caption?.()}
                            </button>
                            <ul class="dropdown-menu" aria-labelledby=${this.elementId + '-plus' }>
                                ${subButtons}
                            </ul>
                        </div>`);
                } else
                    buttons.push(html`
                        <button class="btn btn-primary " @click=${plusPageEvent} ?disabled=${this.plusPage?.disabled}>
                            ${this.plusPage?.caption?.()}
                        </button>`);
            }

            const pageButtons = this.activePage?.buttonMenu?.() ?? html``;
            this.menuIcons?.forEach(icon => buttons.push(buttonMenuTemplate(icon)));
            const template = html`
                <li class="nav-item page-nav-buttons" role="presentation">
                    <div class="page-nav-buttons-left">${pageButtons}</div>
                    <div class="page-nav-buttons-right">${buttons}</div>
                </li>`;
            result.push(template);
        }

        for (let i = 0; i < count; i++) {
            const pageManager = this.pages[i];
            const id = `"item${i}-tab"`;
            const contentid = `itemtab-${i}`;
            const classes = `nav-link ${!this.uiLocked && this.activeTabIndex === i ? "active" : ""} ${this.uiLocked ? " disabled " : ""}`;

            const clickEvent = async (e: Event) => {
                e.stopPropagation();
                e.preventDefault();
                if (this.uiLocked) return;
                await this.setActiveTabIndex(i);
            };
            const deleteEvent = async (e: Event) => {
                e.stopPropagation();
                e.preventDefault();
                if (this.uiLocked) return;
                await this.deleteTab(i);
            };
            const deleteTemplate = pageManager.hasDelete?.() ?? false
                ? html`
                        <button @click=${deleteEvent} type="button" class="btn-close " aria-label="Close"></button>`
                : html``;
            const caption = pageManager.caption?.() ?? "?";
            const template = html`
                <li class="nav-item" role="presentation">
                    <a class=${classes} id=${id} type="button" role="tab" aria-controls=${contentid} aria-selected="true"
                        @click=${clickEvent}>
                        ${caption}
                        ${deleteTemplate}
                    </a>
                </li>`;
            result.push(template);
        }

        return result;
    }

    async runPlusPage(): Promise<void> {
        if (this.plusPage?.disabled) return;
        if (this.plusPage && await this.canLeavePage(this.activeTabIndex, this.activePage)) {
            this.plusPage.disabled = true;
            try {
                await this.render();
                if (!Array.isArray(this.plusPage.event))
                    await this.plusPage.event?.();
            } finally {
                this.plusPage.disabled = false;
                await this.render();
            }
        }
    }

    public async clear() {
        while (this.pageCount > 0) {
            await this.deleteTab(0);
        }
    }

    private async canLeavePage(index: number, page: PageManager | null): Promise<boolean> {
        return page == null ||
            (page.canLeave
                ? (await page.canLeave?.(index, page))
                : true);
    }

    private pageRangeTest(value: number, allowNegOne?: boolean) {
        if ((allowNegOne && value === -1) || (value >= 0 && value < this.pageCount)) return;
        throw new Error(`Page index ${value} out of range [0,${this.pages.length - 1}]`);
    }

    public async canCloseAllTabs(): Promise<boolean> {
        let result = true;
        for (let i = 0; i < this.pageCount; i++) {
            const page = this.pages[i];
            if (page.hasDelete?.() ?? false) {
                if (!await page.canClose?.(i, page)) result = false;
            } else if (page === this.activePage) {
                if (!await page.canLeave?.(i, page)) result = false;
            }
        }
        return result;
    }
    private async deleteTab(i: number): Promise<boolean> {
        const reValidatePage = async () => {
            //there may be no pages left, so test to make sure the index is valid
            if (this.activeTabIndex >= 0)
                //the index has not changed, but the page has changed
                try {
                    await this.activePage?.onEnter?.(this._activeTabIndex, this.activePage);
                } catch (error) {
                    if (error instanceof EAbort) {
                        this._activeTabIndex = 0;
                        await this.activePage?.onEnter?.(this._activeTabIndex, this.activePage);
                    } else throw error;
                }

        };

        this.pageRangeTest(i);
        const isActivePage = i == this.activeTabIndex;
        await this.lockUI(true);
        try {

            const page = this.pages[i];
            const canDelete = page.canClose
                ? (await page.canClose?.(i, page))
                : true;
            if (canDelete) {
                //remove the page
                this.pages.splice(i, 1);
                if (!isActivePage) {
                    //adjust the index before render, but we are not really changing pages
                    //we are just compensating for removing a prior page
                    if (i < this.activeTabIndex) this._activeTabIndex--;
                } else {
                    //if we are removing the active page, if we were the last page decrement the index.
                    if (this._activeTabIndex >= this.pageCount) this._activeTabIndex = this.pageCount - 1;


                }
                await reValidatePage();
                await this.render();
                await this.events?.onDeleteTab?.(page, i);
                return true;
            }
            return false;
        } finally {
            await this.lockUI(false);
        }
    }

}