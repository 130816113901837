// eslint-disable-next-line import/named
import { html, TemplateResult } from "lit";
import { DataBinding } from "../databinding/databinding";
import { DataTracker } from "../databinding/data-tracker";

export function formRadioGroup(fieldName: string, title: string, options: string,
    dataBinding: DataBinding, dataTracker: DataTracker, readOnly = false, required = false, classes = ""): TemplateResult {
    return html`
        <bs-form-radio-group .class=${classes} data-id=${dataBinding.field(fieldName)} data-label=${title} ?required=${required}
            ?readonly=${readOnly} .value=${dataTracker.getObjectValue(fieldName)} .options=${options}>
        </bs-form-radio-group>`;
}