import { html, TemplateResult } from 'lit';
import * as data from '../v6-quote-item-data';
import { V6FrameAttributeView, V6FrameAttributeViewEventHandlers, V6FrameAttributeViewOptions } from './v6-frame-attribute';
import { V6AttributeBoolean } from './v6-frame-attribute-boolean';
import { V6AttributePicker, V6AttributePickerSelectHandler } from './v6-frame-attribute-picker';
import { V6AttributeNumber } from './v6-frame-attribute-number';
import { V6AttributeDropDown } from './v6-frame-attribute-dropdown';
import { V6AttributeText } from './v6-frame-attribute-text';
import { PromiseTemplate } from '../../../components/ui/events';
import { V6AttributeDimension } from './v6-frame-attribute-dimension';

//used to get unique id on elements for debugging
let frameAttributeGroupSequencer = 0;

//a view for a set of attributes in the same group
export class V6FrameAttributeGroup {
    group: data.V6PropertyGroup;
    title: string;
    attrViewOptions: V6FrameAttributeViewOptions;
    attrViewEvents: V6FrameAttributeViewEventHandlers;
    attributeViews: V6FrameAttributeView[] | null;
    private contentIndex: number;
    selectHandler: V6AttributePickerSelectHandler;
    filterAttr: ((attr: data.V6Property, group: data.V6PropertyGroup) => boolean) | undefined;

    constructor(group: data.V6PropertyGroup,
        attrViewOptions: V6FrameAttributeViewOptions,
        attrViewEvents: V6FrameAttributeViewEventHandlers,
        selectHandler: V6AttributePickerSelectHandler, title?: string,
        filterAttr?: (attr: data.V6Property, group: data.V6PropertyGroup) => boolean) {
        this.selectHandler = selectHandler;
        this.group = group;
        this.filterAttr = filterAttr;
        this.attributeViews = null;
        this.title = title ?? "";
        this.attrViewEvents = attrViewEvents;
        this.attrViewOptions = attrViewOptions;

        //using a module global value so that we can create as many instances as required without id conflicts.
        this.contentIndex = frameAttributeGroupSequencer++;
    }
    public get visible(): boolean {
        return this.group.attributes.filter(x => x.visible && (!this.filterAttr || this.filterAttr(x, this.group))).length > 0;
    }
    public get count() { return this.group.attributes.length; }
    // this is not an ideal solution yet, as it prevents multiple instances on the same page.
    // we need to add a sequencer
    public get contentId(): string { return this.asId('content'); }
    public get groupId(): string { return this.asId('group'); }
    protected asId(name: string, withHash = false): string { return `${withHash ? "#" : ""}${name}_${this.contentIndex}`; }

    public async template(visible: boolean): PromiseTemplate {

        const title = () => {
            if (!this.group.description || this.group.description === "")
                return this.group.name;
            return this.group.description ?? "Other";
        };
        if (visible)
            return html`
        <div class="form-one-col quoteItemFrameProperties">
            <div class="row attributeContents">
                <div class="v6config-group">
                    <div class="v6config-group-header">
                        ${title()}
                    </div>
                    <!--parent removed to allow all open -->
                    ${await this.getAttributeTemplates()}
                </div>
            </div>
        </div>
        `;
        else
            return html``;
    }

    //this is going to scan and build all the options view objects if they dont exist, and then
    //stage them all into an array for rendering
    async getAttributeTemplates(): Promise<TemplateResult[]> {
        if (!this.attributeViews) {
            const attrViews: V6FrameAttributeView[] = [];
            this.group.attributes?.forEach(
                (item) => {


                    let valueType = item.valueType;
                    if (valueType >= 8000 && valueType < 9000) valueType -= 8000;
                    //build an attribute view and push it into the array.
                    switch (valueType) {
                        case data.ValueEditorType.Boolean: {
                            attrViews.push(new V6AttributeBoolean(item, this.attrViewOptions, this.attrViewEvents));
                            break;
                        }
                        case data.ValueEditorType.CombinationGlazing:
                        case data.ValueEditorType.Glazing:
                        case data.ValueEditorType.Picker: {
                            attrViews.push(new V6AttributePicker(item, this.attrViewOptions, this.attrViewEvents, this.selectHandler));
                            break;
                        }

                        case data.ValueEditorType.Dimension: {
                            attrViews.push(new V6AttributeDimension(item, this.attrViewOptions, this.attrViewEvents));
                            break;
                        }
                        case data.ValueEditorType.Currency:
                        case data.ValueEditorType.Number:
                            {
                                attrViews.push(new V6AttributeNumber(item, this.attrViewOptions, this.attrViewEvents));
                                break;
                            }
                        case data.ValueEditorType.Dropdown: {
                            if (this.isImageBased(item)) {
                                attrViews.push(new V6AttributePicker(item, this.attrViewOptions, this.attrViewEvents, this.selectHandler));
                            } else {
                                attrViews.push(new V6AttributeDropDown(item, this.attrViewOptions, this.attrViewEvents));
                            }
                            break;
                        }
                        case data.ValueEditorType.Length:
                        case data.ValueEditorType.Text: {
                            attrViews.push(new V6AttributeText(item, this.attrViewOptions, this.attrViewEvents));
                            break;
                        }
                        default: {
                            throw new Error("Unknown ValueType " + item.valueType);
                        }
                    }
                });

            this.attributeViews = attrViews;
        }
        const results: TemplateResult[] = [];
        for (let i = 0; i < this.attributeViews.length; i++) {
            const x = this.attributeViews[i];
            if (!(this.filterAttr && !this.filterAttr(x.attribute, this.group)))
                results.push(await x.template());
        }
        return results;
    }
    isImageBased(item: data.V6Property): boolean {
        const list = item.list?.listValues.filter(x => x.imageReference && x.imageReference !== "");
        if (list && (list.length > 0))
            return true;
        return false;

    }
};
