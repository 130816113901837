// eslint-disable-next-line import/named
import { html } from "lit";
import { multiCastPromise } from "../../multicast-promise";
import { DevelopmentError, showDevelopmentError } from "../../development-error";
import { allowPageControlTabChange, canClose, ErrorAbandon, isAutoSaving, SavePromptOptions, saveWithIndicator } from "../save-workflow";
import { checkValidations } from "./data-entry-screen-helpers";
import { getInternalId } from "./databinding/databinding";
import { EventNotify, PromiseTemplate, Snippet } from "./events";
import { ModalDialog } from "./modal-base";
import { PageControl, PageManager } from "./page-control";
import { tlang } from "../../language/lang";
import { ViewBase } from "./view-base";


export interface DataEntryOwner {
    tryClose(): Promise<boolean>;
    forceClose(): Promise<boolean>;
}

/**
 * Base class for dataentry screens that will edit database objects that need to be saved, or autosaved
 */
export class ModalViewBase extends ModalDialog implements DataEntryOwner {
    async tryClose(): Promise<boolean> {
        return await this.closeIfAllowed();
    }
    async forceClose(): Promise<boolean> {
        await this.hideModal();
        return true;
    }

    protected getCloseMessage(): string {
        return'(Autosave enabled)'
    }
}

/**
 * base class that is designed to make it easy to support the Save/Autosave workflow of data in a consistent
 * way
 *
 * when creating any class of this type, use "await constructAsync(new DataEntryView())" to return an
 * instance.. this will ensure the afterconstructor is called asynchronously
 */
export class DataEntryView extends ViewBase {

    owner: DataEntryOwner | null;
    onRender: EventNotify | null = null;


    constructor(owner?: DataEntryOwner) {
        super();
        this.owner = owner ?? null;
    }

    protected async tryClose(): Promise<boolean> {
        if (this.owner) return await this.owner.tryClose();
        return false;
    }

    protected async forceClose(): Promise<boolean> {
        if (this.owner) return await this.owner.forceClose();
        return false;
    }

    protected _elementId = getInternalId();
    /**
     *
     * @returns the name part for creating unique UI Elements
     */
    protected name() {
        return "modal-dialog";
    }
    /**
     * used for adding unique id's to a template.
     */
    protected get elementId() {
        return `${this.name()}-${this._elementId}`;
    }

    /**
     * override this function to do UI level validation prior to any data being loaded for saving by client backend.
     * @returns list of validation errors
     */
    protected getUIValidationErrors(): string[] {
        return [];
    }

    protected async checkUIValidations(): Promise<boolean> {
        const errors = this.getUIValidationErrors();
        return await checkValidations(errors);
    }

    protected getValidationErrors(): string[] {
        return [];
    }
    protected async checkValidations(): Promise<boolean> {
        const errors = this.getValidationErrors();
        return await checkValidations(errors);
    }
    /**
     * override this and peform the actual data save and any updates that might need to be done.
     * @returns true if the save worked correctly and data is committed
     */
    protected async internalSaveData(): Promise<boolean> {
        await showDevelopmentError("internalSaveData must be overridden");
        return false;
    }
    /**
     * override this to return true if we can actually save the data. this
     * is called at a time when the dataobject should already reflect the UI
     * and can be tested for validations and other things that might block a save.
     * @returns true if we can call the internalSaveData
     */
    protected async canProceedWithSave(): Promise<boolean> {
        return true;
    }

    /**
     * override this to alert with modals, and return false if the view is "busy"
     * @returns false if the view is doing work, and needs to wait for it to finish before any closing or saving can be done
     */
    protected async readyToClose(): Promise<boolean> {
        return true;
    }
    /***
     * performs the autosave workflow/ or save workflow when autosave is off.
     * do not need to override this, but rather override its individal called parts.
     */
    async performAutoSave(): Promise<boolean> {

        // Check UI level validations.
        if (!await this.checkUIValidations()) return false;

        //if we dont need to save, then just exit.
        //but if we are not in autosave mode, we are going to force a save.
        const needsSave = await this.dataNeedsSaving();

        //this is to let us abandon new items that have had no editing
        if (!needsSave && this.isNew()) throw new ErrorAbandon("Cancel", tlang`Cancel New Edit`);

        if (!await this.checkValidations()) return false;
        if (isAutoSaving() && !needsSave) return true;
        if (await this.canProceedWithSave())
            return await saveWithIndicator(async () => this.internalSaveData());
        else return false;
    }

    protected isNew(): boolean {
        return false;
    }

    /**
     * internally this will perform any autosaves etc needed to allow this view to be exited
     * @returns true if this view can be either moved away from or destroyed.
     */
    async canClose(): Promise<boolean> {
        return (await this.readyToClose()) && (await canClose(this.getAutoSavePromptOptions()));
    }
    /**
     * provide a string such as '%%quote%%' used as part of any save workflow
     * @returns a dictionary entry for the data type we are saving
     */
    getDataDictionaryName(): string {
        return "";
    }
    /**
     * should not typically need to override this. override the parts.
     * @returns the parameters used for performing autosaves and form leave/close workflow
     */
    getAutoSavePromptOptions(): SavePromptOptions {
        return {
            isReadonly: this.isReadonly(),
            autoSaveEvent: async () => await this.performAutoSave(),
            dictionaryName: this.getDataDictionaryName(),
            needsSaveEvent: async () => await this.dataNeedsSaving()
        };
    }
    /**
     * override this to perform any post construction work that must be done async.
     */
    public async afterConstruction(): Promise<void> {
        // do nothing
    }
    /**
     * override this and do any work necessary to put the dataobject that is going to be saved into the state
     * it needs to be. this would be such as applying datatracker changes etc to copy UI data into the object.
     * this will be done as part of the conditions for dataNeedsSaving()
     */
    async prepareForSave(): Promise<void> {
        await showDevelopmentError("prepareForSave must be overridden");
        //
    }
    /***
     * override and return true if the data, and the ui should be treated as readonly.
     * it is up to the template writer of the body to enforce this. this is also used
     * as part of the save workflow
     */
    public isReadonly(): boolean {
        return false;
    }

    /**
     * override this and do any actual saving of data. at this point all data should be assumed to be in the
     * correct state ready to post to the server.
     * @returns true if a save was successful
     */
    public internalDataChanged(): boolean {
        throw new DevelopmentError("internalDataChanged must be overridden");
    }

    /**
     * do not need to override this. override internalDataChanged. it is up to each subclass
     * to manage and track its own data and differences and reflect it with internalDataChanged
     * @returns true if the data has changed.
     */
    async dataNeedsSaving(): Promise<boolean> {
        if (this.isReadonly()) return this.internalDataChanged();
        //base value will always force a save
        //override to be accurate on this
        await this.prepareForSave();
        return this.internalDataChanged();
    }

    /**
     *
     * @returns the template used to fill the ui element
     */
    protected async bodyTemplate(): PromiseTemplate {
        return html``;
    }

    /**
     * redraw the template into our UI element. call onrender if assigned.
     * owning parents should call render as needed as part of any rendering workflow,
     * and embed the ui element into themselves.
     */

    protected async template(): PromiseTemplate {
        return await this.bodyTemplate();
    }
    public async render() {
        await super.render();
        await this.onRender?.();
    }
}

/**
 * use this class when building a view that is dominated by a single page control
 */
export class DataEntryPageControlView extends DataEntryView {
    protected _pageControl: PageControl | null = null;

    /**
        * inherited
        * @returns
        */
    protected async bodyTemplate(): PromiseTemplate {
        return html`
            <div id=${this.elementId} class="page-content">
                ${this._pageControl?.ui ?? ""}
            </div>`;
    }

    public async afterConstruction(): Promise<void> {
        this._pageControl = this.createPageControl();
    }
    protected get pageControl(): PageControl {
        if (!this._pageControl) throw new DevelopmentError("PageControl was not created");
        return this._pageControl;
    }
    async canClose(): Promise<boolean> {
        return (await this.pageControl.canCloseAllTabs()) && (await super.canClose());
    }
    protected createPageControl(): PageControl {
        throw new Error("Method not implemented.");
    }

    private allowPageSwitchPromise: Promise<boolean> | null = null;
    public async allowPageSwitch(): Promise<boolean> {
        return await multiCastPromise(this.allowPageSwitchPromise,
            (p) => this.allowPageSwitchPromise = p,
            async () => await allowPageControlTabChange(this.getAutoSavePromptOptions()));
    }

}

/**
 * use this class for creating pagecontrol pages that must manage their own save workflow outside of the
 * parent view.
 *
 */
export class PageControlTabWithIndependantSaving extends DataEntryView {
    private _updatePageControl: (() => void) | null = null;
    protected getCaption(): Snippet {
        return html``;
    }
    public async allowPageSwitch(): Promise<boolean> {
        return await this.canClose();
    }
    public allowDeletePage(): boolean {
        return true;
    }

    protected refreshParent() {
        this._updatePageControl?.();
    }
    public isTab(): boolean {
        return true;
    }
    public createPageManager(title?: Snippet): PageManager {
        if (!this.isTab()) throw new DevelopmentError("Does not have a pagemanager");
        return {
            caption: () => title ?? this.getCaption(),
            canClose: async (_index: number, _page: PageManager): Promise<boolean> => {
                //check if we need to save anything here.. how? comparison? keep original copy?
                return await this.allowPageSwitch();
            },
            canLeave: async () => {
                return await this.allowPageSwitch();
            },
            onEnter: async (_pageIndex, _page) => { await this.onEnter(); },
            hasDelete: () => this.allowDeletePage(),
            content: async () => {
                return this.ui;
            },
            buttonMenu: () => {
                return html``;
            },
            callbacks: {
                setNeedsRefreshEvent: (event: () => void) => {
                    this._updatePageControl = event;
                }
            },
            data: this
        };
    }
    async onEnter(): Promise<void> {
        await this.render();
    }
}

export class PageControlChildTab extends ViewBase {
    owner: DataEntryPageControlView;
    constructor(owner: DataEntryPageControlView) {
        super();
        this.owner = owner;
    }
    private _updatePageControl: (() => void) | null = null;
    protected getCaption(): Snippet {
        return html``;
    }
    public allowDeletePage(): boolean {
        return true;
    }
    protected refreshParent() {
        this._updatePageControl?.();
    }
    public isTab(): boolean {
        return true;
    }
    public createPageManager(title?: Snippet): PageManager {
        if (!this.isTab()) throw new DevelopmentError("Does not have a pagemanager");
        return {
            caption: () => title ?? this.getCaption(),
            canClose: async (_index: number, _page: PageManager): Promise<boolean> => {
                //check if we need to save anything here.. how? comparison? keep original copy?
                return await this.owner.allowPageSwitch();
            },
            canLeave: async () => {
                return await this.owner.allowPageSwitch();
            },
            onEnter: async (_pageIndex, _page) => { await this.onEnter(); },
            hasDelete: () => this.allowDeletePage(),
            content: async () => {
                return this.ui;
            },
            buttonMenu: () => {
                return html``;
            },
            callbacks: {
                setNeedsRefreshEvent: (event: () => void) => {
                    this._updatePageControl = event;
                }
            },
            data: this
        };
    }
    async onEnter(): Promise<void> {
        await this.render();
    }
    /**
      * redraw the template into our UI element. call onrender if assigned.
      * owning parents should call render as needed as part of any rendering workflow,
      * and embed the ui element into themselves.
      */

    protected async template(): PromiseTemplate {
        return await this.bodyTemplate();
    }
    /**
   *
   * @returns the template used to fill the ui element
   */
    protected async bodyTemplate(): PromiseTemplate {
        return html``;
    }
}
