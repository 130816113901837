import { html } from 'lit';

import { PromiseTemplate } from '../../../components/ui/events';


import { V6Property } from '../v6-quote-item-data';
import { DimensionDirection, V6DimensionPropertySelector } from './v6-dimension-selector';
import { V6FrameAttributeView, V6FrameAttributeViewEventHandlers, V6FrameAttributeViewOptions } from './v6-frame-attribute';

export interface V6AttributePickerSelectValue {
    value: string | null | undefined;
    displayValue: string | null | undefined;
}


export interface DimensionValue {
    value: string;
    direction: number;
    locked: boolean;
}

export class V6AttributeDimension extends V6FrameAttributeView {
    lastValuePicked: V6AttributePickerSelectValue | null = null;
    activeDisplayValue: string | null = null;
    dimensionValue: DimensionValue | null = null;
    dimensionSelector: V6DimensionPropertySelector = new V6DimensionPropertySelector;

    constructor(attribute: V6Property, viewOptions: V6FrameAttributeViewOptions,
        viewEvents: V6FrameAttributeViewEventHandlers) {
        super(attribute, viewOptions, viewEvents);
        const value = this.decodeValue(attribute.value);
        this.dimensionValue = this.decodeValue(attribute.value);
        this.dimensionSelector.changeDimensionEvent = (e) => this.changeEvent(e);
        this.dimensionSelector.dimensionName = this.attribute.code;
        this.dimensionSelector.allowBoth = (this.attribute.dimension?.allowBoth) ?? false;
        this.dimensionSelector.allowLeftOrDown = this.attribute.dimension?.allowLeftOrDown ?? false;
        this.dimensionSelector.allowRightOrUp = this.attribute.dimension?.allowRightOrUp ?? false;
        this.dimensionSelector.allowLock = this.attribute.dimension?.allowLock ?? false;
        this.dimensionSelector.direction = value.direction;
        this.dimensionSelector.locked = value.locked;

    }

    protected getInput(e: Event): HTMLInputElement {
        return $(e.target as HTMLElement).closest('.v6config-attribute').find('input')[0] as HTMLInputElement;
    }

    doDataChanged(): void {
        const newValue = this.decodeValue(this.attribute.value);
        const oldValue = this.decodeValue(this.attribute.originalValue);
        if (newValue.value !== oldValue.value || newValue.locked !== oldValue.locked)
            super.doDataChanged();
    }
    private decodeValue(value: string): DimensionValue {
        const values = value.split('#');
        return {
            value: values[0],
            direction: parseFloat(values[1] ?? 0),
            locked: values[2].toLowerCase() === "true"
        };
    }
    private encodeValue(value: string, direction: number, locked: boolean): string {
        return `${value}#${direction}#${locked ? "true" : "false"}`;
    }

    protected isTarget(e: Event): boolean {
        return super.isTarget(e) ||
            $(e.target as HTMLElement).closest('button').hasClass('btn-v6-dimension');
    }
    public async editTemplate(): PromiseTemplate {

        const d = this.attribute;
        const value = this.decodeValue(d.value);
        const editValue = value.value;
        const focusEvent = () => this.editControlFocused();
        const focusoutEvent = (e) => {
            this.changeEvent(e);
            this.editControlUnfocused();
        };
        this.dimensionSelector.readonly = this.readonly;

        const str = html`
        <div class="row" @mouseenter=${() =>
                this.editControlFocused(true)} @mouseleave=${() => this.editControlUnfocused(true)}>
            <div class="col-4">
                <input @focus=${focusEvent} ?readonly=${this.readonly} class="form-control" .type="text" .value=${editValue}
                    @focusout=${focusoutEvent} @keydown=${(e)=> this.keydownEvent(e)} />
            </div>
            <div class="col-8">
                ${this.dimensionSelector}
            </div>
        </div>`;

        return str;

    }
    protected keydownEvent(e: KeyboardEvent) {
        if (e.key === "Enter") {
            e.preventDefault();
            this.changeEvent(e);
        }
    }

    protected getValue(e: Event): string {
        const input = $(e.target as HTMLElement).closest('.v6config-attribute').find('input')[0] as HTMLInputElement;
        const val = parseInt(input.value);
        const inputValue = !isNaN(val) && val > 0 ? input.value : this.attribute.originalValue;

        return this.encodeValue(inputValue, this.dimensionSelector.direction ?? DimensionDirection.rightOrUp
            , this.dimensionSelector?.locked ?? false);
    }
}