import { getApi, getApiFactory } from "../../api/api-injector";
import { getCurrentUser } from "../../api/current-user";
import { Branch, Franchisee, FranchiseeConfiguration } from "../../api/dealer-api-interface-franchisee";
import { multiCastPromise } from "../../multicast-promise";
import { NullPromise } from "../../null-promise";
import { DevelopmentError } from "../../development-error";
import { quoteSupplierProvider } from "../../quotes/data/quoteSupplierProvider";
import { navigate } from "../../components/ui/navigator";

export class CurrentUserDataStore {

    loaded = false;
    private _branches: Branch[] | null = null;
    private _defaultBranchId = "";
    private _loadPromise: Promise<void> | null = null;
    private _franchiseeConfig: FranchiseeConfiguration | null = null;

    private _franchisee: Franchisee | null = null;

    public get franchisee(): Franchisee | null {
        if (getCurrentUser()?.isSupplier) {
            return null;
        }
        this.checkLoaded();
        if (!this._franchisee) throw new DevelopmentError("Franchisee is null");
        return this._franchisee;
    }

    public get config(): FranchiseeConfiguration {
        this.checkLoaded();
        if (!this._franchiseeConfig) throw new DevelopmentError("Franchisee is null");
        return this._franchiseeConfig;
    }

    public get defaultBranch(): Branch | null {
        if (getCurrentUser()?.isSupplier) {
            return null;
        }
        this.checkLoaded();
        const branch = this._branches?.find(x => x.id == this._defaultBranchId) ?? null;
        if (!branch) throw new DevelopmentError("Default Branch is null");
        return branch;
    }

    public get quoteSupplierProvider(): string {
        //TODO - this will eventually become a system provided value based on licensing.
        //for now we will hard code it.
        // change to "v7" for testing v6 frame engine and pricing engine integrations
        return quoteSupplierProvider.v7;
    }

    updateFranchisee(franchisee: Franchisee) {
        this._franchisee = franchisee;
    }

    updateConfig(franchiseeConfiguration: FranchiseeConfiguration) {
        this._franchiseeConfig = franchiseeConfiguration;
    }

    updateBranch(branch: Branch) {
        this._branches = this._branches?.filter(x => x.id !== branch.id) ?? null;
        if (!this._branches) this._branches = [];
        this._branches.push(branch);
    }

    clear() {
        this.loaded = false;
        this._loadPromise = null;
        this._franchisee = null;
        this._branches = null;
        this._defaultBranchId = "";
        this._franchiseeConfig = null;
    }

    /**
     * forces the cache to refresh
     * @returns a fresh copy of the franchisee
     */
    async reLoadFranchisee(): NullPromise<Franchisee> {
        this.clear();
        await this.loadCoreDetails();
        return this.franchisee;
    }

    private checkLoaded() {
        if (!this.loaded)
            throw new Error("CurrentUserDataStore has not been loaded");
    }

    private async performLoadCoreDetails() {
        const user = getCurrentUser();
        if (!user) throw new DevelopmentError("Authentication could not be performed");
        if (!user.isSupplier) {
            const api = getApiFactory().franchisee();
            const result = await api.getActiveUserInformation({
                systemUserId: user.id
            });
            if (result) {
                this._franchisee = result.franchisee;
                this._branches = result.branches;
                this._franchiseeConfig = result.franchiseeConfiguration;

                if (this._branches && this._branches.length > 0) {
                    this._defaultBranchId = this._branches[0].id;
                }
            } else
                throw new Error("could not load user details");
        }

        this.loaded = true;
    }
    async loadCoreDetailsAfterLogin() {
        if (this.loaded) return;
        await this.performLoadCoreDetails();
    }
    async loadCoreDetails() {
        return await multiCastPromise(
            this._loadPromise,
            //we keep the promise unless clear is called. this is a run once multicast
            (p) => this._loadPromise = p !== null ? p : this._loadPromise,
            async () => {
                if (!getCurrentUser()) {
                    const result = await getApi().performAuthenticationProcess();
                    if (!result) {
                        window.location.href = "/";
                    }
                    if (getCurrentUser()?.isSupplier) {
                        if (!this.isSupplierEndpoint()) {
                            navigate('/supplier');
                        }
                      } else {
                        if (this.isSupplierEndpoint()) {
                            navigate('/quotes');
                        }
                      }
                    return;
                } else {
                    await this.performLoadCoreDetails();
                }
            }
        );
    }

    private isSupplierEndpoint() {
        const supplierEndpoints = ['/supplier', '/pricing', 'catalogues'];
        return supplierEndpoints.some(endpoint => window.location.href.endsWith(endpoint));
    }
}

export const userDataStore = new CurrentUserDataStore();
