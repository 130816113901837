import { EventBooleanAsync, EventVoidAsync } from "../../components/ui/events";
import { navigation } from "../../components/ui/navigation-path";
import { launchUI, navigate } from "../../components/ui/navigator";

export interface LaunchPurchaseOrderOptions {
    id?: string;
    data?: any;
    title?: string;
    navigateIfAllowed?: EventBooleanAsync;
    after?: EventVoidAsync;
}

export async function launchPurchaseOrder(options: LaunchPurchaseOrderOptions) {
    return await launchUI({
        code: navigation.purchaseOrder,
        id: options.id ?? "",
        data: options.data,
        eventInfo: { title: options.title ?? "" },
        onAllowNavigation: async () => {
            if (options.navigateIfAllowed === undefined) return true;
            //call the canClose event from the parent. and if this is true
            //we assume everything is saved, and we can navigate away with this link
            if ((await options.navigateIfAllowed())) {
                await navigate("/purchase-orders");
                return true;
            }
            return false;
        },
        onFinally: options.after
    });
}

