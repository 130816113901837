import { getApiFactory } from "../../../../api/api-injector";
import { showError } from "../../../../components/ui/show-error";
import { tlang } from "../../../../language/lang";
import { NullPromise } from "../../../../null-promise";
import { QuoteContainerManager } from "../../../../quotes/data/quote-container";
import { QuoteItemContainer } from "../../../../quotes/data/quote-item-container";
import { supplierQuoteItemContentType } from "../../../../quotes/data/supplier-quote-item-content-type";
import { QuoteDetailView, QuoteDetailViewOptions } from "../../../../quotes/views/quote-detail-view";
import { QuoteItemView } from "../../../../quotes/views/quote-item-view";
import { QuoteItemsView, QuoteItemsViewOptions } from "../../../../quotes/views/quote-items-view";
import { QuoteDataEntryView, QuoteViewChildFactory, QuoteViewChildFactoryImpl } from "../../../../quotes/views/quote-view";
import { quoteSupplierProvider } from "../../../../quotes/data/quoteSupplierProvider";
import { FranchiseeQuoteContainerManager } from "../../data/franchisee-quote-manager";
import { FranchiseeQuoteDetailView, QuoteReportType } from "../franchisee-quote-detail-view";
import { FranchiseeQuoteItemsViewV7 } from "./franchisee-quote-items-view-v7";
import { FranchiseeQuoteView, FranchiseeQuoteDEView } from "../franchisee-quote-view";
import { CustomOrStandardFrame, QuoteItemFrameViewForV7, V7ItemProviderContent } from "./v7-quote-item-frame-view";
import { Project } from "../../../../api/dealer-api-interface-project";
import { validId } from "../../../../components/ui/helper-functions";
import { QuoteState, Quote, QuoteItem, QuoteItemPrice, QuotePrice, QuoteItemProviderData } from "../../../../api/dealer-api-interface-quote";
import { userDataStore } from "../../../common/current-user-data-store";
import { QuoteReport, ReportQuoteType, ReportQuoteDetails, ReportQuoteItem, ReportItemGroup, ReportQuoteItemType, ReportQuotePriceDynamicSummary, FileResponse } from "../../../../api/dealer-api-interface-report";
import { emptyGuid } from "../../../../api/guid";
import { isShipping, isFrame, isFreeHand, getReportCompanyDetails, quoteStateToReportState, getReportProjectDetails } from "../../../Reporting/report-helpers";
import { base64ToObject } from "../../../../blob/converters";
import { V6QuoteItem } from "../../../../v6config/v6-quote-item/v6-quote-item-data";
import { frameOptionV6Template } from "../../../Reporting/v6/v6-report-helpers";
import { getQuoteNumberFormatted } from "../../../../quotes/data/quote-helper-functions";
import { exposedDataValue, ResultPutExposedDataClient } from "../../../../api/pricing-engine-api-interface";
import { ResultAttributeSummary } from "../../../../api/frame-engine-api-interface";


export class FranchiseeQuoteViewChildFactoryV7 extends QuoteViewChildFactoryImpl {
    protected get view(): FranchiseeQuoteDEViewV7 {
        return this.parent as FranchiseeQuoteDEViewV7;
    }
    getDetailView(options: QuoteDetailViewOptions): QuoteDetailView {
        return new FranchiseeQuoteDetailView({
            quoteManager: options.quoteManager,
            canGenerateReport: options.canGenerateReport,
            generateReportEvent: async (reportType, internal) => await (this.view as FranchiseeQuoteDEViewV7).generateReportGenerate(reportType, internal)
        });
    }
    getItemsView(options: QuoteItemsViewOptions): QuoteItemsView {
        return new FranchiseeQuoteItemsViewV7(options);
    }
}

export class FranchiseeQuoteDEViewV7 extends FranchiseeQuoteDEView {

    private readonly quoteSummaryDataKey = 'QuoteData';
    private readonly quoteConfiguredItemDataKey = 'ConfiguredItemData';
    private readonly quoteFreehandItemDataKey = 'FreehandItemData';
    private readonly quoteItemJoinKey = 'ProjectItemId';

    protected quoteContainerManagerFactory(): QuoteContainerManager {
        return new FranchiseeQuoteContainerManager(this.quoteContainer, this.quoteApi, this.blobApi, getApiFactory().franchisee());
    }

    protected quoteItemsViewFactory(): QuoteItemsView {
        return new FranchiseeQuoteItemsViewV7({
            quoteManager: this.quoteContainerManager,
            userProfileCache: this.userProfileCache,
            eventRunQuoteItemAction: async (quoteItemContainer: QuoteItemContainer, action: number) => await this.runQuoteItemAction(quoteItemContainer, action)
        });
    }


    protected getQuoteViewChildFactory(): QuoteViewChildFactory {
        return new FranchiseeQuoteViewChildFactoryV7(this);
    }

    protected hasSupplierGlassView(): boolean {
        return false;
    }

    protected async addNewQuoteItemFrame(): Promise<boolean> {
        this.closeQuoteItemPages();

        const providerType = await this.selectService();
        const quoteItemView = await this.quoteItemViewFactory(null, providerType, supplierQuoteItemContentType.CID_V7_FRAM);
        try {
            if (quoteItemView) {
                await quoteItemView.prepareEditor();
                if (quoteItemView.readyToEdit) {
                    await this.launchQuoteItemView(quoteItemView);
                    return true;
                }
                return false;
            }
        } catch (e) {
            await showError(e as Error);
            return false;
        }

        return false;
    }

    protected async quoteItemViewFactory(quoteItemContainer: QuoteItemContainer | null, providerType: string, contentType: number): NullPromise<QuoteItemView> {
        if (providerType === quoteSupplierProvider.v7) {
            switch (contentType) {
                case supplierQuoteItemContentType.CID_V7_FRAM:
                case supplierQuoteItemContentType.CID_FRAM:
                    return new QuoteItemFrameViewForV7(await this.getQuoteItemViewOptions(quoteItemContainer));

                case supplierQuoteItemContentType.CID_V7_CUSTOM_FRAM:
                    return new QuoteItemFrameViewForV7(await this.getQuoteItemViewOptions(quoteItemContainer), CustomOrStandardFrame.Custom);
                default:
                    alert(tlang`Content Type ${contentType} on Provider "${providerType}" is not supported`);
                    return null;
            }
        } else
            return super.quoteItemViewFactory(quoteItemContainer, providerType, contentType);
    }

    public async generateReportGenerate(reportType: QuoteReportType, internal = false): Promise<void> {
        let pdf: FileResponse | null;

        switch (reportType)
        {
            case QuoteReportType.QuoteReport:

                pdf = await this.GetQuoteReportPdf(internal)

                break;
            case QuoteReportType.CutPlanReport:

                pdf = await this.GetCutPlanReportPdf()

                break;
            case QuoteReportType.CutPlan2DReport:

                pdf = await this.GetCutPlan2DReportPdf()

                break;
            case QuoteReportType.CostedBomReport:

                pdf = await this.GetCostedBomReportPdf()

                break;
            case QuoteReportType.FabReport:

                pdf = await this.GetFabReportPdf()

                break;
            case QuoteReportType.QuoteReportV2:

                pdf = await this.GetQuoteReportV2Pdf()

                break;
            default:
                throw new Error('Report type unknown');
        }

        if (pdf != null) {
            const file = new Blob([pdf.data], { type: pdf.data.type });
            const fileUrl = URL.createObjectURL(file);
            window.open(fileUrl);
        }
    }

    private async GetCutPlanReportPdf(): Promise<FileResponse | null> {
        const pdf = await this.reportApi.getCutPlanReport({ quoteId:this.quote.id });

        return pdf
    }

    private async GetCutPlan2DReportPdf(): Promise<FileResponse | null> {
        const pdf = await this.reportApi.getCutPlan2DReport({ quoteId:this.quote.id });

        return pdf
    }

    private async GetCostedBomReportPdf(): Promise<FileResponse | null> {
        const pdf = await this.reportApi.getCostedBomReport({ quoteId:this.quote.id });

        return pdf
    }

    private async GetFabReportPdf(): Promise<FileResponse | null> {
        const pdf = await this.reportApi.getFabReport({ quoteId:this.quote.id });

        return pdf
    }

    private async GetQuoteReportV2Pdf(): Promise<FileResponse | null> {
        const pdf = await this.reportApi.getQuoteReportV2({ quoteId:this.quote.id });

        return pdf
    }

    private async GetQuoteReportPdf(internal: boolean): Promise<FileResponse | null> {
        const projectId = await this.projectId();
        let project: Project | undefined;

        if (validId(projectId)) {
            const projectResult = await this.projectApi.getProject({ projectId: projectId });

            if (projectResult) {
                project = projectResult.project;
            }
        }

        const createdUser = await this.userCache.get(this.quote.creationUserId);
        const client = await this.clientCache.get(this.branchQuote?.clientId ?? emptyGuid);
        const contact = await this.contactCache.get(this.branchQuote?.contactId ?? emptyGuid);
        await this.quoteContainerManager.needsQuoteItems(true);

        let issuedDate: string | undefined;
        const quoteAuditStateResults = await this.quoteApi.getQuoteStateAudit({
            quoteId: this.quote.id,
            state: QuoteState.Issued
        });

        if (quoteAuditStateResults) {
            issuedDate = quoteAuditStateResults.quoteStateAudit.sort((a, b) => {
                return a.dateModified.localeCompare(b.dateModified, "en", { sensitivity: "base" });
            })[0]?.dateModified;
        }

        const company = userDataStore.franchisee;

        const priceId = this.quote.priceId;
        const pricing = await this.pricingEngineApi.getPriceExposedData(priceId,
            [
                this.quoteSummaryDataKey,
                this.quoteConfiguredItemDataKey,
                this.quoteFreehandItemDataKey
            ]);

        const reportRequest: QuoteReport = {
            projectDetails: getReportProjectDetails(project),
            quoteDetails: {
                ...await this.getReportQuoteDetails(this.quote, this.quoteContainer.quotePrice, this.quoteContainer.items,
                    this.quoteContainer.itemPrices, this.quoteContainer.itemsData, pricing),
                ...{
                    issuedBy: createdUser?.displayValue ?? null,
                    issuePersonEmail: createdUser?.data.emailAddress ?? null,
                    clientName: client?.displayValue ?? "",
                    clientContactName: contact?.displayValue ?? "",
                    issuedDate: issuedDate ?? null
                }
            },
            companyDetails: getReportCompanyDetails(this.franchiseeApi.api.fullUrl(`api/file/${company?.negativeLogoVirtualPath}`)),
        };

        const pdf = await this.reportApi.getQuoteReport({ quoteReportData: reportRequest, internalReport: internal });

        return pdf
    }

    private async getReportQuoteDetails(quote: Quote, quotePrice: QuotePrice | null, items: QuoteItem[] | null,
        itemPrices: QuoteItemPrice[] | null, itemProviderData: QuoteItemProviderData[] | null, priceOverride: ResultPutExposedDataClient | null):
        Promise<ReportQuoteDetails> {

        const combinedItems: { [id: string]: string[]; } = {};
        let dynamicPriceColumnHeaders: string[] = [];
        if (priceOverride != null) {
            const [newColumns, flatValues] = this.getMergedItemTypes([
                priceOverride[this.quoteFreehandItemDataKey],
                priceOverride[this.quoteConfiguredItemDataKey]]
            );
            dynamicPriceColumnHeaders = newColumns;

            const itemKeyIndex = newColumns.indexOf(this.quoteItemJoinKey);
            newColumns.splice(itemKeyIndex, 1);
            for (const value of flatValues)
            {
                combinedItems[value[itemKeyIndex]] = value;
                value.splice(itemKeyIndex, 1);
            }
        }


        const reportItems = items ? await Promise.all(items.map(async (item) => {
            let thumbnail: string | undefined;
            let itemType: ReportQuoteItemType | undefined;
            let configurationId: string | undefined;

            if (isShipping(item)) {
                thumbnail = this.franchiseeApi.getFranchiseeImagePath("delivery", "svg");
                itemType = ReportQuoteItemType.Shipping;
            } else if (isFreeHand(item)) {
                thumbnail = this.franchiseeApi.getFranchiseeImagePath("freehand", "svg");
                itemType = ReportQuoteItemType.Freehand;
            } else if (isFrame(item)) {
                if (item.serviceProvider === quoteSupplierProvider.v6) {
                    thumbnail = this.quoteApi.api.fullUrl(`api/file/${item.virtualThumbnailPath}`);
                }

                const itemData = itemProviderData?.find(x => x.id == item.id);
                try{
                    const decodedProviderData = base64ToObject<V7ItemProviderContent>(itemData?.providerData);
                    if (decodedProviderData && decodedProviderData.configurationId) {
                        configurationId = decodedProviderData.configurationId.toString();
                    }
                } catch {
                    // the base64ToObject() can fail for quotes that just have the raw config id in providerData
                    // so we catch and absorb the exception to allow below code to have a go.
                    //NB this should not actually be a problem for prod. Just for testing site before 2022-08-15
                    console.error('Failed to get configId')
                }
                itemType = ReportQuoteItemType.ProviderContent;
            }

            const price = itemPrices?.find(price => price.id == item.id);

            let dynamicPriceValues: string[] = [];
            if (priceOverride !== null) {
                dynamicPriceValues = combinedItems[item.id];
            }


            let options: ReportItemGroup | undefined;
            if (item.serviceProvider === quoteSupplierProvider.v6) {
                const itemData = itemProviderData?.find(x => x.id == item.id);

                if (itemData) {
                    const v6QuoteItem = (base64ToObject(itemData.providerData)) as V6QuoteItem;
                    options = frameOptionV6Template(v6QuoteItem);
                }
            }
            if (item.serviceProvider === quoteSupplierProvider.v7 && configurationId){
                const summary = await this.frameEngineApi.getConfigurationAttributeSummary(configurationId);
                if (summary !== null) {
                    options = this.attributeSummaryToOptions(summary);
                }
            }

            const quoteMargin = quotePrice?.marginPercentage ?? null;

            return {
                title: item.title, description: item.description,
                imageUrl: thumbnail ?? "",
                quantity: item.quantity,
                options: options,
                price: price?.calculatedGrossSellingPrice ?? 0,
                optionTree: "",
                quoteItemType: itemType ?? ReportQuoteItemType.ProviderContent,
                contentType: null,
                itemNumber: null,
                cost: price?.singleUnitCost ?? null,
                priceAdjustment: price?.priceAdjustment ?? null,
                margin: price?.marginPercentage ?? quoteMargin,
                imageData: null,
                configurationId: configurationId ?? null,
                dynamicPriceValues: dynamicPriceValues,
            } as ReportQuoteItem;
        })) : [];

        const dynamicPriceSummary: ReportQuotePriceDynamicSummary[] = [];
        if (priceOverride !== null) {
            const priceInfo = priceOverride[this.quoteSummaryDataKey][0];
            const labels = priceInfo.columns;
            const values = priceInfo.rows[0];

            for (let i = 0; i < labels.length; i++) {
                dynamicPriceSummary.push({ name: labels[i], value: values[i] });
            }
        }


        return {
            title: quote.title,
            number: getQuoteNumberFormatted(quote),
            description: quote.description,
            deliveryDate: quote.installationDate,
            installDate: quote.installationDate,
            validDate: quote.validUntilDate,
            termsAndConditions: quote.termsAndConditions ?? "",
            priceSummary: {
                priceAdjustment: quotePrice?.priceAdjustment ?? 0,
                tax: quotePrice?.calculatedTaxAmount ?? 0,
                total: quotePrice?.calculatedGrossTotal ?? 0,
                subTotal: quotePrice?.calculatedNetTotal ?? 0
            },
            dynamicPriceSummary: dynamicPriceSummary,
            quoteItemPriceColumnHeaders: dynamicPriceColumnHeaders,
            state: quoteStateToReportState(quote.state),
            clientContactName: "",
            items: reportItems ?? [],
            clientName: "",
            issuedBy: null,
            issuedDate: null,
            type: ReportQuoteType.None,
            issuePersonEmail: null,
            issuePersonMobile: null
        };
    }

    private attributeSummaryToOptions(summary: ResultAttributeSummary): ReportItemGroup {
        const result: ReportItemGroup = {
            description: `Frame: ${summary.frameName}`,
            propertyGroups: [{
                description: 'Summary',
                properties: summary.attributes.map(a => { return { label: a.name, value: a.value } })
            }],
            nestedItemGroups: summary.nestedSummarys.map(nested => this.attributeSummaryToOptions(nested))
        };
        return result;
    }

    private getMergedItemTypes(items: exposedDataValue[][]): [string[], string[][]]
    {
        const allColumns: string[] = [];

        for (const itemSet of items) {
            for (const column of itemSet[0].columns) {
                if (!allColumns.includes(column)) {
                    allColumns.push(column);
                }
            }
        }

        const flattenedValues: string[][] = [];

        for (const itemSet of items) {
            for (const item of itemSet) {
                const mapping: number[] = [];
                for (const column of allColumns)
                {
                    mapping.push(item.columns.indexOf(column));
                }

                for (const values of item.rows)
                {
                    const remappedValues: string[] = [];
                    for (let index = 0; index < mapping.length; index++) {
                        if (mapping[index] >= 0 && mapping[index] < values.length)
                        {
                            remappedValues.push(values[mapping[index]])
                        } else {
                            remappedValues.push('--')
                        }
                    }
                    flattenedValues.push(remappedValues);
                }

            }
        }

        return [allColumns, flattenedValues];
    }
}

export class FranchiseeQuoteViewV7 extends FranchiseeQuoteView {
    protected createView(): QuoteDataEntryView {
        return new FranchiseeQuoteDEViewV7(this.options, this);
    }
}
